import {
  MIGRATE_CONTENT,
  RESET_MIGRATE_CONTENT,
} from '~/constants/ActionTypes';
import { onMigrate } from '@package/helpers/MigrateContent/MigrateContent';

export function onMigrateBlock(formData, blocks) {
  const newFormData = onMigrate(formData, blocks);
  return {
    type: MIGRATE_CONTENT,
    newFormData,
  };
}

export function resetMigrateBlock() {
  return {
    type: RESET_MIGRATE_CONTENT,
  };
}
