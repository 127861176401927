/**
 * Toolbar reducer.
 * @module reducers/toolbar/toolbar
 */

import { CAN_BLOCK_PREVIEW } from '~/constants/ActionTypes';

const initialState = {
  canPreview: false,
};

/**
 * Toolbar reducer.
 * @function toolbar
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function canBlockPreview(state = initialState, action = {}) {
  switch (action.type) {
    case CAN_BLOCK_PREVIEW:
      return {
        canPreview: action.canPreview,
      };
    default:
      return state;
  }
}
