import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cookie from 'react-cookie';
import { CookieSetting } from '../../constants/Setting';
import { connect } from 'react-redux';
import { displayWelcome } from '../../actions';
import { Cookies } from 'react-cookie';

/**
 * ページ遷移したときに実行されるアクション
 *
 * @class PageMove
 * @extends {Component}
 */
class PageMove extends React.Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
      hash: PropTypes.string,
      search: PropTypes.string,
    }).isRequired,
    children: PropTypes.node.isRequired,
  };

  /**
   * @param {*} prevProps Previous Props
   * @returns {null} Null
   * @memberof PageMove
   */
  componentDidUpdate(prevProps) {
    // console.log('------------------------------- location');
    // console.log(this.props.location);
    // console.log(prevProps.location);
    if (this.props.location !== prevProps.location) {
      new Cookies().set(
        CookieSetting.WELCOME_VISIBLE.name,
        CookieSetting.WELCOME_VISIBLE.off,
        CookieSetting.WELCOME_VISIBLE.option,
      );
      displayWelcome(CookieSetting.WELCOME_VISIBLE.off);
    }
  }

  /**
   * @returns {node} Children
   * @memberof PageMove
   */
  render() {
    return this.props.children;
  }
}

const PageMoveContainer = connect(
  (state, props) => {
    return {};
  },
  (dispatch, props) => {
    return {
      displayWelcome: (visible) => dispatch(displayWelcome(visible)),
    };
  },
)(PageMove);

export default withRouter(PageMoveContainer);
