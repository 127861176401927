/**
 * SourceDIsplayWidget component.
 * @module components/manage/Widgets/SourceDisplayWidget
 */

import React from 'react';
import PropTypes from 'prop-types';
import { TextArea } from 'semantic-ui-react';

import { injectIntl } from 'react-intl';
import { FormFieldWrapper } from '@plone/volto/components';

/**
 * SourceDisplayWidget component function.
 * @function SourceDisplayWidget
 * @returns {string} Markup of the component.
 */
const SourceDisplayWidget = (props) => {
  const { id, value, placeholder } = props;

  return (
    <>
      {/* {console.log(value)} */}
      <FormFieldWrapper {...props} className="textarea">
        <TextArea
          id={`field-${id}`}
          name={id}
          value={value?.data || ''}
          disabled="disabled"
          placeholder={placeholder}
          // onChange={({ target }) =>
          //   onhandleChange(id, target.value === '' ? undefined : target.value)
          // }
        />
        {/* {lengthError.length > 0 && (
        <Label key={lengthError} basic color="red" pointing>
          {lengthError}
        </Label>
      )} */}
      </FormFieldWrapper>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SourceDisplayWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  // maxLength: PropTypes.number,
  // required: PropTypes.bool,
  // error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  // onChange: PropTypes.func,
  // onEdit: PropTypes.func,
  // onDelete: PropTypes.func,
  wrapped: PropTypes.bool,
  placeholder: PropTypes.string,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
SourceDisplayWidget.defaultProps = {
  description: null,
  // maxLength: null,
  // required: false,
  // error: [],
  value: null,
  // onChange: null,
  // onEdit: null,
  // onDelete: null,
};

export default injectIntl(SourceDisplayWidget);
