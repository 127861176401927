/**
 * OU Now view block.
 * @module components/manage/Blocks/OUNow/View
 */

import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import NoSSR from 'react-no-ssr';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import redraft from 'redraft';
import Slider from 'react-slick';
import { flattenToAppURL } from '@plone/volto/helpers';
import { LRFBlockHeader } from '~/components';
import { isShowBlockByTargets } from '~/helpers/Targets/Targets';
import { getUserType } from '~/helpers/UserType/UserType';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './View.css';

/**
 * OUNow view block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    adaptiveHeight: true,
    variableWidth: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 1,
    responsive: [
      {
        breakpoint: 1310,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };
  const content = data.ou_now;
  const headerText = (header) => {
    return redraft(header, settings.ToHTMLRenderers, settings.ToHTMLOptions);
  };
  // const sliderRef = useFixSlider();
  const [showBlock, setShowBlock] = useState(false);
  useEffect(() => {
    const trackElem = document
      .getElementsByClassName('OUNow')?.[0]
      ?.getElementsByClassName('slick-track')?.[0];
    if (trackElem) {
      const obserber = new MutationObserver((records) => {
        // console.log({ records });
        const items = trackElem.getElementsByClassName('slick-slide');
        const trackWidth = 361 * items.length;
        // const listWidth = window.innerWidth - 204;
        // const listPOS = Math.abs(
        //   trackElem.style.transform
        //     .replace('translate3d(', '')
        //     .split('px', 1)[0],
        // );
        // const hiddenTrackWidthRight = trackWidth - listWidth - listPOS;
        // console.log({ listWidth });
        // console.log({ trackWidth });
        // console.log({ listPOS });
        // console.log({ hiddenTrackWidthRight });

        trackElem.style.width = `${trackWidth}px`;
        // if (hiddenTrackWidthRight > 0 && hiddenTrackWidthRight < 337) {
        //   const newPOS = -(listPOS + hiddenTrackWidthRight);
        //   // console.log({ newPOS });
        //   trackElem.style.transform = `translate3d(${newPOS}px, 0px, 0px)`;
        // }
      });
      obserber.observe(trackElem, {
        attributes: true,
        attributeFilter: ['style'],
      });
    }
  });

  useEffect(() => {
    const isShowBlock = isShowBlockByTargets(content.targets, getUserType());
    if (isShowBlock !== showBlock) {
      setShowBlock(isShowBlock);
    }
  }, [content.targets, setShowBlock, showBlock]);
  return showBlock ? (
    <div className="OUNow">
      <NoSSR>
        <LRFBlockHeader title={data.ou_now?.title} />
        <div className="contents">
          <Slider {...settings}>
            {map(data.ou_now.items, (element) => {
              return (
                <div key={element.key}>
                  <div className="content">
                    {element.image && element.image.length ? (
                      <img
                        src={`${flattenToAppURL(
                          element.image?.[0]['@id'],
                        )}/@@images/image`}
                        alt={`${element.image?.[0].title}`}
                      />
                    ) : null}
                    <h3>
                      {element?.link &&
                      element.link.length &&
                      element.link[0]['@id'] ? (
                        element.link[0]['@id'].startsWith('http://') ||
                        element.link[0]['@id'].startsWith('https://') ? (
                          <a
                            href={element.link[0]['@id']}
                            target="_blank"
                            rel="noreferrer"
                            className="external-link"
                          >
                            {headerText(element.header)}
                          </a>
                        ) : (
                          <Link
                            to={`${flattenToAppURL(element.link[0]['@id'])}`}
                          >
                            {headerText(element.header)}
                          </Link>
                        )
                      ) : (
                        headerText(element.header)
                      )}
                    </h3>
                    <p style={{ marginBottom: '1rem' }}>
                      {redraft(
                        element.text,
                        settings.ToHTMLRenderers,
                        settings.ToHTMLOptions,
                      )}
                    </p>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </NoSSR>
    </div>
  ) : null;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
