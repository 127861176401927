/**
 * ENVより情報を取得
 *
 * @param {*} key
 * @returns
 */
export function getEnvValue(key = '') {
  if (!__SERVER__) {
    return window?.env[key];
  } else {
    return process?.env[key];
  }
}
