/**
 * View youtube block.
 * @module components/manage/Blocks/Video/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Embed } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import NoSSR from 'react-no-ssr';
import YT from 'react-youtube';
import cx from 'classnames';
import {
  isInternalURL,
  getParentUrl,
  flattenToAppURL,
} from '@plone/volto/helpers';
import config from '@plone/volto/registry';
// import { settings } from '~/config';
import { MediaQueryConfig } from '~/helpers/GlobalStyle/GlobalStyle';
import { isSupportedLanguageForYouTube } from '@package/helpers/Locale/Locale';
import './View.css';

/**
 * View youtube block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  const id = data.url.match(/.be\//)
    ? data.url.match(/^.*\.be\/(.*)/)[1]
    : data.url.match(/^.*\?v=(.*)$/)[1];
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  const opts = {
    // width: String(width),
    // height: String(height),
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
      disablekb: 0,
      fs: 0,
      iv_load_policy: 3,
      modestbranding: 1,
      playsinline: 1,
      rel: 0,
      showinfo: 0,
      start: 3,
      loop: 1,
    },
  };
  const spOpts = {
    width: 767,
    height: 431,
  };
  const onPlayerReady = (event) => {
    // プレビューをする際はアンコメントして再生をする
    // if (!isDev) {
    event.target.mute();
    event.target.playVideo();
    // }
  };

  const onPlayerStateChange = (event) => {
    // プレビューをする際はアンコメントして再生をする
    // if (!isDev) {
    const yt = YT;
    // console.log({ YouTube_State: event.data })
    if (event.data === yt.PlayerState.ENDED) {
      // console.log('Loop!')
      event.target.playVideo();
    }
    // }
  };

  const isYoutube = isSupportedLanguageForYouTube();

  return (
    <div
      className={cx(
        'Youtube block video align',
        {
          center: !Boolean(data.align),
        },
        data.align,
      )}
    >
      {data.url && (
        <div
          className={cx('video-inner', {
            'full-width': data.align === 'full',
          })}
        >
          {data.url.match('youtu') ? (
            <>
              {data.url.match('list') ? (
                data.preview_image ? (
                  <Embed
                    url={`https://www.youtube.com/embed/videoseries?list=${
                      data.url.match(/^.*\?list=(.*)$/)[1]
                    }`}
                    placeholder={
                      isInternalURL(data.preview_image)
                        ? `${flattenToAppURL(
                            data.preview_image,
                          )}/@@images/image`
                        : data.preview_image
                    }
                    defaultActive
                    autoplay={false}
                  />
                ) : (
                  <Embed
                    url={`https://www.youtube.com/embed/videoseries?list=${
                      data.url.match(/^.*\?list=(.*)$/)[1]
                    }`}
                    icon="play"
                    defaultActive
                    autoplay={false}
                  />
                )
              ) : (
                <>
                  {isYoutube ? (
                    <NoSSR>
                      <YT
                        className="youtube"
                        // videoId={props.src}
                        videoId={id}
                        opts={isSP ? { ...opts, ...spOpts } : opts}
                        onReady={onPlayerReady}
                        onStateChange={onPlayerStateChange}
                      ></YT>
                    </NoSSR>
                  ) : Boolean(data.preview_image) === true ? (
                    isInternalURL(data.preview_image) === true ? (
                      <img
                        src={`${flattenToAppURL(
                          data.preview_image,
                        )}/@@images/image`}
                        alt=""
                      />
                    ) : (
                      <img src={data.preview_image} alt="" />
                    )
                  ) : null}
                </>
              )}
            </>
          ) : (
            <>
              {data.url.match('vimeo') ? (
                data.preview_image ? (
                  <Embed
                    id={data.url.match(/^.*\.com\/(.*)/)[1]}
                    source="vimeo"
                    placeholder={
                      isInternalURL(data.preview_image)
                        ? `${flattenToAppURL(
                            data.preview_image,
                          )}/@@images/image`
                        : data.preview_image
                    }
                    icon="play"
                    autoplay={false}
                  />
                ) : (
                  <Embed
                    id={data.url.match(/^.*\.com\/(.*)/)[1]}
                    source="vimeo"
                    icon="play"
                    defaultActive
                    autoplay={false}
                  />
                )
              ) : (
                <>
                  {data.url.match('.mp4') ? (
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video
                      src={
                        isInternalURL(
                          data.url.replace(
                            getParentUrl(config.settings.apiPath),
                            '',
                          ),
                        )
                          ? `${data.url}/@@download/file`
                          : data.url
                      }
                      controls
                      poster={
                        data.preview_image
                          ? isInternalURL(data.preview_image)
                            ? `${flattenToAppURL(
                                data.preview_image,
                              )}/@@images/image`
                            : data.preview_image
                          : ''
                      }
                      type="video/mp4"
                    />
                  ) : (
                    <div className="invalidVideoFormat" />
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
