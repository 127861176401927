import { FOR_YOU_BUTTON } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  result: {},
};

/**
 * For You Button Reducer
 * @param {*} state
 * @param {*} action
 */
export default function forYouButton(state = initialState, action = {}) {
  switch (action.type) {
    case `${FOR_YOU_BUTTON}_PENDING`:
      return {
        ...state,
        error: null, // state.error = null
        loaded: false, // state.loaded = false
        loading: true, // state.loading = true
      };
    case `${FOR_YOU_BUTTON}_SUCCESS`:
      return {
        ...state,
        error: null,
        result: action?.result,
        loaded: true,
        loading: false,
      };
    case `${FOR_YOU_BUTTON}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
