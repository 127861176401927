/**
 * Edit text cell block.
 * @module components/manage/Blocks/Title/Cell
 * based on v13.8.2
 * @nk24:CMScom 2021/12/16
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Editor from 'draft-js-plugins-editor';
import { convertFromRaw, EditorState, RichUtils } from 'draft-js';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';
import { includes } from 'lodash';
/* @nk24 begin */
import { updateToolbarPOS } from '~/helpers/Toolbar/Toolbar';
/* @nk24 end */
import config from '@plone/volto/registry';

import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import loadable from '@loadable/component';

/* @nk24 begin 2023/04/05 */
import {
  inlineToolbarBlockButtons,
  inlineToolbarButtons,
} from '@package/constants/RichTextEditor';
/* @nk24 end 2023/04/05 */

const Editor = loadable(() => import('draft-js-plugins-editor'));

/**
 * Edit text cell class.
 * @class Cell
 * @extends Component
 */
class CellComponent extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    onSelectCell: PropTypes.func.isRequired,
    row: PropTypes.number,
    cell: PropTypes.number,
    value: PropTypes.object,
    selected: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    isTableBlockSelected: PropTypes.bool,
    disableNewBlocks: PropTypes.bool,
    editable: PropTypes.bool,
    block: PropTypes.str,
    setCellToolbarPOS: PropTypes.func,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    detached: false,
    editable: true,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Cell
   */
  constructor(props) {
    super(props);

    const { EditorState, convertFromRaw } = props.draftJs;
    const createInlineToolbarPlugin = props.draftJsInlineToolbarPlugin.default;

    if (!__SERVER__) {
      this.draftConfig = config.settings.richtextEditorSettings(props);
      let editorState;
      editorState = EditorState.createWithContent(convertFromRaw(props.value));

      const inlineToolbarPlugin = createInlineToolbarPlugin({
        /* @nk24 begin 2023/04/05 */
        // structure: this.draftConfig.richTextEditorInlineToolbarButtons,
        structure: [
          ...this.draftConfig.richTextEditorInlineToolbarButtons.slice(0, 3),
          ...inlineToolbarButtons,
          ...this.draftConfig.richTextEditorInlineToolbarButtons.slice(3, 10),
          ...inlineToolbarBlockButtons,
        ],
        /* @nk24 end 2023/04/05 */
      });

      this.state = {
        editorState,
        inlineToolbarPlugin,
      };
    }

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Component did mount lifecycle method
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    const blockNode = document.querySelector(
      `[data-rbd-draggable-id="${this.props.block}"]`,
    );
    if (this.node) {
      const onFocus = this.node.editor._onFocus;
      this.node.editor._onFocus = (event) => {
        onFocus(event);
        this.props.onSelectCell(this.props.row, this.props.cell);
        /* @nk24 begin@2023/09/29 */
        const focusedCellOffsetTop =
          event.target.parentElement.parentElement.offsetTop;
        const cellToolbarPOS = focusedCellOffsetTop - 46;
        this.props.setCellToolbarPOS(cellToolbarPOS);
        /* @nk24 end */
      };
    }

    /* @nk24 begin */
    const toolbarObserver = new MutationObserver(updateToolbarPOS);
    const cellNode = blockNode
      ? blockNode.querySelector(
          `[data-table-cell-id="${this.props.row}-${this.props.cell}"]`,
        )
      : null;
    const toolbarNode = cellNode
      ? cellNode.querySelector('.draftJsToolbar__toolbar__dNtBH')
      : null;
    if (toolbarNode) {
      const obsConf = { attributes: true };
      toolbarObserver.observe(toolbarNode, obsConf);
    }
    /* @nk24 end */
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  /* @nk24 begin https://cmscom.backlog.jp/view/OU-1158 on 2023/09/05 */
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (
  //     nextProps.isTableBlockSelected !== this.props.isTableBlockSelected &&
  //     this.props.cell === 0 &&
  //     this.props.row === 0
  //   ) {
  //     this.node.focus();
  //   }
  // }
  /* @nk24 end*/

  /**
   * Change handler
   * @method onChange
   * @param {object} editorState Editor state.
   * @returns {undefined}
   */
  onChange(editorState) {
    this.setState({ editorState }, () => {
      this.props.onChange(this.props.row, this.props.cell, editorState);
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    const { InlineToolbar } = this.state.inlineToolbarPlugin;
    // @manaka begin
    //const { settings } = config;
    // @manaka end
    const isSoftNewlineEvent = this.props.draftJsLibIsSoftNewlineEvent.default;
    const { RichUtils } = this.props.draftJs;

    return (
      <div data-table-cell-id={[`${this.props.row}-${this.props.cell}`]}>
        {/* add above data attr @nk24@20230831 */}
        <Editor
          readOnly={!this.props.editable}
          onChange={this.onChange}
          onFocus={this.onFocus}
          editorState={this.state.editorState}
          plugins={[
            this.state.inlineToolbarPlugin,
            ...this.draftConfig.richTextEditorPlugins,
          ]}
          /* @nk24 begin 2023/04/05 */
          // blockRenderMap={this.draftConfig.extendedBlockRenderMap}
          blockRenderMap={{
            ...this.draftConfig.extendedBlockRenderMap,
            ...config.settings.richtextViewSettings.ToHTMLRenderers,
          }}
          // @nk24 begin
          // blockStyleFn={this.draftConfig.blockStyleFn}
          // blockStyleFn={this.draftConfig.textBlockStyleFn}
          blockStyleFn={config.settings.textBlockStyleFn}
          // @nk24 end
          // customStyleMap={this.draftConfig.customStyleMap}
          customStyleMap={{
            ...this.draftConfig.customStyleMap,
            ...config.settings.customStyleMap,
          }}
          /* @nk24 end 2023/04/05 */
          handleReturn={(e) => {
            if (isSoftNewlineEvent(e)) {
              this.onChange(
                RichUtils.insertSoftNewline(this.state.editorState),
              );
              return 'handled';
            }
            if (!this.props.detached && !this.props.disableNewBlocks) {
              const selectionState = this.state.editorState.getSelection();
              const anchorKey = selectionState.getAnchorKey();
              const currentContent = this.state.editorState.getCurrentContent();
              const currentContentBlock = currentContent.getBlockForKey(
                anchorKey,
              );
              const blockType = currentContentBlock.getType();
              if (!includes(this.draftConfig.listBlockTypes, blockType)) {
                this.props.onSelectBlock(
                  this.props.onAddBlock(
                    config.settings.defaultBlockType,
                    this.props.index + 1,
                  ),
                );
                return 'handled';
              }
              return 'un-handled';
            }
            return {};
          }}
          ref={(node) => {
            this.node = node;
          }}
        />
        {/* @nk24 begin */}
        {/* <InlineToolbar /> */}
        <InlineToolbar
          ref={(node) => {
            this.toolbarNode = node;
          }}
        />
        {/* @nk24 end */}
      </div>
    );
  }
}

export const Cell = injectLazyLibs([
  'draftJs',
  'draftJsBlockBreakoutPlugin',
  'draftJsCreateBlockStyleButton',
  'draftJsCreateInlineStyleButton',
  'draftJsFilters',
  'draftJsImportHtml',
  'draftJsInlineToolbarPlugin',
  'draftJsLibIsSoftNewlineEvent',
  'immutableLib',
])(CellComponent);

const Preloader = (props) => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    Editor.load().then(() => setLoaded(true));
  }, []);
  return loaded ? <Cell {...props} /> : null;
};

export default Preloader;
