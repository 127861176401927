/**
 * BlackBox component.
 * @module components/theme/Element/BlackBox/BlackBox
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import './BlackBox.css';

/**
 * Component to display the BlackBox
 * @function BlackBox
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const BlackBox = ({ title }) => {
  return (
    <div className="BlackBox">
      <div className="blackBox">{title}</div>
    </div>
  );
};

export default injectIntl(BlackBox);
