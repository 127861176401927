/**
 * Migrated content page view.
 * @module components/theme/View/OuMigratePageView
 * @nk24:CMScom 2021/05/18
 */
import React, { useEffect, useState } from 'react';
import { find, last } from 'lodash';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { Portal } from 'react-portal';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { previewBlock as setPreviewBlock } from '~/actions';
import { Link } from 'react-router-dom';
// import showBlocksSVG from '@plone/volto/icons/show-blocks.svg';
import { Icon as VoltoIcon } from '@plone/volto/components';
import { Helmet, flattenToAppURL } from '@plone/volto/helpers';
import hideSVG from '@plone/volto/icons/hide.svg';
import showSVG from '@plone/volto/icons/show.svg';
// import { hasBlocksData } from '@plone/volto/helpers';
import {
  BlackBox,
  HelmetSite,
  OuBody,
  OuHTMLBody,
  PageMainImage,
  PageTitle,
  SNSShare,
  VisualClear,
} from '../../index';
import './OuPageView.css';
import './OuMigratePageView.css';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  previewMessageTitle: {
    id: 'This is a preview of Block display mode.',
    defaultMessage: 'This is a preview of Block display mode.',
  },
  previewMessageHelp: {
    id:
      'To publish in Block display mode, please check the "Block display mode" checkbox in the edit screen and save the file.',
    defaultMessage:
      'To publish in Block display mode, please check the "Block display mode" checkbox in the edit screen and save the file.',
  },
});

/**
 * Component to display the default view.
 * @function DefaultView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OuMigratePageView = ({
  content,
  intl,
  // canBlockPreview,
  // previewBlock,
  // setPreviewBlock,
}) => {
  // const [content, previewBlock] = useSelector((state) => {
  //   return [state?.content?.data, state?.previewBlock?.preview];
  // });
  const [previewBlock, actions, contentType, pathname] = useSelector(
    (state) => {
      return [
        state?.previewBlock?.preview,
        state?.actions?.actions,
        state?.content?.data?.['@type'],
        state?.router?.location?.pathname,
      ];
    },
  );

  const isImportedPageType = contentType === 'OuImportedPage';
  const hasEditAction = find(actions.object, { id: 'edit' }); // ログインユーザがEdit可能かどうか
  const isNotEditView = last(pathname.split('/')) !== 'edit'; // EditViewかどうか
  // const isPrivateState = review_state === 'private'; // review_stateがprivateかどうか
  const showButton = isImportedPageType && hasEditAction && isNotEditView;

  // const [canBlockPreviewState, setCanBlockPreviewState] = useState(false);
  const [previewBlockState, setPreviewBlockState] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (previewBlockState === null) {
      // 初期化
      dispatch(setPreviewBlock(false));
      setPreviewBlockState(false);
    } else if (previewBlockState !== previewBlock) {
      setPreviewBlockState(previewBlock);
    }
  }, [dispatch, setPreviewBlock, previewBlock, previewBlockState]);

  // useEffect(() => {
  //   if (hasBlocksData(content) && !content.show_block_view) {
  //     if (!canBlockPreviewState) {
  //       setCanBlockPreviewState(true);
  //       canBlockPreview(true);
  //     }
  //   } else {
  //     if (canBlockPreviewState) {
  //       setCanBlockPreviewState(false);
  //       canBlockPreview(false);
  //     }
  //   }
  // }, [
  //   canBlockPreviewState,
  //   content,
  //   canBlockPreview,
  //   previewBlock,
  //   setPreviewBlock,
  // ]);
  //   console.log({ content });

  return (
    <div className="OuMigratePageView">
      <div className="OuPageView">
        <HelmetSite content={content} />

        <section className="mainArea">
          <div className="mainAreaInner">
            <div className="mainAreaInnerInner">
              {previewBlock && (
                <div class="ui info icon message">
                  <i class="eye icon"></i>
                  <div class="content">
                    <div class="header">
                      {intl.formatMessage(messages.previewMessageTitle)}
                    </div>
                    <p>{intl.formatMessage(messages.previewMessageHelp)}</p>
                  </div>
                </div>
              )}

              <div className="blackBoxWrapper">
                <Link to={flattenToAppURL(content?.parent['@id'])}>
                  <BlackBox title={content?.parent?.title} />
                </Link>
              </div>

              <PageMainImage content={content} />
              <div className="bodyArea">
                <PageTitle title={content.title} />
                {/* {hasBlocksData(content) && */}
                {/* (content.select_view_type || previewBlock) ? ( */}
                {content.show_block_view || previewBlock ? (
                  <OuBody content={content} />
                ) : content.html_code ? (
                  <OuHTMLBody html={content.html_code} />
                ) : null}
                <VisualClear />
                <SNSShare url={content['@id']} />
              </div>
            </div>
          </div>
        </section>
      </div>
      {showButton ? (
        <Portal
          node={__CLIENT__ && document.getElementById('toolbar-additional')}
        >
          {content.show_block_view ? (
            <button
              className="toggle-preview-blocks"
              // aria-label={intl.formatMessage(messages.toggle_preview_blocks)}
              disabled
              tabIndex={0}
              id="disabled-preview-blocks"
            >
              <VoltoIcon name={showSVG} size="30px" color="#CCCCCC" />
            </button>
          ) : previewBlockState ? (
            <button
              className="toggle-preview-blocks"
              // aria-label={intl.formatMessage(messages.toggle_preview_blocks)}
              onClick={(e) => dispatch(setPreviewBlock(false))}
              tabIndex={0}
              id="hide-preview-blocks"
            >
              <VoltoIcon name={hideSVG} size="30px" />
            </button>
          ) : (
            <button
              className="toggle-preview-blocks"
              // aria-label={intl.formatMessage(messages.toggle_preview_blocks)}
              onClick={(e) => dispatch(setPreviewBlock(true))}
              tabIndex={0}
              id="toggle-preview-blocks"
            >
              <VoltoIcon name={showSVG} size="30px" />
            </button>
          )}
        </Portal>
      ) : null}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuMigratePageView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
  // previewBlock: PropTypes.bool,
  // canBlockPreview: PropTypes.func,
};

export default injectIntl(OuMigratePageView);

// export default compose(
//   injectIntl,
//   connect(
//     (state, props) => ({
//       content: state.content.data,
//       previewBlock: state.previewBlock?.preview,
//     }),
//     { canBlockPreview, setPreviewBlock: previewBlock },
//   ),
// )(OuMigratePageView);
