/**
 * Migrate content helpers functions
 * @module helpers/MigrateContent/MigrateContent
 * @nk24:CMScom 2021/08/25
 */

import { find, findKey, last } from 'lodash';
import {
  changeBlock,
  deleteBlock,
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  previousBlockId,
} from '@plone/volto/helpers';
import { v4 as uuid } from 'uuid';
import config from '@plone/volto/registry';

/**
 * Check can use migrate functions
 * @method migratable
 * @param {object} actions Content actions
 * @param {string} pathname Content pathname
 * @param {string} review_state Content review state
 * @returns {boolean} Can use migrate functions or not
 */
export const migratable = (actions, contentType, pathname, review_state) => {
  const hasEditAction = find(actions.object, { id: 'edit' }); // ログインユーザがEdit可能かどうか
  const isImportedPageType = contentType === 'OuImportedPage';
  const isEditView = last(pathname.split('/')) === 'edit'; // EditViewかどうか
  const isPrivateState = review_state === 'private'; // review_stateがprivateかどうか
  return hasEditAction && isImportedPageType && isEditView && isPrivateState;
};

/**
 * Add block without trailing block
 * based on helpers/Blocks/Blocks/addBlock v13.8.2
 * @function addBlockWithoutTrailing
 * @param {Object} formData Form data
 * @param {string} type Block type
 * @param {number} index Destination index
 * @return {Array} New block id, New form data
 */
export function addBlockWithoutTrailing(formData, type, index) {
  //   const { settings } = config;
  const id = uuid();
  //   const idTrailingBlock = uuid();
  const blocksFieldname = getBlocksFieldname(formData);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(formData);
  const totalItems = formData[blocksLayoutFieldname].items.length;
  const insert = index === -1 ? totalItems : index;
  return [
    id,
    {
      ...formData,
      [blocksLayoutFieldname]: {
        items: [
          ...formData[blocksLayoutFieldname].items.slice(0, insert),
          id,
          //   ...(type !== settings.defaultBlockType ? [idTrailingBlock] : []),
          ...formData[blocksLayoutFieldname].items.slice(insert),
        ],
      },
      [blocksFieldname]: {
        ...formData[blocksFieldname],
        [id]: {
          '@type': type,
        },
        // ...(type !== settings.defaultBlockType && {
        //   [idTrailingBlock]: {
        //     '@type': settings.defaultBlockType,
        //   },
        // }),
      },
      selected: id,
    },
  ];
}

/**
 * Add block handler
 * based on components/manage/Block/Block/BlocksForm v13.8.2
 * @method onAddBlock
 * @param {object} properties Content properties
 * @param {string} type Type of the block
 * @param {Number} index Index where to add the block
 * @returns {string} Id of the block
 */
const onAddBlock = (properties, type, index) => {
  //   if (editable) {
  const res = addBlockWithoutTrailing(properties, type, index);
  //   onChangeFormData(newFormData);
  //   }
  return res;
};

/**
 * Change block handler
 * based on components/manage/Block/Block/BlocksForm v13.8.2
 * @method onAddBlock
 * @param {object} properties Content properties
 * @param {string} type Type of the block
 * @param {Number} index Index where to add the block
 * @returns {string} Id of the block
 */
const onChangeBlock = (id, properties, value) => {
  const newFormData = changeBlock(properties, id, value);
  //   onChangeFormData(newFormData);
  return newFormData;
};

/**
 * Remove blocks exclude title block
 * @method onDeleteBlocksExcludeTitle
 * @returns
 */
const onResetBlock = (properties) => {
  const blocksFieldname = getBlocksFieldname(properties);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(properties);
  const uidTitle = findKey(properties[blocksFieldname], { '@type': 'title' });
  const newFormData = {
    ...properties,
    [blocksFieldname]: { [uidTitle]: { '@type': 'title' } },
    [blocksLayoutFieldname]: { items: [uidTitle] },
  };
  return newFormData;
};

/**
 * Migrate blocks handler
 * @method onMigrateBlock
 * @param {object} properties Content properties
 * @param {string} type Type of the block
 * @param {Number} index Index where to add the block
 * @returns {string} Id of the block
 */
export const onMigrate = (properties, blocks) => {
  var newFormData = onResetBlock(properties);
  for (let block of blocks) {
    let type = block.rawData['@type'];
    let result = onAddBlock(newFormData, type, -1);
    newFormData = onChangeBlock(...result, block.rawData);
  }
  return newFormData;
};
