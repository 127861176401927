/**
 * Targets.js
 * Helper functions for targets
 * @nk24@CMScom 2021/11/05
 */

// unselected
export function targetsWithUnselected(targets) {
  const unselectedProps = {
    en: 'Unselected',
    id: 'unselected',
    ja: '未選択者',
  };
  return [...targets, unselectedProps];
}

export function isShowBlockByTargets(targets, user) {
  if (!targets || targets.length === 0) {
    return true;
  } else if (!user && targets.indexOf('unselected') !== -1) {
    return true;
  } else if (targets.indexOf(user) !== -1) {
    return true;
  } else {
    return false;
  }
}
