/**
 * View header <H2> block.
 * @module components/manage/Blocks/Header2/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import './View.css';

/**
 * View header2 block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  return (
    <h2 id={data.key} className="Header2">
      {data.header2}
    </h2>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  // properties: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
