/**
 * ForYouButton component.
 * @module components/theme/Site/ForYouButton/ForYouButton
 */

import _ from 'lodash';

import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link as LinkScroll } from 'react-scroll';
import { Icon } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getUserType } from '../../../../helpers/UserType/UserType.js';
import { forYouButton as forYouButtonApi } from '../../../../actions/forYouButton/forYouButton';
import './ForYouButton.css';

export const FOR_YOU_TYPES = [
  'OuPage',
  'OuNews',
  'OuEvent',
  'OuImportedPage',
  'OuHTML',
];

/**
 * Component to display the ForYouButton.
 * @function ForYouButton
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const ForYouButton = ({ app, content, visibleForYouButton }) => {
  const [icon, recommended_count, tours_count] = useSelector((state) => {
    return [
      state?.forYouButton?.result?.data?.attributes?.icon ?? '',
      state?.forYouButton?.result?.data?.attributes?.recommended_count ?? 0,
      state?.forYouButton?.result?.data?.attributes?.tours_count ?? 0,
    ];
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (FOR_YOU_TYPES.includes(_.get(content, '@type'))) {
      dispatch(forYouButtonApi(_.get(content, '@id')));
    }
  }, [content]);
  return (
    visibleForYouButton &&
    FOR_YOU_TYPES.includes(_.get(content, '@type')) && (
      <div className="ForYouButton">
        {/* SP */}
        <div className="sp">
          <LinkScroll
            to="ForYou"
            smooth={true}
            duration={600}
            onClick={() => {
              app.setVisibleForYou(true);
              app.setVisibleForYouButton(false);
            }}
          >
            {/*<div className="left leftSide">
              <div className="recommended">
                <div className="count">{recommended_count}</div>
                <div className="label">Recommended</div>
              </div>
              <div className="tours">
                <div className="count">{tours_count}</div>
                <div className="label">
                  <Icon name="flag" />
                  Tours
                </div>
              </div>
            </div>*/}
            <div className="right rightSide">
              <div className="logo">For You</div>
            </div>
          </LinkScroll>
        </div>

        {/* PC */}
        <div className="pc">
          <div className="forYouIconWrapper">
            <img alt="" className="forYouIcon" src={flattenToAppURL(icon)} />
          </div>
          <div className="forYouBox">
            <div className="title">For You</div>
            {/*<div className="forYouBoxInner">
              <div className="recommended">
                <div className="count">{recommended_count}</div>
                <div className="label">Recommended</div>
              </div>
              <div className="tours">
                <div className="count">{tours_count}</div>
                <div className="label">
                  <Icon name="flag" />
                  Tours
                </div>
              </div>
              {getUserType() === undefined ? (
                <p className="desc">
                  あなたにお勧めのコンテンツを紹介しています
                </p>
              ) : (
                <p></p>
              )}
              </div>*/}
            <LinkScroll
              to="ForYou"
              smooth={true}
              duration={600}
              onClick={() => {
                app.setVisibleForYou(true);
                app.setVisibleForYouButton(false);
              }}
            >
              <div className="lookForYou">
                <FormattedMessage id="see_for_you" defaultMessage="" />
              </div>
            </LinkScroll>
          </div>
        </div>
      </div>
    )
  );
};

export default injectIntl(ForYouButton);
