/**
 * Edit image block.
 * @module components/manage/Blocks/Image/Edit
 * based on v13.8.2
 */

import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { readAsDataURL } from 'promise-file-reader';
import { Button, Dimmer, Input, Loader, Message } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import loadable from '@loadable/component';
import cx from 'classnames';
import { isEqual } from 'lodash';

import { Icon, SidebarPortal } from '@plone/volto/components';
/* @nk24 begin */
import ImageSidebar from '@package/components/manage/Blocks/OuGrid2/InnerBlocks/Image/ImageSidebar';
/* @nk24 end */
import { createContent } from '@plone/volto/actions';
import {
  flattenToAppURL,
  getBaseUrl,
  isInternalURL,
} from '@plone/volto/helpers';

import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import aheadSVG from '@plone/volto/icons/ahead.svg';
import uploadSVG from '@plone/volto/icons/upload.svg';

// import { filesfolders } from '@package/actions';
import CreateFilesFolder from '@package/components/manage/CreateFilesFolder/components/CreateFilesFolder';
import { thomsonCrossSectionDependencies } from 'mathjs';

const Dropzone = loadable(() => import('react-dropzone'));

const messages = defineMessages({
  ImageBlockInputPlaceholder: {
    id: 'Browse the site, drop an image, or type an URL',
    defaultMessage: 'Browse the site, drop an image, or type an URL',
  },
});

/**
 * Edit image block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    content: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,
    filesFolderExistance: PropTypes.bool,
  };

  state = {
    uploading: false,
    url: '',
    dragging: false,
    /** @nk24 start */
    filesFolderPathname: null,
    /** @nk24 end */
  };

  /** @nk24 start */
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Object method
   */
  constructor(props) {
    super(props);
    this.setFilesfolderPathname = this.setFilesfolderPathname.bind(this);
  }
  /** @nk24 end */

  /** @nk24 start */
  /**
   * Set files folder state
   * state変更メソッド
   * @param {string, null} pathname filesFolderのpathname
   */
  setFilesfolderPathname(pathname) {
    // console.log({ pathname, statePathname: this.state.filesFolderPathname });
    this.setState({
      filesFolderPathname: pathname,
    });
  }
  /** @nk24 end */

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.request.loading &&
      nextProps.request.loaded &&
      this.state.uploading
    ) {
      this.setState({
        uploading: false,
      });
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        url: nextProps.content['@id'],
        alt: nextProps.properties.title,
      });
    }
  }

  /**
   * @param {*} nextProps
   * @returns {boolean}
   * @memberof Edit
   */
  // shouldComponentUpdate(nextProps) {
  //   return (
  //     this.props.selected ||
  //     nextProps.selected ||
  //     !isEqual(this.props.data, nextProps.data) ||
  //     this.state.filesFolderPathname ||
  //     nextProps.filesFolderPathname ||
  //     this.props.filesfolderList
  //   );
  // }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.selected ||
      nextProps.selected ||
      !isEqual(this.props.data, nextProps.data) ||
      !isEqual(this.state.filesFolderPathname, nextState.filesFolderPathname)
    );
  }

  /**
   * Upload image handler (not used), but useful in case that we want a button
   * not powered by react-dropzone
   * @method onUploadImage
   * @returns {undefined}
   */
  onUploadImage = (e) => {
    e.stopPropagation();
    const file = e.target.files[0];
    this.setState({
      uploading: true,
    });
    readAsDataURL(file).then((data) => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      this.props.createContent(
        /** @nk24 start */
        // getBaseUrl(this.props.pathname),
        this.state.filesFolderPathname,
        /** @nk24 end */
        {
          '@type': 'Image',
          title: file.name,
          image: {
            data: fields[3],
            encoding: fields[2],
            'content-type': fields[1],
            filename: file.name,
          },
        },
        this.props.block,
      );
    });
  };

  /**
   * Align block handler
   * @method onAlignBlock
   * @param {string} align Alignment option
   * @returns {undefined}
   */
  onAlignBlock(align) {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      align,
    });
  }

  /**
   * Change url handler
   * @method onChangeUrl
   * @param {Object} target Target object
   * @returns {undefined}
   */
  onChangeUrl = ({ target }) => {
    this.setState({
      url: target.value,
    });
  };

  /**
   * Submit url handler
   * @method onSubmitUrl
   * @param {object} e Event
   * @returns {undefined}
   */
  onSubmitUrl = () => {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      url: this.state.url,
    });
  };

  resetSubmitUrl = () => {
    this.setState({
      url: '',
    });
  };

  /**
   * Drop handler
   * @method onDrop
   * @param {array} files File objects
   * @returns {undefined}
   */
  onDrop = (file) => {
    this.setState({
      uploading: true,
    });

    readAsDataURL(file[0]).then((data) => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      this.props.createContent(
        /** @nk24 start */
        // getBaseUrl(this.props.pathname),
        this.state.filesFolderPathname,
        /** @nk24 end */
        {
          '@type': 'Image',
          title: file[0].name,
          image: {
            data: fields[3],
            encoding: fields[2],
            'content-type': fields[1],
            filename: file[0].name,
          },
        },
        this.props.block,
      );
    });
  };

  /**
   * Keydown handler on Variant Menu Form
   * This is required since the ENTER key is already mapped to a onKeyDown
   * event and needs to be overriden with a child onKeyDown.
   * @method onKeyDownVariantMenuForm
   * @param {Object} e Event object
   * @returns {undefined}
   */
  onKeyDownVariantMenuForm = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.onSubmitUrl();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      // TODO: Do something on ESC key
    }
  };
  onDragEnter = () => {
    this.setState({ dragging: true });
  };
  onDragLeave = () => {
    this.setState({ dragging: false });
  };

  node = React.createRef();

  /**
   * 編集モードに応じてparent of filesFolderのパスを得る
   * @function fetchParentPathname
   * @param {*} e
   */
  // fetchParentPathname() {
  //   // console.log({ PATHNAME: this.props.pathname });
  //   const editMode = this.props.pathname.split('/').slice(-1)[0];
  //   if (editMode === 'edit' && this.props.parentId) {
  //     return this.props.parentId;
  //   } else if (editMode === 'add') {
  //     return getBaseUrl(this.props.pathname);
  //   } else {
  //     return null;
  //   }
  // }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { data } = this.props;
    const placeholder =
      this.props.data.placeholder ||
      this.props.intl.formatMessage(messages.ImageBlockInputPlaceholder);
    // const shouldFolderPath = this.fetchParentPathname()
    //   ? flattenToAppURL(`${this.fetchParentPathname()}/files`)
    //   : null;
    return (
      <div
        className={cx(
          'block image align',
          {
            center: !Boolean(data.align),
          },
          data.align,
        )}
      >
        {data.url ? (
          <img
            className={cx({
              'full-width': data.align === 'full',
              large: data.size === 'l',
              medium: data.size === 'm',
              small: data.size === 's',
            })}
            src={
              isInternalURL(data.url)
                ? // Backwards compat in the case that the block is storing the full server URL
                  (() => {
                    if (data.size === 'l')
                      return `${flattenToAppURL(data.url)}/@@images/image`;
                    if (data.size === 'm')
                      return `${flattenToAppURL(
                        data.url,
                      )}/@@images/image/preview`;
                    if (data.size === 's')
                      return `${flattenToAppURL(data.url)}/@@images/image/mini`;
                    return `${flattenToAppURL(data.url)}/@@images/image`;
                  })()
                : data.url
            }
            alt={data.alt || ''}
          />
        ) : (
          /** @nk24 start */
          /* UI for create filesFolder if it is not exsists */
          <>
            {this.props.editable && !this.state.filesFolderPathname && (
              <CreateFilesFolder
                block={this.props.block}
                pathname={this.props.pathname}
                filesfolderPathname={this.state.filesFolderPathname}
                setFilesfolderPathname={this.setFilesfolderPathname}
                selected={this.props.selected}
                parentId={this.props.parentId}
                versionId={this.props.versionId}
              />
            )}
            {/** @nk24 end */}

            <div>
              {/** @nk24 begin */}
              {/* {this.props.editable && this.state.filesFolderPathname && ( */}
              {/* {this.props.editable &&
              _.includes(this.props.filesfolderList, shouldFolderPath) ? ( */}
              {this.props.editable && this.state.filesFolderPathname ? (
                /** @nk24 end */
                <Dropzone
                  noClick
                  onDrop={this.onDrop}
                  onDragEnter={this.onDragEnter}
                  onDragLeave={this.onDragLeave}
                  className="dropzone"
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <Message>
                        {this.state.dragging && <Dimmer active></Dimmer>}
                        {this.state.uploading && (
                          <Dimmer active>
                            <Loader indeterminate>Uploading image</Loader>
                          </Dimmer>
                        )}
                        {/** @nk24 start */}
                        {/* <div className="no-image-wrapper"> */}
                        <center>
                          {/** @nk24 end */}
                          <img src={imageBlockSVG} alt="" />
                          <div className="toolbar-inner">
                            <Button.Group>
                              <Button
                                basic
                                icon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  this.props.openObjectBrowser();
                                }}
                              >
                                <Icon name={navTreeSVG} size="24px" />
                              </Button>
                            </Button.Group>
                            <Button.Group>
                              <label className="ui button basic icon">
                                <Icon name={uploadSVG} size="24px" />
                                <input
                                  {...getInputProps({
                                    type: 'file',
                                    onChange: this.onUploadImage,
                                    style: { display: 'none' },
                                  })}
                                />
                              </label>
                            </Button.Group>
                            <Input
                              onKeyDown={this.onKeyDownVariantMenuForm}
                              onChange={this.onChangeUrl}
                              placeholder={placeholder}
                              value={this.state.url}
                              onClick={(e) => {
                                e.target.focus();
                              }}
                              onFocus={(e) => {
                                this.props.onSelectBlock(this.props.id);
                              }}
                            />
                            {this.state.url && (
                              <Button.Group>
                                <Button
                                  basic
                                  className="cancel"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({ url: '' });
                                  }}
                                >
                                  <Icon name={clearSVG} size="30px" />
                                </Button>
                              </Button.Group>
                            )}
                            <Button.Group>
                              <Button
                                basic
                                primary
                                disabled={!this.state.url}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.onSubmitUrl();
                                }}
                              >
                                <Icon name={aheadSVG} size="30px" />
                              </Button>
                            </Button.Group>
                          </div>
                          {/** @nk24 start */}
                        </center>
                        {/* </div> */}
                        {/** @nk24 end */}
                      </Message>
                    </div>
                  )}
                </Dropzone>
              ) : null}
            </div>
            {/** @nk24 begin */}
          </>
          /** @nk24 end */
        )}
        <SidebarPortal selected={this.props.selected}>
          <ImageSidebar {...this.props} resetSubmitUrl={this.resetSubmitUrl} />
        </SidebarPortal>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state, ownProps) => ({
      request: state.content.subrequests[ownProps.block] || {},
      content: state.content.subrequests[ownProps.block]?.data,
      // filesfolderList: state.filesfolderList?.paths,
      parentId: state.content.data?.parent?.['@id'],
      versionId: state.content.data?.version,
    }),
    // { createContent, filesfolders },
    { createContent },
  ),
)(Edit);
