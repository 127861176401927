/**
 * PageMainImage component.
 * @module components/theme/Element/PageMainImage/PageMainImage
 */

import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import './PageMainImage.css';

/**
 * Component to display the PageMainImage
 * @function PageMainImage
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const PageMainImage = ({ content }) => {
  const mainImageSrc = flattenToAppURL(content?.thumbnail?.download ?? '');

  return (
    content?.thumbnail && (
      <div className="PageMainImage">
        <div className="mainImage">
          <img alt={content?.title} src={mainImageSrc} />
        </div>
      </div>
    )
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PageMainImage.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.required,
  }).isRequired,
};

export default injectIntl(PageMainImage);
