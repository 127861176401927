import { AUTOMATIC_LIST_FOR_OU_GRID } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  result: {},
};

/**
 * Automatic List for Ou Grid Reducer
 * @param {*} state
 * @param {*} action
 */
export default function automaticListForOuGrid(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case `${AUTOMATIC_LIST_FOR_OU_GRID}_PENDING`:
      return {
        ...state,
        [action.subrequest]: {
          error: null, // state.error = null
          loaded: false, // state.loaded = false
          loading: true, // state.loading = true
        },
      };
    case `${AUTOMATIC_LIST_FOR_OU_GRID}_SUCCESS`:
      return {
        ...state,
        [action.subrequest]: {
          error: null,
          result: action?.result,
          loaded: true,
          loading: false,
        },
      };
    case `${AUTOMATIC_LIST_FOR_OU_GRID}_FAIL`:
      return {
        ...state,
        [action.subrequest]: {
          error: action.error,
          loaded: false,
          loading: false,
        },
      };
    default:
      return state;
  }
}
