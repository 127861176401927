/**
 * View univ info block.
 * @module components/manage/Blocks/UnivInfo/View
 */

import React, { useEffect, useState } from 'react';
import NoSSR from 'react-no-ssr';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { MediaQueryConfig } from '~/helpers/GlobalStyle/GlobalStyle';
import { LRFBlockHeader } from '~/components';
import { isShowBlockByTargets } from '~/helpers/Targets/Targets';
import { getUserType } from '~/helpers/UserType/UserType';
import './View.css';

/**
 * View univ info block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  const type = 'univ_info';
  const content = data[type];
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  const background_image_PC_path = content?.background_image_PC?.[0]['@id'];
  const background_image_SP_path = content?.background_image_SP?.[0]['@id'];
  const targets = content?.targets;
  let backgroundStylePC;
  if (background_image_PC_path) {
    backgroundStylePC = {
      backgroundImage: `url(${flattenToAppURL(
        background_image_PC_path,
      )}/@@images/image)`,
    };
  } else {
    backgroundStylePC = { background: 'none' };
  }
  let backgroundStyleSP;
  if (background_image_SP_path) {
    backgroundStyleSP = {
      backgroundImage: `url(${flattenToAppURL(
        background_image_SP_path,
      )}/@@images/image)`,
    };
  } else {
    backgroundStyleSP = { background: 'none' };
  }
  const [showBlock, setShowBlock] = useState(false);
  useEffect(() => {
    const isShowBlock = isShowBlockByTargets(targets, getUserType());
    if (isShowBlock !== showBlock) {
      setShowBlock(isShowBlock);
    }
  }, [targets, setShowBlock, showBlock]);
  return showBlock ? (
    <NoSSR>
      <section
        className="UnivInfo"
        id={data.key}
        style={isSP ? backgroundStyleSP : backgroundStylePC}
      >
        <div className="inner">
          {isSP ? (
            <LRFBlockHeader title={content?.title} />
          ) : (
            <h2>{content?.title}</h2>
          )}
          <p>{content?.text}</p>
        </div>
      </section>
    </NoSSR>
  ) : null;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
