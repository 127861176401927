/**
 * Feature block sidebar.
 * @module components/manage/Blocks/Feature/Sidebar
 * @nk24:CMScom created 2021/03/19
 */

import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import Schema from './Schema';

const messages = defineMessages({
  events: {
    id: 'Events',
    defaultMessage: 'Events',
  },
});

const Sidebar = (props) => {
  const _ = props.intl.formatMessage;
  const type = props.data['@type'];
  const content = props.data[type];
  if (!content) {
    return null;
  }
  // const schema_data = {
  //   background_image_PC: [],
  //   background_image_SP: [],
  //   target: {},
  //   ...props.data?.[type],
  // };
  return (
    <InlineForm
      schema={Schema}
      title={_(messages.events)}
      onChangeField={(id, value) => {
        props.onChangeBlock(props.block, {
          ...props.data,
          [type]: { ...props.data[type], [id]: value },
        });
      }}
      formData={{
        background_image_PC: content.background_image_PC || [],
        background_image_SP: content.background_image_SP || [],
      }}
    />
  );
};

export default injectIntl(Sidebar);
