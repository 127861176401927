/**
 * View image block.
 * based on v13.8.2
 * @module components/manage/Blocks/Image/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { flattenToAppURLWithClean } from '@package/helpers/Url/Url';
// import './View.css';
import config from '@plone/volto/registry';

/**
 * View image block class.
 * @class View
 * @extends Component
 */
const View = ({ url, alt }) => {
  // const View = ({ data, detached }) => {
  /* @terapyon begin */
  /* same as UniversalLink.jsx */

  // const excludeHosts = useSelector(
  //   (state) =>
  //     state?.siteMainInfo?.result?.data?.attributes?.common?.excludeHosts,
  // );
  // const url = flattenToAppURLWithClean(data.url, excludeHosts);
  // const isBlacklisted = (config.settings.externalRoutes ?? []).find((route) =>
  //   matchPath(url, route.match),
  // );
  // const isExternal = !isInternalURL(url) || isBlacklisted;
  /* @terapyon end */
  return url ? <img src={`${url}/@@images/image`} alt={alt || ''} /> : null;
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  // data: PropTypes.objectOf(PropTypes.any).isRequired,
  url: PropTypes.string,
  alt: PropTypes.string,
};

export default View;
