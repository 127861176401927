import React, { useState } from 'react';
import NoSSR from 'react-no-ssr';
import { useMediaQuery } from 'react-responsive';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { flattenToAppURL } from '@plone/volto/helpers';

const View = ({ data }) => {
  // Responsive
  const isPC = useMediaQuery({ query: '(min-width: 768px)' });

  // Definition
  const items = data.ouLinkList || [];

  // Dispatch
  const [countPc, countSp] = useSelector((state) => {
    return [
      state?.siteMainInfo?.result?.data?.attributes?.common
        ?.listForOuGridCoutPc ?? 7,
      state?.siteMainInfo?.result?.data?.attributes?.common
        ?.listForOuGridCoutSp ?? 5,
    ];
  });

  // showMoreList
  const moreListCount = isPC ? countPc : countSp;
  const [showMoreList, setShowMoreList] = useState(false);
  const clickMoreButton = () => {
    setShowMoreList(true);
  };

  // Condition
  const classHiddenMoreList = (index) =>
    index > moreListCount - 1 && !showMoreList ? ' hiddenMoreList' : '';
  const showMoreButton = !showMoreList && items.length > moreListCount;

  return (
    items.length > 0 && (
      <NoSSR>
        <ul className="links">
          {items.map((item, index) => {
            const sItem = item?.path?.[0];
            return (
              item?.text && (
                <li
                  className={`link arrow${classHiddenMoreList(index)}`}
                  key={index}
                >
                  {sItem ? (
                    item?.isExternal ? (
                      <a href={sItem['@id']} target="blank">
                        {item.text}
                      </a>
                    ) : (
                      <Link to={flattenToAppURL(sItem['@id'])}>
                        {item.text}
                      </Link>
                    )
                  ) : (
                    item.text
                  )}
                </li>
              )
            );
          })}
          {showMoreButton && (
            <li
              className="link plus"
              key="moreButton"
              onClick={clickMoreButton}
            >
              MORE
            </li>
          )}
        </ul>
      </NoSSR>
    )
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(View);
