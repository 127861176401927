/**
 * SearchBox component.
 * @module components/theme/Site/SearchBox/SearchBox
 */

import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import NoSSR from 'react-no-ssr';
import { useMediaQuery } from 'react-responsive';
import { Form, Icon, Input, Sidebar } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { MediaQueryConfig } from '../../../../helpers/GlobalStyle/GlobalStyle';
import config from '@plone/volto/registry';
// import cookie from 'react-cookie';
import { Cookies } from 'react-cookie';
import './SearchBox.css';
import { getLocaleFromPathname } from 'helpers/Locale/Locale';

/**
 * Component to display the SearchBox.
 * @function SearchBox
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const SearchBox = ({ app, visibleSearchBox, inputValue, isSearchPage }) => {
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });

  const locale =
    new Cookies().get('I18N_LANGUAGE') || config.settings.defaultLanguage;
  // const locale = getLocaleFromPathname(
  //   __CLIENT__ ? window?.location.pathname : null,
  //   config.settings.defaultLanguage,
  // );

  return (
    <div className="SearchBox">
      <NoSSR>
        <Sidebar
          animation="overlay"
          className="searchBox"
          direction={isSP ? 'bottom' : 'top'}
          onHide={() => app.setVisibleSearchBox(false)}
          visible={visibleSearchBox || (isSP && isSearchPage)}
        >
          <Form action={flattenToAppURL('/search')} className="searchForm">
            <Form.Field>
              <input type="hidden" name="lang" value={locale}></input>
              <Input
                type="text"
                className="searchableText"
                name="q"
                value={inputValue}
              />
              <button className="searchButton">
                <Icon name="search" />
              </button>
            </Form.Field>
          </Form>
        </Sidebar>
      </NoSSR>
    </div>
  );
};

export default injectIntl(SearchBox);
