/**
 * Routes.
 * @module routes
 */

import React from 'react';
import { App } from '@plone/volto/components';
import { defaultRoutes } from '@plone/volto/routes';
import config from '@plone/volto/registry';

import FullReviewListView from './components/theme/View/FullReviewListView';
import OuFAQSearchView from './components/theme/View/OuFAQSearchView';
import ComponentsBook from './components/theme/Element/ComponentsBook/ComponentsBook';
import SitemapView from './components/theme/View/SitemapView';

/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const customedOuFAQSearch = {
  path: '*/faq-search',
  component: OuFAQSearchView,
  exact: true,
};

const customedFullReviewList = {
  path: '*/full-review-list',
  component: FullReviewListView,
  exact: true,
};

const sitemap = {
  path: '*/sitemap',
  component: SitemapView,
  exact: true,
};

const componentsBook = {
  path: '*/componentsBook',
  component: ComponentsBook,
  exact: true,
};

const routes = [
  {
    path: '/',
    component: App, // Change this if you want a different component
    routes: [
      // Add your routes here
      ...(config.addonRoutes || []),
      customedOuFAQSearch,
      customedFullReviewList,
      sitemap,
      // TODO: 開発時利用
      // componentsBook,
      ...defaultRoutes,
    ],
  },
];

export default routes;
