import React from 'react';
import ChatItemElement from './ChatItemElement';
import { ChatItemType } from '../../../../constants/Types';
import './ChatItemSelectElement.css';
import { Button } from 'semantic-ui-react';
import welcomeStore from '../WelcomeElement/store/welcome';
import _ from 'lodash';
import cookie from 'react-cookie';
import { saveUserType } from 'helpers/UserType/UserType';
import { injectIntl } from 'react-intl';
import { isJa } from 'helpers/I18N/I18N';
import { connect } from 'react-redux';
import NoSSR from 'react-no-ssr';

function onClickUserTypeButton(event, item, locale, callback) {
  // console.log(event);
  // console.log(userType);
  welcomeStore.sendMessage({
    id: 'X123456',
    type: 'ChatItemNormal',
    from: {
      src: '/assets/images/you_icon@2x.png',
      alt: 'ChatItemNormalの自分アイコンのALT',
      position: 'right',
    },
    content: isJa(locale) ? `私は ${item?.ja} です！` : `I am ${item?.en}! `,
    thumbnail: null,
    link: null,
  });
  welcomeStore.sendMessage({
    id: 'X123457',
    type: 'ChatItemNormal',
    from: {
      src: '/assets/images/icon_ou@2x.png',
      alt: 'ChatItemNormalの自分アイコンのALT',
      position: 'left',
    },
    content: isJa(locale)
      ? `${item?.ja} にお勧めのページを表示します。`
      : `Show recommend page for ${item?.en}.`,
    thumbnail: null,
    link: null,
  });
  saveUserType(item?.id);
  if (callback) {
    callback(item?.id);
  }
}

// const targets = [
//   { en: 'student', id: 'student', ja: '学部受験生' },
//   { en: 'student1', id: 'student1', ja: '大学院受験生' },
//   { en: 'student2', id: 'student2', ja: '在学生' },
//   { en: 'student3', id: 'student3', ja: '在学生保護者' },
//   { en: 'student4', id: 'student4', ja: '教職員' },
//   { en: 'student5', id: 'student5', ja: '卒業生' },
//   { en: 'student6', id: 'student6', ja: '研究者' },
//   { en: 'student7', id: 'student7', ja: '公官庁・他大学の方' },
//   { en: 'student8', id: 'student8', ja: '民間企業の方' },
//   { en: 'student9', id: 'student9', ja: '地域・一般の方' },
//   { en: 'student10', id: 'student10', ja: '支援をお考えの方' },
//   { en: 'student11', id: 'student11', ja: 'メディア関係者' },
// ];

/**
 * Chat Item Select
 * @param {*} param0
 */
const ChatItemSelectElement = ({ item, intl, targets, callback }) => {
  // console.log(intl);
  return (
    <ChatItemElement item={item} additionalClass={'dark'}>
      <NoSSR>
        <div className="ChatItemSelectElement">
          <h3 className="title">{item?.title}</h3>
          <ul className="select-user-type">
            {_.map(targets, (item) => {
              return (
                <li className="user-type" key={item?.id}>
                  <Button
                    onClick={(event) =>
                      onClickUserTypeButton(event, item, intl?.locale, callback)
                    }
                  >
                    {isJa(intl?.locale) ? item?.ja : item?.en}
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
      </NoSSR>
    </ChatItemElement>
  );
};

ChatItemSelectElement.propTypes = ChatItemType;

const ChatItemSelectContainer = connect(
  (state, props) => {
    return {
      targets:
        state?.siteMainInfo?.result?.data?.attributes?.dynamic_common?.targets,
    };
  },
  (dispatch, props) => {
    return {};
  },
)(ChatItemSelectElement);

export default injectIntl(ChatItemSelectContainer);
