import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import Schema from './Schema';

const messages = defineMessages({
  welcome: {
    id: 'Welcome',
    defaultMessage: 'Welcome',
  },
});

const Sidebar = (props) => {
  const _ = props.intl.formatMessage;
  const type = props.data['@type'];
  const content = props.data[type];
  // const formData = {
  //   background_image_PC: [],
  //   background_image_SP: [],
  //   ...props.data[type],
  // };
  if (!content) {
    return null;
  }
  return (
    <InlineForm
      schema={Schema}
      title={_(messages.welcome)}
      onChangeField={(id, value) => {
        props.onChangeBlock(props.block, {
          ...props.data,
          [type]: { ...props.data[type], [id]: value },
        });
      }}
      formData={{
        background_image_PC: content.background_image_PC || [],
        backbround_image_SP: content.backbround_image_SP || [],
        targets: content?.targets || [],
      }}
    />
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Sidebar.propTypes = {
  // properties: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(Sidebar);
