/**
 * AdditionaltoolbarButtons Component
 * カスタムツールバーボタンのwrapper
 * @module components/manage/AdditioinalToolbarButtons/components/AddtionalToolbarButtons
 */

import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
// import PreviewBlocksButton from './PreviewBlocksButton';
import showBlocksSVG from '@plone/volto/icons/show-blocks.svg';

// const bodyToBlockTransableTypes = ['OuImportedPage']; // text ==> Blocks変換対応CT

/**
 * カスタムボタンの表示エリア
 * @function AdditionalToolbarButtons
 */
const AdditionalToolbarButtons = (props) => {
  // const is_show = bodyToBlockTransableTypes.some((type) => type === props.type);
  return (
    <>
      {/* <PreviewBlocksButton></PreviewBlocksButton> */}
      <div id="toolbar-additional"></div>
    </>
  );
};

export default AdditionalToolbarButtons;

// export default connect(
//   (state, props) => ({
//     type: state?.content?.data?.['@type'],
//   }),
//   {},
// )(AdditionalToolbarButtons);
