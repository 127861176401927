import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import NoSSR from 'react-no-ssr';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
// import { Icon } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { MediaQueryConfig } from '~/helpers/GlobalStyle/GlobalStyle';
import { isShowBlockByTargets } from '~/helpers/Targets/Targets';
import { getUserType } from '~/helpers/UserType/UserType';
import './View.css';

const View = ({ data }) => {
  const content = data.welcome;
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  const targets = content?.targets;
  const backgroundImagePath = !isSP
    ? content?.background_image_PC?.[0]?.['@id']
    : content?.background_image_SP?.[0]?.['@id'];
  const backgroundStyle = backgroundImagePath
    ? {
        backgroundImage: `url(${flattenToAppURL(
          backgroundImagePath,
        )}/@@images/image)`,
      }
    : null;
  const [showBlock, setShowBlock] = useState(false);
  useEffect(() => {
    const isShowBlock = isShowBlockByTargets(targets, getUserType());
    if (isShowBlock !== showBlock) {
      setShowBlock(isShowBlock);
    }
  }, [targets, setShowBlock, showBlock]);
  return showBlock ? (
    <NoSSR>
      <div className="WelcomeLink" style={backgroundStyle}>
        <div className="inner">
          <div className="innerInner">
            <div className="content">
              <h3 className="title">{content.title}</h3>
              <p className="text">{content.text}</p>
              <div className="linkButtons">
                {map(content.items, (elem, index) => {
                  return (
                    <Link
                      className="linkButton"
                      // to={flattenToAppURL(content.items?.[index]?.url ?? '')}
                      to={flattenToAppURL(elem.url?.blocks?.[0]?.text ?? '')}
                      target={content.openLinkInNewTab ? '_blank' : null}
                    >
                      <span>{elem.label?.blocks?.[0]?.text ?? ''}</span>
                      {/* <Icon
                        name="angle right"
                        className="icon"
                        size="large"
                      ></Icon> */}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </NoSSR>
  ) : null;
};

export default View;
