/**
 * OuStoryZTopFolderView component.
 * @module components/theme/View/OuStoryZTopFolderView
 */

import _ from 'lodash';

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { HelmetSite } from '../../index';
import { storyZTopFolder as storyZTopFolderAction } from '../../../actions/storyZTopFolder/storyZTopFolder';
import './OuStoryZTopFolderView.css';

/**
 * Component to display the OuStoryZTopFolderView.
 * @function OuStoryZTopFolderView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
export const StoryZHeader = ({ rootPath }) => {
  return (
    <div className="StoryZHeader">
      <h1>
        <Link to={flattenToAppURL(rootPath)}>
          <img
            alt="StoryZ"
            className="headerImage"
            src={flattenToAppURL(`/assets/images/storyz/logo.svg`)}
          />
        </Link>
      </h1>
      <div className="leadSentence">
        阪大生にも、研究者にも、卒業生にも誰しも必ずある“物語”
        <br />
        その一小節があつまると大阪大学という壮大なドキュメンタリーを生み出します。
        <br />
        それぞれのStoryをお楽しみください。
      </div>
    </div>
  );
};

export const StoryZMenu = ({ navs }) => {
  return (
    navs.length > 0 && (
      <nav className="StoryZMenu">
        <ul>
          {navs.map((nav, index) => {
            return (
              <li className={`theme ${nav.theme}`} key={index}>
                <NavLink
                  to={flattenToAppURL(nav.url)}
                  exact={nav.theme === 'all'}
                >
                  {nav.title}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    )
  );
};

export const StoryZThemecopy = ({ theme }) => {
  return (
    <div className="StoryZThemecopy">
      {(theme === 'education' || theme === 'all') && (
        <section className="themecopy education">
          <p className="themecopyInner">
            学びは、道を拓く力を授ける。
            <br />
            そんな一味違う阪大での学びの
            <ruby>
              教科書<rp>(</rp>
              <rt>ストーリー</rt>
              <rp>)</rp>
            </ruby>
          </p>
        </section>
      )}
      {(theme === 'research' || theme === 'all') && (
        <section className="themecopy research">
          <p className="themecopyInner">
            未知の世界に挑む学問の
            <ruby>
              化物<rp>(</rp>
              <rt>モンスター</rt>
              <rp>)</rp>
            </ruby>
            たち
            <br />
            そんな世界を驚かす研究者たちの
            <ruby>
              生き様<rp>(</rp>
              <rt>ストーリー</rt>
              <rp>)</rp>
            </ruby>
          </p>
        </section>
      )}
      {(theme === 'campuslife' || theme === 'all') && (
        <section className="themecopy campuslife">
          <p className="themecopyInner">
            のびやかに、ひたむきに、
            <br />
            時に悩み、それでも前を向く。
            <br />
            そんな阪大生たちのきらめきの
            <ruby>
              学生生活<rp>(</rp>
              <rt>ストーリー</rt>
              <rp>)</rp>
            </ruby>
          </p>
        </section>
      )}
      {(theme === 'alumni' || theme === 'all') && (
        <section className="themecopy alumni">
          <p className="themecopyInner">
            答えのない世界でどう生きるか？
            <br />
            楽しむこと──
            <br />
            そんな阪大生が描くその後の
            <ruby>
              人生<rp>(</rp>
              <rt>ストーリー</rt>
              <rp>)</rp>
            </ruby>
          </p>
        </section>
      )}
    </div>
  );
};

export const StoryZMoreButton = ({
  apiAction,
  bSize,
  bStart,
  hasNext,
  path,
}) => {
  const dispatch = useDispatch();
  return (
    hasNext && (
      <div className="StoryZMoreButton">
        <button
          className="button arrow"
          onClick={() => dispatch(apiAction(bSize + bStart, path))}
        >
          MORE
        </button>
      </div>
    )
  );
};

export const StoryZList = ({
  apiAction,
  bSize,
  bStart,
  hasNext,
  items,
  path,
}) => {
  const StoryZListParts = ({ item }) => {
    return (
      <>
        {item.src && (
          <div className={`header ${item.theme}`}>
            <img alt="" src={flattenToAppURL(item.src)} />
          </div>
        )}
        <h2 className="title">{item.title}</h2>
        {item.subtitle && <div className="subtitle">{item.subtitle}</div>}
        <div className="button" />
      </>
    );
  };
  return (
    items.length > 0 && (
      <>
        <section className="StoryZList">
          {items.map((item, index) => {
            return (
              <div className="summary" key={index}>
                {item.portal_type === 'OuStoryZ' ? (
                  <Link to={flattenToAppURL(item?.url)}>
                    <StoryZListParts item={item} />
                  </Link>
                ) : (
                  <a href={flattenToAppURL(item?.url)} target="_blank">
                    <StoryZListParts item={item} />
                  </a>
                )}
              </div>
            );
          })}
        </section>
        <StoryZMoreButton
          apiAction={apiAction}
          bSize={bSize}
          bStart={bStart}
          hasNext={hasNext}
          path={path}
        />
      </>
    )
  );
};

const OuStoryZTopFolderView = ({ content }) => {
  const path = _.get(content, '@id');
  const [bSize, bStart, hasNext, navs, items] = useSelector((state) => {
    return [
      state?.storyZTopFolder?.result?.data?.attributes?.b_size ?? 0,
      state?.storyZTopFolder?.result?.data?.attributes?.b_start ?? 0,
      state?.storyZTopFolder?.result?.data?.attributes?.has_next ?? true,
      state?.storyZTopFolder?.result?.data?.attributes?.navs ?? [],
      state?.storyZTopFolder?.storyZList ?? [],
    ];
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(storyZTopFolderAction(0, path));
  }, [content]);
  return (
    <div className="OuStoryZTopFolderView OuStoryZsView">
      <HelmetSite content={content} />
      <section className="mainArea">
        <div className="mainAreaInner">
          <StoryZHeader rootPath={path} />
          <StoryZMenu navs={navs} />
          <StoryZThemecopy theme="all" />
          <StoryZList
            apiAction={storyZTopFolderAction}
            bSize={bSize}
            bStart={bStart}
            hasNext={hasNext}
            items={items}
            path={path}
          />
        </div>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuStoryZTopFolderView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default injectIntl(OuStoryZTopFolderView);
