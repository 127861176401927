/**
 * RedirectElement component.
 * @module components/theme/Element/RedirectElement/RedirectElement
 */

import _ from 'lodash';

import React from 'react';
import { injectIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import './RedirectElement.css';

/**
 * Component to display the RedirectElement
 * @function RedirectElement
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const RedirectElement = ({ token, url }) => {
  return token === undefined ? (
    <Redirect to={flattenToAppURL(url)}></Redirect>
  ) : (
    <div className="RedirectElement">
      <Icon name="check circle" />
      <span className="notice">
        非ログインユーザは上位フォルダに強制リダイレクトします。
      </span>
    </div>
  );
};

export default injectIntl(RedirectElement);
