/**
 * UrgentNotifyWidget component.
 * nk24@CMScom 2021/04/28
 * @module components/manage/Widgets/UrgentNotifyWidget
 */

import { map, find, isBoolean, isObject, isArray } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
// import Editor from 'draft-js-plugins-editor';
// import { convertFromRaw, EditorState } from 'draft-js';
// import { convertToRaw } from 'draft-js';
// import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import loadable from '@loadable/component';
import { getBoolean } from '@plone/volto/helpers';
// import SelectWidget from '@plone/volto/components';
import {
  Option,
  DropdownIndicator,
  selectTheme,
  customSelectStyles,
} from '@plone/volto/components/manage/Widgets/SelectStyling';
// import { settings } from '~/config';
import './UrgentNotifyForm.css';

const Select = loadable(() => import('react-select'));
const messages = defineMessages({
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  idTitle: {
    id: 'Short Name',
    defaultMessage: 'Short Name',
  },
  idDescription: {
    id: 'Used for programmatic access to the fieldset.',
    defaultMessage: 'Used for programmatic access to the fieldset.',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  required: {
    id: 'Required',
    defaultMessage: 'Required',
  },
  delete: {
    id: 'Delete',
    defaultMessage: 'Delete',
  },
  no_value: {
    id: 'No Value',
    defaultMessage: 'No Value',
  },
  target: {
    id: 'Target',
    defaultMessage: 'Target',
  },
  path_or_url: {
    id: 'Path or URL',
    defaultMessage: 'Path or URL',
  },
});

/**
 * getDefaultValue function
 * Copied from components/manage/Widgets/SelectWidget/getDefaultValue@v12.0.0
 * @param {*} choices
 * @param {*} value
 * @returns
 */
function getDefaultValues(choices, value) {
  if (!isObject(value) && isBoolean(value)) {
    // We have a boolean value, which means we need to provide a "No value"
    // option
    const label = find(choices, (o) => getBoolean(o[0]) === value);
    return label
      ? {
          label: label[1],
          value,
        }
      : {};
  }
  if (!value || value.length === 0) return null;
  if (value === 'no-value') {
    return {
      label: this.props.intl.formatMessage(messages.no_value),
      value: 'no-value',
    };
  }
  if (isObject(value)) {
    return {
      label: value.title !== 'None' && value.title ? value.title : value.token,
      value: value.token,
    };
  }
  if (isArray(value) && choices.length > 0) {
    return value.map((v) => ({
      label: find(choices, (o) => o[0] === v)?.[1] || v,
      value: v,
    }));
  } else if (value && choices.length > 0) {
    return { label: find(choices, (o) => o[0] === value)?.[1] || value, value };
  } else {
    return [];
  }
}

/**
 * Get choices by locale function
 */
function getChoicesByLocale(choices, locale) {
  return map(choices, (choice) => [choice.id, choice[locale]]);
}

/**
 * UrgentNotifyForm component class.
 * @class UrgentNotifyForm
 * @extends Component
 */
class UrgentNotifyForm extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    index: PropTypes.number,
    value: PropTypes.object,
    onChangeTarget: PropTypes.func,
    onChangeTitle: PropTypes.func,
    onChangeUrl: PropTypes.func,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    index: 0,
    value: [],
    onChangeTarget: null,
    onChangeTitle: null,
    onChangeUrl: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Cell
   */
  constructor(props) {
    super(props);
    if (!__SERVER__) {
      //   let editorState;
      //   if (!props.value.description) {
      //     editorState = EditorState.createEmpty();
      //   } else {
      //     editorState = EditorState.createWithContent(
      //       convertFromRaw(props.value.description),
      //     );
      //   }
      //   const inlineToolbarPlugin = createInlineToolbarPlugin({
      //     structure: settings.richTextEditorInlineToolbarButtons,
      //   });
      this.state = {
        // targetValue: null,
        titleValue: null,
        urlValue: null,
      };
    }
    this.onChangeTarget = this.onChangeTarget.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeUrl = this.onChangeUrl.bind(this);
    // this.setSelectedIndexState = this.setSelectedIndexState.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * Component did mount lifecycle method
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    // if (this.node) {
    //   const onFocus = this.node.editor._onFocus;
    //   this.node.editor._onFocus = event => {
    //     onFocus(event);
    //   };
    // }
    // if (this.state.targetValue === null) {
    //   this.setState({ targetValue: this.props.value.target });
    // }
    console.log({ test: this.props.test });
    if (this.state.titleValue === null) {
      this.setState({ titleValue: this.props.value.title });
    }
    if (this.state.urlValue === null) {
      this.setState({ urlValue: this.props.value.url });
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    //   if (
    //     nextProps.isTableBlockSelected !== this.props.isTableBlockSelected //&&
    //     // this.props.cell === 0 &&
    //     // this.props.row === 0
    //   ) {
    //     this.node.focus();
    //   }
  }

  /**
   * Set selected index to state
   * @method setSelectedIndexState
   * @returns {undefined}
   */
  // setSelectedIndexState(index) {
  //   this.setState({
  //     selected_index: index,
  //   });
  // }
  handleClick() {
    this.props.setSelectedIndex(this.props.index);
  }

  /**
   * Target change handler
   * @method onChangeTarget
   * @param {object} string target value.
   * @returns {undefined}
   */
  onChangeTarget(targetValue) {
    // this.setState({ targetValue: targetValue });
    // this.props.value.items[0].term = termValue;
    // console.log({ targetValue });
    this.props.onChange(this.props.index, 'target', targetValue);
  }
  onChangeTitle(titleValue) {
    this.setState({ titleValue: titleValue });
    // this.props.value.items[0].term = termValue;
    this.props.onChange(this.props.index, 'title', titleValue);
  }
  onChangeUrl(urlValue) {
    this.setState({ urlValue: urlValue });
    // this.props.value.items[0].term = termValue;
    this.props.onChange(this.props.index, 'url', urlValue);
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }
    // console.log(this.props.test);
    // console.log({
    //   state: this.state,
    //   value: this.props.value,
    //   choices: this.props.choices,
    // });
    // console.log({
    //   defaultValue: this.state.targetValue
    //     ? getDefaultValues(this.props.choices, this.state.targetValue)
    //     : {},
    // });

    const {
      id,
      index,
      isActive,
      title,
      required,
      description,
      error,
      value,
      onChange,
      onEdit,
      onDelete,
      intl,
      icon,
      iconAction,
      fieldSet,
    } = this.props;
    // const { InlineToolbar } = this.state.inlineToolbarPlugin;

    const choices = getChoicesByLocale(this.props.choices, this.props.locale);

    return (
      <div
        className={
          isActive ? 'UrgentNotifyForm activeColumn' : 'UrgentNotifyForm'
        }
      >
        <div className="ui grid">
          <div className="stretched row">
            <div className="four wide column" onClick={this.handleClick}>
              <div className="wrapper">
                <label for={`field-${id}-target`}>
                  {this.props.intl.formatMessage(messages.target)}
                </label>
              </div>
            </div>
            <div className="eight wide column">
              <Select
                id={`field-${id}-target`}
                key={choices}
                name={`{id}-target`}
                isDisabled={this.props.isDisabled}
                className="react-select-container"
                classNamePrefix="react-select"
                isMulti={
                  this.props.isMulti
                    ? this.props.isMulti
                    : id === 'roles' || id === 'groups'
                }
                options={[
                  ...map(choices, (option) => ({
                    value: option[0],
                    label:
                      // Fix "None" on the serializer, to remove when fixed in p.restapi
                      option[1] !== 'None' && option[1] ? option[1] : option[0],
                  })),
                  ...(this.props.noValueOption
                    ? [
                        {
                          label: this.props.intl.formatMessage(
                            messages.no_value,
                          ),
                          value: 'no-value',
                        },
                      ]
                    : []),
                ]}
                styles={customSelectStyles}
                theme={selectTheme}
                components={{ DropdownIndicator, Option }}
                defaultValue={getDefaultValues(choices, value.target)}
                onChange={(data) => {
                  // let dataValue = [];
                  // if (Array.isArray(data)) {
                  //   for (let obj of data) {
                  //     dataValue.push(obj.value);
                  //   }
                  //   return onChange(id, dataValue);
                  // }
                  // console.log({ data });
                  // this.setState({
                  //   targetValue:
                  //     data && data.value !== 'no-value' ? data.value : undefined,
                  // });
                  this.onChangeTarget(
                    data && data.value !== 'no-value' ? data.value : undefined,
                  );
                  // return this.onChangeTarget(
                  //   id,
                  //   data && data.value !== 'no-value' ? data.value : undefined,
                  // );
                }}
              />
            </div>
          </div>
        </div>
        <div className="ui grid">
          <div className="stretched row">
            <div className="four wide column" onClick={this.handleClick}>
              <div className="wrapper">
                <label for={`field-${id}-target`}>
                  {this.props.intl.formatMessage(messages.title)}
                </label>
              </div>
            </div>
            <div className="eight wide column">
              <Input
                id={`field-${this.props.id}-title`}
                name={`${this.props.id}-title`}
                value={this.state.titleValue || ''}
                onChange={({ target }) => {
                  this.onChangeTitle(
                    target.value === '' ? undefined : target.value,
                  );
                }}
                onClick={this.handleClick}
              />
            </div>
          </div>
        </div>
        <div className="ui grid">
          <div className="stretched row">
            <div className="four wide column" onClick={this.handleClick}>
              <div className="wrapper">
                <label for={`field-${id}-target`}>
                  {this.props.intl.formatMessage(messages.path_or_url)}
                </label>
              </div>
            </div>
            <div className="eight wide column">
              <Input
                id={`field-${this.props.id}-url`}
                name={`${this.props.id}-url`}
                value={this.state.urlValue || ''}
                onChange={({ target }) =>
                  this.onChangeUrl(
                    target.value === '' ? undefined : target.value,
                  )
                }
                onClick={this.handleClick}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state, props) => ({
      test: state,
      choices:
        state?.siteMainInfo?.result?.data?.attributes?.dynamic_common
          ?.urgent_targets,
      //   choices: [
      //     ['examinee', '学部受験生'],
      //     ['student', '在学生'],
      //   ],
      locale: state?.intl?.locale ?? 'ja',
      noValueOption: true,
    }),
    {},
    // { getVocabulary, getVocabularyTokenTitle },
  ),
)(UrgentNotifyForm);
