/**
 * MoreButton component.
 * @module components/theme/Element/MoreButton/MoreButton
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import './MoreButton.css';

/**
 * Component to display the MoreButton
 * @function MoreButton
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const MoreButton = ({
  bSize,
  bStart,
  hasNext,
  loadList,
  locale,
  path,
  target,
  term,
}) => {
  return (
    <div className={hasNext ? 'MoreButton' : 'MoreButton hidden'}>
      <div className="moreButton">
        <button
          className="button arrow"
          onClick={() => {
            loadList(hasNext, bSize, bStart, target, term, locale, path);
          }}
          role="button"
        >
          MORE
        </button>
      </div>
    </div>
  );
};

export default injectIntl(MoreButton);
