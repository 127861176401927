/**
 * Chat風UIのテスト用Store
 */

import { Subject, of } from "rxjs";
import {
    map,
    take,
    filter,
    tap,
    concatMap,
    delay,
    mergeMap,
  } from "rxjs/operators";
const subject = new Subject();

const initialState = {
  data: [],
};

let state = initialState;

const welcomeStore = {
  init: () => {
    state = { ...initialState, newDataCount: 0 };
    subject.next(state);
  },
  subscribe: (setState) => subject.pipe(concatMap((val) => of(val).pipe(delay(500)))).subscribe(setState),
  sendMessage: (message) => {
    state = {
      ...state,
      data: [...state.data, message],
    };
    subject.next(state);
  },
  clearChat: () => {
    state = initialState;
    subject.next(state);
  },
  initialState,
};

export default welcomeStore;
