/**
 * Breadcrumbs components.
 * @module components/theme/Breadcrumbs/Breadcrumbs
 * based on v13.8.2
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
/** @nk24 start */
import { Breadcrumb, Icon } from 'semantic-ui-react';
// import { Breadcrumb, Container, Segment } from 'semantic-ui-react';
/** @nk24 end */
import { defineMessages, injectIntl } from 'react-intl';

/** @nk24 start */
// import { Icon } from '@plone/volto/components';
/** @nk24 start */
import { getBreadcrumbs } from '@plone/volto/actions';
/** @nk24 end */
import {
  flattenToAppURL,
  getBaseUrl,
  hasApiExpander,
} from '@plone/volto/helpers';

/** @nk24 start */
// import homeSVG from '@plone/volto/icons/home.svg';
/** @nk24 end */

import './Breadcrumbs.css';

const messages = defineMessages({
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  breadcrumbs: {
    id: 'Breadcrumbs',
    defaultMessage: 'Breadcrumbs',
  },
});

/**
 * Breadcrumbs container class.
 * @class Breadcrumbs
 * @extends Component
 */
export class BreadcrumbsComponent extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    getBreadcrumbs: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    /** @nk24 start */
    // root: PropTypes.string,
    /** @nk24 end */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
  };

  componentDidMount() {
    this.setState({ visibleNav: true });
    if (!hasApiExpander('breadcrumbs', getBaseUrl(this.props.pathname))) {
      this.props.getBreadcrumbs(getBaseUrl(this.props.pathname));
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      if (!hasApiExpander('breadcrumbs', getBaseUrl(this.props.pathname))) {
        this.props.getBreadcrumbs(getBaseUrl(nextProps.pathname));
      }
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return this.state?.visibleNav ? (
      /* @nk24 start */
      <div className="Breadcrumbs">
        <div
          role="navigation"
          aria-label={this.props.intl.formatMessage(messages.breadcrumbs)}
          className="breadcrumbs"
        >
          {/* <Segment
          role="navigation"
          aria-label={this.props.intl.formatMessage(messages.breadcrumbs)}
          className="breadcrumbs"
          secondary
          vertical
          >
          <Container> */}

          <Breadcrumb>
            <Link
              to={this.props.root || '/'}
              className="section"
              title={this.props.intl.formatMessage(messages.home)}
            >
              {/* <Icon name={homeSVG} size="18px" /> */}
              <div className="home">
                <Icon name="home" />
              </div>
            </Link>
            {this.props.items.map((item, index, items) => [
              <Breadcrumb.Divider key={`divider-${item.url}`} />,
              index < items.length - 1 ? (
                // <Link
                //   key={item.url}
                //   to={item.url}
                //   className="section"
                // >
                <a
                  key={item.url}
                  href={`${item.url}/contents`}
                  className="section"
                >
                  {item.title}
                </a>
              ) : (
                // </Link>
                <Breadcrumb.Section key={item.url} active>
                  {item.title}
                </Breadcrumb.Section>
              ),
            ])}
            <span
              className="hiddenButton"
              onClick={() => this.setState({ visibleNav: false })}
            >
              <Icon name="delete" />
            </span>
          </Breadcrumb>

          <div className="fullReviewList">
            <Link to={flattenToAppURL('/full-review-list')}>
              Full Review List
            </Link>
          </div>

          {/* </Container>
          </Segment> */}
        </div>
      </div>
    ) : null;
    /* @nk24 end */
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      items: state.breadcrumbs.items,
      /** @nk24 start */
      //   root: state.breadcrumbs.root,
      /** @nk24 end */
    }),
    { getBreadcrumbs },
  ),
)(BreadcrumbsComponent);
