/**
 * OuUnauthorizedView component.
 * @module components/theme/View/OuUnauthorizedView
 */

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { SiteHeaderForError } from '@package/components';
import { Helmet } from '@plone/volto/helpers';
import { OuNotFoundInnerView } from './OuNotFoundView';

/**
 * Component to display the OuUnauthorizedView.
 * @function OuUnauthorizedView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OuUnauthorizedView = () => {
  return (
    <section className="OuUnauthorizedView OuNotFoundView">
      <Helmet title="401 Unauthorized" />
      <SiteHeaderForError />
      <div className="mainAreaInner">
        <h2 className="title">401 Unauthorized</h2>
        <OuNotFoundInnerView />
      </div>
    </section>
  );
};

export default injectIntl(OuUnauthorizedView);
