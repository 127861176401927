/**
 * Edit emphasis link block.
 * @module components/manage/Blocks/EmphasisLink/Edit
 * @nk24@cmscom updated 2021/10/19
 * based on components/manage/Blocks/Text/Edit v13.15.1
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
const Editor = loadable(() => import('draft-js-plugins-editor'));
import DraftEditorUtils from 'draft-js-plugins-utils';
import { convertFromRaw, convertToRaw, EditorState, RichUtils } from 'draft-js';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import { compose } from 'redux';

import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';
import { defineMessages, injectIntl } from 'react-intl';
import { includes, isEqual } from 'lodash';
import EditorUtils from 'draft-js-plugins-utils';
import { filterEditorState } from 'draftjs-filters';
import AddLinkForm from '@plone/volto/components/manage/AnchorPlugin/components/LinkButton/AddLinkForm';
import { createLink } from '@package/helpers/RichText/RichText';

import config from '@plone/volto/registry';

import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { BlockChooserButton } from '@plone/volto/components';

import './Edit.css';

const messages = defineMessages({
  link_text: {
    id: 'Type link text…',
    defaultMessage: 'Type link text…',
  },
});

/**
 * Edit text block class.
 * @class Edit
 * @extends Component
 */
class EditComponent extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    detached: PropTypes.bool,
    index: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    onAddBlock: PropTypes.func.isRequired,
    onInsertBlock: PropTypes.func.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onMutateBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    editable: PropTypes.bool,
    allowedBlocks: PropTypes.arrayOf(PropTypes.string),
    showRestricted: PropTypes.bool,
    formTitle: PropTypes.string,
    formDescription: PropTypes.string,
    blocksConfig: PropTypes.objectOf(PropTypes.any),
    properties: PropTypes.objectOf(PropTypes.any),
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    detached: false,
    editable: true,
    showAddLinkForm: false,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);

    const { settings } = config;

    this.draftConfig = settings.richtextEditorSettings(props);

    const { EditorState, convertFromRaw } = props.draftJs;
    const createInlineToolbarPlugin = props.draftJsInlineToolbarPlugin.default;

    if (!__SERVER__) {
      let editorState;
      if (props.data && props.data.emphasis_link) {
        editorState = EditorState.createWithContent(
          convertFromRaw(props.data.emphasis_link),
        );
      } else {
        editorState = EditorState.createEmpty();
      }

      const inlineToolbarPlugin = createInlineToolbarPlugin({
        // structure: config.settings.richTextEditorInlineToolbarButtons,
        structure: this.draftConfig.richTextEditorInlineToolbarButtons,
      });

      this.state = {
        editorState,
        inlineToolbarPlugin,
      };
    }

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Component will receive props
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (this.props.selected) {
      // See https://github.com/draft-js-plugins/draft-js-plugins/issues/800
      setTimeout(this.node.focus, 0);
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log({ nextProps });
    // console.log({ props: this.props });
    const currentSelected = this.props.selected;
    const nextSelected = nextProps.selected;
    const currentText = this.props.data?.emphasis_link?.blocks?.[0]?.text;
    const nextText = nextProps.data?.emphasis_link?.blocks?.[0]?.text;
    if (nextSelected && !currentSelected && currentText) {
      // Focus
      this.setState({ showAddLinkForm: true });
    } else if (!nextSelected && currentSelected && currentText) {
      // Blur
      this.setState({ showAddLinkForm: false });
    } else if (currentSelected && nextText && !currentText) {
      // 空 -> 文字入力
      this.setState({ showAddLinkForm: true });
    } else if (currentSelected && !nextText && currentText) {
      // 文字全削除
      this.setState({ showAddLinkForm: false });
    }

    if (!this.props.selected && nextProps.selected) {
      const selectionState = this.state.editorState.getSelection();

      if (selectionState.getStartOffset() < selectionState.getEndOffset()) {
        //keep selection
      } else {
        //nothing is selected, move focus to end
        // See https://github.com/draft-js-plugins/draft-js-plugins/issues/800
        setTimeout(this.node.focus, 0);

        this.setState({
          editorState: EditorState.moveFocusToEnd(this.state.editorState),
        });
      }
    }
  }

  /**
   * @param {*} nextProps
   * @param {*} nextState
   * @returns {boolean}
   * @memberof Edit
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.selected ||
      !isEqual(this.props.data, nextProps.data) ||
      !isEqual(this.state.editorState, nextState.editorState)
    );
  }

  /**
   * Change handler
   * @method onChange
   * @param {object} editorState Editor state.
   * @returns {undefined}
   */
  onChange(editorState) {
    const shouldFilterPaste =
      editorState.getLastChangeType() === 'insert-fragment';

    const { convertToRaw } = this.props.draftJs;
    const { filterEditorState } = this.props.draftJsFilters;

    if (
      !isEqual(
        convertToRaw(editorState.getCurrentContent()),
        convertToRaw(this.state.editorState.getCurrentContent()),
      )
    ) {
      if (shouldFilterPaste) {
        let filteredState = editorState;
        filteredState = filterEditorState(
          {
            blocks: ['unordered-list-item', 'ordered-list-item'],
            styles: ['BOLD', 'ITALIC'],
            entities: [
              {
                type: 'LINK',
                attributes: ['url'],
              },
            ],
            whitespacedCharacters: [],
          },
          filteredState,
        );
        editorState = filteredState;
      }
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        emphasis_link: convertToRaw(editorState.getCurrentContent()),
      });
    }
    this.setState({ editorState });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    const placeholder =
      this.props.data.placeholder ||
      this.props.formTitle ||
      this.props.intl.formatMessage(messages.link_text);

    const disableNewBlocks =
      this.props.data?.disableNewBlocks || this.props.detached;
    // const { InlineToolbar } = this.state.inlineToolbarPlugin;
    // const { settings } = config;
    const isSoftNewlineEvent = this.props.draftJsLibIsSoftNewlineEvent.default;
    const { RichUtils } = this.props.draftJs;

    const link = EditorUtils.getCurrentEntity(
      this.state.editorState,
      // this.props.getEditorState(),
    )?.getData()?.url;
    const addLinkFormWrapperClassName = this.state.showAddLinkForm
      ? 'add-link-form-wrapper visible'
      : 'add-link-form-wrapper invisible';
    return (
      <div className="EmphasisLink">
        <Editor
          readOnly={!this.props.editable}
          onChange={this.onChange}
          editorState={this.state.editorState}
          plugins={[
            this.state.inlineToolbarPlugin,
            // ...settings.richTextEditorPlugins,
            ...this.draftConfig.richTextEditorPlugins,
          ]}
          // blockRenderMap={settings.extendedBlockRenderMap}
          blockRenderMap={this.draftConfig.extendedBlockRenderMap}
          // blockStyleFn={settings.blockStyleFn}
          blockStyleFn={this.draftConfig.textBlockStyleFn} // TODO
          // customStyleMap={settings.customStyleMap}
          customStyleMap={this.draftConfig.customStyleMap}
          placeholder={placeholder}
          handleReturn={(e) => {
            if (isSoftNewlineEvent(e)) {
              this.onChange(
                RichUtils.insertSoftNewline(this.state.editorState),
              );
              return 'handled';
            }
            if (!disableNewBlocks) {
              const selectionState = this.state.editorState.getSelection();
              const anchorKey = selectionState.getAnchorKey();
              const currentContent = this.state.editorState.getCurrentContent();
              const currentContentBlock = currentContent.getBlockForKey(
                anchorKey,
              );
              const blockType = currentContentBlock.getType();
              // if (!includes(settings.listBlockTypes, blockType)) {
              if (!includes(this.draftConfig.listBlockTypes, blockType)) {
                this.props.onSelectBlock(
                  this.props.onAddBlock('text', this.props.index + 1),
                );
                return 'handled';
              }
              return 'un-handled';
            }
            return {};
          }}
          handleKeyCommand={(command, editorState) => {
            if (this.props.data.required) {
              return;
            }
            if (
              command === 'backspace' &&
              editorState.getCurrentContent().getPlainText().length === 0
            ) {
              this.props.onDeleteBlock(this.props.block, true);
            }
          }}
          onUpArrow={() => {
            const selectionState = this.state.editorState.getSelection();
            const currentCursorPosition = selectionState.getStartOffset();

            if (currentCursorPosition === 0) {
              this.props.onFocusPreviousBlock(this.props.block, this.node);
            }
          }}
          onDownArrow={() => {
            const selectionState = this.state.editorState.getSelection();
            const { editorState } = this.state;
            const currentCursorPosition = selectionState.getStartOffset();
            const blockLength = editorState
              .getCurrentContent()
              .getFirstBlock()
              .getLength();

            if (currentCursorPosition === blockLength) {
              this.props.onFocusNextBlock(this.props.block, this.node);
            }
          }}
          ref={(node) => {
            this.node = node;
          }}
        />
        <div className={addLinkFormWrapperClassName}>
          <AddLinkForm
            {...this.props}
            placeholder={placeholder}
            // theme={ownTheme}
            theme=""
            block="draft-js"
            data={{ url: link || '' }}
            onChangeBlock={() => {}}
            onClear={() => {
              this.setState({
                editorState: DraftEditorUtils.removeLinkAtSelection(
                  this.state.editorState,
                ),
              });
            }}
            onChangeValue={(url) => {
              this.props.onChangeBlock(this.props.block, {
                ...this.props.data,
                emphasis_link: convertToRaw(
                  createLink(this.state.editorState, url).getCurrentContent(),
                ),
              });
              this.setState({
                editorState: createLink(this.state.editorState, url),
              });
            }}
            onOverrideContent={() => {}}
          />
        </div>
        {/* <InlineToolbar /> */}
        {/* {this.props.selected && (
          <BlockChooserButton
            data={this.props.data}
            block={this.props.block}
            onInsertBlock={(id, value) => {
              this.props.onSelectBlock(this.props.onInsertBlock(id, value));
            }}
            allowedBlocks={this.props.allowedBlocks}
            blocksConfig={this.props.blocksConfig}
            size="24px"
            className="block-add-button"
            properties={this.props.properties}
          />
        )} */}
      </div>
    );
  }
}

// export default injectIntl(Edit);

export const Edit = compose(
  injectIntl,
  injectLazyLibs([
    'draftJs',
    'draftJsLibIsSoftNewlineEvent',
    'draftJsFilters',
    'draftJsInlineToolbarPlugin',
    'draftJsBlockBreakoutPlugin',
    'draftJsCreateInlineStyleButton',
    'draftJsCreateBlockStyleButton',
    'immutableLib',
    // TODO: add all plugin dependencies, also in Wysiwyg and Cell
  ]),
)(EditComponent);

const Preloader = (props) => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    Editor.load().then(() => setLoaded(true));
  }, []);
  return loaded ? <Edit {...props} /> : null;
};

export default Preloader;
