import { defineMessages } from 'react-intl';

import ViewHeader2Block from '../components/manage/Blocks/Header2/View';
import EditHeader2Block from '../components/manage/Blocks/Header2/Edit';
import ViewHeader3Block from '../components/manage/Blocks/Header3/View';
import EditHeader3Block from '../components/manage/Blocks/Header3/Edit';
import ViewEmphasisLinkBlock from '../components/manage/Blocks/EmphasisLink/View';
import EditEmphasisLinkBlock from '../components/manage/Blocks/EmphasisLink/Edit';
import ViewUnivInfoBlock from '../components/manage/Blocks/UnivInfo/View';
import EditUnivInfoBlock from '../components/manage/Blocks/UnivInfo/Edit';
import ViewOUNowBlock from '../components/manage/Blocks/OUNow/View';
import EditOUNowBlock from '../components/manage/Blocks/OUNow/Edit';
import ViewToppageItemBlock from '../components/manage/Blocks/ToppageItem/View';
import EditToppageItemBlock from '../components/manage/Blocks/ToppageItem/Edit';
import ViewYoutubeBlock from '../components/manage/Blocks/Youtube/View';
import EditYoutubeBlock from '../components/manage/Blocks/Youtube/Edit';
import ViewOUNumbersBlock from '../components/manage/Blocks/OUNumbers/View';
import EditOUNumbersBlock from '../components/manage/Blocks/OUNumbers/Edit';
import ViewWelcomeBlock from '../components/manage/Blocks/WelcomeLink/View';
import EditWelcomeBlock from '../components/manage/Blocks/WelcomeLink/Edit';
import ViewEventsBlock from '../components/manage/Blocks/Events/View';
import EditEventsBlock from '../components/manage/Blocks/Events/Edit';
import ViewNewsBlock from '../components/manage/Blocks/News/View';
import EditNewsBlock from '../components/manage/Blocks/News/Edit';
import ViewResearchBlock from '../components/manage/Blocks/Research/View';
import EditResearchBlock from '../components/manage/Blocks/Research/Edit';
import ViewSocialLinkBlock from '../components/manage/Blocks/SocialLink/View';
import EditSocialLinkBlock from '../components/manage/Blocks/SocialLink/Edit';
// import ViewTourBlock from '../components/manage/Blocks/Tour/View';
// import EditTourBlock from '../components/manage/Blocks/Tour/Edit';
import ViewTitleBlock from '@plone/volto/components/manage/Blocks/Title/View';
import EditTitleBlock from '@plone/volto/components/manage/Blocks/Title/Edit';
import ViewLeadImageBlock from '@plone/volto/components/manage/Blocks/LeadImage/View';
import EditLeadImageBlock from '@plone/volto/components/manage/Blocks/LeadImage/Edit';
import BlockSettingsSchema from '@plone/volto/components/manage/Blocks/Block/Schema';
import ViewListingBlock from '@plone/volto/components/manage/Blocks/Listing/View';
import EditListingBlock from '@plone/volto/components/manage/Blocks/Listing/Edit';
import DefaultListingBlockTemplate from '@plone/volto/components/manage/Blocks/Listing/DefaultTemplate';
import SummaryListingBlockTemplate from '@plone/volto/components/manage/Blocks/Listing/SummaryTemplate';
import ImageGalleryListingBlockTemplate from '@plone/volto/components/manage/Blocks/Listing/ImageGallery';
import ViewHeroImageLeftBlock from '@plone/volto/components/manage/Blocks/HeroImageLeft/View';
import EditHeroImageLeftBlock from '@plone/volto/components/manage/Blocks/HeroImageLeft/Edit';
import emailSVG from '@plone/volto/icons/email.svg';
import headingSVG from '@plone/volto/icons/heading.svg';
import rectRightSVG from '@plone/volto/icons/right-key.svg';
import homeSVG from '@plone/volto/icons/home.svg';
import cameraSVG from '@plone/volto/icons/camera.svg';
import heroSVG from '@plone/volto/icons/hero.svg';
import listBulletSVG from '@plone/volto/icons/list-bullet.svg';
import titleSVG from '@plone/volto/icons/text.svg';
import ViewMailFormTextLineBlock from '@package/components/manage/Blocks/MailForm/TextLineBlock/View';
import EditMailFormTextLineBlock from '@package/components/manage/Blocks/MailForm/TextLineBlock/Edit';
import ViewMailFormTextAreaBlock from '@package/components/manage/Blocks/MailForm/TextAreaBlock/View';
import EditMailFormTextAreaBlock from '@package/components/manage/Blocks/MailForm/TextAreaBlock/Edit';
import ViewMailFormSelectBlock from '@package/components/manage/Blocks/MailForm/SelectBlock/View';
import EditMailFormSelectBlock from '@package/components/manage/Blocks/MailForm/SelectBlock/Edit';
import ViewGridSubImageBlock from '@package/components/manage/Blocks/ImageForOuGrid/View';
import EditGridSubImageBlock from '@package/components/manage/Blocks/ImageForOuGrid/Edit';
import OuLinkListViewBlock from '@package/components/manage/Blocks/OuLinkList/View';
import OuLinkListEditBlock from '@package/components/manage/Blocks/OuLinkList/Edit';
import OuGridHeaderViewBlock from '@package/components/manage/Blocks/OuGridHeader/View';
import OuGridHeaderEditBlock from '@package/components/manage/Blocks/OuGridHeader/Edit';
import OuGridDescriptionViewBlock from '@package/components/manage/Blocks/OuGridDescription/View';
import OuGridDescriptionEditBlock from '@package/components/manage/Blocks/OuGridDescription/Edit';

import {
  GridViewBlock,
  // GridEditBlock,
} from '@kitconcept/volto-blocks-grid/components';
import GridEditBlock from '@package/components/manage/Blocks/OuGrid2/Edit';

import EditGrid from '../components/manage/Blocks/ImagesGrid/Edit';
import ViewGrid from '../components/manage/Blocks/ImagesGrid/View';

import AutomaticListForOuGridEditBlock from '@package/components/manage/Blocks/AutomaticListForOuGrid/Edit';
import AutomaticListForOuGridViewBlock from '@package/components/manage/Blocks/AutomaticListForOuGrid/View';
import ManualListForOuGridEditBlock from '@package/components/manage/Blocks/ManualListForOuGrid/Edit';
import ManualListForOuGridViewBlock from '@package/components/manage/Blocks/ManualListForOuGrid/View';
import gridSVG from '@kitconcept/volto-blocks-grid/icons/grid.svg';
import imagesSVG from '@plone/volto/icons/images.svg';
import textSVG from '@plone/volto/icons/text.svg';
/* Added on 2023/02/24@nk24 *begin */
import searchSVG from '@plone/volto/icons/zoom.svg';

import SearchBlockView from '@plone/volto/components/manage/Blocks/Search/SearchBlockView';
import SearchBlockEdit from '@plone/volto/components/manage/Blocks/Search/SearchBlockEdit';

import RightColumnFacets from '@plone/volto/components/manage/Blocks/Search/layout/RightColumnFacets';
import LeftColumnFacets from '@plone/volto/components/manage/Blocks/Search/layout/LeftColumnFacets';
import TopSideFacets from '@plone/volto/components/manage/Blocks/Search/layout/TopSideFacets';
import {
  SelectFacet,
  CheckboxFacet,
  DateRangeFacet,
  ToggleFacet,
  ToggleFacetFilterListEntry,
  SelectFacetFilterListEntry,
  DateRangeFacetFilterListEntry,
} from '@plone/volto/components/manage/Blocks/Search/components';
import SearchBlockSchema from '@plone/volto/components/manage/Blocks/Search/schema';
/* Added on 2023/02/24@nk24 *end */

import EditGrid2SubImageBlock from '@package/components/manage/Blocks/OuGrid2/SubBlocks/ImageForOuGrid2/Edit';
import ViewGrid2SubImageBlock from '@package/components/manage/Blocks/OuGrid2/SubBlocks/ImageForOuGrid2/View';
import EditGrid2SubVideoBlock from '@package/components/manage/Blocks/OuGrid2/SubBlocks/VideoForOuGrid2/Edit';
import ViewGrid2SubVideoBlock from '@package/components/manage/Blocks/OuGrid2/SubBlocks/VideoForOuGrid2/View';
import ViewGrid2InnerImageBlock from '@package/components/manage/Blocks/OuGrid2/InnerBlocks/Image/View';
import EditGrid2InnerImageBlock from '@package/components/manage/Blocks/OuGrid2/InnerBlocks/Image/Edit';
import ViewGrid2InnerLinkListBlock from '@package/components/manage/Blocks/OuGrid2/InnerBlocks/LinkList/View';
import EditGrid2InnerLinkListBlock from '@package/components/manage/Blocks/OuGrid2/InnerBlocks/LinkList/Edit';
import ViewGrid2InnerPlainTextBlock from '@package/components/manage/Blocks/OuGrid2/InnerBlocks/PlainText/View';
import EditGrid2InnerPlainTextBlock from '@package/components/manage/Blocks/OuGrid2/InnerBlocks/PlainText/Edit';

defineMessages({
  header2: {
    id: 'header2',
    defaultMessage: 'Header2',
  },
  header3: {
    id: 'header3',
    defaultMessage: 'Header3',
  },
  emphasis_link: {
    id: 'emphasis_link',
    defaultMessage: 'Emphasis Link',
  },
  univ_info: {
    id: 'univ_info',
    defaultMessage: 'University Information',
  },
  ou_now: {
    id: 'ou_now',
    defaultMessage: 'OU Now',
  },
  toppage_item: {
    id: 'toppage_item',
    defaultMessage: 'Pick Up / General',
  },
  youtube: {
    id: 'youtube',
    defaultMessage: 'Youtube',
  },
  ou_numbers: {
    id: 'ou_numbers',
    defaultMessage: 'OU Numbers',
  },
  welcome: {
    id: 'welcome',
    defaultMessage: 'Welcome! OU',
  },
  events: {
    id: 'events',
    defaultMessage: 'Events',
  },
  news: {
    id: 'news',
    defaultMessage: 'News',
  },
  research: {
    id: 'research',
    defaultMessage: 'Research',
  },
  socail_link: {
    id: 'social_link',
    defaultMessage: 'Social Link',
  },
  tour: {
    id: 'tour',
    defaultMessage: 'Tour',
  },
  holizontal_rule: {
    id: 'holizontal_rule',
    defaultMessage: 'Holizontal Rule',
  },
  mailform_textline: {
    id: 'mailform_textline',
    defaultMessage: 'Textline Form',
  },
  mailform_textarea: {
    id: 'mailform_textarea',
    defaultMessage: 'Textarea Form',
  },
  mailform_select: {
    id: 'mailform_select',
    defaultMessage: 'Select Form',
  },
  ouGrid: {
    id: 'ouGrid',
    defaultMessage: 'OU Grid',
  },
  imageForOuGrid: {
    id: 'imageForOuGrid',
    defaultMessage: 'Image for OU Grid',
  },
  automaticListForOuGrid: {
    id: 'automaticListForOuGrid',
    defaultMessage: 'Automatic List for OU Grid',
  },
  manualListForOuGrid: {
    id: 'manualListForOuGrid',
    defaultMessage: 'Manual List for OU Grid',
  },
  grid: {
    id: 'grid',
    defaultMessage: 'Grid',
  },
});

export const groupBlocksOrder = [
  { id: 'admin', title: 'Administrator' },
  { id: 'grid', title: 'Grid' },
];

export const customBlocks = {
  header2: {
    id: 'header2',
    title: 'Header2',
    icon: headingSVG,
    group: '', // Disabled
    view: ViewHeader2Block,
    edit: EditHeader2Block,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    sidebarTab: 0,
    security: {
      addPermission: [],
      view: [],
    },
  },
  header3: {
    id: 'header3',
    title: 'Header3',
    icon: headingSVG,
    group: '', // Disabled
    view: ViewHeader3Block,
    edit: EditHeader3Block,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    sidebarTab: 0,
    security: {
      addPermission: [],
      view: [],
    },
  },
  emphasis_link: {
    id: 'emphasis_link',
    title: 'emphasis_link',
    icon: rectRightSVG,
    group: 'text',
    view: ViewEmphasisLinkBlock,
    edit: EditEmphasisLinkBlock,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    sidebarTab: 0,
    security: {
      addPermission: [],
      view: [],
    },
  },
  univ_info: {
    id: 'univ_info',
    title: 'University Information',
    icon: homeSVG,
    group: 'admin',
    view: ViewUnivInfoBlock,
    edit: EditUnivInfoBlock,
    restricted: false,
    mostUsed: false,
    type: 'LRF',
    blockHasOwnFocusManagement: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  ou_now: {
    id: 'ou_now',
    title: 'OU Now',
    icon: homeSVG,
    group: 'admin',
    view: ViewOUNowBlock,
    edit: EditOUNowBlock,
    restricted: false,
    mostUsed: false,
    type: 'LRF',
    blockHasOwnFocusManagement: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  toppage_item: {
    id: 'toppage_item',
    title: 'toppage_item',
    icon: homeSVG,
    group: 'admin',
    view: ViewToppageItemBlock,
    edit: EditToppageItemBlock,
    restricted: false,
    mostUsed: false,
    type: 'LRF',
    blockHasOwnFocusManagement: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  youtube: {
    id: 'youtube',
    title: 'Youtube',
    icon: homeSVG,
    group: 'admin',
    view: ViewYoutubeBlock,
    edit: EditYoutubeBlock,
    restricted: false,
    mostUsed: false,
    type: 'LRF',
    blockHasOwnFocusManagement: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  ou_numbers: {
    id: 'ou_numbers',
    title: 'OU Numbers',
    icon: homeSVG,
    group: 'admin',
    view: ViewOUNumbersBlock,
    edit: EditOUNumbersBlock,
    restricted: false,
    mostUsed: false,
    type: 'LRF',
    blockHasOwnFocusManagement: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  welcome: {
    id: 'welcome',
    title: 'Welcome! OU',
    icon: homeSVG,
    group: 'admin',
    view: ViewWelcomeBlock,
    edit: EditWelcomeBlock,
    restricted: false,
    mostUsed: false,
    type: 'LRF',
    blockHasOwnFocusManagement: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  events: {
    id: 'events',
    title: 'Events',
    icon: homeSVG,
    group: 'admin',
    view: ViewEventsBlock,
    edit: EditEventsBlock,
    restricted: false,
    mostUsed: false,
    type: 'LRF',
    blockHasOwnFocusManagement: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  news: {
    id: 'news',
    title: 'News',
    icon: homeSVG,
    group: 'admin',
    view: ViewNewsBlock,
    edit: EditNewsBlock,
    restricted: false,
    mostUsed: false,
    type: 'LRF',
    blockHasOwnFocusManagement: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  research: {
    id: 'research',
    title: 'Research',
    icon: homeSVG,
    group: 'admin',
    view: ViewResearchBlock,
    edit: EditResearchBlock,
    restricted: false,
    mostUsed: false,
    type: 'LRF',
    blockHasOwnFocusManagement: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  social_link: {
    id: 'social_link',
    title: 'Social Link',
    icon: homeSVG,
    group: 'admin',
    view: ViewSocialLinkBlock,
    edit: EditSocialLinkBlock,
    restricted: false,
    mostUsed: false,
    type: 'LRF',
    blockHasOwnFocusManagement: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  // tour: {
  //   id: 'tour',
  //   title: 'Tour',
  //   icon: homeSVG,
  //   group: 'admin',
  //   view: ViewTourBlock,
  //   edit: EditTourBlock,
  //   restricted: false,
  //   mostUsed: false,
  //   type: 'LRF',
  //   blockHasOwnFocusManagement: true,
  //   sidebarTab: 0,
  //   security: {
  //     addPermission: [],
  //     view: [],
  //   },
  // },
  // holizontal_rule: {
  //   id: 'holizontal_rule',
  //   title: 'HolizontalRule',
  //   icon: removeSVG,
  //   group: 'common',
  //   view: HolizontalRuleBlock,
  //   edit: HolizontalRuleBlock,
  //   restricted: false,
  //   mostUsed: false,
  //   blockHasOwnFocusManagement: true,
  //   security: {
  //     addPermission: [],
  //     view: [],
  //   },
  // },
  mailform_textline: {
    id: 'mailform_textline',
    title: 'MailFormTextLine',
    icon: emailSVG,
    group: 'common',
    view: ViewMailFormTextLineBlock,
    edit: EditMailFormTextLineBlock,
    restricted: false,
    mostUsed: false,
    type: 'OuMailForm',
    blockHasOwnFocusManagement: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  mailform_textarea: {
    id: 'mailform_textarea',
    title: 'MailFormTextArea',
    icon: emailSVG,
    group: 'common',
    view: ViewMailFormTextAreaBlock,
    edit: EditMailFormTextAreaBlock,
    restricted: false,
    mostUsed: false,
    type: 'OuMailForm',
    blockHasOwnFocusManagement: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  mailform_select: {
    id: 'mailform_select',
    title: 'MailFormSelect',
    icon: emailSVG,
    group: 'common',
    view: ViewMailFormSelectBlock,
    edit: EditMailFormSelectBlock,
    restricted: false,
    mostUsed: false,
    type: 'OuMailForm',
    blockHasOwnFocusManagement: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },

  /** Overrides to Disable unused default blocks */
  title: {
    id: 'title',
    title: 'Title',
    icon: titleSVG,
    group: '',
    view: ViewTitleBlock,
    edit: EditTitleBlock,
    schema: BlockSettingsSchema,
    restricted: ({ properties, block }) =>
      properties.blocks_layout?.items?.find(
        (uid) => properties.blocks?.[uid]?.['@type'] === block.id,
      ),
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    sidebarTab: 0,
    security: {
      addPermission: [],
      view: [],
    },
  },
  leadimage: {
    id: 'leadimage',
    title: 'Lead Image Field',
    icon: cameraSVG,
    group: '',
    view: ViewLeadImageBlock,
    edit: EditLeadImageBlock,
    schema: BlockSettingsSchema,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  listing: {
    id: 'listing',
    title: 'Listing',
    icon: listBulletSVG,
    group: '', // Disabled
    view: ViewListingBlock,
    edit: EditListingBlock,
    schema: BlockSettingsSchema,
    restricted: false,
    // mostUsed: true,
    mostUsed: false,
    sidebarTab: 1,
    showLinkMore: false,
    security: {
      addPermission: [],
      view: [],
    },
    variations: [
      {
        id: 'default',
        isDefault: true,
        title: 'Default',
        template: DefaultListingBlockTemplate,
      },
      {
        id: 'imageGallery',
        title: 'Image gallery',
        template: ImageGalleryListingBlockTemplate,
      },
      {
        id: 'summary',
        title: 'Summary',
        template: SummaryListingBlockTemplate,
      },
    ],
  },
  hero: {
    id: 'hero',
    title: 'Hero',
    icon: heroSVG,
    group: '', // Disabled
    view: ViewHeroImageLeftBlock,
    edit: EditHeroImageLeftBlock,
    schema: BlockSettingsSchema,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    sidebarTab: 0,
    security: {
      addPermission: [],
      view: [],
    },
  },
  /* Added on 20230224 @nk24 *begin */
  search: {
    id: 'search',
    title: 'Search',
    icon: searchSVG,
    group: '', // Disabled
    view: SearchBlockView,
    edit: SearchBlockEdit,
    blockSchema: SearchBlockSchema,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    variations: [
      {
        id: 'facetsRightSide',
        title: 'Facets on right side',
        view: RightColumnFacets,
        isDefault: true,
      },
      {
        id: 'facetsLeftSide',
        title: 'Facets on left side',
        view: LeftColumnFacets,
        isDefault: false,
      },
      {
        id: 'facetsTopSide',
        title: 'Facets on top',
        view: TopSideFacets,
        isDefault: false,
      },
    ],
    extensions: {
      facetWidgets: {
        rewriteOptions: (name, choices) => {
          return name === 'review_state'
            ? choices.map((opt) => ({
                ...opt,
                label: opt.label.replace(/\[.+\]/, '').trim(),
              }))
            : choices;
        },
        types: [
          {
            id: 'selectFacet',
            title: 'Select',
            view: SelectFacet,
            isDefault: true,
            schemaEnhancer: SelectFacet.schemaEnhancer,
            stateToValue: SelectFacet.stateToValue,
            valueToQuery: SelectFacet.valueToQuery,
            filterListComponent: SelectFacetFilterListEntry,
          },
          {
            id: 'checkboxFacet',
            title: 'Checkbox',
            view: CheckboxFacet,
            isDefault: false,
            schemaEnhancer: CheckboxFacet.schemaEnhancer,
            stateToValue: CheckboxFacet.stateToValue,
            valueToQuery: CheckboxFacet.valueToQuery,
            filterListComponent: SelectFacetFilterListEntry,
          },
          {
            id: 'daterangeFacet',
            title: 'Date range',
            view: DateRangeFacet,
            isDefault: false,
            stateToValue: DateRangeFacet.stateToValue,
            valueToQuery: DateRangeFacet.valueToQuery,
            filterListComponent: DateRangeFacetFilterListEntry,
          },
          {
            id: 'toggleFacet',
            title: 'Toggle',
            view: ToggleFacet,
            isDefault: false,
            stateToValue: ToggleFacet.stateToValue,
            valueToQuery: ToggleFacet.valueToQuery,
            filterListComponent: ToggleFacetFilterListEntry,
          },
        ],
      },
    },
  },
  /* Added on 20230224 @nk24 *end */

  // Grids用Image Block
  ouGrid: {
    id: 'ouGrid',
    title: 'ouGrid',
    icon: gridSVG,
    group: 'grid',
    view: GridViewBlock, // ViewGrid,
    edit: EditGrid, //GridEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    maxNumberOfColumns: 2,
    gridAllowedBlocks: [
      'imageForOuGrid',
      'automaticListForOuGrid',
      'manualListForOuGrid',
    ],
  },
  imageForOuGrid: {
    id: 'imageForOuGrid',
    title: 'imageForOuGrid',
    icon: cameraSVG,
    group: 'grid',
    view: ViewGridSubImageBlock,
    edit: EditGridSubImageBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    global: false,
  },
  automaticListForOuGrid: {
    id: 'automaticListForOuGrid',
    title: 'automaticListForOuGrid',
    icon: imagesSVG,
    group: 'grid',
    view: AutomaticListForOuGridViewBlock,
    edit: AutomaticListForOuGridEditBlock,
    restricted: false,
    // mostUsedがtrueの場合のみrestrictedが有効になるみたい
    //restricted: ({ properties, block }) =>
    //  properties['@type'] !== 'OuCategoryFolder',
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    global: false,
  },
  manualListForOuGrid: {
    id: 'manualListForOuGrid',
    title: 'manualListForOuGrid',
    icon: imagesSVG,
    group: 'grid',
    view: ManualListForOuGridViewBlock,
    edit: ManualListForOuGridEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 0,
    security: {
      addPermission: [],
      view: [],
    },
    global: false,
  },
  ouLinkList: {
    id: 'ouLinkList',
    title: 'List of Link',
    icon: listBulletSVG,
    group: '',
    view: OuLinkListViewBlock,
    edit: OuLinkListEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  ouGridHeader: {
    id: 'ouGridHeader',
    title: 'Ou grid header',
    icon: textSVG,
    group: '',
    view: OuGridHeaderViewBlock,
    edit: OuGridHeaderEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 0,
    security: {
      addPermission: [],
      view: [],
    },
  },
  ouGridDescription: {
    id: 'ouGridDescription',
    title: 'Ou grid description',
    icon: textSVG,
    group: '',
    view: OuGridDescriptionViewBlock,
    edit: OuGridDescriptionEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 0,
    security: {
      addPermission: [],
      view: [],
    },
  },
  __grid: {
    group: '',
    maxNumberOfColumns: 2,
  },
  teaserGrid: {
    group: '',
  },
  imagesGrid: {
    group: '',
  },
  teaser: {
    group: '',
  },
  /* Added on 20230313 for OU Grid block 2 system @nk24 begin */
  ouGrid2: {
    id: 'ouGrid2',
    title: 'Ou Grid 2',
    icon: gridSVG,
    group: 'grid',
    view: GridViewBlock, // ViewGrid,
    edit: GridEditBlock, //GridEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    maxNumberOfColumns: 3,
    gridAllowedBlocks: ['ouGrid2Image', 'ouGrid2Video'],
  },
  ouGrid2Image: {
    id: 'ouGrid2Image',
    title: 'Image container for Ou Grid 2',
    icon: cameraSVG,
    group: 'grid',
    view: ViewGrid2SubImageBlock,
    edit: EditGrid2SubImageBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    global: false,
  },
  ouGrid2Video: {
    id: 'ouGrid2Video',
    title: 'Video container for Ou Grid 2',
    icon: homeSVG,
    group: 'grid',
    view: ViewGrid2SubVideoBlock,
    edit: EditGrid2SubVideoBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    global: false,
  },
  innerImageForOuGrid: {
    id: 'innerImageForOuGrid',
    title: 'Inner image for Ou Grid',
    icon: cameraSVG,
    group: '',
    view: ViewGrid2InnerImageBlock,
    edit: EditGrid2InnerImageBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    global: false,
  },
  innerPlainTextForOuGrid: {
    id: 'innerPlainTextForOuGrid',
    title: 'Inner plain text for Ou Grid',
    icon: cameraSVG,
    group: '',
    view: ViewGrid2InnerPlainTextBlock,
    edit: EditGrid2InnerPlainTextBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    global: false,
  },
  innerLinkListForOuGrid: {
    id: 'innerLinkListForOuGrid',
    title: 'Inner link list for Ou Grid',
    icon: cameraSVG,
    group: '',
    view: ViewGrid2InnerLinkListBlock,
    edit: EditGrid2InnerLinkListBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    global: false,
  },
  /* Added on 20230313 for OU Grid block 2 system @nk24 end */
};
