import { flattenToAppURL } from '@plone/volto/helpers';
import { FOR_YOU } from '../../constants/ActionTypes';

export function forYou(path, target) {
  return {
    type: FOR_YOU,
    request: {
      op: 'get',
      path: `${flattenToAppURL(path)}/@for-you?target=${target}`,
    },
  };
}
