/**
 * OuNewsFolderView component.
 * @module components/theme/View/OuNewsFolderView
 */

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  BlackBox,
  HelmetSite,
  FolderHeaderImage,
  MoreButton,
  Pickup,
  TargetFilter,
  TermFilterNews,
} from '../../index';
import { newsList } from '../../../actions/newsList/newsList';
import { formatedDateDay } from '../../../helpers/GlobalStyle/Date.js';
import { getUserType } from '../../../helpers/UserType/UserType.js';
import './OuNewsFolderView.css';

/**
 * Component to display the OuNewsFolderView.
 * @function OuNewsFolderView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
export const NewsListItem = ({ contentURL = '', item }) => {
  const itemPath = flattenToAppURL(item?.url);
  const contentPath = flattenToAppURL(contentURL);
  return (
    <div className="NewsListItem">
      <div
        className={
          itemPath == contentPath ? 'newsListItem selected' : 'newsListItem'
        }
      >
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column className="date">
              {formatedDateDay(item?.date)}
            </Grid.Column>
            <Grid.Column className="title">
              <Link to={itemPath}>{item?.name}</Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

const OuNewsFolderView = ({
  bSize,
  bStart,
  content,
  hasNext,
  loadNewsList,
  locale,
  newsList,
  pickups,
}) => {
  const userType = getUserType() === undefined ? '' : getUserType();
  const [target, setTarget] = useState(userType);
  const [newsTerm, setNewsTerm] = useState('');
  const [displayPickup, setDisplayPickup] = useState(true);
  const path = flattenToAppURL(content['@id']).replace(/\?.*$/, '');
  useEffect(() => {
    // hasNext: true, bSize: 0, bStart: 0
    loadNewsList(true, 0, 0, target, newsTerm, locale, path); // 1回目の読み込み
  }, []);

  return (
    <div className="OuNewsFolderView OuNewsFolderAndEventFolderView">
      <HelmetSite content={content} />
      <FolderHeaderImage content={content} />
      <section className="mainArea">
        <div className="mainAreaInner">
          <div className="blackBoxWrapper">
            <BlackBox title="Search News" />
          </div>
          <div className="filters">
            <div className="targetFilterWrapper">
              <TargetFilter
                loadList={loadNewsList}
                locale={locale}
                path={path}
                setTarget={setTarget}
                target={target}
                term={newsTerm}
                userType={userType}
              />
            </div>
            <div className="termFilterWrapper">
              <TermFilterNews
                loadList={loadNewsList}
                locale={locale}
                path={path}
                setDisplayPickup={setDisplayPickup}
                setNewsTerm={setNewsTerm}
                target={target}
              />
            </div>
          </div>
          {displayPickup && <Pickup ctype="OuNewsFolder" pickups={pickups} />}
          {newsList?.length > 0 && (
            <>
              <div className="mainAreaInnerInner">
                <ul className="newsList items">
                  {_.map(newsList, (item) => {
                    return (
                      <li className="item" key={item?.url}>
                        <NewsListItem item={item} />
                      </li>
                    );
                  })}
                </ul>
              </div>
              <MoreButton
                bSize={bSize}
                bStart={bStart}
                hasNext={hasNext}
                loadList={loadNewsList}
                locale={locale}
                path={path}
                target={target}
                term={newsTerm}
              />
            </>
          )}
        </div>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuNewsFolderView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

const OuNewsFolderViewContainer = connect(
  (state, props) => {
    return {
      bSize: state?.newsList?.result?.data?.attributes?.b_size ?? 0,
      bStart: state?.newsList?.result?.data?.attributes?.b_start ?? 0,
      newsList: state?.newsList?.newsList,
      hasNext: state?.newsList?.result?.data?.attributes?.has_next ?? true,
      locale: state?.content?.data?.language?.token ?? 'ja',
      pickups: state?.newsList?.result?.data?.attributes?.pickups,
    };
  },
  (dispatch, props) => ({
    loadNewsList: (hasNext, bSize, bStart, target, term, locale, path) => {
      if (hasNext) {
        dispatch(newsList(bSize + bStart, target, term, locale, path));
      }
    },
  }),
)(OuNewsFolderView);

export default injectIntl(OuNewsFolderViewContainer);
