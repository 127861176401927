/**
 * OU Numbers edit block.
 * @module components/manage/Blocks/OUNubers/Edit
 */

import React, { Component } from 'react';
import { Map } from 'immutable';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { cloneDeep, isEmpty, isEqual, map, remove } from 'lodash';
import move from 'lodash-move';
import PropTypes from 'prop-types';
import { stateFromHTML } from 'draft-js-import-html';
import {
  convertToRaw,
  DefaultDraftBlockRenderMap,
  Editor,
  EditorState,
} from 'draft-js';
import dragSVG from '@plone/volto/icons/drag.svg';
import { Icon } from '@plone/volto/components';
import { defineMessages, injectIntl } from 'react-intl';
import { SidebarPortal } from '@plone/volto/components';
import {
  blockHasValue,
  difference,
  FormValidation,
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  // messages,
} from '@plone/volto/helpers';
import config from '@plone/volto/registry';
// import { settings } from '~/config';
// import { ImageForm, TargetsForm } from '../../../../components';
import Sidebar from './Sidebar';
import Item from './Item';
import './Edit.css';

const getId = () => Math.floor(Math.random() * Math.pow(2, 24)).toString(32);

const messages = defineMessages({
  placeholder: {
    id: 'Type the block title…',
    defaultMessage: 'Type the block title…',
  },
});

/**
 * Object structure similar one of draft-js.
 */
const valueToDraft = (value) => ({
  blocks: [
    {
      data: {},
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      key: 'co3kh',
      text: value,
      type: 'unstyled',
    },
  ],
  entityMap: {},
});

// const emptyItem = () => ({
//   key: getId(),
//   header: cloneDeep(valueToDraft('')),
//   text: cloneDeep(valueToDraft('')),
//   image_PC: [],
//   image_SP: [],
// });
const emptyItem = () => ({
  key: getId(),
  header: cloneDeep(valueToDraft('')),
  number: cloneDeep(valueToDraft('')),
  text: cloneDeep(valueToDraft('')),
  background_image_PC: [],
  background_image_SP: [],
  unit: '',
});

// const initialList = {
//   title: '',
//   //   dummyCheckBox: true,
//   items: [
//     {
//       key: getId(),
//       header: cloneDeep(valueToDraft('')),
//       text: cloneDeep(valueToDraft('')),
//       image: [],
//     },
//   ],
//   target: [],
// };
const initialList = {
  title: '',
  items: [
    {
      key: getId(),
      header: cloneDeep(valueToDraft('')),
      number: cloneDeep(valueToDraft('')),
      text: cloneDeep(valueToDraft('')),
      background_image_PC: [],
      background_image_SP: [],
      unit: '',
    },
  ],
  targets: [],
};

const blockRenderMap = Map({
  unstyled: {
    element: 'h2',
  },
});

const blockStyle = 'OUNow_Header2';

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

/**
 * Edit title block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    // data: PropTypes.objectOf(PropTypes.any).isRequired,
    // selected: PropTypes.bool.isRequired,
    // index: PropTypes.number.isRequired,
    // onChangeField: PropTypes.func.isRequired,
    // onSelectBlock: PropTypes.func.isRequired,
    // onDeleteBlock: PropTypes.func.isRequired,
    // onAddBlock: PropTypes.func.isRequired,
    // onFocusPreviousBlock: PropTypes.func.isRequired,
    // onFocusNextBlock: PropTypes.func.isRequired,
    // block: PropTypes.string.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    if (!__SERVER__) {
      let titleEditorState;
      if (props.data.ou_numbers?.title) {
        const contentState = stateFromHTML(props.data.ou_numbers.title);
        titleEditorState = EditorState.createWithContent(contentState);
      } else {
        titleEditorState = EditorState.createEmpty();
      }
      this.state = {
        titleEditorState,
        selectedItem: 0,
        isClient: false,
        placeholderProps: {},
      };
    }
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onSelectList = this.onSelectList.bind(this);
    this.onChangeList = this.onChangeList.bind(this);
    this.onInsertItemAfter = this.onInsertItemAfter.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this);
  }

  /**
   * Component did mount lifecycle method
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.data.ou_numbers) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        ou_numbers: cloneDeep(initialList),
      });
    }
    this.setState({ isClient: true });
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.data.ou_numbers) {
      this.props.onChangeBlock(nextProps.block, {
        ...nextProps.data,
        ou_numbers: initialList,
      });
    }
  }

  /**
   * Component will receive props
   * @method componentWillUnmount
   * @returns {undefined}
   */
  componentWillUnmount() {
    // if (this.props.selectedItem) {
    //   this.node.focus();
    // }
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  /**
   * Select cell handler
   * @method onSelectCell
   * @param {Number} row Row index.
   * @param {Number} cell Cell index.
   * @returns {undefined}
   */
  onSelectList(index) {
    // console.log(index);
    this.setState({ selectedItem: index });
  }

  /**
   * Change cell handler
   * @method onChangeCell
   * @param {Number} row Row index.
   * @param {Number} cell Cell index.
   * @param {Object} editorState Editor state.
   * @returns {undefined}
   */
  onChangeList(index, formId, editorState) {
    // console.log(this.props.data);
    // console.log(index, formId);
    const ou_numbers = { ...this.props.data.ou_numbers };
    ou_numbers.items[index][formId] = convertToRaw(
      editorState.getCurrentContent(),
    );
    // console.log(restricted_sample);
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      ou_numbers,
    });
  }

  /**
   * Insert item before handler
   * @method onInsertRowBefore
   * @returns {undefined}
   */
  onInsertItemAfter() {
    const ou_numbers = { ...this.props.data.ou_numbers };
    const selectedIndex = this.state.selectedItem;
    const beforeItems = ou_numbers.items;
    let afterIndex;
    if (beforeItems.length === selectedIndex + 1) {
      afterIndex = [
        ...beforeItems.slice(0, this.state.selectedItem + 1),
        emptyItem(),
      ];
    } else {
      afterIndex = [
        ...beforeItems.slice(0, this.state.selectedItem + 1),
        emptyItem(),
        ...beforeItems.slice(this.state.selectedItem + 1),
      ];
    }
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      ou_numbers: {
        ...ou_numbers,
        items: afterIndex,
      },
    });
    this.setState({
      selectedItem: this.state.selectedItem + 1,
    });
  }

  /**
   * Delete row handler
   * @method onDeleteRow
   * @returns {undefined}
   */
  onDeleteItem() {
    const ou_numbers = { ...this.props.data.ou_numbers };

    if (this.state.selectedItem === ou_numbers.items.length - 1) {
      this.setState({
        selectedItem: this.state.selectedItem - 1,
      });
    }
    // console.log(this.state.selectedItem, this.props.data.restricted_sample);
    let items;
    if (this.props.data.ou_numbers.items.length > 1) {
      if (this.state.selectedItem === 0) {
        items = ou_numbers.items.slice(1);
      } else if (this.state.selectedItem === ou_numbers.items.length - 1) {
        items = ou_numbers.items.slice(0, ou_numbers.items.length - 1);
      } else {
        // console.log(
        //   '#####',
        //   0,
        //   this.state.selectedItem,
        //   this.state.selectedItem + 1,
        //   restricted_sample.items.length,
        // );
        items = ou_numbers.items
          .slice(0, this.state.selectedItem)
          .concat(
            ou_numbers.items.slice(
              this.state.selectedItem + 1,
              ou_numbers.items.length,
            ),
          );
      }
    }
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      ou_numbers: {
        ...ou_numbers,
        items: items,

        // items: remove(
        //   restricted_sample.items,
        //   (index) => index !== this.state.selectedItem,
        // ),
      },
    });
    // console.log(
    //   remove(
    //     restricted_sample.items,
    //     (index) => index !== this.state.selectedItem,
    //   ),
    // );
  }

  /**
   * d&d sorting handler.
   * @method onDragEnd
   * @param {*} result
   */
  // onDragEnd(result) {
  //   if (!result.destination) {
  //     return;
  //   }
  //   if (result.destination.index === result.source.index) {
  //     return;
  //   }
  //   const items = reorder(
  //     state.items,
  //     result.source.index,
  //     result.destination.index,
  //   );

  //   setState({ items });
  // }
  onDragEnd = (result) => {
    console.log('onDragEnd');
    const { source, destination } = result;
    console.log({
      source,
      destination,
      formData: this.state.formData,
      placeholderProps: this.state.placeholderProps,
    });
    if (!destination) {
      return;
    }
    // const blocksLayoutFieldname = getBlocksLayoutFieldname(this.state.formData);
    console.log(destination);
    this.setState({
      placeholderProps: {},
      selectedItem: destination.index ?? 0,
    });
    const ou_numbers = { ...this.props.data.ou_numbers };
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      ou_numbers: {
        ...ou_numbers,
        items: move(ou_numbers.items, source.index, destination.index),
      },
    });
    // this.setState({
    //   formData: {
    //     ...this.state.formData,
    //     [blocksLayoutFieldname]: {
    //       items: move(
    //         this.state.formData[blocksLayoutFieldname].items,
    //         source.index,
    //         destination.index,
    //       ),
    //     },
    //   },
    // });
  };

  handleDragStart = (event) => {
    console.log('handleDragStart');
    const queryAttr = 'data-rbd-draggable-id';
    const domQuery = `[${queryAttr}='${event.draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;
    var clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, sourceIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    this.setState({
      placeholderProps: {
        clientHeight,
        clientWidth,
        clientY,
        clientX: parseFloat(
          window.getComputedStyle(draggedDOM.parentNode).paddingLeft,
        ),
      },
    });
  };

  onDragUpdate = (update) => {
    console.log('onDragUpdate');
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;
    const destinationIndex = update.destination.index;

    const queryAttr = 'data-rbd-draggable-id';
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = update.source.index;
    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    var clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    this.setState({
      placeholderProps: {
        clientHeight,
        clientWidth,
        clientY,
        clientX: parseFloat(
          window.getComputedStyle(draggedDOM.parentNode).paddingLeft,
        ),
      },
    });
  };

  /**
   * Change event handler method.
   * @method onChangeTitle
   * @param {Object} editorState Editor state.
   * @returns {undefined}
   */
  onChangeTitle(editorState) {
    if (
      !isEqual(
        editorState.getCurrentContent().getPlainText(),
        this.state.titleEditorState.getCurrentContent().getPlainText(),
      )
    ) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        ou_numbers: {
          ...this.props.data.ou_numbers,
          title: editorState.getCurrentContent().getPlainText(),
        },
      });
    }
    this.setState({ titleEditorState: editorState });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    const placeholder =
      this.props.data.placeholder ||
      this.props.intl.formatMessage(messages.placeholder);
    const placeholderProps = this.state.placeholderProps;

    return (
      <div className="RestrictedByViewSample ui container">
        <Editor
          onChange={this.onChangeTitle}
          editorState={this.state.titleEditorState}
          blockRenderMap={extendedBlockRenderMap}
          handleReturn={() => {
            if (this.props.data.disableNewBlocks) {
              return 'handled';
            }
            this.props.onSelectBlock(
              this.props.onAddBlock(
                config.settings.defaultBlockType,
                this.props.index + 1,
              ),
            );
            return 'handled';
          }}
          placeholder={placeholder}
          blockStyleFn={() => blockStyle}
          onUpArrow={() => {
            const selectionState = this.state.editorState.getSelection();
            const { titleEditorState } = this.state;
            if (
              titleEditorState
                .getCurrentContent()
                .getBlockMap()
                .first()
                .getKey() === selectionState.getFocusKey()
            ) {
              this.props.onFocusPreviousBlock(this.props.block, this.node);
            }
          }}
          onDownArrow={() => {
            const selectionState = this.state.editorState.getSelection();
            const { titleEditorState } = this.state;
            if (
              titleEditorState
                .getCurrentContent()
                .getBlockMap()
                .last()
                .getKey() === selectionState.getFocusKey()
            ) {
              this.props.onFocusNextBlock(this.props.block, this.node);
            }
          }}
          ref={(node) => {
            this.node = node;
          }}
        />
        <DragDropContext
          onDragEnd={this.onDragEnd}
          onDragStart={this.handleDragStart}
          onDragUpdate={this.onDragUpdate}
        >
          <Droppable droppableId={`form-example-${this.props.block}`}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ position: 'relative' }}
              >
                {map(
                  this.props.data.ou_numbers?.items ?? [],
                  (itemData, index) => (
                    <Draggable
                      draggableId={itemData.key}
                      index={index}
                      key={itemData.key}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          // className={`block-editor-${blocksDict[block]['@type']}`}
                          className={'ou-now-editor draggableItem'}
                        >
                          <div style={{ position: 'relative' }}>
                            {/* {console.log(provided.draggableProps)} */}

                            {this.state.selectedItem === index ? (
                              <div
                                style={{ position: 'absolute' }}
                                // style={{
                                //   visibility:
                                //     this.state.selected === block &&
                                //     !this.hideHandler(blocksDict[block])
                                //       ? 'visible'
                                //       : 'hidden',
                                //   display: 'inline-block',
                                // }}
                                {...provided.dragHandleProps}
                                className="drag handle wrapper"
                              >
                                <Icon name={dragSVG} size="18px" />
                              </div>
                            ) : null}

                            <Item
                              key={itemData.key}
                              provided={provided}
                              isTableBlockSelected={this.props.selected}
                              index={index}
                              value={itemData}
                              selected={this.state.selectedItem}
                              onChangeList={this.onChangeList}
                              onSelectList={this.onSelectList}
                              onInsertItem={this.onInsertItemAfter}
                              onDeleteItem={this.onDeleteItem}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ),
                )}
                {provided.placeholder}
                {!isEmpty(placeholderProps) && (
                  <div
                    style={{
                      position: 'absolute',
                      top: `${placeholderProps.clientY}px`,
                      height: `${placeholderProps.clientHeight + 18}px`,
                      background: '#eee',
                      width: `${placeholderProps.clientWidth}px`,
                      borderRadius: '3px',
                    }}
                  ></div>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <SidebarPortal selected={this.props.selected}>
          <Sidebar {...this.props} index={this.state.selectedItem} />
          {/* <TargetsForm {...this.props} /> */}
        </SidebarPortal>
      </div>
    );
  }
}

export default injectIntl(Edit);
