/**
 * Setting
 */
export const SiteSetting = {
  searchPath: '/search',
};

/**
 * Localeのデフォルト値
 */
export const LocaleSetting = {
  DEFAULT_LANG: 'ja',
};

/**
 * Cookieに関する設定
 */
export const CookieSetting = {
  /**
   * Welcomeコンポーネントの表示非表示コントロール
   */
  WELCOME_VISIBLE: {
    name: 'www.welcome.visible',
    option: {
      path: '/',
      expires: new Date('2031/04/01'),
    },
    on: '1',
    off: '0',
  },
  /**
   * ユーザーの属性を保存する
   */
  USER_TYPE: {
    name: 'www.user.type',
    option: {
      path: '/',
      expires: new Date('2031/04/01'),
    },
  },
};
