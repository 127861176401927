import React, { useState } from 'react';
import ChatItemElement from './ChatItemElement';
import { ChatItemType } from '../../../../constants/Types';
import './ChatItemSelectForYouElement.css';
import { Button } from 'semantic-ui-react';
import welcomeStore from '../WelcomeElement/store/welcome';
import _ from 'lodash';
//import cookie from 'react-cookie';
import { Cookies } from 'react-cookie';
import { findTargetName, saveUserType } from 'helpers/UserType/UserType';
import { injectIntl } from 'react-intl';
import { isJa } from 'helpers/I18N/I18N';
import { connect } from 'react-redux';
import { CookieSetting } from 'constants/Setting';
import { Icon } from 'semantic-ui-react';
import NoSSR from 'react-no-ssr';

function onClickUserTypeButton(event, item, locale) {
  saveUserType(item?.id);
  return new Cookies().get(CookieSetting.USER_TYPE.name);
}

/**
 * Chat Item Select
 * @param {*} param0
 */
const ChatItemSelectForYouElement = ({ item, intl, targets, callback }) => {
  const [isClosed, setClosed] = useState(true);
  const [userType, setUserType] = useState(
    new Cookies().get(CookieSetting.USER_TYPE.name) ?? '',
  );
  // console.log(intl);
  return (
    <ChatItemElement item={item} additionalClass={'dark'}>
      <NoSSR>
        <div className="ChatItemSelectForYouElement">
          <div className="closeButton">
            <button onClick={() => setClosed(!isClosed)}>
              <img src="/assets/images/chat_item/you@4x.png" alt="" />
              {item?.label}
            </button>
            <Icon
              className="arrow"
              name={isClosed ? 'chevron down' : 'chevron up'}
              onClick={() => setClosed(!isClosed)}
            />
          </div>
          <div className={`body ${isClosed ? 'closed' : ''}`}>
            <h3 className="title">
              {item?.title} {findTargetName(targets, userType, intl?.locale)}
            </h3>
            <p className="content">{item?.content}</p>
            <ul className="select-user-type">
              {_.map(targets, (item) => {
                return (
                  <li className="user-type" key={item?.id}>
                    <Button
                      onClick={(event) => {
                        const userType = onClickUserTypeButton(
                          event,
                          item,
                          intl?.locale,
                        );
                        setUserType(userType);
                        // 引数として渡すCallbackを実行
                        if (callback) {
                          callback();
                        }
                      }}
                    >
                      {isJa(intl?.locale) ? item?.ja : item?.en}
                    </Button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </NoSSR>
    </ChatItemElement>
  );
};

ChatItemSelectForYouElement.propTypes = ChatItemType;

const ChatItemSelectForYouElementContainer = connect(
  (state, props) => {
    return {
      targets:
        state?.siteMainInfo?.result?.data?.attributes?.dynamic_common?.targets,
    };
  },
  (dispatch, props) => {
    return {};
  },
)(ChatItemSelectForYouElement);

export default injectIntl(ChatItemSelectForYouElementContainer);
