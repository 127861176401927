import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { isEqual } from 'lodash';
import { Editor, DefaultDraftBlockRenderMap, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { defineMessages, injectIntl } from 'react-intl';
import './Edit.css';

const messages = defineMessages({
  placeholder: {
    id: 'Type the title…',
    defaultMessage: 'Type the title…',
  },
});

const blockRenderMap = Map({
  unstyled: {
    element: 'div',
  },
});
const blockStyle = 'title';
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

const initEditorState = (text) => {
  if (!__SERVER__) {
    let editorState;
    //console.log({ text });
    if (text) {
      const contentState = stateFromHTML(text);
      editorState = EditorState.createWithContent(contentState);
    } else {
      editorState = EditorState.createEmpty();
    }
    return editorState;
  }
};

const Edit = ({ block, data, selected, onChangeBlock, intl }) => {
  //console.log({ data });
  const node = useRef(null);
  const text = data?.ouGridHeader;
  const [editorState, setEditorState] = useState(initEditorState(text));

  const onChange = (nextEditorState) => {
    if (
      !isEqual(
        nextEditorState.getCurrentContent().getPlainText(),
        editorState.getCurrentContent().getPlainText(),
      )
    ) {
      onChangeBlock(block, {
        ...data,
        ouGridHeader: nextEditorState.getCurrentContent().getPlainText(),
      });
    }
    setEditorState(nextEditorState);
  };

  return (
    <div className="Item">
      <Editor
        onChange={onChange}
        editorState={editorState}
        blockRenderMap={extendedBlockRenderMap}
        handleReturn={() => {
          // if (this.props.data.disableNewBlocks) {
          //   return 'handled';
          // }
          // this.props.onSelectBlock(
          //   this.props.onAddBlock(
          //     config.settings.defaultBlockType,
          //     this.props.index + 1,
          //   ),
          // );
          return 'handled';
        }}
        placeholder={intl.formatMessage(messages.placeholder)}
        // placeholder="Type text..."
        blockStyleFn={() => blockStyle}
        //   onUpArrow={() => {
        //     const selectionState = editorState.getSelection();
        //     if (
        //       editorState.getCurrentContent().getBlockMap().first().getKey() ===
        //       selectionState.getFocusKey()
        //     ) {
        //       onFocusPreviousBlock(block, this.node);
        //     }
        //   }}
        //   onDownArrow={() => {
        //     const selectionState = this.state.editorState.getSelection();
        //     const { editorState } = this.state;
        //     if (
        //       editorState.getCurrentContent().getBlockMap().last().getKey() ===
        //       selectionState.getFocusKey()
        //     ) {
        //       onFocusNextBlock(block, this.node);
        //     }
        //   }}
        ref={node}
      />
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Edit.propTypes = {
  block: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default injectIntl(Edit);
