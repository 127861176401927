import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import Schema from './Schema';

const messages = defineMessages({
  ou_numbers: {
    id: 'OU Numbers',
    defaultMessage: 'OU Numbers',
  },
});

const Sidebar = (props) => {
  const _ = props.intl.formatMessage;
  const type = props.data?.['@type'];
  const content = props.data?.[type];
  const index = props.index;
  if (!content) {
    return null;
  }
  // const initFormData = {
  //   unit: '',
  //   background_image_PC: [],
  //   background_image_SP: [],
  // };
  // console.log({ type, index, data: props.data });
  // console.log({ ...props.data[type], image: props.data[type].items[index] });

  return (
    <InlineForm
      schema={Schema}
      title={_(messages.ou_numbers)}
      // onChangeField={(id, value) => {
      //   // console.log({ type });
      //   // console.log(props.data);
      //   // console.log({ id, value });
      //   // if (id === 'image') {
      //   const items = props.data[type].items;
      //   items[index][id] = value;
      //   // console.log(items);
      //   props.onChangeBlock(props.block, {
      //     ...props.data,
      //     [type]: { ...props.data[type], items },
      //   });
      //   // } else {
      //   //   props.onChangeBlock(props.block, {
      //   //     ...props.data,
      //   //     [type]: { ...props.data[type], [id]: value },
      //   //   });
      //   // }
      // }}
      onChangeField={(id, value) => {
        if (
          id === 'background_image_PC' ||
          id === 'background_image_SP' ||
          id === 'unit'
        ) {
          const items = content.items;
          items[index][id] = value;
          props.onChangeBlock(props.block, {
            ...props.data,
            [type]: { ...content, items },
          });
        } else {
          props.onChangeBlock(props.block, {
            ...props.data,
            [type]: { ...content, [id]: value },
          });
        }
      }}
      formData={{
        background_image_PC: content.items?.[index]?.background_image_PC,
        background_image_SP: content.items?.[index]?.background_image_SP,
        unit: content?.items?.[index]?.unit,
        targets: content?.targets,
      }}
      // formData={initFormData}
    />
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Sidebar.propTypes = {
  // properties: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(Sidebar);
