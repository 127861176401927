import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
/* customize@nk24 on 20240318 v */
// import EditorUtils from '../../utils/EditorUtils';
import EditorUtils from '@plone/volto/components/manage/AnchorPlugin/utils/EditorUtils';
import { EditorState, Modifier, SelectionState } from 'draft-js';
/* customize@nk24 on 20240318 ^ */
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import AddLinkForm from '@plone/volto/components/manage/AnchorPlugin/components/LinkButton/AddLinkForm';
import Icon from '@plone/volto/components/theme/Icon/Icon';

import linkSVG from '@plone/volto/icons/link.svg';
import unlinkSVG from '@plone/volto/icons/unlink.svg';

// import unionClassNames from 'union-class-names';
//import EditorUtils from 'draft-js-plugins-utils';

/**
 * Add link form class.
 * @class LinkButton
 * @extends Component
 */
class LinkButton extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    theme: PropTypes.shape({}).isRequired,
    ownTheme: PropTypes.shape({}).isRequired,
    onRemoveLinkAtSelection: PropTypes.func.isRequired,
    onOverrideContent: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.DraftEditorUtils = props.draftJsPluginsUtils.default;
    this.EditorUtils = EditorUtils(props);
    /* @nk24 on 20240318 v */
    this.patchSelection = this.patchSelection.bind(this);
    /* @nk24 on 20240318 ^ */
  }

  /* @nk24 on 20240318 v */
  /* リンク確定時、前後に半角スペースを追加 */
  patchSelection = (editorState) => {
    const sel = editorState.getSelection();
    const anchorOffset = sel.anchorOffset;
    const focusOffset = sel.focusOffset;
    let start;
    let end;
    const key = sel.anchorKey;
    if (anchorOffset > focusOffset) {
      start = focusOffset;
      end = anchorOffset + 1;
    } else {
      start = anchorOffset;
      end = focusOffset + 1;
    }
    const startSelection = new SelectionState({
      anchorKey: key,
      anchorOffset: start,
      focusKey: key,
      focusOffset: start,
    });
    const startContentState = editorState.getCurrentContent();
    const insertedStartContentState = Modifier.insertText(
      startContentState,
      startSelection,
      ' ',
    );
    const bsies = EditorState.push(
      editorState,
      insertedStartContentState,
      'insert-fragment',
    );
    const endSelection = new SelectionState({
      anchorKey: key,
      anchorOffset: end,
      focusKey: key,
      focusOffset: end,
    });
    const endContentState = bsies.getCurrentContent();
    const insertedEndContentState = Modifier.insertText(
      endContentState,
      endSelection,
      ' ',
    );
    const esies = EditorState.push(
      bsies,
      insertedEndContentState,
      'insert-fragment',
    );
    const finalSelection = new SelectionState({
      anchorKey: key,
      anchorOffset: end + 1,
      focusKey: key,
      focusOffset: end + 1,
    });
    const fsies = EditorState.forceSelection(esies, finalSelection);
    return fsies;
  };
  /* @nk24 on 20240318 ^ */

  static defaultProps = {
    placeholder: '',
  };

  onMouseDown = (event) => {
    event.preventDefault();
  };

  onAddLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { ownTheme, placeholder, onOverrideContent } = this.props;
    const link = this.EditorUtils.getCurrentEntity(
      this.props.getEditorState(),
    )?.getData()?.url;

    const content = (props) => (
      <AddLinkForm
        {...props}
        placeholder={placeholder}
        theme={ownTheme}
        block="draft-js"
        data={{ url: link || '' }}
        onChangeBlock={() => {}}
        onClear={() => {
          this.props.setEditorState(
            this.DraftEditorUtils.removeLinkAtSelection(
              this.props.getEditorState(),
            ),
          );
        }}
        onChangeValue={(url) => {
          this.props.setEditorState(
            /* @nk24 on 20240318 v */
            this.patchSelection(
              this.DraftEditorUtils.createLinkAtSelection(
                this.props.getEditorState(),
                url,
              ),
            ),
            // this.DraftEditorUtils.createLinkAtSelection(
            //   this.props.getEditorState(),
            //   url,
            // ),
            /* @nk24 on 20240318 ^ */
          );
        }}
      />
    );
    onOverrideContent(content);
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { theme } = this.props;
    const hasLinkSelected = this.EditorUtils.hasEntity(
      this.props.getEditorState(),
      'LINK',
    );
    const className = hasLinkSelected
      ? cx(theme.button, theme.active)
      : theme.button;

    return (
      <div
        className={theme.buttonWrapper}
        onMouseDown={this.onMouseDown}
        role="presentation"
      >
        <button
          className={className}
          onClick={
            this.onAddLinkClick
            // hasLinkSelected
            //   ? () =>
            //       onRemoveLinkAtSelection(
            //         this.props.setEditorState,
            //         this.props.getEditorState,
            //       )
            //   : this.onAddLinkClick
          }
          type="button"
        >
          {!hasLinkSelected ? (
            <Icon name={linkSVG} size="24px" />
          ) : (
            <Icon name={unlinkSVG} size="24px" />
          )}
        </button>
      </div>
    );
  }
}

export default injectLazyLibs(['draftJs', 'draftJsPluginsUtils'])(LinkButton);
