import React, { useEffect, useState } from 'react';
import './ChatItemElement.css';
import { ChatItemType } from '../../../../constants/Types';

/**
 * Chat Itemの位置
 */
export const POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
  NONE: 'none',
};

/**
 * Chat ItemのType
 */
export const CHAT_ITEM_TYPE = {
  CHAT_ITEM_NORMAL: 'ChatItemNormal',
  CHAT_ITEM_GREETING: 'ChatItemGreeting',
  CHAT_ITEM_SELECT: 'ChatItemSelect',
  CHAT_ITEM_LIST: 'ChatItemList',
  CHAT_ITEM_MORE_BUTTON: 'ChatItemMoreButton',
  CHAT_ITEM_BANNER: 'ChatItemBanner',
  CHAT_ITEM_SELECT_FOR_YOU: 'ChatItemSelectForYou',
  CHAT_ITEM_TOUR: 'ChatItemTour',
};

const ChatItemElement = ({ children, item, additionalClass }) => {
  // console.log(item);
  const fromOu = item?.from?.position === POSITION.LEFT;
  const fromUser = item?.from?.position === POSITION.RIGHT;

  // const [start, setStart] = useState(false);
  // useEffect(() => {
  //   setStart(true);
  // }, [])
  return (
    <div
      className={`ChatItemElement ${additionalClass} ${item?.from?.position}`}
    >
      {fromOu && item?.from?.src ? (
        <div className="balloon-icon from-ou">
          <img src={item?.from?.src} alt={item?.from?.alt ?? ''} />
        </div>
      ) : null}

      <div
        className={`balloon balloon-${item?.from?.position ?? POSITION.NONE} ${
          item?.from?.src ? 'with-icon' : 'no-icon'
        }`}
        key={Math.random().toString(32).substring(2)}
      >
        {children}
      </div>
      {fromUser && item?.from?.src ? (
        <div className="balloon-icon from-user">
          <img src={item?.from?.src} alt={item?.from?.alt ?? ''} />
        </div>
      ) : null}

      {/* 指示線付きコメントは最大32文字程度 */}
      {item?.meta?.message ? (
        <div
          className={`meta ${
            item?.from?.position === 'right' ? 'right' : 'left'
          }`}
        >
          <div className="meta-inner">
            {item?.from?.position === 'right' ? (
              <img
                className="meta-right"
                src="/assets/images/chat_item/meta_right.svg"
                alt=""
                width="140"
              />
            ) : (
              <img
                className="meta-left"
                src="/assets/images/chat_item/meta_left.svg"
                alt=""
                width="140"
              />
            )}
            <div className="meta-info">
              <p>{item?.meta?.message}</p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

ChatItemElement.propTypes = ChatItemType;

export default ChatItemElement;
