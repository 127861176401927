import { GET_TOURS } from '@package/constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  result: {},
};

/**
 * Tour Reducer
 * @param {*} state
 * @param {*} action
 */
export default function tours(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_TOURS}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_TOURS}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        result: action?.result,
      };
    case `${GET_TOURS}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
