import React from 'react';

import { ListForOuGrid } from './Edit';
import './Edit.css';

const View = (props) => {
  return (
    <div className="ManualListForOuGridView">
      <ListForOuGrid props={props} />
    </div>
  );
};

export default View;
