import { AUTOMATIC_LIST_FOR_OU_GRID } from '../../constants/ActionTypes';

export function automaticListForOuGrid(path, subrequest) {
  return {
    type: AUTOMATIC_LIST_FOR_OU_GRID,
    subrequest,
    request: {
      op: 'get',
      path: `/@automatic-list-for-ou-gird?path=${path}`,
    },
  };
}
