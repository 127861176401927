/**
 * SitemapView component.
 * @module components/theme/View/SitemapView
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { sitemap as sitemapAction } from '../../../actions/index';
import './SitemapView.css';

/**
 * Component to display the SitemapView.
 * @function SitemapView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const SitemapView = () => {
  const [items, locale] = useSelector((state) => {
    return [
      state?.sitemap?.result?.data?.attributes?.items ?? [],
      state?.content?.data?.language?.token ?? 'ja',
    ];
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(sitemapAction(`/${locale}`));
  }, []);
  return (
    <div className="SitemapView OuPageView">
      <section className="mainArea">
        <div className="mainAreaInner">
          <div className="mainAreaInnerInner">
            <h2 className="title">Site Map</h2>
            {items.length > 0 && (
              <ul className="itemList">
                {items.map((item, index) => {
                  const { title, innerItems, url } = item;
                  return (
                    <li className="itemTitle arrow" key={index}>
                      <Link to={flattenToAppURL(url)}>{title}</Link>
                      {innerItems.length > 0 && (
                        <ul className="innerItemList">
                          {innerItems.map((innerItem, index) => {
                            const { title, url } = innerItem;
                            return (
                              <li className="innerItemTitle arrow" key={index}>
                                <Link to={flattenToAppURL(url)}>{title}</Link>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default injectIntl(SitemapView);
