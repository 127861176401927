import { STORYZ_FOLDER } from '../../constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

export function storyZFolder(bStart, path) {
  return {
    type: STORYZ_FOLDER,
    request: {
      op: 'get',
      path: `${flattenToAppURL(path)}/@storyz-folder?b_start=${bStart}`,
    },
    bStart: bStart,
  };
}
