/**
 * Data.jsx
 * based on @kitconcept/volto-blocks-grid v7.0.0
 * @nk24 2023/04/05
 */
import React from 'react';
import { useIntl } from 'react-intl';
import { BlockDataForm } from '@plone/volto/components';
/* @nk24 begin 2023/04/06 */
import { GridSchema } from './schema';
/* @nk24 end 2023/04/06 */

const GridData = (props) => {
  const { block, blocksConfig, data, onChangeBlock } = props;
  const intl = useIntl();
  const schema = GridSchema({ ...props, intl });

  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      block={block}
      blocksConfig={blocksConfig}
      onChangeBlock={onChangeBlock}
    />
  );
};

export default GridData;
