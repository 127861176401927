/**
 * Sidebar schema for news block.
 * @module components/manage/Blocks/News/Schema
 * @nk24:CMScom created 2021/04/18
 */

const Schema = {
  title: 'News',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['topics'],
    },
    {
      id: 'background-images',
      title: 'Background Images',
      fields: ['background_image_PC', 'background_image_SP'],
    },
  ],
  properties: {
    topics: {
      title: 'Topics',
      widget: 'object_browser',
      // mode: 'image',
      selectedItemAttrs: [],
    },
    background_image_PC: {
      title: 'Background image for PC',
      widget: 'object_browser',
      mode: 'image',
      selectedItemAttrs: [],
    },
    background_image_SP: {
      title: 'Background image for SP',
      widget: 'object_browser',
      mode: 'image',
      selectedItemAttrs: [],
    },
  },
  required: [],
};

export default Schema;
