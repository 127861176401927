import _ from 'lodash';
import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import './WelcomeDisplayElement.css';
import { interval, from, of, BehaviorSubject, Subject } from 'rxjs';
import { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import welcomeStore from './store/welcome';
import {
  ChatItemNormalElement,
  ChatItemGreetingElement,
  ChatItemSelectElement,
  ChatItemSelectForYouElement,
  ChatItemListElement,
  ChatItemBannerElement,
  ChatItemMoreButtonElement,
  ChatItemTourElement,
} from '../../../index';
import { connect, useDispatch } from 'react-redux';
import { getWelcomeList, getMoreWelcomeList } from '../../../../actions';
import { CHAT_ITEM_TYPE } from '../ChatItemElement/ChatItemElement';
import { ChatItemType } from '../../../../constants/Types';
import { isEn, isJa } from 'helpers/I18N/I18N';

/**
 * アイテムサンプル
 * @param {*} param0
 */
const WelcomeItem = ({ item, callback }) => {
  // console.log(item?.type);
  // console.log({type: CHAT_ITEM_TYPE.CHAT_ITEM_NORMAL})
  switch (item?.type) {
    case `${CHAT_ITEM_TYPE.CHAT_ITEM_NORMAL}`:
      return <ChatItemNormalElement item={item}></ChatItemNormalElement>;
    case `${CHAT_ITEM_TYPE.CHAT_ITEM_GREETING}`:
      return <ChatItemGreetingElement item={item}></ChatItemGreetingElement>;
    case `${CHAT_ITEM_TYPE.CHAT_ITEM_SELECT}`:
      return (
        <ChatItemSelectElement
          item={item}
          callback={callback}
        ></ChatItemSelectElement>
      );
    case `${CHAT_ITEM_TYPE.CHAT_ITEM_LIST}`:
      return <ChatItemListElement item={item}></ChatItemListElement>;
    case `${CHAT_ITEM_TYPE.CHAT_ITEM_BANNER}`:
      return <ChatItemBannerElement item={item}></ChatItemBannerElement>;
    case `${CHAT_ITEM_TYPE.CHAT_ITEM_MORE_BUTTON}`:
      return (
        <ChatItemMoreButtonElement
          item={item}
          callback={callback}
        ></ChatItemMoreButtonElement>
      );
    case `${CHAT_ITEM_TYPE.CHAT_ITEM_TOUR}`:
      return <ChatItemTourElement item={item}></ChatItemTourElement>;
    default:
      return <ChatItemNormalElement item={item}></ChatItemNormalElement>;
  }
};

/**
 * Type
 */
WelcomeItem.propTypes = ChatItemType;

/**
 * スクロール可能かどうか確認
 * @returns
 */
export function hasScrollBar() {
  return (
    document.documentElement.scrollHeight >
    document.documentElement.clientHeight
  );
}

/**
 * チャット表示を開始するポジション
 */
const RECOMMENT_ENTER_SCROLL_VALUE = 0;

/**
 * チャット表示コンポーネント
 * @param {*} param0
 */
const WelcomeDisplayElement = ({ getWelcomeList, intl, locale }) => {
  const [chatState, setChatState] = useState(welcomeStore.initialState);
  /**
   * 表示エリアに入ったかどうかのフラグ
   */
  const [enter, setEnter] = useState(false);
  /**
   * 表示エリアのノード
   */
  const [node, setNode] = useState(null);

  const dispatch = useDispatch();

  /**
   * 表示エリア確認用のスクロール時のハンドラ
   */
  const scrollHandler = useCallback(
    (event) => {
      if (typeof window !== 'undefined' && node) {
        const scrollTop = getScrollTop(node);
        if (scrollTop >= RECOMMENT_ENTER_SCROLL_VALUE && !enter) {
          // setEnter(true);
          // console.log('DISPLAY RECOMMEND!');
          // onFormSubmitAll(event, listAdditional);
          // getWelcomeList(locale);
        }
      }
    },
    [
      node,
      enter,
      // setEnter,
      // locale,
      // getWelcomeList
    ],
  );

  const getScrollTop = (node) => {
    const clientRect = node.getBoundingClientRect();
    // const scrollTop = document.documentElement.offsetHeight - clientRect.top;
    const scrollTop = window.pageYOffset;
    return scrollTop;
  };

  /**
   * 表示エリア確認ハンドラ発火のためのイベントリスナ追加
   */
  useEffect(() => {
    if (typeof window !== 'undefined' && node) {
      window.addEventListener('scroll', scrollHandler);
      const scrollTop = getScrollTop(node);
      console.log({
        scrollTop,
        RECOMMENT_ENTER_SCROLL_VALUE,
        enter,
        scrollTopPosition: window.pageYOffset,
      });

      // あとでリファクタリング
      if (!enter) {
        setEnter(true);
        // console.log('DISPLAY RECOMMEND!');
        // listAdditional.map((v) => welcomeStore.sendMessage(v));
        getWelcomeList(locale);
      }
    }

    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler, locale, enter, getWelcomeList, node]);

  useLayoutEffect(() => {
    if (__CLIENT__) {
      const subs = welcomeStore.subscribe(setChatState);
      welcomeStore.init();
      return () => subs.unsubscribe();
    }
  }, []);

  // const onFormSubmit = (e, message) => {
  //   e.preventDefault();
  //   welcomeStore.sendMessage(message);
  // };

  // const onFormSubmitAll = (e, messages) => {
  //   e.preventDefault();
  //   messages.map((v) => welcomeStore.sendMessage(v));
  // };

  // const onClearAll = (e) => {
  //   e.preventDefault();
  //   welcomeStore.clearChat();
  // };

  return (
    <main
      className="WelcomeDisplayElement"
      ref={(node) => {
        // 表示エリア判定用のノード
        setNode(node);
      }}
    >
      <div className="language-selector">
        <nav>
          <a className={isJa(intl.locale) ? 'active' : ''} href="/ja">
            日本語
          </a>
          <a className={isEn(intl.locale) ? 'active' : ''} href="/en">
            English
          </a>
        </nav>
      </div>
      <div className="inner">
        {chatState.data?.map((v) => {
          return (
            <WelcomeItem
              key={Math.random().toString(32).substring(2)}
              item={v}
              callback={(id) => dispatch(getMoreWelcomeList(locale, id))}
            ></WelcomeItem>
          );
        })}
      </div>
    </main>
  );
};

const WelcomeDisplayElementContainer = connect(
  (state, props) => {
    return {};
  },
  (dispatch, props) => {
    return {
      getWelcomeList: (locale) => dispatch(getWelcomeList(locale)),
    };
  },
)(WelcomeDisplayElement);

export default injectIntl(WelcomeDisplayElementContainer, {});
