import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import NoSSR from 'react-no-ssr';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { SidebarPortal } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import EditImageBlock from '@package/components/manage/Blocks/ImageForOuGrid/Edit';
import ViewImageBlock from '@package/components/manage/Blocks/ImageForOuGrid/View';

import Sidebar from './Sidebar';
import { automaticListForOuGrid } from '../../../../actions/automaticListForOuGrid/automaticListForOuGrid';

import './Edit.css';

export const ListForOuGrid = ({ props }) => {
  // Responsive
  const isPC = useMediaQuery({ query: '(min-width: 768px)' });

  // Definition
  const { data } = props;
  const blockSelected = props.selected; // true or false
  const objectPath = data?.object_path ?? [];
  const objectPathId = objectPath.length > 0 ? objectPath[0]['@id'] : '';
  const title = objectPath.length > 0 ? objectPath[0].title : '';
  const isEdit = props.edit; // true or false
  const sideImage = data.url; // string or undefined

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(automaticListForOuGrid(objectPathId, props.block));
    //console.log(elm);
    //console.log(JSON.stringify(elm.current.getBoundingClientRect().height));
    //if (JSON.stringify(elm.current.getBoundingClientRect().height > 200)) {
    //  console.log('###');
    //}
    //dispatch(
    //  searchContent(
    //    objectPathId,
    //    {
    //      portal_type: ['OuPage'],
    //      exclude_from_nav: false,
    //    },
    //    props.block,
    //  ),
    //);
  }, [objectPathId]);

  const [leadSentence, items, countPc, countSp] = useSelector((state) => {
    return [
      //state?.search?.subrequests[props.block]?.items ?? []
      state?.automaticListForOuGrid[props.block]?.result?.data?.attributes
        ?.lead_sentence ?? '',
      state?.automaticListForOuGrid[props.block]?.result?.data?.attributes
        ?.items ?? [],
      state?.siteMainInfo?.result?.data?.attributes?.common
        ?.listForOuGridCoutPc ?? 7,
      state?.siteMainInfo?.result?.data?.attributes?.common
        ?.listForOuGridCoutSp ?? 5,
    ];
  });

  // BoxHeight
  //const elm = useRef(null);
  //const [boxHeight, setBoxHeight] = useState(0);
  //const [openBox, setOpenBox] = useState(false);
  //useEffect(() => {
  //  setBoxHeight(elm?.current?.getBoundingClientRect()?.height);
  //}, [items]);
  //const overBoxHeight = boxHeight > 330;

  // showMoreList
  const moreListCount = isPC ? countPc : countSp;
  const [showMoreList, setShowMoreList] = useState(false);
  const clickMoreButton = () => {
    setShowMoreList(true);
  };

  // SelectSubBlock
  const [selectedSubBlock, setSelectedSubBlock] = useState(null);
  const patchdProps = (type) => {
    return {
      ...props,
      selected: blockSelected && selectedSubBlock === type,
      type,
    };
  };

  // Condition
  const isEditOrHasSideImage = isEdit || sideImage;
  const classHiddenMoreList = (index) =>
    index > moreListCount - 1 && !showMoreList ? ' hiddenMoreList' : '';
  const showMoreButton = !showMoreList && items.length > moreListCount;
  //const isViewAndOverBoxHeightAndNotOpenBox =
  //  isView && overBoxHeight && !openBox;

  // Function
  const clickSubBlock = (e) => {
    setSelectedSubBlock('list');
  };
  const clickImageSubBlock = (e) => {
    setSelectedSubBlock('image');
    if (blockSelected) {
      e.stopPropagation();
    }
  };

  return (
    <div
      className="ListForOuGrid"
      //className={`ListForOuGrid${
      //  isViewAndOverBoxHeightAndNotOpenBox ? ' overBoxHeight' : ''
      //}`}
      //ref={elm}
    >
      <div className="gridRow" onClick={clickSubBlock}>
        <NoSSR>
          {isEditOrHasSideImage && (
            <div className="gridColumn gridColumnImage gridColumnHalf">
              {isEdit ? (
                <div className="imageSubBlock" onClick={clickImageSubBlock}>
                  <EditImageBlock {...patchdProps('image')} />
                </div>
              ) : (
                <ViewImageBlock {...props} detached onChangeBlock={() => {}} />
              )}
            </div>
          )}
          <div
            className={`gridColumn gridColumn${
              isEditOrHasSideImage ? 'Half' : 'Full'
            }`}
          >
            <div className="boxHeightGradient">
              {title && <div className="title">{title}</div>}
              {leadSentence && (
                <div className="description">{leadSentence}</div>
              )}
              {items.length > 0 && (
                <ul className="links">
                  {items.map((item, index) => {
                    return (
                      <li
                        className={`link arrow${classHiddenMoreList(index)}`}
                        key={item.url}
                      >
                        <Link to={flattenToAppURL(item.url)}>{item.title}</Link>
                      </li>
                    );
                  })}
                  {showMoreButton && (
                    <li
                      className="link plus"
                      key="moreButton"
                      onClick={clickMoreButton}
                    >
                      MORE
                    </li>
                  )}
                </ul>
              )}
              {/*isViewAndOverBoxHeightAndNotOpenBox && (
                <ShowButton setOpenBox={setOpenBox} />
              )*/}
            </div>
          </div>
        </NoSSR>
      </div>
      {isEdit ? (
        <SidebarPortal selected={blockSelected && selectedSubBlock === 'list'}>
          <Sidebar
            // props={props}
            data={data}
            block={props.block}
            onChangeBlock={props.onChangeBlock}
          />
        </SidebarPortal>
      ) : null}
    </div>
  );
};

const Edit = (props) => {
  return (
    <div className="AutomaticListForOuGridView">
      <ListForOuGrid props={props} />
      {/* <SidebarPortal selected={blockSelected}> */}
    </div>
  );
};

export default Edit;
