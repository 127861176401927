/**
 * Schema for OU Now block sidebar
 * @nk24@CMScom
 */

const Schema = {
  title: 'OU Now',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['image', 'link'],
    },
    {
      id: 'taregets',
      title: 'Targets',
      fields: ['targets'],
    },
  ],
  properties: {
    image: {
      title: 'Image',
      widget: 'object_browser',
      mode: 'image',
      selectedItemAttrs: [], // path and Title
    },
    link: {
      title: 'Link',
      widget: 'object_browser',
      mode: 'link',
      allowExternals: true,
      selectedItemAttrs: [], // path and Title
    },
    targets: {
      title: 'Targets',
      widget: 'target_with_unselected',
      isMulti: true,
    },
  },
  required: [],
};

export default Schema;
