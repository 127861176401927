import React from 'react';
import ChatItemElement from './ChatItemElement';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { ChatItemType } from '../../../../constants/Types';
import './ChatItemNormalElement.css';

/**
 * Chat Item Normal
 * @param {*} param0
 */
const ChatItemNormalElement = ({ item }) => {
  return (
    <ChatItemElement
      item={item}
      additionalClass={`${item.link ? 'with-link' : ''}`}
    >
      <div className="ChatItemNormalElement">
        <div className="body">
          {item?.thumbnail?.src ? (
            <div className="thumbnail">
              <img
                src={item?.thumbnail?.src ?? ''}
                alt={item?.thumbnail?.alt ?? ''}
              />
            </div>
          ) : null}
          {item?.title ? <h3>{item?.title}</h3> : null}
        </div>
        {item?.content ? <div className="content">{item?.content}</div> : null}
        {item.link ? (
          <div className="link">
            <Link to={flattenToAppURL(item.link)}>
              <Icon className="arrow" name="chevron right" />
            </Link>
          </div>
        ) : null}
      </div>
    </ChatItemElement>
  );
};

ChatItemNormalElement.propTypes = ChatItemType;

export default ChatItemNormalElement;
