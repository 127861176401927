/**
 * emailNotification reducer.
 * @module reducers/emailNotification/emailNotification
 */

import {
  POST_MAIL_FORM_CONTENT,
  POST_MAIL_FORM_CONTENT_CLEAR,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
};

/**
 * emailNotification reducer.
 * @function emailNotification
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function sendMailForm(state = initialState, action = {}) {
  switch (action.type) {
    case `${POST_MAIL_FORM_CONTENT}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${POST_MAIL_FORM_CONTENT}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${POST_MAIL_FORM_CONTENT}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    case POST_MAIL_FORM_CONTENT_CLEAR:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
