/**
 * Toolbar reducer.
 * @module reducers/toolbar/toolbar
 */

import { PREVIEW_BLOCK } from '~/constants/ActionTypes';

const initialState = {
  preview: false,
};

/**
 * Toolbar reducer.
 * @function toolbar
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function previewBlock(state = initialState, action = {}) {
  switch (action.type) {
    case PREVIEW_BLOCK:
      return {
        preview: action.preview,
      };
    default:
      return state;
  }
}
