import { STORYZ_TOP_FOLDER } from '../../constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

export function storyZTopFolder(bStart, path) {
  return {
    type: STORYZ_TOP_FOLDER,
    request: {
      op: 'get',
      path: `${flattenToAppURL(path)}/@storyz-top-folder?b_start=${bStart}`,
    },
    bStart: bStart,
  };
}
