import React from 'react';
import ChatItemElement from './ChatItemElement';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ChatItemType } from '../../../../constants/Types';
import _ from 'lodash';
import './ChatItemTourElement.css';

/**
 * Chat Item Tour
 * @param {*} param0
 */
const ChatItemTourElement = ({ item }) => {
  return (
    <ChatItemElement
      item={item}
      additionalClass={`${item.link ? 'with-link' : ''}`}
    >
      <div className="ChatItemTourElement">
        {item?.thumbnail?.src ? (
          <div className="thumbnail">
            <a href={item?.link ?? ''}>
              <img
                src={item?.thumbnail?.src}
                alt={item?.thumbnail?.alt ?? ''}
              />
            </a>
          </div>
        ) : null}
        <div className="tour-meta">
          {item?.title ? (
            <h3>
              <Icon className="" name="flag" /> {item?.title}
            </h3>
          ) : null}
          {item?.content ? (
            <div className="content">{item?.content}</div>
          ) : null}
          {item?.tour ? (
            <div className="tour">
              {item?.tour?.title ? <h4>{item?.tour?.title}</h4> : null}
              {item?.tour?.list ? (
                <ul>
                  {_.map(item?.tour?.list, (item) => (
                    <li key={item?.link}>
                      <a href={item?.link ?? ''}>{item?.title ?? '-'}</a>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          ) : null}
          {item.link ? (
            <div className="link">
              <a href={item.link}>
                <Icon className="arrow" name="chevron right" />
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </ChatItemElement>
  );
};

ChatItemTourElement.propTypes = ChatItemType;

export default ChatItemTourElement;
