/**
 * View image block.
 * based on v13.8.2
 * @module components/manage/Blocks/Image/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { flattenToAppURLWithClean } from '@package/helpers/Url/Url';
import './View.css';
import config from '@plone/volto/registry';

/**
 * View image block class.
 * @class View
 * @extends Component
 */
const View = ({ data, detached }) => {
  /* @terapyon begin */
  /* same as UniversalLink.jsx */
  const excludeHosts = useSelector(
    (state) =>
      state?.siteMainInfo?.result?.data?.attributes?.common?.excludeHosts,
  );
  const url = flattenToAppURLWithClean(data.url, excludeHosts);
  const isBlacklisted = (config.settings.externalRoutes ?? []).find((route) =>
    matchPath(url, route.match),
  );
  const isExternal = !isInternalURL(url) || isBlacklisted;
  /* @terapyon end */
  return (
    <p
      className={cx(
        'block image align ImageBlock',
        {
          center: !Boolean(data.align),
          detached,
        },
        data.align,
      )}
    >
      {/* @nk24 begin */}
      <span
        className={cx({
          'wrapper full-width': data.align === 'full',
          'wrapper large': data.size === 'l',
          'wrapper medium': data.size === 'm',
          'wrapper small': data.size === 's',
        })}
      >
        {/* @nk24 end */}

        {data.url && (
          <>
            {(() => {
              const image = (
                <img
                  className={cx({
                    'full-width': data.align === 'full',
                    large: data.size === 'l',
                    medium: data.size === 'm',
                    small: data.size === 's',
                  })}
                  src={
                    !isExternal
                      ? // Backwards compat in the case that the block is storing the full server URL
                        (() => {
                          if (data.size === 'l') {
                            return `${url}/@@images/image`;
                          }
                          if (data.size === 'm') {
                            return `${url}/@@images/image/preview`;
                          }
                          if (data.size === 's') {
                            return `${url}/@@images/image/mini`;
                          }
                          return `${url}/@@images/image`;
                        })()
                      : url
                  }
                  alt={data.alt || ''}
                />
              );
              // @nk24 begin
              const caption = data.caption ? (
                <span className="caption">{data.caption}</span>
              ) : null;
              // @nk24 end
              if (data.href) {
                if (!isInternalURL(data.href)) {
                  return (
                    <a
                      target={data.openLinkInNewTab ? '_blank' : null}
                      href={data.href}
                    >
                      {image}
                      {/* nk24 begin */}
                      <br />
                      {caption}
                      {/* @nk24 end */}
                    </a>
                  );
                  /* nk24 begin */
                } else if (data.href.includes('@@download')) {
                  return (
                    <a href={flattenToAppURL(data.href)}>
                      {image}
                      <br />
                      {caption}
                    </a>
                  );
                  /* @nk24 end */
                } else {
                  return (
                    <Link
                      to={flattenToAppURL(data.href)}
                      target={data.openLinkInNewTab ? '_blank' : null}
                    >
                      {image}
                      {/* nk24 begin */}
                      <br />
                      {caption} {/* @nk24 end */}
                    </Link>
                  );
                }
              } else {
                /* nk24 begin */
                // return { image };
                return (
                  <>
                    {image} <br /> {caption}
                  </>
                );
                /* @nk24 end */
              }
            })()}
          </>
        )}
        {/* @nk24 begin */}
      </span>
      {/* @nk24 end */}
    </p>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
