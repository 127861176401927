/**
 * OU Numbers view block.
 * @module components/manage/Blocks/OUNumbers/View
 */

import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import NoSSR from 'react-no-ssr';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import redraft from 'redraft';
import Slider from 'react-slick';
import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
// import { settings } from '~/config';
import { LRFBlockHeader } from '~/components';
import { MediaQueryConfig } from '~/helpers/GlobalStyle/GlobalStyle';
import { isShowBlockByTargets } from '~/helpers/Targets/Targets';
import { getUserType } from '~/helpers/UserType/UserType';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './View.css';

/**
 * OUNow view block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  const content = data.ou_numbers;
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  const device_key = isSP ? 'background_image_SP' : 'background_image_PC';
  // const background_image_PC_path = content?.background_image_PC?.[0]['@id'];
  // const background_image_SP_path = content?.background_image_SP?.[0]['@id'];
  // let backgroundStylePC;
  // if (background_image_PC_path) {
  //   backgroundStylePC = {
  //     backgroundImage: `url(${flattenToAppURL(
  //       background_image_PC_path,
  //     )}/@@images/image)`,
  //   };
  // } else {
  //   backgroundStylePC = { background: 'none' };
  // }
  // let backgroundStyleSP;
  // if (background_image_SP_path) {
  //   backgroundStyleSP = {
  //     backgroundImage: `url(${flattenToAppURL(
  //       background_image_SP_path,
  //     )}/@@images/image)`,
  //   };
  // } else {
  //   backgroundStyleSP = { background: 'none' };
  // }

  const settings = {
    // className: 'slider variable-width center',
    dots: true,
    infinite: true,
    speed: 500,
    // variableWidth: true,
    // responsive: [
    //   {
    //     breakpoint: 767,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       variableWidth: false,
    //       centerMode: false,
    //     },
    //   },
    // ],
  };
  const [showBlock, setShowBlock] = useState(false);
  useEffect(() => {
    const isShowBlock = isShowBlockByTargets(content.targets, getUserType());
    if (isShowBlock !== showBlock) {
      setShowBlock(isShowBlock);
    }
  }, [content.targets, setShowBlock, showBlock]);
  return showBlock ? (
    <div className="OUNumbers">
      <NoSSR>
        <div className="BlockHeaderContainer">
          <LRFBlockHeader title={data.ou_numbers?.title} />
        </div>
        <div className="contents">
          <Slider {...settings}>
            {map(data.ou_numbers?.items || [], (element, index) => {
              return (
                <div>
                  <div
                    className="content"
                    key={element.key}
                    style={
                      content.items?.[index]?.[device_key]?.[0]?.['@id']
                        ? {
                            backgroundImage: `url(${flattenToAppURL(
                              content.items[index][device_key][0]['@id'],
                            )}/@@images/image)`,
                          }
                        : {}
                    }
                  >
                    <div className="container">
                      <h3>
                        {redraft(
                          element.header,
                          config.settings.ToHTMLRenderers,
                          config.settings.ToHTMLOptions,
                        )}
                      </h3>
                      <p className="number">
                        <span className="number">
                          {redraft(
                            element.number,
                            config.settings.ToHTMLRenderers,
                            config.settings.ToHTMLOptions,
                          )}
                        </span>
                        {element.unit && (
                          <span className="unit">{element.unit}</span>
                        )}
                      </p>
                      {element.text && (
                        <p className="comment">
                          {redraft(
                            element.text,
                            config.settings.ToHTMLRenderers,
                            config.settings.ToHTMLOptions,
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </NoSSR>
    </div>
  ) : null;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
