/**
 * Edit research block schema.
 * @module components/manage/Blocks/Research/Schema
 * @nk24:CMScom created 2021/04/17
 */

const Schema = {
  title: 'Research',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [],
    },
    {
      id: 'background-images',
      title: 'Background Images',
      fields: ['background_image_PC', 'background_image_SP'],
    },
    // {
    //   id: 'taregets',
    //   title: 'Targets',
    //   fields: ['targets'],
    // },
  ],
  properties: {
    background_image_PC: {
      title: 'Background image for PC',
      widget: 'object_browser',
      mode: 'image',
      selectedItemAttrs: [],
    },
    background_image_SP: {
      title: 'Background image for SP',
      widget: 'object_browser',
      mode: 'image',
      selectedItemAttrs: [],
    },
    // targets: {
    //   title: 'Targets',
    //   widget: 'target_with_unselected',
    //   isMulti: true,
    // },
  },
  required: [],
};

export default Schema;
