/**
 * emailNotification actions.
 * @module actions/emailNotification/emailNotification
 */

import {
  POST_MAIL_FORM_CONTENT,
  POST_MAIL_FORM_CONTENT_CLEAR,
} from '../../constants/ActionTypes';

/**
 * Email notification function
 * @function emailNotification
 * @param {string} from User id
 * @param {string} message Old password.
 * @param {string} name New password.
 * @param {string} subject New password.
 * @returns {Object} Edit password action.
 */
export function sendMailForm(path, formData) {
  return {
    type: POST_MAIL_FORM_CONTENT,
    request: {
      op: 'post',
      path: `${path}/@resou-mail-send`,
      data: formData,
    },
  };
}
export function clearSendMailFormStatus() {
  return { type: POST_MAIL_FORM_CONTENT_CLEAR };
}
