/**
 * コンテンツ移行機能を含むページコンテンツタイプ用View
 * @module components/theme/View/ImportedPageView
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container, Image } from 'semantic-ui-react';
import { map } from 'lodash';

// import { blocks } from '~/config';
import config from '@plone/volto/registry';

// import { canBlockPreview } from '~/actions';

import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
  getBaseUrl,
} from '@plone/volto/helpers';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

/**
 * Component to display the default view.
 * @function DefaultView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const ImportedPageView = ({
  content,
  intl,
  location,
  canBlockPreview,
  previewBlock,
}) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  // console.log(content);

  const [canBlockPreviewState, setCanBlockPreviewState] = useState(false);
  useEffect(() => {
    console.log(hasBlocksData(content), content.select_view_type);
    if (hasBlocksData(content) && !content.select_view_type) {
      if (!canBlockPreviewState) {
        setCanBlockPreviewState(true);
        canBlockPreview(true);
      }
    } else {
      if (canBlockPreviewState) {
        setCanBlockPreviewState(false);
        canBlockPreview(false);
      }
    }
  }, [canBlockPreviewState, content, canBlockPreview]);

  return hasBlocksData(content) &&
    (content.select_view_type || previewBlock) ? (
    <div id="page-document" className="ui container">
      {/* Preview mode message */}
      {previewBlock ? (
        <div class="ui info icon message">
          <i class="eye icon"></i>
          <div class="content">
            <div class="header">Preview block mode</div>
            <p>
              To publish as block mode, check (TODO) on the edit screen and save
              content.
            </p>
          </div>
        </div>
      ) : null}
      {map(content[blocksLayoutFieldname].items, (block) => {
        const Block =
          config.blocks.blocksConfig[
            content[blocksFieldname]?.[block]?.['@type']
          ]?.['view'] || null;
        return Block !== null ? (
          <Block
            key={block}
            id={block}
            properties={content}
            data={content[blocksFieldname][block]}
            path={getBaseUrl(location?.pathname || '')}
          />
        ) : (
          <div key={block}>
            {intl.formatMessage(messages.unknownBlock, {
              block: content[blocksFieldname]?.[block]?.['@type'],
            })}
          </div>
        );
      })}
    </div>
  ) : (
    <Container id="page-document">
      <h1 className="documentFirstHeading">{content.title}</h1>
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content.image && (
        <Image
          className="document-image"
          src={content.image.scales.thumb.download}
          floated="right"
        />
      )}
      {content.remoteUrl && (
        <span>
          The link address is:
          <a href={content.remoteUrl}>{content.remoteUrl}</a>
        </span>
      )}
      {content.text && (
        <div
          dangerouslySetInnerHTML={{
            __html: content.text.data,
          }}
        />
      )}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ImportedPageView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Text of the object
     */
    text: PropTypes.shape({
      /**
       * Data of the text of the object
       */
      data: PropTypes.string,
    }),
  }).isRequired,
  previewBlock: PropTypes.bool,
  canBlockPreview: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state, props) => ({
      content: state.content.data,
      previewBlock: state.previewBlock?.preview,
    }),
    {},
    // { canBlockPreview },
  ),
)(ImportedPageView);
