import { WelcomeElement } from 'components';
import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import { SampleChatItem } from '../ChatItemElement/SampleChatItem';
import './ComponentsBook.css';

const ComponentsBook = () => {
  return (
    <div className="ComponentsBook">
      {/* <h1>WelcomeDisplay</h1> */}
      {/* <WelcomeDisplayElement locale={'ja'}></WelcomeDisplayElement> */}
      <WelcomeElement defaultVisible={true}></WelcomeElement>
      <h1>Components Book</h1>
      <div className="chat-items">
        <SampleChatItem></SampleChatItem>
      </div>
    </div>
  );
};

export default injectIntl(ComponentsBook, {});
