/**
 * Home container.
 * @module components/theme/NotFound/NotFound
 */

import React from 'react';
//import { FormattedMessage } from 'react-intl';
//import { Link } from 'react-router-dom';
//import { Container } from 'semantic-ui-react';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';

import { OuNotFoundView } from '@package/components';

/**
 * Not found function.
 * @function NotFound
 * @returns {string} Markup of the not found page.
 */
const NotFound = () => <OuNotFoundView />;

export default withServerErrorCode(404)(NotFound);
