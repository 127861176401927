import { GET_EVENT_LIST } from '../../constants/ActionTypes';

export function eventList(bStart, target, term, locale, path = null) {
  return {
    type: GET_EVENT_LIST,
    request: {
      op: 'get',
      path: `${
        path ? path : `/${locale}/event`
      }/@event-list?b_start=${bStart}&target=${target}&term=${term}`,
    },
    bStart: bStart,
  };
}
