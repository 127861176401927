/**
 * TermFilterNews component.
 * @module components/theme/Element/TermFilter/TermFilterNews
 */

import moment from 'moment';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { englishMonth } from '../../../../helpers/GlobalStyle/Date.js';
import './TermFilter.css';

/**
 * Component to display the TermFilterNews
 * @function TermFilterNews
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const TermFilterNews = ({
  loadList,
  locale,
  path,
  setDisplayPickup,
  setNewsTerm,
  target,
}) => {
  const latest = locale === 'ja' ? '最新' : 'Latest';

  function termFilterOptions() {
    const dt = moment();
    const year = dt.year();
    const month = dt.month() + 1;

    const result = [
      {
        key: '',
        value: '',
        text: latest,
      },
    ];

    for (let m = month; m > 0; m--) {
      //const mdt = moment(`${year}-${m}-01`, 'YYYY-MM-DD');
      //const endOfMonth = mdt.endOf('month');
      //const ld = endOfMonth.date();
      const endOfMonth = dt.endOf('month');
      const ld = endOfMonth.date();
      dt.add('months', -1);
      result.push({
        key: m,
        value: `${year}-${m}-1|${year}-${m}-${ld}`,
        text:
          locale === 'ja' ? `${year}年${m}月` : `${englishMonth(m)}. ${year}`,
      });
    }

    for (let y = year - 1; y > 2007; y--) {
      result.push({
        key: y,
        value: `${y}-1-1|${y}-12-31`,
        text: `${y}`,
        //description: '年別表示',
      });
    }
    return result;
  }

  const changeHandle = (event, data) => {
    const newsTerm = data.value;
    setDisplayPickup(false);
    setNewsTerm(newsTerm);
    // hasNext: true, bSize: 0, bStart: 0
    loadList(true, 0, 0, target, newsTerm, locale, path);
  };

  return (
    <div className="TermFilterNews TermFilter">
      <Dropdown
        closeOnBlur
        name="term"
        options={termFilterOptions()}
        placeholder={latest}
        selection
        onChange={changeHandle}
      />
    </div>
  );
};

export default injectIntl(TermFilterNews);
