/**
 * View social link block
 * @module components/manage/Blocks/SocialLink/View
 * @nk24:CMScom 2021/05/13
 */

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import NoSSR from 'react-no-ssr';
import { useMediaQuery } from 'react-responsive';
import { Icon } from 'semantic-ui-react';
import { Icon as IconComponent } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { MediaQueryConfig } from '~/helpers/GlobalStyle/GlobalStyle';
import { isShowBlockByTargets } from '~/helpers/Targets/Targets';
import { getUserType } from '~/helpers/UserType/UserType';
import xSVG from '~/icons/x.svg';
import './View.css';

/**
 * View social link block class.
 * @function View
 */
const View = ({ data }) => {
  const content = data.social_link;
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  const background_image_PC_path = content?.background_image_PC?.[0]?.['@id'];
  const background_image_SP_path = content?.background_image_SP?.[0]?.['@id'];
  const targets = content?.targets;
  let backgroundStylePC;
  if (background_image_PC_path) {
    backgroundStylePC = {
      backgroundImage: `url(${flattenToAppURL(
        background_image_PC_path,
      )}/@@images/image)`,
    };
  } else {
    backgroundStylePC = { background: 'none' };
  }
  let backgroundStyleSP;
  if (background_image_SP_path) {
    backgroundStyleSP = {
      backgroundImage: `url(${flattenToAppURL(
        background_image_SP_path,
      )}/@@images/image)`,
    };
  } else {
    backgroundStyleSP = { background: 'none' };
  }
  const [showBlock, setShowBlock] = useState(false);
  useEffect(() => {
    const isShowBlock = isShowBlockByTargets(targets, getUserType());
    if (isShowBlock !== showBlock) {
      setShowBlock(isShowBlock);
    }
  }, [targets, setShowBlock, showBlock]);
  return showBlock ? (
    <NoSSR>
      <section
        className="SocialLink"
        id={data.key}
        style={isSP ? backgroundStyleSP : backgroundStylePC}
      >
        <h2 className="title">{content?.title}</h2>
        <p>{content?.description}</p>
        <div className="content">
          {content?.fb_url ? (
            <a href={content.fb_url}>
              <Icon name="facebook f" />
            </a>
          ) : null}
          {content?.twitter_url ? (
            <a href={content.twitter_url}>
              {/* <Icon name="twitter" /> */}
              <i aria-hidden="true">
                {isSP ? (
                  <IconComponent name={xSVG} size="18px" />
                ) : (
                  <IconComponent name={xSVG} size="28px" />
                )}
              </i>
            </a>
          ) : null}
          {content?.instagram_url ? (
            <a href={content.instagram_url}>
              <Icon name="instagram" />
            </a>
          ) : null}
          {content?.youtube_url ? (
            <a href={content.youtube_url}>
              <Icon name="youtube" />
            </a>
          ) : null}
          {content?.linkedin_url ? (
            <a href={content.linkedin_url}>
              <Icon name="linkedin" />
            </a>
          ) : null}
        </div>
      </section>
    </NoSSR>
  ) : null;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  // properties: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
