/**
 * Search Component
 */

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from 'semantic-ui-react';
import { MediaQueryConfig } from '../../../../helpers/GlobalStyle/GlobalStyle';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import './SearchElement.css';
import { SearchBoxForPage } from '~/components';
import qs from 'query-string';

//　Chat表示コンポーネント関連
import SearchDisplayElement from './SearchDisplayElement';
//import cookie from 'react-cookie';
import { Cookies } from 'react-cookie';
import { CookieSetting } from '../../../../constants/Setting';
import { getSearchResult } from '../../../../actions';
import { stringToBoolean } from '../../../../helpers/Cookie/Cookie';
import { doSearch } from '../../../../helpers/Search/Search';
import { SampleChatItem } from '../ChatItemElement/SampleChatItem';
import config from '@plone/volto/registry';
import { getLocaleFromPathname } from 'helpers/Locale/Locale';

/**
 * 検索関連エレメントのラッパーコンポーネント
 * @param {*} param0
 * @returns
 */
const SerachElement = ({
  getSearchResult,
  intl,
  q,
  subject,
  path,
  locale,
  history,
}) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(null);

  useEffect(() => {
    doSearch(history, dispatch, q, subject, path, null, 0, locale);
  }, [history, dispatch, q, subject, path, locale]);

  /**
   * 表示エリアのノード
   */
  const [node, setNode] = useState(null);

  return (
    <NoSSR>
      <div
        className="SearchElement outer"
        ref={(node) => {
          // 表示エリア判定用のノード
          setNode(node);
        }}
      >
        <div className="inner">
          <div className="header">
            <h1>Search Results</h1>
            <div className="searchBoxForPage">
              <SearchBoxForPage inputValue={q}></SearchBoxForPage>
            </div>
          </div>
          {__CLIENT__ ? (
            <div className="body">
              <SearchDisplayElement
                parent={node}
                history={history}
                inputValue={q}
                path={path}
                locale={locale}
              ></SearchDisplayElement>
            </div>
          ) : null}
        </div>
      </div>
    </NoSSR>
  );
};

SerachElement.propTypes = {
  intl: PropTypes.object,
  visible: PropTypes.string,
  location: PropTypes.object,
};

const SerachElementContainer = connect(
  (state, props) => {
    // console.log({ props__location__search: props?.location?.search });
    // TODO: 検索なのでこれでない形でのロケール判定が必要
    // const locale = state?.content?.data?.language?.token ?? 'ja';
    const locale =
      qs.parse(props?.location?.search).lang ||
      new Cookies().get('I18N_LANGUAGE') ||
      config.settings.defaultLanguage;
    // getLocaleFromPathname(
    //   __CLIENT__ ? window?.location.pathname : null,
    //   config.settings.defaultLanguage,
    // );

    return {
      //    visible: state?.welcomeList?.visible,
      locale,
      q: qs.parse(props?.location?.search).q ?? '',
      subject: qs.parse(props.location.search).Subject,
      path: qs.parse(props.location.search).path,
    };
  },
  (dispatch, props) => {
    return {
      // TODO: 不要
      // getSearchResult: () => dispatch(getSearchResult()),
    };
  },
)(SerachElement);

export default injectIntl(SerachElementContainer, {});
