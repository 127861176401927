import { map } from 'lodash';
import React, { createRef, useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import redraft from 'redraft';

import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Modal } from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  flattenToAppURL,
  getBlocksFieldname,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';
import { MediaQueryConfig } from '~/helpers/GlobalStyle/GlobalStyle';
import { validateFormValue } from '@package/helpers/Validator/Validator';
import { BlackBox, HelmetSite } from '@package/components';
import { clearSendMailFormStatus, sendMailForm } from '@package/actions';
// import { settings } from '~/config';
// import NoSSR from 'react-no-ssr';
import config from '@plone/volto/registry';
import './OuMailFormView.css';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  requiredItem: {
    id: 'requiredItem',
    defaultMessage: 'Required',
  },
  acceptPrivacyPolicy: {
    id: 'Accept Privacy Policy',
    defaultMessage: 'I accept the terms of the Privacy Policy.',
  },
  inquirySent: {
    id: 'Inquiry Sent',
    defaultMessage: 'This message has been sent.',
  },
  // backToPreviousPage: {
  //   id: 'Back to Previous Page',
  //   defaultMessage: 'Back to the previous page',
  // },
  close: {
    id: 'Close',
    defaultMessage: 'Close',
  },
  submitMailForm: {
    id: 'Submit Mail Form',
    defaultMessage: 'Send',
  },
});

/**
 * formDataObj Object
 * MailFormView - 各Block間で情報をやりとりするためのオブジェクト
 * @param {*} id
 * @param {*} data
 * @param {*} value
 */
const formDataObj = (id, data, value = '') => {
  return {
    id: id,
    formId: data.formId,
    label: data.label,
    placeholder: data.placeholder,
    value: value,
    required: data.requiredBlock,
    validation: data.validation || '',
    errorMsg: '',
    options: data.options || [],
  };
};

/**
 * Mailform content view component class.
 * @function ResearchFolderView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OuMailFormView = ({
  content,
  intl,
  clearMessage,
  sendMessage,
  error,
  loading,
  loaded,
  history,
  recaptchaSiteKey,
  form_url,
}) => {
  const recaptchaRef = createRef();
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  const agree_IPS_sentence =
    content.agree_IPS_sentence.data !== '<p><br/></p>'
      ? content.agree_IPS_sentence.data
      : null;
  /* ステートフック */
  const [formData, setFormData] = useState({}); // フォーム入力値の一時ストア
  // const [errorStatus, setErrorStatus] = useState([]); // 各Blockのエラー状態
  const [modalOpen, setModalOpen] = useState(true); // 送信ずみmessageモーダルの開閉状態をセット
  const [agreeIps, setAgreeIps] = useState(false); // 個人情報チェックボタンのチェック状態
  const [rechaptchaStatus, setRecaptchaStatus] = useState(false); // reCAPTCHAのチェック状態
  // const [submitClicked, setSubmitClicked] = useState(false); // submitボタンが押されたかどうか
  useEffect(() => {
    const initFormData = () => {
      let formDataObjs = {};
      const blocksAttr = content[blocksFieldname];
      Object.keys(blocksAttr).forEach(function (key) {
        const data = blocksAttr[key];
        if (
          [
            'mailform_textline',
            'mailform_textarea',
            'mailform_select',
          ].includes(data['@type'])
        ) {
          const formData = formDataObj(key, data);
          formDataObjs = {
            ...formDataObjs,
            [key]: formData,
          };
        }
      });
      return formDataObjs;
    };
    setFormData(initFormData());
  }, [blocksFieldname, content]);

  /**
   * handleInputData
   * フォーム入力値をstateに保存する（データ入力ごとにonChange様にコールされる）
   * @param {*} data
   */
  const handleInputData = (data) => {
    setFormData((formData) => ({
      ...formData,
      [data.id]: { ...formData[data.id], value: data.value },
    }));
  };

  const handleBlur = (data) => {
    setFormData((formData) => ({
      ...formData,
      [data.id]: {
        ...formData[data.id],
        errorMsg:
          validateFormValue(
            data.value,
            data.label,
            data.required,
            data.validation,
            intl.locale,
          ) || '',
      },
    }));
  };

  const handleChecked = (event, data) => {
    setFormData({
      ...formData,
      [data.id]: {
        label: data.label,
        value: data.checked,
        readableValue: data.checked ? 'Yes' : 'No',
      },
    });
    setAgreeIps(data.checked);
  };

  /**
   * handleCompleteCaptcha
   * reCAPTCHAをpassした時コールされる関数
   * @param {*} value pass時に返される値
   */
  function handleCompleteCaptcha(value) {
    // console.log({ captchaValue: value });
    setFormData({ ...formData, captchaValue: value });
    setRecaptchaStatus(true);
  }

  const handleSend = (e) => {
    let hasError = false;
    let checkedFormData = {};
    for (let key in formData) {
      if (key !== 'captchaValue') {
        const data = formData[key];
        const errorMsg =
          validateFormValue(
            data.value,
            data.label,
            data.required,
            data.validation,
            intl.locale,
          ) || '';
        if (errorMsg) {
          hasError = true;
        }
        checkedFormData = {
          ...checkedFormData,
          [key]: { ...data, errorMsg: errorMsg },
        };
      } else {
        checkedFormData = {
          ...checkedFormData,
          [key]: formData[key],
        };
      }
    }
    setFormData(checkedFormData);
    if (!hasError) {
      sendMessage({ url: form_url, ...formData });
    } else {
      e.preventDefault();
    }
  };

  const closeModal = () => {
    recaptchaRef.current.props.grecaptcha.reset();
    setModalOpen(false);
    // 入力フォームクリア
    let clearedFormData = {};
    for (let key in formData) {
      const data = formData[key];
      clearedFormData = {
        ...clearedFormData,
        [key]: { ...data, value: '' },
      };
    }
    clearMessage();
    setFormData(clearedFormData);
    setAgreeIps(false);
    setRecaptchaStatus(false);
    if (content.redirect_url) {
      history.push(content.redirect_url);
    } else {
      history.push(flattenToAppURL(content['@id']));
    }
  };
  return (
    <div className="MailFormView">
      <HelmetSite content={content} />
      <section className="mainArea">
        <div className="mainAreaInner">
          <div className="mainAreaInnerInner">
            <div className="bodyArea">
              <div className="mailFormHeader">
                <div className="blackBoxWrapper">
                  <Link to={flattenToAppURL(content?.parent['@id'])}>
                    <BlackBox title={content?.parent?.title} />
                  </Link>
                </div>
                <h2 className="title">{content.title}</h2>
                <p className="description">{content.description}</p>
              </div>
              <div className="mailFormBody">
                <Form method="post" onSubmit={handleSend}>
                  <div className="RequiredGuide">
                    <FormattedMessage
                      id="Required Guide"
                      defaultMessage="* Required"
                    />
                  </div>
                  {map(content[blocksLayoutFieldname].items, (block) => {
                    const blockType =
                      config.blocks.blocksConfig[
                        content[blocksFieldname]?.[block]?.['@type']
                      ];
                    const isTitle = blockType?.['id'] === 'title';
                    if (isTitle) {
                      return;
                    }
                    const Block = blockType?.['view'] || null;
                    return Block !== null ? (
                      <Block
                        key={block}
                        id={block}
                        data={content[blocksFieldname][block]}
                        formData={formData[block]}
                        handleInputData={handleInputData}
                        handleBlur={handleBlur}
                      />
                    ) : (
                      <div key={block}>
                        {intl.formatMessage(messages.unknownBlock, {
                          block: content[blocksFieldname]?.[block]?.['@type'],
                        })}
                      </div>
                    );
                  })}
                  {agree_IPS_sentence ? (
                    <>
                      <div
                        className="agreeIpsSentence"
                        dangerouslySetInnerHTML={{
                          __html: agree_IPS_sentence,
                        }}
                      />
                      <Checkbox
                        id="agreeIps"
                        checked={agreeIps}
                        className="agreeIps"
                        label={intl.formatMessage(messages.acceptPrivacyPolicy)}
                        onChange={handleChecked}
                      />
                    </>
                  ) : null}
                  <div className="rcpc">
                    <div className="rcpcView">
                      {recaptchaSiteKey ? (
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={recaptchaSiteKey || ''}
                          onChange={handleCompleteCaptcha}
                          hl={intl.locale === 'ja' ? 'ja' : 'en'}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="ButtonContainer">
                    <Button
                      className="PrimaryButton"
                      disabled={
                        !((!agree_IPS_sentence || agreeIps) && rechaptchaStatus)
                      }
                    >
                      <FormattedMessage id="Send" defaultMessage="Send" />
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </Container> */}
      <Modal
        className="MailFormView MailSentInfoModal"
        open={loaded}
        onClose={closeModal}
        centered={false}
        closeIcon
      >
        <div className="modalContent">
          <h2>{intl.formatMessage(messages.inquirySent)}</h2>
          <button onClick={closeModal}>
            {intl.formatMessage(messages.close)}
          </button>
        </div>
      </Modal>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuMailFormView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    agree_IPS_sentence: PropTypes.object,
  }).isRequired,
};

const OuMailFormViewContainer = connect(
  (state, props) => {
    return {
      recaptchaSiteKey:
        state?.siteMainInfo?.result?.data?.attributes?.dynamic_common
          ?.recaptcha_site_key,
      error: state.sendMailForm?.error,
      loading: state.sendMailForm?.loading,
      loaded: state.sendMailForm?.loaded,
      form_url: props?.content?.['@id']
        ? props.content['@id'].replace('/api', '')
        : '',
    };
  },
  (dispatch, props) => ({
    sendMessage: (formData) => {
      const path = props?.location?.pathname;
      dispatch(sendMailForm(path, formData));
    },
    clearMessage: () => {
      dispatch(clearSendMailFormStatus());
    },
  }),
)(OuMailFormView);

export default injectIntl(OuMailFormViewContainer);
