/**
 * OuBody component.
 * @module components/theme/Element/OuBody/OuBody
 */

import { map } from 'lodash';
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
// import { blocks } from '~/config';
import config from '@plone/volto/registry';
import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
} from '@plone/volto/helpers';
import './OuBody.css';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

/**
 * Component to display the OuBody
 * @function OuBody
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const OuBody = ({ content, intl }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);

  return (
    <div className="OuBody">
      {hasBlocksData(content) &&
        map(content[blocksLayoutFieldname].items, (block) => {
          const blockType =
            config.blocks.blocksConfig[
              content[blocksFieldname]?.[block]?.['@type']
            ];
          const isTitle = blockType?.['id'] === 'title';
          if (isTitle) {
            return <div key="title"></div>;
          }
          const Block = blockType?.['view'] || null;
          return Block !== null ? (
            <Block
              key={block}
              id={block}
              properties={content}
              data={content[blocksFieldname][block]}
            />
          ) : (
            <div key={block}>
              {intl.formatMessage(messages.unknownBlock, {
                block: content[blocksFieldname]?.[block]?.['@type'],
              })}
            </div>
          );
        })}
    </div>
  );
};

export default injectIntl(OuBody);
