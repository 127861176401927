/**
 * Header viewlet component for block of language root folders.
 * @module component/Element/LRFBlockHeader/LRFBlockHeader
 * @nk24:CMScom
 */
import React from 'react';
import './LRFBlockHeader.css';

const LRFBlockHeader = (props) => {
  const { title, negative } = props;
  const className = negative ? 'LRFBlockHeader negative' : 'LRFBlockHeader';
  return (
    <header className={className}>
      <hr />
      <h2>{title}</h2>
    </header>
  );
};

export default LRFBlockHeader;
