/**
 * ForYou component.
 * @module components/theme/Site/ForYou/ForYou
 */

import _ from 'lodash';

import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FOR_YOU_TYPES } from '../ForYouButton/ForYouButton';
import { SearchItem } from '../../../index';
import { forYou as forYouApi } from '../../../../actions/forYou/forYou';
import { forYouMore as forYouMoreApi } from '../../../../actions/forYouMore/forYouMore';
import { getUserType } from '../../../../helpers/UserType/UserType.js';
import './ForYou.css';

/**
 * Component to display the ForYou.
 * @function ForYou
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const ForYou = ({ app, content, visibleForYou }) => {
  const [display, moreButton, displayMore] = useSelector((state) => {
    return [
      state?.forYou?.result?.data?.attributes?.display ?? [],
      state?.forYou?.result?.data?.attributes?.more_button ?? {},
      state?.forYouMore?.result?.data?.attributes?.display ?? [],
    ];
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (FOR_YOU_TYPES.includes(_.get(content, '@type'))) {
      dispatch(forYouApi(_.get(content, '@id'), getUserType()));
    }
    return () => {
      app.setVisibleForYou(false);
      app.setVisibleForYouButton(true);
    };
  }, [content]);
  const [visibleMoreButton, setVisibleMoreButton] = useState(true);
  return (
    <div id="ForYou">
      {visibleForYou && (
        <div className="ForYou">
          <div className="forYouInner">
            <h2 className="header">
              <img
                alt="for you"
                src={flattenToAppURL(
                  '/assets/images/foryou/foryou-ttl-img.png',
                )}
              />
              <h2 className="title">FOR YOU</h2>
              <div className="subtitle">Recommended</div>
            </h2>
            {display.map((item, index) => {
              return (
                <SearchItem
                  item={item}
                  key={index}
                  callback={() =>
                    dispatch(forYouApi(_.get(content, '@id'), getUserType()))
                  }
                />
              );
            })}
            <div className={visibleMoreButton ? '' : 'hidden'}>
              <SearchItem
                item={moreButton}
                callback={() => {
                  dispatch(forYouMoreApi(_.get(content, '@id'), getUserType()));
                  setVisibleMoreButton(false);
                }}
              />
            </div>
            {displayMore.map((item, index) => {
              return <SearchItem item={item} key={index} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default injectIntl(ForYou);
