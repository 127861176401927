/**
 * OuNewsView component.
 * @module components/theme/View/OuNewsView
 */

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import NoSSR from 'react-no-ssr';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { NewsListItem } from './OuNewsFolderView';
import {
  BlackBox,
  HelmetSite,
  MoreButton,
  OuBody,
  PageMainImage,
  PageTitle,
  Pickup,
  SNSShare,
  TargetFilter,
  TermFilterNews,
  VisualClear,
} from '../../index';
import { newsList } from '../../../actions/newsList/newsList';
import { formatedDateDay } from '../../../helpers/GlobalStyle/Date.js';
import { getUserType } from '../../../helpers/UserType/UserType.js';
import './OuNewsView.css';

/**
 * Component to display the OuNewsView.
 * @function OuNewsView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const MainArea = ({ content }) => {
  return (
    <section className="mainArea">
      <div className="mainAreaInner">
        <div className="blackBoxWrapper">
          <BlackBox title="News" />
        </div>
        <PageMainImage content={content} />
        <div className="infoBox">
          <div className="date">
            {formatedDateDay(content?.announcement_date)}
          </div>
          {!content?.exclude_from_for_you &&
            (content?.targets?.length > 0 ? (
              <ul className="targetList">
                {_.map(content?.targets, (target) => {
                  return (
                    <li className="target" key={target?.token}>
                      <Icon name="user" />
                      <span className="name">{target?.title}</span>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <ul className="targetList">
                <li className="target">
                  <Icon name="users" />
                  <span className="name">ALL</span>
                </li>
              </ul>
            ))}
          <div style={{ clear: 'left' }} />
        </div>
        <PageTitle title={content?.title} />
        <OuBody content={content} />
        <VisualClear />
        <SNSShare url={content['@id']} />
      </div>
    </section>
  );
};

const SideAreaComponent = ({
  bSize,
  bStart,
  content,
  hasNext,
  loadNewsList,
  locale,
  newsList,
  pickups,
}) => {
  const userType = getUserType() === undefined ? '' : getUserType();
  const [target, setTarget] = useState(userType);
  const [newsTerm, setNewsTerm] = useState('');
  const [displayPickup, setDisplayPickup] = useState(true);
  useEffect(() => {
    // hasNext: true, bSize: 0, bStart: 0
    loadNewsList(true, 0, 0, target, newsTerm, locale); // 1回目の読み込み
  }, []);

  return (
    <aside className="sideArea">
      <div className="sideAreaInner">
        <div className="blackBoxWrapper">
          <BlackBox title="Search News" />
        </div>
        <div className="filters">
          <div className="targetFilterWrapper">
            <TargetFilter
              loadList={loadNewsList}
              locale={locale}
              setTarget={setTarget}
              target={target}
              term={newsTerm}
              userType={userType}
            />
          </div>
          <div className="termFilterWrapper">
            <TermFilterNews
              loadList={loadNewsList}
              locale={locale}
              setDisplayPickup={setDisplayPickup}
              setNewsTerm={setNewsTerm}
              target={target}
            />
          </div>
        </div>
        {displayPickup && <Pickup ctype="OuNews" pickups={pickups} />}
        {newsList?.length > 0 && (
          <ul className="newsList items">
            {_.map(newsList, (item) => {
              return (
                <li className="item" key={item?.url}>
                  <NewsListItem contentURL={content['@id']} item={item} />
                </li>
              );
            })}
          </ul>
        )}
        <MoreButton
          bSize={bSize}
          bStart={bStart}
          hasNext={hasNext}
          loadList={loadNewsList}
          locale={locale}
          target={target}
          term={newsTerm}
        />
      </div>
    </aside>
  );
};

const SideArea = connect(
  (state, props) => {
    return {
      bSize: state?.newsList?.result?.data?.attributes?.b_size ?? 0,
      bStart: state?.newsList?.result?.data?.attributes?.b_start ?? 0,
      newsList: state?.newsList?.newsList,
      hasNext: state?.newsList?.result?.data?.attributes?.has_next ?? true,
      locale: state?.content?.data?.language?.token ?? 'ja',
      pickups: state?.newsList?.result?.data?.attributes?.pickups,
    };
  },
  (dispatch, props) => ({
    loadNewsList: (hasNext, bSize, bStart, target, term, locale) => {
      if (hasNext) {
        dispatch(newsList(bSize + bStart, target, term, locale));
      }
    },
  }),
)(SideAreaComponent);

const OuNewsView = ({ content }) => {
  return (
    <div className="OuNewsView OuNewsAndEventView">
      <HelmetSite content={content} />
      <div className="ouNewsAndEventViewWrapper">
        <MainArea className="mainArea" content={content} />
        <SideArea className="sideArea" content={content} />
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuNewsView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default injectIntl(OuNewsView);
