/**
 * Add your config changes here.
 * @module config
 * @example
 * export const settings = {
 *   ...defaultSettings,
 *   port: 4300,
 *   listBlockTypes: {
 *     ...defaultSettings.listBlockTypes,
 *     'my-list-item',
 *   }
 * }
 */

import { Map } from 'immutable';
import React from 'react';
import { defineMessages } from 'react-intl';
import { customBlocks, groupBlocksOrder } from './constants/CustomBlocks';
import { ObjectBrowserWidgetMode } from '@plone/volto/components/manage/Widgets/ObjectBrowserWidget';
import OuMigratePageView from '@package/components/theme/View/OuMigratePageView';
import OuHTMLView from '@package/components/theme/View/OuHTMLView';
import {
  LanguageRootFolderView,
  OuCategoryFolderView,
  OuNewsFolderView,
  OuEventFolderView,
  OuFolderView,
  OuPageView,
  OuNewsView,
  OuEventView,
  // ImportedPageView,
  // OuImportedPageView,
  //OuHTMLView,
  // SourceDisplayWidget,
  // UrgentNotifyWidget,
} from './components';

import OuFAQTopFolderView from '@package/components/theme/View/OuFAQTopFolderView';
import OuFAQFolderView from '@package/components/theme/View/OuFAQFolderView';
import OuFAQView from '@package/components/theme/View/OuFAQView';
import OuStoryZTopFolderView from '@package/components/theme/View/OuStoryZTopFolderView';
import OuStoryZFolderView from '@package/components/theme/View/OuStoryZFolderView';
import OuStoryZView from '@package/components/theme/View/OuStoryZView';
import OuStoryZLinkView from '@package/components/theme/View/OuStoryZLinkView';
import OuTourView from '@package/components/theme/View/OuTourView';
import OuMailFormView from '@package/components/theme/View/OuMailFormView';
import OuCategoryGridFolderView from '@package/components/theme/View/OuGridCategoryFolderView';
import OuGridFolderView from '@package/components/theme/View/OuGridFolderView';

import { blockStyleFn } from '@plone/volto/config/RichTextEditor/Blocks';

import createInlineStyleButton from 'draft-js-buttons/lib/utils/createInlineStyleButton';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import superindexSVG from '@plone/volto/icons/superindex.svg';
import subindexSVG from '@plone/volto/icons/subindex.svg';
// import TextareaWidget from '../omelette/src/components/manage/Widgets/TextareaWidget';
import TargetsWidget from '~/components/manage/Widgets/TargetsWidget';
import SourceDisplayWidget from '~/components/manage/Widgets/SourceDisplayWidget'; // TODO: ./components/index経由で取得できないため直接取得した
import UrgentNotifyWidget from '~/components/manage/Widgets/UrgentNotifyWidget'; // TODO: ./components/index経由で取得できないため直接取得した
import { SortableObjectBrowserWidgetMode } from '~/components/manage/Widgets/SortableObjectBrowserWidget';
import SortableObjectBrowserWidget from '~/components/manage/Widgets/SortableObjectBrowserWidget';
// import TargetsForm from 'components/manage/Blocks/OUNow/TargetsForm/components/TargetsForm';
// import EmphasisLinksWidget from './components/manage/Widgets/EmphasisLinksWidget';
import SelectTargetsWtUnselectedWidget from '~/components/manage/Widgets/SelectTargetsWtUnselectedWidget';
import {
  inlineToolbarBlockButtons,
  inlineToolbarButtons,
} from './constants/RichTextEditor';

import { nonContentRoutes } from './config/NonContentRoutes';
import {
  addBreaklinesInline,
  processChildren,
} from '@package/customizations/config/RichTextEditor/ToHTML';

import '@plone/volto/config';

defineMessages({
  header2: {
    id: 'admin',
    defaultMessage: 'Administrator',
  },
});

// EditorのclassNameに'Text'を追加する
// const customBlockStyleFn = (contentBlock) => {
//   if (blockStyleFn(contentBlock)) {
//     return `${blockStyleFn(contentBlock)} Text`;
//   } else {
//     return 'Text';
//   }
// };

const customBlockRenderMap = Map({
  aligncenter: {
    element: 'h2',
  },
});

export default function applyConfig(config) {
  config.settings.hasWorkingCopySupport = true;

  config.settings = {
    ...config.settings,
    isMultilingual: true,
    supportedLanguages: ['ja', 'en'],
    defaultLanguage: 'ja',
    // richTextEditorInlineToolbarButtons: [
    //   ...inlineToolbarButtons,
    //   ...config.settings.richTextEditorInlineToolbarButtons,
    //   ...inlineToolbarBlockButtons,
    // ],
    customStyleMap: {
      ...config.settings.customStyleMap,
      SUBSCRIPT: { fontSize: '0.6em', verticalAlign: 'sub' },
      SUPERSCRIPT: { fontSize: '0.6em', verticalAlign: 'super' },
      HIGHLIGHT: { color: '#E80000' },
    },
    richtextViewSettings: {
      ...config.settings.richtextViewSettings,
      ToHTMLRenderers: {
        ...config.settings.richtextViewSettings.ToHTMLRenderers,
        inline: {
          ...config.settings.richtextViewSettings.ToHTMLRenderers.inline,
          SUBSCRIPT: (children, { key }) => (
            <sub key={key}>{addBreaklinesInline(children)}</sub>
          ),
          SUPERSCRIPT: (children, { key }) => (
            <sup key={key}>{addBreaklinesInline(children)}</sup>
          ),
          HIGHLIGHT: (children, { key }) => (
            <span style={{ color: '#E80000' }} key={key}>
              {addBreaklinesInline(children)}
            </span>
          ),
        },
        blocks: {
          ...config.settings.richtextViewSettings.ToHTMLRenderers.blocks,
          alignleft: (children, { keys }) => {
            return processChildren(children, keys, 'align-left');
          },
          aligncenter: (children, { keys }) => {
            return processChildren(children, keys, 'align-center');
          },
          alignright: (children, { keys }) => {
            return processChildren(children, keys, 'align-right');
          },
          // alignleft: (children, { keys }) =>
          //   children.map((child, i) => (
          //     <p id={keys[i]} key={keys[i]} className="align-left">
          //       {child}
          //     </p>
          //   )),
          // aligncenter: (children, { keys }) =>
          //   children.map((child, i) => (
          //     <p id={keys[i]} key={keys[i]} className="align-center">
          //       {child}
          //     </p>
          //   )),
          // alignright: (children, { keys }) =>
          //   children.map((child, i) => (
          //     <p id={keys[i]} key={keys[i]} className="align-right">
          //       {child}
          //     </p>
          //   )),
        },
      },
    },

    externalRoutes: [
      { match: '/zh' },
      { match: '/ko' },
      { match: '/sp' },
      { match: '/ssp' },
      { match: '/jp' },
      { match: '/daikou' },
      { match: '/namikou' },
      { match: '/haikukai' },
      { match: '/lpf2013' },
      { match: '/kitei' },
      { match: '/ja/researchinfo' },
    ],
    nonContentRoutes,
    extendedBlockRenderMap: config.settings.extendedBlockRenderMap,
    //   extendedBlockRenderMap: config.settings.extendedBlockRenderMap.merge(
    //   customBlockRenderMap,
    // ),
    textBlockStyleFn: (contentBlock) => {
      /* @nk24 begin 2023/04/05 */
      // if (config.settings.blockStyleFn(contentBlock)) {
      //   return config.settings.blockStyleFn(contentBlock);
      // } else {
      /* @nk24 end 2023/04/05 */
      const blockType = contentBlock.getType();
      if (blockType === 'header-two') {
        return 'Header2';
      } else if (blockType === 'header-three') {
        return 'Header3';
      } else if (blockType === 'alignleft') {
        return 'alignleft';
      } else if (blockType === 'aligncenter') {
        return 'aligncenter';
      } else if (blockType === 'alignright') {
        return 'alignright';
      } else {
        return null;
      }
      // }
    },
  };

  // WYSIWYGインラインツールバーに上下付き文字と赤色文字を追加
  const { richTextEditorInlineToolbarButtons } = config.settings;
  config.settings.richTextEditorInlineToolbarButtons = (props) => {
    // const result = richTextEditorInlineToolbarButtons(props);
    const result = [
      ...config.settings.richTextEditorInlineToolbarButtons,
      ...inlineToolbarButtons,
      ...inlineToolbarBlockButtons,
    ];
    return result;
  };

  config.views = {
    ...config.views,
    layoutViews: {
      ...config.views.layoutViews,
      LanguageRootFolderView: LanguageRootFolderView,
      OuCategoryFolderView: OuCategoryFolderView,
      OuNewsFolderView: OuNewsFolderView,
      OuEventFolderView: OuEventFolderView,
      OuFolderView: OuFolderView,
      OuPageView: OuPageView,
      OuNewsView: OuNewsView,
      OuEventView: OuEventView,
      // ImportedPageView: ImportedPageView,
      // OuImportedPageView: OuImportedPageView,
      OuMailFormView: OuMailFormView,
      OuImportedPageView: OuMigratePageView,
      OuHTMLView: OuHTMLView,
      OuFAQTopFolderView: OuFAQTopFolderView,
      OuFAQFolderView: OuFAQFolderView,
      OuFAQView: OuFAQView,
      OuStoryZTopFolderView: OuStoryZTopFolderView,
      OuStoryZFolderView: OuStoryZFolderView,
      OuStoryZView: OuStoryZView,
      OuStoryZLinkView: OuStoryZLinkView,
      OuTourView: OuTourView,
      OuGridCategoryFolderView: OuCategoryGridFolderView,
      OuGridFolderView: OuGridFolderView,
    },
  };
  config.widgets = {
    ...config.widgets,
    id: {
      ...config.widgets.id,
      text: SourceDisplayWidget,
      urgent_list: UrgentNotifyWidget,
      // targets: TargetsWidget,
      //   related_links: EmphasisLinksWidget,
      // refers: TourObjectBrowserWidgetMode(),
      // refers: SortableObjectBrowserWidget,
      tour_items: SortableObjectBrowserWidget,
    },
    widget: {
      ...config.widgets.widget,
      target_with_unselected: SelectTargetsWtUnselectedWidget,
    },
  };
  config.blocks = {
    ...config.blocks,
    blocksConfig: {
      ...config.blocks.blocksConfig,
      // ...updateDefaultBlocksConfig,
      ...customBlocks,
      // ...customizedBlocks,
    },
    groupBlocksOrder: [...config.blocks.groupBlocksOrder, ...groupBlocksOrder],
    // initialBlocks: {
    //   ...config.blocks.initialBlocks,
    // LRF: ['univ_info', 'ou_now'],
    //   OuPage: ['title', 'image', 'text'],
    //   OuCategoryFolder: ['title', 'restricted_sample', 'text'],
    // },
  };
  // config.addonRoutes = {
  //   ...config.addonRoutes,
  // };
  // config.addonReducers = {
  //   ...config.addonReducers,
  // };
  // console.log(config);
  return config;
}
