import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { save, load } from 'redux-localstorage-simple';

import config from '@plone/volto/registry';
// import { settings } from '~/config';
//import reducers from '~/reducers';
import { getUserType } from '../helpers/UserType/UserType';
import reducers from '@root/reducers';

import { api, crashReporter, blacklistRoutes } from '@plone/volto/middleware';

// 追加分
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from '../epics';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

if (__CLIENT__) {
  ReactGA.initialize('UA-9055195-1', {
    debug: false,
    gaOptions: {
      // anonymizeIp: true,
    },
  });
  // GA4 設定 https://www.npmjs.com/package/react-ga4
  ReactGA4.initialize('G-KMWNNDRHPC'); // 新しいコードに変更が必要
}

const trackPage = (pathName, userType) => {
  if (userType) {
    ReactGA.set({ anonymizeIp: true });
    ReactGA.set({ dimension1: userType });
  }
  ReactGA.pageview(pathName);
  // GA4 対応
  ReactGA4.send('pageview');
};

const gaTrackingMiddleware = (store) => (next) => (action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const pathName = window.location.pathname;
    const userType = getUserType();
    trackPage(pathName, userType);
  }
  return next(action);
};

const configureStore = (initialState, history, apiHelper) => {
  //追加
  const epicMiddleware = createEpicMiddleware();
  let stack = [
    blacklistRoutes,
    routerMiddleware(history),
    crashReporter,
    thunk,
    ...(apiHelper ? [api(apiHelper)] : []),
    ...(__CLIENT__
      ? [save({ states: config.settings.persistentReducers, debounce: 500 })]
      : []),
    // 追加分
    epicMiddleware,
    gaTrackingMiddleware,
  ];
  stack = config.settings.storeExtenders.reduce(
    (acc, extender) => extender(acc),
    stack,
  );
  const middlewares = composeWithDevTools(applyMiddleware(...stack));
  const store = createStore(
    combineReducers({
      router: connectRouter(history),
      ...reducers,
      ...config.addonReducers,
    }),
    {
      ...initialState,
      ...(__CLIENT__
        ? load({ states: config.settings.persistentReducers })
        : {}),
    },
    middlewares,
  );

  // 追加分
  epicMiddleware.run(rootEpic);

  return store;
};

export default configureStore;
