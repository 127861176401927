/**
 * OuFAQSearchView component.
 * @module components/theme/View/OuFAQSearchView
 */

import _ from 'lodash';

import qs from 'query-string';
import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { flattenToAppURL, Helmet } from '@plone/volto/helpers';
import { FAQMainImage, FAQSearchBox, FAQThemeArea } from './OuFAQTopFolderView';
import { SearchResult } from './OuFAQFolderView';
import { faqSearch as faqSearchAction } from '../../../actions/faqSearch/faqSearch';
import './OuFAQSearchView.css';

/**
 * Component to display the OuFAQSearchView.
 * @function OuFAQSearchView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
export const FAQSearchArea = (props) => {
  return (
    <div className="FAQSearchArea">
      <div className="searchArea">
        <div className="searchAreaInner">
          <FAQSearchBox {...props} />
        </div>
      </div>
    </div>
  );
};

export const SiteSearch = (props) => {
  const query = props.query;
  return (
    <div className="SiteSearch">
      <h2 className="header">
        <span>{props.locale === 'ja' ? `「${query}」` : `[${query}] `}</span>
        <FormattedMessage id="keyword_of_site_search" defaultMessage="" />
      </h2>
      <div className="link">
        <Link to={flattenToAppURL(`/search?q=${query}`)}>
          <FormattedMessage id="see_all_search_results" defaultMessage="" />
          <Icon name="arrow alternate circle right outline" />
        </Link>
      </div>
    </div>
  );
};

const OuFAQSearchView = (props) => {
  const [
    faqSearch,
    bSize,
    bStart,
    count,
    hasNext,
    folderList,
    rootPath,
    rootTheme,
    rootTitle,
    locale,
  ] = useSelector((state) => {
    return [
      state?.faqSearch?.faqSearch ?? [],
      state?.faqSearch?.result?.data?.attributes?.b_size ?? 0,
      state?.faqSearch?.result?.data?.attributes?.b_start ?? 0,
      state?.faqSearch?.result?.data?.attributes?.count,
      state?.faqSearch?.result?.data?.attributes?.has_next ?? true,
      state?.faqSearch?.result?.data?.attributes?.root?.folder_list ?? [],
      state?.faqSearch?.result?.data?.attributes?.root?.path ?? '',
      state?.faqSearch?.result?.data?.attributes?.root?.themes,
      state?.faqSearch?.result?.data?.attributes?.root?.title,
      state?.content?.data?.language?.token ?? 'ja',
    ];
  });
  const pathname = props?.location?.pathname;
  const physicalPath = pathname.split('/');
  const curPath = physicalPath.slice(0, physicalPath.length - 1).join('/');
  const query = qs.parse(props.location.search).q;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(faqSearchAction(0, curPath, query));
  }, []);
  return (
    <div className="OuFAQSearchView OuFAQsView">
      <Helmet title="FAQ Search Result" />
      <section className="mainArea">
        <div className="mainAreaInner">
          <FAQMainImage
            alt={rootTitle}
            locale={locale}
            theme={rootTheme}
            url={rootPath}
          />
          <FAQSearchArea query={query} rootPath={rootPath} />
          <SearchResult
            bStart={bStart}
            bSize={bSize}
            count={count}
            faqList={faqSearch}
            hasNext={hasNext}
            listAction={faqSearchAction}
            locale={locale}
            theme={rootTheme}
            rootPath={rootPath}
            query={query}
          />
          <SiteSearch locale={locale} query={query} />
          <FAQThemeArea
            largeFolders={folderList}
            locale={locale}
            query={query}
            rootPath={rootPath}
          />
        </div>
      </section>
    </div>
  );
};

export default injectIntl(OuFAQSearchView);
