/**
 * UrgentNotifyWidget component.
 * nk24@CMScom 2021/04/28
 * @module components/manage/Widgets/UrgentNotifyWidget
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { Button, Form, Grid, Label } from 'semantic-ui-react';
import { EditorState } from 'draft-js';
import { convertToRaw } from 'draft-js';
import { defineMessages, injectIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import plusSVG from '@plone/volto/icons/circle-plus.svg';
import minusSVG from '@plone/volto/icons/circle-minus.svg';
import upSVG from '@plone/volto/icons/circle-top.svg';
import downSVG from '@plone/volto/icons/circle-bottom.svg';
import UrgentNotifyForm from '../UrgentNotifyForm/components/UrgentNotifyForm';
import './UrgentNotifyWidget.css';

const messages = defineMessages({
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  idTitle: {
    id: 'Short Name',
    defaultMessage: 'Short Name',
  },
  idDescription: {
    id: 'Used for programmatic access to the fieldset.',
    defaultMessage: 'Used for programmatic access to the fieldset.',
  },
  target: {
    id: 'Target',
    defaultMessage: 'Target',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  url: {
    id: 'URL',
    defaultMessage: 'URL',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  required: {
    id: 'Required',
    defaultMessage: 'Required',
  },
  delete: {
    id: 'Delete',
    defaultMessage: 'Delete',
  },
  insertElement: {
    id: 'Insert next elements',
    defaultMessage: 'Insert next elements',
  },
  deleteElement: {
    id: 'Delete elements',
    defaultMessage: 'Delete elements',
  },
  moveElementUp: {
    id: 'Move elements up',
    defaultMessage: 'Move elements up',
  },
  moveElementDown: {
    id: 'Move elements down',
    defaultMessage: 'Move elements down',
  },
});

const getId = () => Math.floor(Math.random() * Math.pow(2, 24)).toString(32);
const initialListItems = [
  {
    key: getId(),
    target: '',
    title: '',
    url: '',
  },
];
const emptyElement = () => ({
  key: getId(),
  target: '',
  title: '',
  url: '',
});

/**
 * UrgentNotifyWidget component class.
 * @class UrgentNotifyWidget
 * @extends Component
 */
class UrgentNotifyWidget extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    error: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    value: { items: [] },
    error: [],
    onChange: null,
  };
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Cell
   */
  constructor(props) {
    super(props);
    if (!__SERVER__) {
      this.state = {
        selected_index: null,
      };
    }
    this.schema = {
      fieldsets: [
        {
          id: 'default',
          title: props.intl.formatMessage(messages.default),
          fields: ['target', 'title', 'url', 'id', 'required'],
        },
      ],
      properties: {
        id: {
          type: 'string',
          title: props.intl.formatMessage(messages.idTitle),
          description: props.intl.formatMessage(messages.idDescription),
        },
        target: {
          type: 'string',
          title: props.intl.formatMessage(messages.target),
        },
        title: {
          type: 'string',
          title: props.intl.formatMessage(messages.title),
        },
        url: {
          type: 'string',
          title: props.intl.formatMessage(messages.url),
        },
        required: {
          type: 'boolean',
          title: props.intl.formatMessage(messages.required),
        },
      },
      required: ['id', 'title', 'target'],
    };
    // this.onChangeTarget = this.onChangeTarget.bind(this);
    // this.onChangeTitle = this.onChangeTitle.bind(this);
    // this.onChangeUrl = this.onChangeUrl.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onInsertElement = this.onInsertElement.bind(this);
    this.onDeleteElement = this.onDeleteElement.bind(this);
    this.onMoveElementUp = this.onMoveElementUp.bind(this);
    this.onMoveElementDown = this.onMoveElementDown.bind(this);
    this.setSelectedIndex = this.setSelectedIndex.bind(this);
  }

  /**
   * Set selected index to state
   * @method setSelectedIndexState
   * @returns {undefined}
   */
  setSelectedIndex(index) {
    this.setState({
      selected_index: index,
    });
  }

  /**
   * Insert element handler
   * @method onInsertElement
   * @returns {undefined}
   */
  onInsertElement() {
    const items = this.props.value.items;
    const newItems = [
      ...items.slice(0, this.state.selected_index + 1),
      emptyElement(),
      ...items.slice(this.state.selected_index + 1),
    ];
    this.props.onChange(this.props.id, {
      items: newItems,
    });
    this.setSelectedIndex(this.state.selected_index + 1);
  }

  /**
   * Delete element handler
   * @method onDeleteElement
   * @returns {undefined}
   */
  onDeleteElement() {
    const element = {
      ...this.props.value,
      items: [
        ...this.props.value.items.slice(0, this.state.selected_index),
        ...this.props.value.items.slice(this.state.selected_index + 1),
      ],
    };
    this.props.onChange(this.props.id, element);
    if (
      this.props.value?.items &&
      this.state.selected_index >= this.props.value.items.length - 1
    ) {
      this.setState({ selected_index: this.state.selected_index - 1 });
    } else {
      this.setState({ selected_index: this.state.selected_index });
    }
  }

  /**
   * Move up element handler
   * @method onMoveElementUp
   * @returns {undefined}
   */
  onMoveElementUp() {
    if (this.state.selected_index !== 0) {
      const element = {
        ...this.props.value,
        items: [
          ...this.props.value.items.slice(0, this.state.selected_index - 1),
          this.props.value.items[this.state.selected_index],
          this.props.value.items[this.state.selected_index - 1],
          ...this.props.value.items.slice(this.state.selected_index + 1),
        ],
      };
      this.props.onChange(this.props.id, element);
      this.setState({
        selected_index: this.state.selected_index - 1,
      });
    }
  }

  /**
   * Move down element handler
   * @method onMoveElementDown
   * @returns {undefined}
   */
  onMoveElementDown() {
    if (
      this.props.value?.items &&
      this.state.selected_index <= this.props.value.items.length - 2
    ) {
      const element = {
        ...this.props.value,
        items: [
          ...this.props.value.items.slice(0, this.state.selected_index),
          this.props.value.items[this.state.selected_index + 1],
          this.props.value.items[this.state.selected_index],
          ...this.props.value.items.slice(this.state.selected_index + 2),
        ],
      };
      this.props.onChange(this.props.id, element);
      this.setState({
        selected_index: this.state.selected_index + 1,
      });
    }
  }

  /**
   * Component did mount lifecycle method
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.value?.items?.length) {
      this.props.onChange(this.props.id, {
        ...this.props.value,
        items: initialListItems,
      });
    }
    if (this.state.selected_index === null) {
      this.setState({ selected_index: 0 });
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  // TODO: Remove after testing
  // UNSAFE_componentWillReceiveProps(nextProps) {
  // }

  /**
   * Change handler
   * @method onChange
   * @param {object} input value.
   * @returns {undefined}
   */
  onChange(index, subId, value) {
    console.log({ index, subId, value });
    const items = this.props.value.items;
    items[index][subId] = value;
    this.props.onChange(this.props.id, {
      items: items,
    });
  }
  onChangeTarget(index, value) {
    const items = this.props.value.items;
    items[index].target = value;
    this.props.onChange(this.props.id, {
      // ...this.props.value.items,
      items: items,
    });
  }
  onChangeTitle(index, value) {
    const items = this.props.value.items;
    items[index].title = value;
    this.props.onChange(this.props.id, {
      // ...this.props.value.items,
      items: items,
    });
  }
  onChangeUrl(index, value) {
    const items = this.props.value.items;
    items[index].url = value;
    this.props.onChange(this.props.id, {
      // ...this.props.value.items,
      items: items,
    });
  }

  /**
   * Change handler
   * @method onChange
   * @param {object} editorState Editor state.
   * @returns {undefined}
   */
  //   onChange(index, value) {
  //     const items = this.props.value.items;
  //     items[index].description = value;
  //     this.props.onChange(this.props.id, {
  //       // ...this.props.value.items,
  //       items: items,
  //     });
  //   }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }
    const {
      id,
      title,
      required,
      description,
      error,
      value,
      // TODO: remove after testing
      // onChange,
      // onEdit,
      // onDelete,
      // intl,
      // icon,
      // iconAction,
      fieldSet,
    } = this.props;
    return (
      <Form.Field
        inline
        required={required}
        error={error && error.length > 0}
        className={description ? 'help' : ''}
        id={`${fieldSet || 'field'}-${id}`}
      >
        <Grid>
          <Grid.Row stretched>
            <Grid.Column width="4">
              <div className="wrapper">
                <label htmlFor={`field-${id}`}>{title}</label>
              </div>
              <div className="toolbar ToolBar">
                <Button.Group>
                  <Button
                    icon
                    basic
                    type="button"
                    onClick={this.onInsertElement}
                    title={this.props.intl.formatMessage(
                      messages.insertElement,
                    )}
                    aria-label={this.props.intl.formatMessage(
                      messages.insertElement,
                    )}
                  >
                    <Icon name={plusSVG} size="21px" />
                  </Button>
                  <Button
                    icon
                    basic
                    type="button"
                    disabled={
                      this.props.value?.items &&
                      this.props.value.items.length === 1
                        ? 'disabled'
                        : ''
                    }
                    onClick={this.onDeleteElement}
                    title={this.props.intl.formatMessage(
                      messages.deleteElement,
                    )}
                    aria-label={this.props.intl.formatMessage(
                      messages.deleteElement,
                    )}
                  >
                    <Icon name={minusSVG} size="21px" />
                  </Button>
                  <Button
                    icon
                    basic
                    type="button"
                    disabled={this.state.selected_index === 0 ? 'disabled' : ''}
                    onClick={this.onMoveElementUp}
                    title={this.props.intl.formatMessage(
                      messages.moveElementUp,
                    )}
                    aria-label={this.props.intl.formatMessage(
                      messages.moveElementUp,
                    )}
                  >
                    <Icon name={upSVG} size="21px" />
                  </Button>
                  <Button
                    icon
                    basic
                    type="button"
                    disabled={
                      this.props.value?.items &&
                      this.state.selected_index ===
                        this.props.value.items.length - 1
                        ? 'disabled'
                        : ''
                    }
                    onClick={this.onMoveElementDown}
                    title={this.props.intl.formatMessage(
                      messages.moveElementDown,
                    )}
                    aria-label={this.props.intl.formatMessage(
                      messages.moveElementDown,
                    )}
                  >
                    <Icon name={downSVG} size="21px" />
                  </Button>
                </Button.Group>
              </div>
            </Grid.Column>
            <Grid.Column width="8">
              {value && value.items ? (
                map(value.items, (item, index) => (
                  <UrgentNotifyForm
                    key={item.key}
                    index={index}
                    isActive={this.state.selected_index === index}
                    value={item}
                    // onChangeTarget={this.onChangeTarget}
                    // onChangeTitle={this.onChangeTitle}
                    // onChangeUrl={this.onChangeUrl}
                    onChange={this.onChange}
                    setSelectedIndex={this.setSelectedIndex}
                  />
                ))
              ) : (
                <div></div>
              )}
              {map(error, (message) => (
                <Label key={message} basic color="red" pointing>
                  {message}
                </Label>
              ))}
            </Grid.Column>
          </Grid.Row>
          {description && (
            <Grid.Row stretched>
              <Grid.Column stretched width="12">
                <p className="help">{description}</p>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Form.Field>
    );
  }
}

export default injectIntl(UrgentNotifyWidget);
