/**
 * Feature block sidebar.
 * @module components/manage/Blocks/Feature/Sidebar
 * @nk24:CMScom created 2021/03/19
 */

import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import Schema from './Schema';

const messages = defineMessages({
  research: {
    id: 'Research',
    defaultMessage: 'Research',
  },
});

const Sidebar = ({ block, data, intl, onChangeBlock }) => {
  const _ = intl.formatMessage;
  const type = data['@type'];
  const content = data?.[type];
  if (!content) {
    return null;
  }
  return (
    <InlineForm
      schema={Schema}
      title={_(messages.research)}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [type]: { ...data[type], [id]: value },
        });
      }}
      formData={{
        background_image_PC: content.background_image_PC || [],
        background_image_SP: content.background_image_SP || [],
      }}
    />
  );
};

export default injectIntl(Sidebar);
