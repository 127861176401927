/**
 * View research block.
 * @module components/manage/Blocks/Feature/View
 */

import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import NoSSR from 'react-no-ssr';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { formatedDateDay } from '../../../../helpers/GlobalStyle/Date.js';
import { MediaQueryConfig } from '~/helpers/GlobalStyle/GlobalStyle';
import { researchList } from '~/actions';
import { LRFBlockHeader } from '~/components';
import './View.css';

const More = ({ link }) => {
  console.log({ link });
  if (link && link.length) {
    return (
      <div className="more">
        <a href={link[0]['@id']}>MORE</a>
      </div>
    );
  } else {
    return null;
  }
};

/**
 * View univ info block class.
 * @class View
 * @extends Component
 */
const View = ({
  title,
  background_image_PC,
  background_image_SP,
  loadResearchList,
  result,
  researchList,
  locale,
}) => {
  // console.log({ researchList });
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  const background_image_PC_path = background_image_PC?.[0]?.['@id'];
  const background_image_SP_path = background_image_SP?.[0]?.['@id'];
  let backgroundStylePC;
  if (background_image_PC_path) {
    backgroundStylePC = {
      backgroundImage: `url(${flattenToAppURL(
        background_image_PC_path,
      )}/@@images/image)`,
    };
  } else {
    backgroundStylePC = { background: 'none' };
  }
  let backgroundStyleSP;
  if (background_image_SP_path) {
    backgroundStyleSP = {
      backgroundImage: `url(${flattenToAppURL(
        background_image_SP_path,
      )}/@@images/image)`,
    };
  } else {
    backgroundStyleSP = { background: 'none' };
  }
  const settings = {
    // className: 'slider variable-width center',
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    speed: 500,
  };
  // const [researchListState, setResearchListState] = useState();
  useEffect(() => {
    if (locale) {
      loadResearchList(locale);
    }
  }, []);
  // useEffect(() => {
  //   if (researchList !== researchListState) {
  //     setResearchListState(researchList);
  //   }
  // });
  return (
    <NoSSR>
      <section
        className="Research"
        style={isSP ? backgroundStyleSP : backgroundStylePC}
      >
        <LRFBlockHeader title={title} negative={true} />
        <div className="contents">
          <Slider {...settings}>
            {map(researchList, (element) => {
              return (
                <div>
                  <div className="content" key={element?.url}>
                    {element.thumbnail ? (
                      <img src={element.thumbnail} alt="" />
                    ) : null}
                    <div className="text">
                      <div className="date">
                        {element?.date
                          ? formatedDateDay(element.date.split(' ')[0])
                          : ''}
                      </div>
                      <h3>{element?.title}</h3>
                      <div>
                        <p>{element?.description}</p>
                        <div className="more">
                          <a href={element?.url} target="_blank">
                            MORE
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    </NoSSR>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  // properties: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const ViewContainer = connect(
  (state, props) => {
    return {
      title: props?.data?.research?.title,
      background_image_PC: props?.data?.research?.background_image_PC,
      background_image_SP: props?.data?.research?.background_image_SP,
      result: state?.researchList?.result?.data?.attributes?.research,
      researchList:
        state?.researchList?.result?.data?.attributes?.research?.data,
      locale: state?.content?.data?.language?.token ?? 'ja',
    };
  },
  (dispatch, props) => ({
    loadResearchList: (locale) => {
      dispatch(researchList(locale));
    },
  }),
)(View);

export default injectIntl(ViewContainer);
