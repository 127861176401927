import { URGENT_NOTIFICATION } from '../../constants/ActionTypes';

export function urgentNotification(locale) {
  return {
    type: URGENT_NOTIFICATION,
    request: {
      op: 'get',
      path: `/@urgent-notification?locale=${locale}`,
    },
  };
}
