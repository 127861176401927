/**
 * SiteMenu component.
 * @module components/theme/Site/SiteMenu/SiteMenu
 */

import _ from 'lodash';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import cookie from 'react-cookie';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { updateIntl } from 'react-intl-redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Grid, Icon } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { MediaQueryConfig } from '../../../../helpers/GlobalStyle/GlobalStyle';
import './SiteMenu.css';
import { Icon as IconSemantic } from 'semantic-ui-react';
import { changeLanguage } from '@plone/volto/actions/language/language';
import { compose } from 'redux';
import { getLocaleFromPathname } from 'helpers/Locale/Locale';
import { Cookies } from 'react-cookie';

/**
 * Component to display the SiteMenu.
 * @function SiteMenu
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */

let locales = {};

if (config.settings) {
  config.settings.supportedLanguages.forEach((lang) => {
    import('../../../../../locales/' + lang + '.json').then((locale) => {
      locales = { ...locales, [lang]: locale.default };
    });
  });
}

const SiteMenu = ({ app, globalMenu, globalNav, languages, links, locale }) => {
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });

  /* Scroll Menu */
  const [mindBottomPOS, setMindBottomPOS] = useState(false);
  const [initPOSwhenDown, setInitPOSwhenDown] = useState(0);
  const [initHeight, setInitHeight] = useState(0);
  const [currentPOS, setCurrentPOS] = useState(0);
  // const [top, setTop] = useState(70);
  const [top, setTop] = useState(0);

  const node = useRef(null);

  const dispatch = useDispatch();

  const scrollHandler = useCallback(
    (event) => {
      if (typeof window !== 'undefined') {
        const clientRect = node.current.getBoundingClientRect();
        const winHeight = window.innerHeight;
        if (mindBottomPOS) {
          if (clientRect.top - currentPOS > 0) {
            if (clientRect.top < 0) {
              setTop(Math.abs(clientRect.top || 0));
              setInitPOSwhenDown(Math.abs(clientRect.top || 0));
            } else {
              setTop(0);
              setInitPOSwhenDown(0);
            }
          } else {
            if (
              clientRect.top +
                200 +
                (initHeight - winHeight) +
                initPOSwhenDown <
              0
            ) {
              setTop(top + (currentPOS - clientRect.top));
            }
          }
          setCurrentPOS(clientRect.top);
        } else {
          if (clientRect.top < 0) {
            setTop(Math.abs(clientRect.top || 0));
          } else {
            setTop(0);
          }
        }
        // console.log({
        //   mindBottomPOS,
        //   clientRect: JSON.stringify(clientRect),
        //   winHeight,
        //   currentPOS,
        // });
      }
    },
    [mindBottomPOS, currentPOS, initPOSwhenDown, initHeight, top],
  );

  // edit 2021-10-08 karad begin
  useEffect(() => {
    if (typeof window !== 'undefined' && node) {
      window.addEventListener('scroll', scrollHandler);
    }

    // edit 2021-10-08 karad end

    // setInitHeight(node.current.getBoundingClientRect().height);
    // setMindBottomPOS(
    //   window.innerHeight - node.current.getBoundingClientRect().height < 0 ||
    //     false,
    // );
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler]);

  // const initPosi = isSP ? 70 : 0;

  /* Language */
  const langs = _.filter(languages, (item) => locale !== item?.locale);

  /* Translation */
  const translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.items,
  );

  /*
  const dispatch = useDispatch();

  function changeLanguage(language) {
    cookie.save('I18N_LANGUAGE', language, {
      expires: new Date((2 ** 31 - 1) * 1000),
      path: '/',
    });

    dispatch(
      updateIntl({
        locale: language,
        messages: locales[language],
      }),
    );
  }
  */

  return (
    <nav
      className="SiteMenu"
      ref={node}
      style={{
        paddingTop: `${top}px`,
        position: 'relative',
      }}
    >
      <div className="siteMenu">
        <div className="siteMenuInner">
          <div className="language">
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column className="first">
                  <div className="iconCaption">
                    <Icon name="world" />
                    <div className="caption">
                      Language
                      {/*<FormattedMessage
                        id="language"
                        defaultMessage="Language"
                      />*/}
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column className="second">
                  <ul className="langList">
                    {_.map(langs, (item) => {
                      const translation = _.find(translations, {
                        language: item?.locale,
                      });
                      return (
                        <li className="item" key={item?.url}>
                          {item?.is_external ? (
                            <a
                              href={flattenToAppURL(item?.url)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item?.label}
                            </a>
                          ) : (
                            <Link
                              onClick={() => {
                                app.setVisibleLeftBar(false);
                                dispatch(
                                  changeLanguage(
                                    item?.locale,
                                    locales[item?.locale],
                                  ),
                                );
                              }}
                              to={
                                translation !== undefined
                                  ? flattenToAppURL(translation['@id'])
                                  : `/${item?.locale}`
                              }
                            >
                              {item?.label}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>

          {globalNav?.length > 0 && (
            <div className="globalNav">
              <ul className="itemList">
                {_.map(globalNav, (item) => {
                  return (
                    <li className="item" key={item?.url}>
                      <Link
                        onClick={() => {
                          app.setVisibleLeftBar(false);
                        }}
                        to={flattenToAppURL(item?.url)}
                      >
                        {item?.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {globalMenu?.length > 0 && (
            <div className="globalMenu">
              <ul className="itemList">
                {_.map(globalMenu, (item) => {
                  return (
                    <li className="item" key={item?.url}>
                      {item?.is_external ? (
                        <a href={flattenToAppURL(item?.url)} target="_blank">
                          <div className="iconCaption">
                            <Icon name={item?.icon} />
                            <div className="caption">{item?.name}</div>
                          </div>
                        </a>
                      ) : (
                        <Link
                          onClick={() => app.setVisibleLeftBar(false)}
                          to={flattenToAppURL(item?.url)}
                        >
                          <div className="iconCaption">
                            <Icon name={item?.icon} />
                            <div className="caption">{item?.name}</div>
                          </div>
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {links?.length > 0 && (
            <div className="links">
              <div className="extra">EXTRA</div>
              <ul className="linkList">
                {_.map(links, (item) => {
                  return (
                    <li className="item" key={item?.url}>
                      {item?.is_external ? (
                        <a href={flattenToAppURL(item?.url)} target="_blank">
                          <img alt={item?.name ?? ''} src={item?.src} />
                          <div className="title">{item?.name}</div>
                        </a>
                      ) : (
                        <Link
                          onClick={() => app.setVisibleLeftBar(false)}
                          to={flattenToAppURL(item?.url)}
                        >
                          <img alt={item?.name ?? ''} src={item?.src} />
                          <div className="title">{item?.name}</div>
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="closeButton">
        <button
          style={{ margin: '1rem 0', paddingTop: `${top}px` }}
          onClick={() => app.setVisibleLeftBar(false)}
        >
          <IconSemantic name="close" className="closeMenu" />
        </button>
      </div>
    </nav>
  );
};

// const SiteMenuContainer = connect(
//   (state, props) => {
//     // const locale = state?.content?.data?.language?.token ?? 'ja';
//     const locale =
//       new Cookies().get('I18N_LANGUAGE') || config.settings.defaultLanguage;
//     return {
//       globalMenu:
//         state?.siteMainInfo?.result?.data?.attributes?.locale?.[locale]
//           ?.side_nav?.global_menu,
//       globalNav:
//         state?.siteMainInfo?.result?.data?.attributes?.locale?.[locale]
//           ?.side_nav?.global_nav,
//       languages:
//         state?.siteMainInfo?.result?.data?.attributes?.common?.languages,
//       links:
//         state?.siteMainInfo?.result?.data?.attributes?.locale?.[locale]
//           ?.side_nav?.links,
//       locale: locale,
//     };
//   },
//   (dispatch, props) => {
//     return {};
//   },
// )(SiteMenu);

// export default injectIntl(SiteMenuContainer);

export default compose(
  injectIntl,
  connect(
    (state, props) => {
      // const locale = state?.content?.data?.language?.token ?? 'ja';
      // const locale =
      //   new Cookies().get('I18N_LANGUAGE') || config.settings.defaultLanguage;
      const locale = getLocaleFromPathname(
        __CLIENT__ ? window?.location.pathname : null,
        config.settings.defaultLanguage,
      );
      return {
        globalMenu:
          state?.siteMainInfo?.result?.data?.attributes?.locale?.[locale]
            ?.side_nav?.global_menu,
        globalNav:
          state?.siteMainInfo?.result?.data?.attributes?.locale?.[locale]
            ?.side_nav?.global_nav,
        languages:
          state?.siteMainInfo?.result?.data?.attributes?.common?.languages,
        links:
          state?.siteMainInfo?.result?.data?.attributes?.locale?.[locale]
            ?.side_nav?.links,
        locale: locale,
      };
    },
    (dispatch, props) => {
      return {};
    },
  ),
)(SiteMenu);
