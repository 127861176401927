/**
 * OuFAQFolderView component.
 * @module components/theme/View/OuFAQFolderView
 */

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import rehypeRaw from 'rehype-raw';
import { Grid, Icon } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FAQMainImage, FAQThemeArea } from './OuFAQTopFolderView';
import { HelmetSite, MoreButton } from '../../index';
import { faqList as faqListAction } from '../../../actions/faqList/faqList';
import './OuFAQFolderView.css';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

/**
 * Component to display the OuFAQFolderView.
 * @function OuFAQFolderView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
export const FAQKeyword = (props) => {
  const displayQuery = (
    <span className="large">
      「 {props.query ? props.query : props.title} 」
    </span>
  );
  return (
    <h2 className="FAQKeyword">
      {props.locale === 'en' ? (
        <>FAQs About {displayQuery}</>
      ) : (
        <>
          {displayQuery}
          <span className="small">の</span>よくある質問
        </>
      )}
      <i className="count">
        {props.count}
        {props.locale === 'ja' && '件'}
      </i>
    </h2>
  );
};

export const FAQQuestion = ({ item }) => {
  return (
    <div className="FAQQuestion">
      <img
        className="iconMan"
        src={flattenToAppURL('/assets/images/faq/icon_man.svg')}
      />
      <div className="question">
        <div>
          <img
            alt="question"
            className="questionIcon"
            src={flattenToAppURL('/assets/images/faq/icon_q.svg')}
          />
          <div className="number">{item.id}</div>
        </div>
        <div className="text">{item.name}</div>
      </div>
    </div>
  );
};

export const FAQAnswer = ({ index, item, theme }) => {
  return (
    <div className="FAQAnswer">
      <Accordion className="answer">
        <AccordionSummary
          aria-controls={`panel-${index}`}
          className="answerBox"
          id={`panel-${index}`}
        >
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <div className="open">
                  <div>Open</div>
                  <Icon name="arrow alternate circle down outline" />
                </div>
                <div className="close">
                  <div>Close</div>
                  <Icon name="arrow alternate circle up outline" />
                </div>
              </Grid.Column>
              <Grid.Column>
                <img
                  alt="answer"
                  className="answerIcon"
                  src={flattenToAppURL('/assets/images/faq/icon_a.svg')}
                />
                <div className="number">{item.id}</div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className="text">
          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={item.answer} />
        </AccordionDetails>
      </Accordion>
      <img
        className="iconShika"
        src={flattenToAppURL(`/assets/images/faq/icon_${theme}.svg`)}
      />
    </div>
  );
};

export const FAQMoreButton = ({
  hasNext,
  bSize,
  bStart,
  query,
  rootPath,
  listAction,
}) => {
  const dispatch = useDispatch();
  const moreButton = () => {
    if (hasNext) {
      dispatch(listAction(bSize + bStart, rootPath, query));
    }
  };
  return (
    hasNext && (
      <div className="FAQMoreButton">
        <button className="button arrow" onClick={() => moreButton()}>
          MORE
        </button>
      </div>
    )
  );
};

export const SearchResult = ({
  bStart,
  bSize,
  count,
  faqList,
  hasNext,
  listAction,
  locale,
  theme,
  rootPath,
  title,
  query,
}) => {
  return (
    <div className="SearchResult">
      <div className="searchResult">
        <FAQKeyword count={count} locale={locale} query={query} title={title} />
        <div className="qaArea">
          {faqList.map((item, index) => {
            return (
              <div className="qa" key={index}>
                <FAQQuestion item={item} />
                <FAQAnswer index={index} item={item} theme={theme} />
              </div>
            );
          })}
          <FAQMoreButton
            hasNext={hasNext}
            bSize={bSize}
            bStart={bStart}
            query={query}
            rootPath={rootPath}
            listAction={listAction}
          />
        </div>
      </div>
    </div>
  );
};

const OuFAQFolderView = ({ content }) => {
  const [
    faqList,
    bSize,
    bStart,
    count,
    hasNext,
    folderList,
    rootPath,
    rootTheme,
    rootTitle,
    locale,
  ] = useSelector((state) => {
    return [
      state?.faqList?.faqList ?? [],
      state?.faqList?.result?.data?.attributes?.b_size ?? 0,
      state?.faqList?.result?.data?.attributes?.b_start ?? 0,
      state?.faqList?.result?.data?.attributes?.count,
      state?.faqList?.result?.data?.attributes?.has_next ?? true,
      state?.faqList?.result?.data?.attributes?.root?.folder_list ?? [],
      state?.faqList?.result?.data?.attributes?.root?.path ?? '',
      state?.faqList?.result?.data?.attributes?.root?.themes,
      state?.faqList?.result?.data?.attributes?.root?.title,
      state?.content?.data?.language?.token ?? 'ja',
    ];
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(faqListAction(0, _.get(content, '@id')));
  }, [content]);
  return (
    <div className="OuFAQFolderView OuFAQsView">
      <HelmetSite content={content} />
      <section className="mainArea">
        <div className="mainAreaInner">
          <FAQMainImage
            alt={rootTitle}
            locale={locale}
            theme={rootTheme}
            url={rootPath}
          />
          <SearchResult
            bSize={bSize}
            bStart={bStart}
            count={count}
            faqList={faqList}
            hasNext={hasNext}
            listAction={faqListAction}
            locale={locale}
            theme={rootTheme}
            rootPath={_.get(content, '@id')}
            title={content?.title}
          />
          <FAQThemeArea
            largeFolders={folderList}
            locale={locale}
            rootPath={rootPath}
          />
        </div>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuFAQFolderView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default injectIntl(OuFAQFolderView);
