import { GET_NEWS_LIST } from '../../constants/ActionTypes';

export function newsList(bStart, target, term, locale, path = null) {
  return {
    type: GET_NEWS_LIST,
    request: {
      op: 'get',
      path: `${
        path ? path : `/${locale}/news/topics`
        // path ? path : `/${locale}/news/topics`
      }/@news-list?b_start=${bStart}&target=${target}&term=${term}`,
    },
    bStart: bStart,
  };
}
