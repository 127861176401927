/**
 * HtmlToBlock.js
 * @module helpers/HtmlToBlock/HtmlToBlock
 * RichTextFieldのHTMLをパースしてdraft-jsのデータ（JSON形式）に変換するヘルパーモジュール
 */

// *   - h2 H2 ✅ -> h2
// *   - h3 H3 ✅ -> h3
// *   - img IMG -> ✅image
// *   - link -> text
// *   - テキスト P ✅ -> text
// *   - テーブル TABLE ✅
// *   - リスト UL ✅ -> text
// *   - h4 H4 デザインなし
// *   - pre PRE -> text デザインなし

import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import { filter, map } from 'lodash';
import { flattenToAppURL } from '@plone/volto/helpers';

const getId = () => Math.floor(Math.random() * Math.pow(2, 24)).toString(32);

/**
 * Convert IMAGE block rawdata
 * @param {*} node 生成するblock毎のDOM
 */
const convertImageBlockRaw = (node, contextURL) => {
  let rawData;
  if (
    node.getElementsByTagName('A').length &&
    node.getElementsByTagName('IMG').length
  ) {
    const linkNode = node.getElementsByTagName('A')[0];
    const imgNode = node.getElementsByTagName('IMG')[0];
    const url = flattenToAppURL(imgNode.getAttribute('src')).split(
      '/@@images',
    )[0]; // TODO
    // console.log(imgNode.getAttribute('src'));
    // console.log(url);
    const pathList = url.split('/');
    const alt = imgNode.getAttribute('alt') ?? pathList[pathList.length - 1];
    const href = linkNode.getAttribute('href');
    const openLinkInNewTab = linkNode.getAttribute('target') === '_blank';
    rawData = {
      '@type': 'image',
      contextURL,
      url,
      alt,
      href,
      openLinkInNewTab,
    };
  } else if (node.childNodes.getElementsByTagName('IMG').length) {
    const imgNode = node.getElementsByTagName('IMG')[0];
    const url = flattenToAppURL(imgNode.getAttribute('src')).replace(
      '/@@images/image',
    ); // TODO
    const pathList = url.split('/');
    const alt = imgNode.getAttribute('alt') ?? pathList[pathList.length - 1];
    rawData = {
      '@type': 'image',
      contextURL,
      url,
      alt,
    };
  } else {
    throw new Error();
  }
  return rawData;
};

/**
 * TABLE element converter
 * @param {string} node Part of html
 */
const convertTableBlockRaw = (node) => {
  let rawData = {
    '@type': 'table',
    table: {
      basic: false,
      celled: true,
      compact: false,
      fixed: true,
      inverted: false,
      striped: false,
      rows: [],
    },
  };

  for (let tbodyNode of filter(
    node.children,
    (child) => child.nodeName === 'TBODY',
  )) {
    for (let rowNode of filter(
      tbodyNode.children,
      (child) => child.nodeName === 'TR',
    )) {
      let rowData = { cells: [], key: getId() };
      for (let cellNode of filter(rowNode.children, (child) =>
        ['TH', 'TD'].includes(child.nodeName),
      )) {
        let type;
        if (cellNode.nodeName === 'TH') {
          type = 'header';
        } else if (cellNode.nodeName === 'TD') {
          type = 'data';
        } // else TODO: 例外処理

        const blocksFromHTML = convertFromHTML(cellNode.outerHTML);
        // const blocksFromHTML = convertFromHTML('<td>Hogehoge</td>');
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        const inner = convertToRaw(state);

        let cellData = {
          key: getId(),
          type,
          value: {
            ...inner,
          },
        };
        rowData = { ...rowData, cells: [...rowData.cells, cellData] };
      }
      rawData = {
        ...rawData,
        table: { ...rawData.table, rows: [...rawData.table.rows, rowData] },
      };
    }
  }
  return rawData;
};

/**
 * function parser
 * @param {*} node 生成するblock毎のDOM
 * blocks(JSON)形式に変換
 */
const converter = (node, index, contextURL) => {
  // return node.firstChild.nodeName;
  let rawData = {};
  let state;
  let error = '';
  const rootTag = node.nodeName;
  const firstChildNode = node.firstChild;
  // console.log({ rootTag, firstChildNode });

  if (node.getElementsByTagName('IMG').length) {
    /**
     * Image Block
     */
    try {
      rawData = convertImageBlockRaw(node, contextURL);
    } catch (e) {
      error = `IMG converter could not parse node ${node.outerHTML}`;
      //   console.log('IMG error');
    }
  } else if (rootTag === 'TABLE') {
    /**
     * Table Block
     */
    try {
      rawData = convertTableBlockRaw(node);
    } catch (e) {
      error = `TABLE converter could not parse node ${node.outerHTML}`;
    }
  } else if (
    ['P', 'H2', 'H3', 'UL', 'OL', 'PRE'].some((target) => target === rootTag)
  ) {
    /**
     * Text block
     */
    try {
      // console.log({ firstChildNode });
      if (
        ['', '\n', '\r', '\r\n'].some((target) => target === firstChildNode)
      ) {
        state = ContentState.createFromText('');
      } else {
        const blocksFromHTML = convertFromHTML(node.outerHTML);
        state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
      }
      const inner = convertToRaw(state);
      rawData = {
        '@type': 'text',
        text: inner,
      };
    } catch (e) {
      error = `TEXT converter could not parse node ${node.outerHTML}`;
    }
    // } else if (rootTag === 'H2') {
    //   try {
    //     rawData = {
    //       '@type': 'header2',
    //       key: getId(),
    //       header2: node.innerHTML,
    //     };
    //   } catch (e) {
    //     error = `H2 converter could not parse node ${node.outerHTML}`;
    //   }
    // } else if (rootTag === 'H3') {
    //   try {
    //     rawData = {
    //       '@type': 'header3',
    //       key: getId(),
    //       header3: node.innerHTML,
    //     };
    //   } catch (e) {
    //     error = `H3 converter could not parse node ${node.outerHTML}`;
    //   }
  } else {
    error = `Unknown (or not implemented) HTML tag found ${node.outerHTML}`;
  }
  return { rawData, error };
};

/**
 * function splitter
 * @param {string} html
 * htmlをパース、最上位のエレメント毎に分割する
 */
function splitter(html) {
  const parser = new DOMParser();
  const document = parser.parseFromString(
    `<div id="root">${html}</div>`,
    'text/html',
  );
  // console.log({ document });
  const root = document.getElementById('root');
  let lst = [];
  if (root) {
    const childNodes = root.childNodes;
    for (let i = 0; i < childNodes.length; i++) {
      const childNode = childNodes[i];
      if (
        ['H2', 'H3', 'TABLE', 'P', 'UL', 'OL', 'PRE'].some(
          (target) => target === childNode.nodeName,
        )
      ) {
        lst.push(childNode);
      }
    }
  }
  // console.log({ lst });
  return lst;
}

/**
 * htmlToBlock
 */
export default function htmlToBlock(html, contextURL) {
  const nodeByBlock = splitter(html);
  // console.log({ nodeByBlock });
  // console.log(
  //   filter(
  //     map(nodeByBlock, (node, index) => converter(node, index, contextURL)),
  //     (item) => {
  //       return !item.error;
  //     },
  //   ),
  // );
  const blocks = map(nodeByBlock, (node, index) =>
    converter(node, index, contextURL),
  );
  //TODO: error出力
  return filter(blocks, (item) => {
    return !item.error;
  });
}
