/**
 * MigrateContent.jsx
 * textスキーマの値からBlockを作成する
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useMediaQuery } from 'react-responsive';
import { defineMessages, injectIntl } from 'react-intl';
// import Modal from 'react-modal';
import { Portal } from 'react-portal';
// import { Button, Header, Icon } from 'semantic-ui-react';
// import { Icon } from 'semantic-ui-react';
import { Icon as VoltoIcon } from '@plone/volto/components';
import { find, last } from 'lodash';
// import {
//   getBlocksFieldname,
//   getBlocksLayoutFieldname,
// } from '@plone/volto/helpers';
import { onMigrateBlock, resetMigrateBlock } from '~/actions';

import showBlocksSVG from '@plone/volto/icons/show-blocks.svg';

// import { MediaQueryConfig } from '~/helpers/GlobalStyle/GlobalStyle';
import htmlToBlock from '~/helpers/HtmlToBlock/HtmlToBlock';
// import { migratable } from '@package/helpers/MigrateContent/MigrateContent';
import './MigrateButton.css';

const messages = defineMessages({
  migrate_to_blocks: {
    id: 'Migrate imported content to blocks',
    defaultMessage: 'Migrate imported content to blocks',
  },
});

// Modal.setAppElement('body');

/**
 * Main functional component.
 * @param {*} props
 */
const MigrateContent = (props) => {
  const { properties, intl, onChangeFormData } = props;
  const html = properties?.html_code || '';
  const blocks = htmlToBlock(html);

  // const isSP = useMediaQuery({
  //   query: MediaQueryConfig.SP_AND_TABLET,
  // });

  // const customModalStyles = {
  //   content: {
  //     width: isSP ? '80%' : '560px',
  //     // height: isSP ? '335px' : '560px',
  //     top: '50%',
  //     left: '50%',
  //     right: 'auto',
  //     bottom: 'auto',
  //     marginRight: '-50%',
  //     padding: '2rem',
  //     transform: 'translate(-50%, -50%)',
  //   },
  //   overlay: {
  //     backgroundColor: 'rgba(0, 0, 0, 0.3)',
  //   },
  // };

  const [
    actions,
    contentType,
    pathname,
    // review_state,
    newFormData,
  ] = useSelector((state) => {
    return [
      state?.actions?.actions,
      state?.content?.data?.['@type'],
      state?.router?.location?.pathname,
      // state?.content?.data?.review_state,
      state?.onMigrateBlock?.newFormData,
    ];
  });

  const dispatch = useDispatch();
  // const isMigratable = migratable(actions, contentType, pathname, review_state);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (formData === null) {
      // 初期ページ読み込み時 ===> stateリセット
      setFormData(properties);
      dispatch(resetMigrateBlock());
    } else if (newFormData === null) {
      // stateリセット結果 ===> 何もしない
    } else if (formData !== newFormData) {
      setFormData(newFormData);
      onChangeFormData(newFormData);
    }
  }, [dispatch, formData, newFormData, onChangeFormData, properties]);

  /** Migrateボタン表示／活性化条件 */
  const isImportedPageType = contentType === 'OuImportedPage';
  const hasEditAction = find(actions.object, {
    id: 'edit',
  }); // ログインユーザがEdit可能かどうか
  const notIsBlockMode = !properties.show_block_view;
  const isEditView = last(pathname.split('/')) === 'edit'; // EditViewかどうか
  const showEditButton = isImportedPageType && isEditView;
  const enableEditButton = hasEditAction && notIsBlockMode;

  // const blocksLayoutFieldname = getBlocksLayoutFieldname(formData);
  // const totalItems = formData?.[blocksLayoutFieldname]?.items
  //   ? formData[blocksLayoutFieldname].items.length
  //   : 0;

  // const showModal = false; //Temp
  // const setConfirmDelBlocks = () => {}; //Temp
  return (
    <Portal node={__CLIENT__ && document.getElementById('toolbar-additional')}>
      {showEditButton ? (
        enableEditButton ? (
          <button
            className="MigrateButton toggle-preview-blocks"
            aria-label={intl.formatMessage(messages.migrate_to_blocks)}
            onClick={() => dispatch(onMigrateBlock(properties, blocks))}
            tabIndex={0}
            id="toggle-preview-blocks"
          >
            <VoltoIcon name={showBlocksSVG} size="30px" />
          </button>
        ) : (
          <button
            className="MigrateButton toggle-preview-blocks disable"
            aria-label={intl.formatMessage(messages.migrate_to_blocks)}
            tabIndex={0}
            id="toggle-preview-blocks"
            disabled
          >
            <VoltoIcon name={showBlocksSVG} size="30px" color="#CCCCCC" />
          </button>
        )
      ) : null}
    </Portal>
    /* ) : {showModal ? (
        <Modal
          isOpen={showModal}
          style={customModalStyles}
          contentLabel="Facilities Modal"
        >
          <Header as="h2" icon>
            <Icon name="exclamation circle" />
            Proceed with translation
            <Header.Subheader>
              When you click a “Proceed” button, exsisting blocks will be
              removed. This operation could not undo.
            </Header.Subheader>
          </Header>
          <Button
            primary
            floated="right"
            onClick={() => {
              setConfirmDelBlocks(true);
            }}
          >
            Proceed
          </Button>
          <Button
            floated="right"
            onClick={() => {
              setConfirmDelBlocks(false);
            }}
          >
            Cancel
          </Button>
        </Modal>
      ) : null} */
    // null;
  );
};

export default injectIntl(MigrateContent);
