import { FAQ_LIST } from '../../constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

export function faqList(bStart, path) {
  return {
    type: FAQ_LIST,
    request: {
      op: 'get',
      path: `${flattenToAppURL(path)}/@faq-list?b_start=${bStart}`,
    },
    bStart: bStart,
  };
}
