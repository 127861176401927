import { SITEMAP } from '../../constants/ActionTypes';

export function sitemap(path) {
  return {
    type: SITEMAP,
    request: {
      op: 'get',
      path: `${path}/@sitemap`,
    },
  };
}
