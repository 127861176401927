import { getSearchResult } from '../../actions';

/**
 * Search based on the given q, subject and path.
 * @param {*} q
 * @param {*} subject
 * @param {*} path
 * @param {*} section
 * @param {*} locale
 */
export function doSearch(
  history,
  dispatch,
  q,
  subject,
  path,
  section,
  b_start = 0,
  locale,
) {
  if (__CLIENT__) {
    // history.push(
    //   `/search?q=${q ?? ''}${section ?? ''}${
    //     locale ? '&lang=' + locale : ''
    //   }`,
    // );
    dispatch(
      getSearchResult('', toSearchOptions(q, subject, path, b_start, locale)),
    );
  }
}

/**
 * 検索オプションの生成
 * @param {*} q
 * @param {*} subject
 * @param {*} path
 * @param {*} b_start
 * @param {*} locale
 * @returns
 */
export function toSearchOptions(q, subject, path, b_start, locale) {
  return {
    ...(q && { q: q }),
    ...(subject && {
      Subject: subject,
    }),
    ...(path && {
      path: path,
    }),
    ...(!isNaN(b_start) && {
      b_start: b_start,
    }),
    ...(locale && {
      lang: locale,
    }),
  };
}
