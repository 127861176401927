/**
 * Indication tour status component module
 * @module components/theme/Site/TourIndicator/TourIndicator
 * nk24@CMScom 2021/06/29
 */

import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
// import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { tourItem } from '@package/actions';
import './TourIndicator.css';

const messages = defineMessages({
  showOtherRelatedTours: {
    id: '(Temporary) Other related tour(s)',
    defaultMessage: '(Temporary) Other related tour(s)',
  },
  hideOtherRelatedTours: {
    id: '(Temporary) Collapse related tour(s)',
    defaultMessage: '(Temporary) Collapse related tour(s)',
  },
  // tourMarkerPrefix: {
  //   id: '(Temporary) This article is a part of ',
  //   defaultMessage: '(Temporary) This article is a part of ',
  // },
  // tourMarkerSuffix: {
  //   id: '(Temporary) This article is a part of ',
  //   defaultMessage: '(Temporary) This article is a part of ',
  // },
});

/**
 * @function TourIndicator
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const TourIndicator = ({ intl }) => {
  const [locale, tours, tourId, uid, url, state] = useSelector((state) => {
    const locale = state?.content?.data?.language?.token ?? 'ja';
    return [
      locale,
      state?.tourItem?.result?.data?.attributes?.tours ?? [],
      state?.router?.location?.query?.tour,
      state?.content?.data?.UID,
      state?.content?.data?.['@id'],
      state,
    ];
  });
  const dispatch = useDispatch();
  let prevUrl = null;
  let nextUrl = null;
  if (tours.length === 1) {
    const items = tours[0].items;
    const index = tours[0].current;
    if (index > 0) {
      prevUrl = items[index - 1].url;
    } else {
      prevUrl = null;
    }
    if (index + 1 < items.length) {
      nextUrl = items[index + 1].url;
    } else {
      nextUrl = null;
    }
  }
  const [isExtract, setIsExtract] = useState(false);
  useEffect(() => {
    dispatch(tourItem(locale, uid, tourId));
    setIsExtract(false);
  }, [dispatch, locale, uid, tourId]);

  return tours.length ? (
    <nav className="TourIndicator">
      {tourId ? (
        <div className="tourNav">
          <div className="controls">
            <div className="closerWrapper">
              <Link to={flattenToAppURL(url)} className="closeTour">
                <span>Close</span>
              </Link>
            </div>
            {prevUrl ? (
              <Link
                to={`${flattenToAppURL(prevUrl)}?tour=${tours[0].uid}`}
                className="prevContent"
              >
                <img src="/assets/images/tour/tour_flag.svg" alt="Tour" />
                <span>Prev</span>
              </Link>
            ) : null}
            {nextUrl ? (
              <Link
                to={`${flattenToAppURL(nextUrl)}?tour=${tours[0].uid}`}
                className="nextContent"
              >
                <img src="/assets/images/tour/tour_flag.svg" alt="Tour" />
                <span>Next</span>
              </Link>
            ) : null}
          </div>
          <div className="header">
            <h1>{tours[0].title}</h1>
          </div>
          <p>{tours[0].description}</p>
          <ul>
            {map(tours[0].items, (item) => {
              return (
                <li key={item.uid}>
                  {item.current ? (
                    <img
                      src="/assets/images/tour/indicator_current.svg"
                      width="22"
                      height="5"
                      alt={item.title}
                      title={item.title}
                      className="current"
                    />
                  ) : (
                    <Link
                      to={`${flattenToAppURL(item.url)}?tour=${tours[0].uid}`}
                    >
                      <img
                        src="/assets/images/tour/indicator.svg"
                        width="22"
                        height="5"
                        alt={item.title}
                        title={item.title}
                      />
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
          <div className="pos">
            [{tours[0].current + 1 ?? 0}/{tours[0].total}]
          </div>
        </div>
      ) : (
        <div className="tourMarker">
          <img src="/assets/images/tour/avatar_drwani_pc.png" alt="Dr. Wani" />
          <div className="balloon">
            <div className="firstTour">
              このページは
              <Link
                to={`${flattenToAppURL(url)}?tour=${tours[0].uid}`}
                className="tourName"
              >
                {tours[0].title}
              </Link>
              に参加しています
              <Link
                to={`${flattenToAppURL(url)}?tour=${tours[0].uid}`}
                className="linkToTour"
              >
                Link
              </Link>
            </div>
            {tours.length > 1 ? (
              !isExtract ? (
                <button
                  className="ui button extract"
                  onClick={() => setIsExtract(true)}
                >
                  {/* {intl.formatMessage(messages.showOtherRelatedTours)} */}
                  その他の参加ツアー
                </button>
              ) : (
                <div>
                  <div className="extractedLabel">
                    <span className="extractedSeparator">
                      <hr></hr>
                    </span>
                    <span className="labelWrapper">
                      <span className="label">
                        {/* {intl.formatMessage(messages.showOtherRelatedTours)} */}
                        その他の参加ツアー
                      </span>
                    </span>
                  </div>
                  <div className="extracted">
                    {map(tours.slice(1), (tour) => (
                      <div className="extractedContent">
                        <Link to={`${flattenToAppURL(url)}?tour=${tour.uid}`}>
                          {tour.title}
                        </Link>
                      </div>
                    ))}
                  </div>
                  <button
                    className="ui button collaps"
                    onClick={() => setIsExtract(false)}
                  >
                    {/* {intl.formatMessage(messages.hideOtherRelatedTours)} */}
                    閉じる
                  </button>
                </div>
              )
            ) : null}
          </div>
        </div>
      )}
    </nav>
  ) : null;
};

export default injectIntl(TourIndicator);
