/**
 * コンテンツ移行機能を含むページコンテンツタイプ用View
 * @module components/theme/View/ImportedPageView
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container, Image } from 'semantic-ui-react';
import { map } from 'lodash';
import { blocks } from '~/config';
// import { canBlockPreview, previewBlock } from '~/actions';
import { Link } from 'react-router-dom';
import { Helmet, flattenToAppURL } from '@plone/volto/helpers';

import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
  getBaseUrl,
} from '@plone/volto/helpers';
import {
  BlackBox,
  OuBody,
  OuHTMLBody,
  PageMainImage,
  PageTitle,
  SNSShare,
  VisualClear,
} from '../../index';
// import './OuImportedPageView.css';
import './OuPageView.css';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

/**
 * Component to display the default view.
 * @function DefaultView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const ImportedPageView = ({
  content,
  intl,
  location,
  canBlockPreview,
  previewBlock,
  setPreviewBlock,
}) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const [canBlockPreviewState, setCanBlockPreviewState] = useState(false);

  useEffect(() => {
    // if (hasBlocksData(content) && !content.select_view_type) {
    if (hasBlocksData(content) && !content.show_block_view) {
      if (!canBlockPreviewState) {
        setCanBlockPreviewState(true);
        canBlockPreview(true);
      }
    } else {
      if (canBlockPreviewState) {
        setCanBlockPreviewState(false);
        canBlockPreview(false);
      }
    }
    // return () => {
    //   console.log('### Will unmount ###');
    //   setPreviewBlock(false);
    // };
  }, [
    canBlockPreviewState,
    content,
    canBlockPreview,
    previewBlock,
    setPreviewBlock,
  ]);
  return (
    <div className="OuImportedPageView">
      <div className="OuPageView">
        <Helmet title={content.title} />

        <section className="mainArea">
          <div className="mainAreaInner">
            <div className="mainAreaInnerInner">
              {previewBlock && (
                <div class="ui info icon message">
                  <i class="eye icon"></i>
                  <div class="content">
                    <div class="header">Preview block mode</div>
                    <p>
                      To publish as block mode, check 'Show as block' checkbox
                      on the edit screen and save content.
                    </p>
                  </div>
                </div>
              )}

              <div className="blackBoxWrapper">
                <Link to={flattenToAppURL(content?.parent['@id'])}>
                  <BlackBox title={content?.parent?.title} />
                </Link>
              </div>

              <PageMainImage content={content} />
              <div className="bodyArea">
                <PageTitle title={content.title} />
                {hasBlocksData(content) &&
                // (content.select_view_type || previewBlock) ? (
                (content.show_block_view || previewBlock) ? (
                  <OuBody content={content} />
                ) : (
                  content.html_code && <OuHTMLBody html={content.html_code} />
                )}
                <VisualClear />
                <SNSShare url={content['@id']} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ImportedPageView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Text of the object
     */
    text: PropTypes.shape({
      /**
       * Data of the text of the object
       */
      data: PropTypes.string,
    }),
  }).isRequired,
  previewBlock: PropTypes.bool,
  canBlockPreview: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state, props) => ({
      content: state.content.data,
      previewBlock: state.previewBlock?.preview,
    }),
    {},
    // { canBlockPreview, setPreviewBlock: previewBlock },
  ),
)(ImportedPageView);
