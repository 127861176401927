/**
 * OuFAQTopFolderView component.
 * @module components/theme/View/OuFAQTopFolderView
 */

import _ from 'lodash';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Icon, Input } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { HelmetSite } from '../../index';
import { faqFolderList as faqFolderListAction } from '../../../actions/index';
import './OuFAQTopFolderView.css';

/**
 * Component to display the OuFAQTopFolderView.
 * @function OuFAQTopFolderView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
export const FAQMainImage = (props) => {
  const src = flattenToAppURL(
    `/assets/images/faq/h1_${props.theme ?? 'shika'}_${props.locale}.svg`,
  );
  return (
    <h1 className="FAQMainImage">
      <Link to={flattenToAppURL(props.url)}>
        <img alt={props.alt} src={src} />
      </Link>
    </h1>
  );
};

export const FAQLeadSentence = () => {
  return (
    <div className="FAQLeadSentence">
      <FormattedMessage id="faq_lead_sentence" defaultMessage="" />
    </div>
  );
};

export const FAQThemes = (props) => {
  const largeFolders = props.largeFolders;
  return (
    largeFolders.length > 0 && (
      <div className="FAQThemes">
        {largeFolders.map((largeFolder, index) => {
          const smallFolders = largeFolder.items;
          return (
            smallFolders.length > 0 && (
              <div className="row" key={index}>
                <div className="title">{largeFolder.name}</div>
                <ul className="buttons">
                  {smallFolders.map((smallFolder, index) => {
                    return (
                      <li className="button" key={index} role="button">
                        <Link to={flattenToAppURL(smallFolder.url)}>
                          {smallFolder.name}
                        </Link>
                        <i className="count">
                          {smallFolder.count}
                          {props.locale === 'ja' && (
                            <span className="countInner">件</span>
                          )}
                        </i>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )
          );
        })}
      </div>
    )
  );
};

export const FAQSearchBox = (props) => {
  const searchPath = flattenToAppURL(`${props.rootPath}/faq-search`);
  const [curQuery, setCurQuery] = useState(props.query);
  const changeHandle = (event, data) => {
    setCurQuery(data.value);
  };
  return (
    <div className="FAQSearchBox">
      <div className="row">
        <div className="title">
          <FormattedMessage
            id="keyword_search"
            defaultMessage="Keyword Search"
          />
        </div>
        <Form action={searchPath} className="searchBox">
          <Form.Field>
            <Input
              className="searchableText"
              name="q"
              onChange={changeHandle}
              value={curQuery}
            />
            <button className="searchButton">
              <Icon name="search" />
            </button>
          </Form.Field>
        </Form>
      </div>
    </div>
  );
};

export const FAQThemeArea = (props) => {
  return (
    <div className="ThemeArea">
      <FAQLeadSentence />
      <div className="themeArea">
        <div className="themeAreaInner">
          <FAQThemes {...props} />
          <FAQSearchBox {...props} />
        </div>
      </div>
    </div>
  );
};

const OuFAQTopFolderView = ({ content }) => {
  const rootPath = _.get(content, '@id');
  const [largeFolders, locale] = useSelector((state) => {
    return [
      state?.faqFolderList?.result?.data?.attributes?.folder_list ?? [],
      state?.content?.data?.language?.token ?? 'ja',
    ];
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(faqFolderListAction(rootPath));
  }, [content]);
  return (
    <div className="OuFAQTopFolderView OuFAQsView">
      <HelmetSite content={content} />
      <section className="mainArea">
        <div className="mainAreaInner">
          <FAQMainImage
            alt={content.title}
            locale={locale}
            theme={content.themes.token}
            url={rootPath}
          />
          <FAQThemeArea
            largeFolders={largeFolders}
            locale={locale}
            rootPath={rootPath}
          />
        </div>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuFAQTopFolderView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default injectIntl(OuFAQTopFolderView);
