/**
 * Edit Automatic List Block.
 * @module components/manage/Blocks/automaticList/Edit
 * word by Hironobu Manaka 2022/07/26
 */

const Schema = {
  title: 'Item',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['alt', 'object_path'],
    },
  ],
  properties: {
    //image: {
    //  title: 'Image',
    //  widget: 'object_browser',
    //  mode: 'image',
    //  selectedItemAttrs: [], // path and Title
    //},
    alt: {
      title: '代替テキスト (Alt)',
      widget: 'text',
    },
    //description: {
    //  title: 'Description',
    //  widget: 'text',
    //},
    object_path: {
      title: 'オブジェクトのパス (Object Path)',
      widget: 'object_browser',
      mode: 'link',
      allowExternals: false,
      selectedItemAttrs: [],
    },
  },
  required: ['object_path'],
};

export default Schema;
