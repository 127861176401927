/**
 * OuHTMLBody component.
 * @module components/theme/Element/OuHTMLBody/OuHTMLBody
 * @nk24 2021/03/02
 */

import React, { useLayoutEffect, useMemo, useRef } from 'react';
import './OuHTMLBody.css';

/**
 * Component to display the OuHTMLBody
 * @function OuHTMLBody
 * @param {Object} content Content object
 * @returns {string} Markup of the component
 */
const OuHTMLBody = ({ html, allowDengerousTags = false }) => {
  // console.log({ html });
  //   const divRef = useRef();
  //   const initialHTML = useMemo(() => {
  //     const scriptStrings = html.match(
  //       /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
  //     );
  //     return scriptStrings
  //       ? scriptStrings.reduce((acc, current) => {
  //           return acc.replace(current, '');
  //         }, html)
  //       : html;
  //   }, [html]);

  //   useLayoutEffect(() => {
  //     if (!divRef.current) {
  //       return;
  //     }

  //     (async () => {
  //       const scriptStrings = html.match(
  //         /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
  //       );

  //       let updatedHtml = html;
  //       await scriptStrings.reduce(async (acc, current) => {
  //         await acc;

  //         const scriptFragment = document
  //           .createRange()
  //           .createContextualFragment(current);
  //         const scriptElement = scriptFragment.querySelector('script');

  //         if (scriptElement.src === '') {
  //           return Promise.resolve();
  //         }

  //         updatedHtml = updatedHtml.replace(current, '');

  //         if (
  //           Array.from(document.querySelectorAll('script')).some(
  //             (se) => se.src === scriptElement.src,
  //           )
  //         ) {
  //           return Promise.resolve();
  //         }

  //         return new Promise((resolve) => {
  //           scriptElement.addEventListener('load', () => {
  //             resolve();
  //           });

  //           document.head.appendChild(scriptElement);
  //         });
  //       }, Promise.resolve());

  //       const fragment = document
  //         .createRange()
  //         .createContextualFragment(updatedHtml);

  //       divRef.current.innerHTML = '';
  //       divRef.current.appendChild(fragment);
  //     })();
  //   }, [html]);

  //   return allowDengerousTags ? (
  //     <div ref={divRef} className="OuHTMLBody" />
  //   ) : (
  //     <div
  //       className="OuHTMLBody"
  //       dangerouslySetInnerHTML={{
  //         __html: html,
  //       }}
  //     />
  //   );
  return (
    <div
      className="OuHTMLBody"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    ></div>
  );
};

export default OuHTMLBody;
