/**
 * OuGridFolderView component.
 * @module components/theme/View/OuGridFolderView
 */

import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { HelmetSite, OuBody, PageTitle } from '../../index';
import './OuGridFolderView.css';

/**
 * Component to display the OuGridFolderView.
 * @function OuGridFolderView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OuGridFolderView = ({ content }) => {
  const headerImageSrc = flattenToAppURL(content.header_image?.download ?? '');
  return (
    <div className="OuFolderView OuGridFolderView">
      <HelmetSite content={content} />
      <section className="mainArea">
        <div className="mainAreaInner">
          {headerImageSrc && (
            <div className="headerImageWrapper">
              <div
                className="headerImage"
                style={{ backgroundImage: `url(${headerImageSrc})` }}
              ></div>
            </div>
          )}
          <div className="mainAreaInnerInner">
            <div className="ouFolderTitle">{content.title}</div>
            <OuBody content={content} />
          </div>
        </div>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuGridFolderView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    lead_sentence: PropTypes.string,
    hide_list: PropTypes.bool,
  }).isRequired,
};

export default injectIntl(OuGridFolderView);
