/**
 * OuHTMLView component.
 * @module components/theme/View/OuHTMLView
 */

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { HelmetSite, OuHTMLBody } from '../../index';
import './OuHTMLView.css';

/**
 * Component to display the OuHTMLView.
 * @function OuHTMLView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OuHTMLView = ({ content }) => {
  return (
    <div className="OuHTMLView">
      <HelmetSite
        content={content}
        link={[{ rel: 'stylesheet', href: content.css_file }]}
      />
      <div className="OuHTMLViewInner">
        <OuHTMLBody html={content.html_code} allowDengerousTags={true} />
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuHTMLView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default injectIntl(OuHTMLView);
