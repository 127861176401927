import React from 'react';
import ChatItemElement from './ChatItemElement';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ChatItemType } from '../../../../constants/Types';
import { flattenToAppURL } from '@plone/volto/helpers';
import './ChatItemBannerElement.css';

/**
 * Chat Item Banner
 * @param {*} param0
 */
const ChatItemBannerElement = ({ item }) => {
  return (
    <ChatItemElement
      item={item}
      additionalClass={`${item.link ? 'with-link' : ''}`}
    >
      <div className="ChatItemBannerElement">
        {item?.thumbnail?.src ? (
          <div className="thumbnail">
            <Link to={flattenToAppURL(item?.link ?? '')}>
              <img
                src={flattenToAppURL(item?.thumbnail?.src)}
                alt={item?.thumbnail?.alt ?? ''}
              />
            </Link>
          </div>
        ) : null}
        <div className="banner-meta">
          {item?.title ? <h3>{item?.title}</h3> : null}
          {item?.content ? (
            <div className="content">{item?.content}</div>
          ) : null}
          {item.link ? (
            <div className="link">
              <Link to={flattenToAppURL(item.link)}>
                <Icon className="arrow" name="chevron right" />
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </ChatItemElement>
  );
};

ChatItemBannerElement.propTypes = ChatItemType;

export default ChatItemBannerElement;
