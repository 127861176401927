/**
 * LanguageBox component.
 * @module components/theme/Site/LanguageBox/LanguageBox
 */

import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import NoSSR from 'react-no-ssr';
import { useMediaQuery } from 'react-responsive';
import { Sidebar } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { MediaQueryConfig } from '../../../../helpers/GlobalStyle/GlobalStyle';
import config from '@plone/volto/registry';
//import cookie from 'react-cookie';
import { Cookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateIntl } from 'react-intl-redux';
import { changeLanguage } from '@plone/volto/actions/language/language';
import './LanguageBox.css';
import { getLocaleFromPathname } from 'helpers/Locale/Locale';

let locales = {};

if (config.settings) {
  config.settings.supportedLanguages.forEach((lang) => {
    import('../../../../../locales/' + lang + '.json').then((locale) => {
      locales = { ...locales, [lang]: locale.default };
    });
  });
}

/**
 * Component to display the LanguageBox.
 * @function LanguageBox
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const LanguageBox = ({ app, visibleLanguage }) => {
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });

  const dispatch = useDispatch();

  // const locale = new Cookies().get('I18N_LANGUAGE') || config.settings.defaultLanguage;
  const locale = getLocaleFromPathname(
    __CLIENT__ ? window?.location.pathname : null,
    config.settings.defaultLanguage,
  );

  /* Translation */
  const [languages, translations] = useSelector((state) => {
    return [
      state?.siteMainInfo?.result?.data?.attributes?.common?.languages,
      state.content.data?.['@components']?.translations?.items,
    ];
  });

  /* Language */
  const langs = _.filter(languages, (item) => locale !== item?.locale);

  /*
  const dispatch = useDispatch();

  function changeLanguage(language) {
    cookie.save('I18N_LANGUAGE', language, {
      expires: new Date((2 ** 31 - 1) * 1000),
      path: '/',
    });
    dispatch(
      updateIntl({
        locale: language,
        messages: locales[language],
      }),
    );
  }
  */

  return (
    <div className="LanguageBox">
      <NoSSR>
        <Sidebar
          animation="overlay"
          className="languageBox-inner"
          direction={isSP ? 'bottom' : 'top'}
          onHide={() => app.setVisibleLanguage(false)}
          visible={visibleLanguage}
        >
          <div className="languageBox">
            <ul className="langList">
              {_.map(langs, (item) => {
                const translation = _.find(translations, {
                  language: item?.locale,
                });
                return (
                  <li className="item" key={item?.url}>
                    {item?.is_external ? (
                      <a
                        href={flattenToAppURL(item?.url)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item?.label}
                      </a>
                    ) : (
                      <Link
                        onClick={() => {
                          app.setVisibleLeftBar(false);
                          dispatch(
                            changeLanguage(item?.locale, locales[item?.locale]),
                          );
                        }}
                        to={
                          translation !== undefined
                            ? flattenToAppURL(translation['@id'])
                            : `/${item?.locale}`
                        }
                      >
                        {item?.label}
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </Sidebar>
      </NoSSR>
    </div>
  );
};

export default injectIntl(LanguageBox);
