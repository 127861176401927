import _ from 'lodash';
import { FOLDER_LIST } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  result: {},
};

/**
 * FolderList Reducer
 * @param {*} state
 * @param {*} action
 */
export default function folderList(state = initialState, action = {}) {
  switch (action.type) {
    case `${FOLDER_LIST}_PENDING`:
      return {
        ...state,
        error: null, // state.error = null
        loaded: false, // state.loaded = false
        loading: true, // state.loading = true
      };
    case `${FOLDER_LIST}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        result: action?.result,
        loading: false,
      };
    case `${FOLDER_LIST}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
