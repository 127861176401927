import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import './View.css';

const View = ({ data }) => {
  return (
    <div id={data.key} className="title">
      {data.ouGridHeader}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  // block: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  // onChangeBlock: PropTypes.func.isRequired,
  // selected: PropTypes.bool.isRequired,
};

export default injectIntl(View);
