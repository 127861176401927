/**
 * View table block.
 * @module components/manage/Blocks/Table/View
 * based on v13.8.2
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { map } from 'lodash';
import redraft from 'redraft';

import config from '@plone/volto/registry';
// @nk24 begin
import { getSpanFromMatrix } from '@package/helpers/Matrix/Matrix';
import './View.css';
// @nk24 end

/**
 * View table block class.
 * @class View
 * @extends Component
 */
// @nk24 begin
const View = ({ data }) => {
  if (data && data.table) {
    return (
      <div className={data.table.unscrollable ? 'Table' : 'Table scrollable'}>
        {/* @nk24 end */}
        <Table
          fixed={data.table.fixed}
          compact={data.table.compact}
          basic={data.table.basic ? 'very' : false}
          celled={data.table.celled}
          inverted={data.table.inverted}
          striped={data.table.striped}
          // @nk24 begin
          className="unstackable"
          // @nk24 end
        >
          <Table.Body>
            {/* @nk24 begin */}
            {map(data.table.rows, (row, rowIndex) => (
              <Table.Row key={row.key}>
                {map(row.cells, (cell, cellIndex) => {
                  const { colspan, rowspan } = getSpanFromMatrix(
                    data.table.matrix,
                    cellIndex,
                    rowIndex,
                  );
                  if (colspan > 0 && rowspan > 0) {
                    return (
                      <Table.Cell
                        key={cell.key}
                        as={cell.type === 'header' ? 'th' : 'td'}
                        colSpan={colspan ? colspan : null}
                        rowSpan={rowspan ? rowspan : null}
                      >
                        {/* @nk24 end */}
                        {cell.value &&
                        cell.value.blocks &&
                        cell.value.blocks[0].text
                          ? redraft(
                              cell.value,
                              config.settings.richtextViewSettings
                                .ToHTMLRenderers,
                              config.settings.richtextViewSettings
                                .ToHTMLOptions,
                            )
                          : '\u00A0'}
                      </Table.Cell>
                    );
                  } else return null;
                  // @nk24 begin
                })}
                {/* @nk24 end */}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {/* @nk24 begin */}
      </div>
    );
  } else return null;
};
// @nk24 end

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
