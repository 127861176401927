/**
 * CreateFilesFolder component.
 * @module components/manage/Block/Image/CreateFilesFolder
 */

import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { createContent, getContent } from '@plone/volto/actions';
import { Icon } from '@plone/volto/components';
import { flattenToAppURL, getBaseUrl } from '@plone/volto/helpers';
import addDocumentSVG from '@plone/volto/icons/add-document.svg';
// import { clearFilesfolders, filesfolders } from '@package/actions';

const messages = defineMessages({
  Next: {
    id: 'Next',
    defaultMessage: 'Next',
  },
  AddFilesFolder: {
    id: 'Add files folder',
    defaultMessage: 'Add files folder',
  },
});

/**
 * Create files folder class.
 * @class CreateFilesFolder
 * @extends Component
 */
class CreateFilesFolder extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    parentId: PropTypes.string.isRequired,
    versionId: PropTypes.string.isRequired,
    setFilesfolderPathname: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    // filesfolderList: PropTypes.arrayOf(PropTypes.string).isRequired,
    filesfolderPathname: PropTypes.string.isRequired,
  };

  // state = {
  //   parentPathname: null,
  //   filesFolderPathname: null,
  // };

  /**
   * Component did mount.
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.getContent(
      `${this.fetchParentPathname()}/files`,
      this.props.versionId,
      `${this.props.block}-folder`,
    );
  }

  componentDidUpdate() {
    // 20230131 terada
    if (!this.props.filesfolderPathname) {
      const existsFolderObjPath = flattenToAppURL(this.props.content?.['@id']);
      const shouldFolderPath = flattenToAppURL(
        `${this.fetchParentPathname()}/files`,
      );
      if (
        existsFolderObjPath &&
        shouldFolderPath &&
        existsFolderObjPath === shouldFolderPath
      ) {
        this.props.setFilesfolderPathname(shouldFolderPath);
      }
    }
    // end of 20230131

    // 20230131 remove
    // const folderList = this.props.filesfolderList;
    // if (_.includes(folderList, shouldFolderPath)) {
    //   if (
    //     shouldFolderPath &&
    //     shouldFolderPath !== this.state.filesFolderPathname
    //   ) {
    //     this.setState({
    //       filesFolderPathname: shouldFolderPath,
    //     });
    //     this.props.setFilesfolderPathname(shouldFolderPath);
    //   }
    // } else {
    //   if (shouldFolderPath === existsFolderObjPath) {
    //     if (
    //       existsFolderObjPath &&
    //       existsFolderObjPath !== this.state.filesFolderPathname
    //     ) {
    //       this.setState({
    //         filesFolderPathname: shouldFolderPath,
    //       });
    //       this.props.filesfolders(shouldFolderPath);
    //       this.props.setFilesfolderPathname(shouldFolderPath);
    //     }
    //   }
    // }
  }

  /**
   * 編集モードに応じてparent of filesFolderのパスを得る
   * @function fetchParentPathname
   * @param {*} e
   */
  fetchParentPathname() {
    const editMode = this.props.pathname.split('/').slice(-1)[0];
    if (editMode === 'edit' && this.props.parentId) {
      return flattenToAppURL(this.props.parentId);
    } else if (editMode === 'add') {
      return getBaseUrl(this.props.pathname);
    } else {
      return null;
    }
  }

  /**
   * アップロード／選択された画像を保存するfilesフォルダを作成する
   * @function createFilesFolder
   * @param {object} e
   */
  createFilesFolder = (e) => {
    const parentPathname = this.fetchParentPathname();
    this.props.createContent(
      parentPathname,
      {
        '@type': 'Folder',
        title: 'files',
      },
      `${this.props.block}-folder`,
    );
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    // return this.state.filesFolderPathname !==
    return this.props.filesfolderPathname !==
      flattenToAppURL(`${this.fetchParentPathname()}/files`) ? (
      <div>
        <Button.Group>
          <Button
            basic
            icon
            onClick={(e) => {
              e.stopPropagation();
              this.createFilesFolder();
            }}
          >
            <Icon name={addDocumentSVG} size="24px" />
          </Button>
        </Button.Group>
        <span className="ButtonText">
          {this.props.intl.formatMessage(messages.AddFilesFolder)}
        </span>
      </div>
    ) : null;
  }
}

export default compose(
  injectIntl,
  connect(
    (state, ownProps) => ({
      test: state,
      // parentId: state.content.data?.parent?.['@id'],
      // versionId: state.content.data?.version,
      request: state.content.subrequests[`${ownProps.block}-folder`] || {},
      content: state.content.subrequests[`${ownProps.block}-folder`]?.data,
      // filesfolderList: state.filesfolderList?.paths,
    }),
    // { createContent, filesfolders, getContent },
    { createContent, getContent },
  ),
)(CreateFilesFolder);
