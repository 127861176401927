/**
 * @module components/theme/Forbidden/Forbidden
 */

import React from 'react';
//import { FormattedMessage } from 'react-intl';
//import { Container } from 'semantic-ui-react';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';
import { OuForbiddenView } from '@package/components';

/**
 * forbidden function.
 * @function Forbidden
 * @returns {string} Markup of the forbidden page.
 */
const Forbidden = () => <OuForbiddenView />;

export default withServerErrorCode(403)(Forbidden);
