import { FAQ_SEARCH } from '../../constants/ActionTypes';

export function faqSearch(bStart, path, query) {
  console.log(path);
  return {
    type: FAQ_SEARCH,
    request: {
      op: 'get',
      path: `${path}/@faq-search?b_start=${bStart}&q=${query}`,
    },
    bStart: bStart,
  };
}
