import React from 'react';
import ChatItemElement from './ChatItemElement';
import { Icon } from 'semantic-ui-react';
import { ChatItemType } from '../../../../constants/Types';
import './ChatItemMoreButtonElement.css';

/**
 * Chat Item More Button
 * @param {*} param0
 * @returns
 */
const ChatItemMoreButtonElement = ({ item, callback }) => {
  return (
    <div className="ChatItemMoreButtonElement">
      <button onClick={() => callback()}>
        {item?.content ?? 'MORE'}
        <Icon className="arrow" name="chevron down" />
      </button>
    </div>
  );
};

ChatItemMoreButtonElement.propTypes = ChatItemType;

export default ChatItemMoreButtonElement;
