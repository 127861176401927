/**
 * Store form ids of mail form
 * メールフォームのフォームID重複管理用
 * @module actions/mailFormFormIds/mailFormFormIds
 */
import {
  POP_MAIL_FORM_FORM_ID,
  ADD_MAIL_FORM_FORM_ID,
} from '../../constants/ActionTypes';

export function addMailFormFormId(uid, block, formId) {
  return {
    type: ADD_MAIL_FORM_FORM_ID,
    formIdData: { uid, block, formId },
  };
}

export function popMailFormFormId(uid, block, formId) {
  return {
    type: POP_MAIL_FORM_FORM_ID,
    formIdData: { uid, block, formId },
  };
}
