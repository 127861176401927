/**
 * FolderHeaderImage component.
 * @module components/theme/Element/FolderHeaderImage/FolderHeaderImage
 */

import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import './FolderHeaderImage.css';

/**
 * Component to display the FolderHeaderImage
 * @function FolderHeaderImage
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const FolderHeaderImage = ({ content }) => {
  const headerImageSrc = flattenToAppURL(content?.header_image?.download ?? '');

  return (
    <section className="FolderHeaderImage">
      <div className="folderHeaderImage">
        <div
          className="headerImage"
          style={{ backgroundImage: `url(${headerImageSrc})` }}
        />
        <div className="blackCover"></div>
        <div className="infoBox">
          <h1 className="title">{content?.title}</h1>
          {content?.lead_sentence && (
            <div className="leadSentence">{content?.lead_sentence}</div>
          )}
        </div>
      </div>
    </section>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
FolderHeaderImage.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.required,
    lead_sentence: PropTypes.string,
  }).isRequired,
};

export default injectIntl(FolderHeaderImage);
