/**
 * Edit sub unit of form block.
 * @module components/manage/Blocks/OUNumbers/Item
 */

import React, { Component } from 'react';
import { Map } from 'immutable';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import Editor from 'draft-js-plugins-editor';
import { Button } from 'semantic-ui-react';
import {
  convertFromRaw,
  convertToRaw,
  DefaultDraftBlockRenderMap,
  EditorState,
} from 'draft-js';
// import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';
import { includes } from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';

import addSVG from '@plone/volto/icons/circle-plus.svg';
import dragSVG from '@plone/volto/icons/drag.svg';

import deleteSVG from '@plone/volto/icons/delete.svg';

import { Icon } from '@plone/volto/components';

import config from '@plone/volto/registry';
// import { settings } from '~/config';
// import './Item.css';

const messages = defineMessages({
  placeholder: {
    id: 'Type the subheader text…',
    defaultMessage: 'Type the subheader text…',
  },
});

const headerBlockRenderMap = Map({
  unstyled: {
    element: 'h3',
  },
});

const blockStyle = 'Header3';
const extendedHeaderBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  headerBlockRenderMap,
);

/**
 * Edit text cell class.
 * @class Cell
 * @extends Component
 */
class Item extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    index: PropTypes.number.isRequired,
    onSelectList: PropTypes.func.isRequired,
    // row: PropTypes.number,
    // cell: PropTypes.number,
    value: PropTypes.object,
    selected: PropTypes.bool,
    onChangeList: PropTypes.func.isRequired,
    onInsertItem: PropTypes.func.isRequired,
    onDeleteItem: PropTypes.func.isRequired,
    isWholeBlockSelected: PropTypes.bool,
    // isTableBlockSelected: PropTypes.bool,
    // disableNewBlocks: PropTypes.bool,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    detached: false,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Cell
   */
  constructor(props) {
    super(props);

    if (!__SERVER__) {
      let headerEditorState;
      let numberEditorState;
      let textEditorState;
      headerEditorState = EditorState.createWithContent(
        convertFromRaw(props.value.header),
      );
      numberEditorState = EditorState.createWithContent(
        convertFromRaw(props.value.number),
      );
      textEditorState = EditorState.createWithContent(
        convertFromRaw(props.value.text),
      );

      // const inlineToolbarPlugin = createInlineToolbarPlugin({
      //   structure: settings.richTextEditorInlineToolbarButtons,
      // });

      this.state = {
        headerEditorState,
        numberEditorState,
        textEditorState,
        // inlineToolbarPlugin,
      };
    }

    this.onChangeHeader = this.onChangeHeader.bind(this);
    this.onChangeNumber = this.onChangeNumber.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
  }

  /**
   * Component did mount lifecycle method
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    // if (this.node) {
    //   const onFocus = this.node.editor._onFocus;
    //   this.node.editor._onFocus = (event) => {
    //     onFocus(event);
    // this.props.onSelectCell(this.props.index, this.props.formId);
    // this.props.onSelectList(this.props.index, 'header');
    // this.props.onSelectList(this.props.index);
    // };
    // }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.isWholeBlockSelected !== this.props.isWholeBlockSelected &&
      this.props.index === 0
    ) {
      this.node.focus();
    }
  }

  /**
   * Change handler
   * @method onChange
   * @param {object} editorState Editor state.
   * @returns {undefined}
   */
  onChangeHeader(headerEditorState) {
    // this.setState({ headerEditorState }, () => {
    //   // this.props.onChange(this.props.row, this.props.cell, editorState);
    //   this.props.onChangeList(this.props.index, 'header', headerEditorState);
    // });
    convertToRaw(headerEditorState.getCurrentContent());
    this.setState({ headerEditorState }, () => {
      this.props.onChangeList(this.props.index, 'header', headerEditorState);
    });
  }
  onChangeNumber(numberEditorState) {
    convertToRaw(numberEditorState.getCurrentContent());
    this.setState({ numberEditorState }, () => {
      this.props.onChangeList(this.props.index, 'number', numberEditorState);
    });
  }
  onChangeText(textEditorState) {
    convertToRaw(textEditorState.getCurrentContent());
    this.setState({ textEditorState }, () => {
      this.props.onChangeList(this.props.index, 'text', textEditorState);
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    // const { InlineToolbar } = this.state.inlineToolbarPlugin;
    // console.log(this.props.selected, this.props.index);
    const isActive = this.props.selected === this.props.index;
    return (
      <div
        className="Item"
        onClick={() => {
          this.props.onSelectList(this.props.index);
        }}
      >
        {/* {isActive ? (
          <div
            style={{
              visibility:
                this.state.selected === block &&
                !this.hideHandler(blocksDict[block])
                  ? 'visible'
                  : 'hidden',
              display: 'inline-block',
            }}
            {...provided.dragHandleProps}
            className="drag handle wrapper"
          >
            {...this.props.provided.dragHandleProps}

            <Icon name={dragSVG} size="18px" />
          </div>
        ) : null} */}
        <div className="header">
          <Editor
            className="header"
            onChange={this.onChangeHeader}
            placeholder="Type the header text…"
            editorState={this.state.headerEditorState}
            // plugins={[
            //   this.state.inlineToolbarPlugin,
            //   ...settings.richTextEditorPlugins,
            // ]}
            blockRenderMap={extendedHeaderBlockRenderMap}
            blockStyleFn={() => blockStyle}
            // handleReturn={(e) => {
            //   if (isSoftNewlineEvent(e)) {
            //     this.onChange(
            //       RichUtils.insertSoftNewline(this.state.headerEditorState),
            //     );
            //     return 'handled';
            //   }
            //   if (!this.props.detached && !this.props.disableNewBlocks) {
            //     const selectionState = this.state.headerEditorState.getSelection();
            //     const anchorKey = selectionState.getAnchorKey();
            //     const currentContent = this.state.headerEditorState.getCurrentContent();
            //     const currentContentBlock = currentContent.getBlockForKey(
            //       anchorKey,
            //     );
            //     const blockType = currentContentBlock.getType();
            //     if (!includes(settings.listBlockTypes, blockType)) {
            //       this.props.onSelectBlock(
            //         this.props.onAddBlock(
            //           settings.defaultBlockType,
            //           this.props.index + 1,
            //         ),
            //       );
            //       return 'handled';
            //     }
            //     return 'un-handled';
            //   }
            //   return {};
            // }}
            ref={(node) => {
              this.node = node;
            }}
          />
        </div>
        {/* <InlineToolbar /> */}
        <div className="number">
          <Editor
            onChange={this.onChangeNumber}
            placeholder="Type the number…"
            editorState={this.state.numberEditorState}
            // plugins={[
            //   this.state.inlineToolbarPlugin,
            //   ...settings.richTextEditorPlugins,
            // ]}
            blockRenderMap={config.settings.extendedBlockRenderMap}
            blockStyleFn={config.settings.blockStyleFn}
            customStyleMap={config.settings.customStyleMap}
            // handleReturn={(e) => {
            //   if (isSoftNewlineEvent(e)) {
            //     this.onChange(
            //       RichUtils.insertSoftNewline(this.state.textEditorState),
            //     );
            //     return 'handled';
            //   }
            //   if (!this.props.detached && !this.props.disableNewBlocks) {
            //     const selectionState = this.state.textEditorState.getSelection();
            //     const anchorKey = selectionState.getAnchorKey();
            //     const currentContent = this.state.textEditorState.getCurrentContent();
            //     const currentContentBlock = currentContent.getBlockForKey(
            //       anchorKey,
            //     );
            //     const blockType = currentContentBlock.getType();
            //     if (!includes(settings.listBlockTypes, blockType)) {
            //       this.props.onSelectBlock(
            //         this.props.onAddBlock(
            //           settings.defaultBlockType,
            //           this.props.index + 1,
            //         ),
            //       );
            //       return 'handled';
            //     }
            //     return 'un-handled';
            //   }
            //   return {};
            // }}
          />
        </div>
        <div className="text">
          <Editor
            onChange={this.onChangeText}
            placeholder="Type the text…"
            editorState={this.state.textEditorState}
            // plugins={[
            //   this.state.inlineToolbarPlugin,
            //   ...settings.richTextEditorPlugins,
            // ]}
            blockRenderMap={config.settings.extendedBlockRenderMap}
            blockStyleFn={config.settings.blockStyleFn}
            customStyleMap={config.settings.customStyleMap}
            // handleReturn={(e) => {
            //   if (isSoftNewlineEvent(e)) {
            //     this.onChange(
            //       RichUtils.insertSoftNewline(this.state.textEditorState),
            //     );
            //     return 'handled';
            //   }
            //   if (!this.props.detached && !this.props.disableNewBlocks) {
            //     const selectionState = this.state.textEditorState.getSelection();
            //     const anchorKey = selectionState.getAnchorKey();
            //     const currentContent = this.state.textEditorState.getCurrentContent();
            //     const currentContentBlock = currentContent.getBlockForKey(
            //       anchorKey,
            //     );
            //     const blockType = currentContentBlock.getType();
            //     if (!includes(settings.listBlockTypes, blockType)) {
            //       this.props.onSelectBlock(
            //         this.props.onAddBlock(
            //           settings.defaultBlockType,
            //           this.props.index + 1,
            //         ),
            //       );
            //       return 'handled';
            //     }
            //     return 'un-handled';
            //   }
            //   return {};
            // }}
          />
        </div>
        {isActive ? (
          <>
            <Button
              basic
              icon
              onClick={() => this.props.onInsertItem()}
              className="block-add-button"
            >
              <Icon name={addSVG} className="block-add-button" size="24px" />
            </Button>
            <Button
              basic
              icon
              onClick={() => this.props.onDeleteItem()}
              className="block-clear-button"
            >
              <Icon
                name={deleteSVG}
                className="block-clear-button"
                size="18px"
              />
            </Button>
          </>
        ) : null}
      </div>
    );
  }
}

export default Item;
