import React from 'react';
import ChatItemElement from './ChatItemElement';
import { ChatItemType } from '../../../../constants/Types';
import './ChatItemGreetingElement.css';

/**
 * Chat Item Greeting
 * @param {*} param0
 */
const ChatItemGreetingElement = ({ item }) => {
  return (
    <ChatItemElement item={item} additionalClass={'double special'}>
      <div className="ChatItemGreetingElement">
        <div className="icon">
          <img src="/assets/images/logo_mini_site_image.png" alt="" />
        </div>
        <h3>{item?.title}</h3>
        <div className="content">{item?.content}</div>
        <div className="banner">
          <a href={item?.link ?? ''}>
            <img src={item?.thumbnail?.src} alt={item?.thumbnail?.alt ?? ''} />
          </a>
        </div>
      </div>
    </ChatItemElement>
  );
};

ChatItemGreetingElement.propTypes = ChatItemType;

export default ChatItemGreetingElement;
