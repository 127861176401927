import React, { Component } from 'react';

/**
 * Edit image block class.
 * @class Edit
 * @extends Component
 */
class ViewGrid extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return <></>;
  }
}

export default ViewGrid;
