import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import Schema from './Schema';

const messages = defineMessages({
  univ_info: {
    id: 'University Information',
    defaultMessage: 'University Information',
  },
});

const Sidebar = (props) => {
  const _ = props.intl.formatMessage;
  const type = props.data['@type'];
  const content = props.data?.[type];
  if (!content) {
    return null;
  }
  // const univ_info = props.data?.univ_info ?? {
  //   ...props.data,
  //   univ_info: {
  //     background_image_PC: [],
  //     background_image_SP: [],
  //     targets: [],
  //   },
  // };
  return (
    <InlineForm
      schema={Schema}
      title={_(messages.univ_info)}
      onChangeField={(id, value) => {
        props.onChangeBlock(props.block, {
          ...props.data,
          univ_info: {
            ...props.data.univ_info,
            [id]: value,
          },
        });
      }}
      formData={{
        background_image_PC: content.background_image_PC || [],
        background_image_SP: content.background_image_SP || [],
        targets: content.targets || [],
      }}
    />
  );
};

export default injectIntl(Sidebar);
