/**
 * OuPageView component.
 * @module components/theme/View/OuPageView
 */

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  BlackBox,
  HelmetSite,
  OuBody,
  PageMainImage,
  PageTitle,
  SNSShare,
  TourIndicator,
  VisualClear,
} from '@package/components';
import './OuPageView.css';

/**
 * Component to display the OuPageView.
 * @function OuPageView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OuPageView = ({ content }) => {
  return (
    <div className="OuPageView">
      <HelmetSite content={content} />
      <section className="mainArea">
        <TourIndicator />
        <div className="mainAreaInner">
          <div className="mainAreaInnerInner">
            <div className="blackBoxWrapper">
              <Link to={flattenToAppURL(content.parent['@id'])}>
                <BlackBox title={content.parent.title} />
              </Link>
            </div>
            <PageMainImage content={content} />
            <div className="bodyArea">
              <PageTitle title={content.title} />
              <OuBody content={content} />
              <VisualClear />
              <SNSShare url={content['@id']} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuPageView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default injectIntl(OuPageView);
