import { FOR_YOU_MORE } from '../../constants/ActionTypes';

export function forYouMore(path, target) {
  return {
    type: FOR_YOU_MORE,
    request: {
      op: 'get',
      path: `${path}/@for-you-more?target=${target}`,
    },
  };
}
