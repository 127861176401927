import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import './View.css';

const View = ({ data }) => {
  /* Enable soft return@nk24 on 2024/6/21 */
  let description = data?.ouGridDescription || '';
  const htmlDescription = description.split('\n').join('<br />');
  return (
    <div
      id={data.key}
      className="description"
      dangerouslySetInnerHTML={{ __html: htmlDescription }}
    ></div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  // block: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  // onChangeBlock: PropTypes.func.isRequired,
  // selected: PropTypes.bool.isRequired,
};

export default injectIntl(View);
