/**
 * URL helper
 *
 * Usage
 *
 * - isVoltoInternalURL : isInternalURLの置き換え
 * - flattenToAppURLWithClean : 内部的なURLを綺麗にした上で flattenToAppURL を使うラッパー関数
 *
 * Test
 *
 * ```
 * $ npx jest src/helpers/Url/Url.test.js
 * ```
 */

// import { settings } from '~/config';
import config from '@plone/volto/registry';
import { flattenToAppURL } from '@plone/volto/helpers';

export function getOrigin() {
  const location = window.location;
  if (location.origin === undefined) {
    return (
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '')
    );
  } else {
    return location.origin;
  }
}

export function getLocaleFromUrl(url) {
  const vocabPath = url && url.replace(config.settings.apiPath, '').split('/');
  if (['ja', 'en'].includes(vocabPath[1])) {
    return vocabPath[1];
  } else {
    return '';
  }
}

/**
 * Check if internal url
 * @method isInternalURL
 * @param {string} url URL of the object
 * @returns {boolean} True if internal url
 */
export function isVoltoInternalURL(url) {
  const { settings } = config;
  return internalURLCheck(
    url,
    settings.publicURL,
    settings.internalApiPath,
    settings.apiPath,
  );
}

/**
 * isVoltoInternalURL の内部関数
 *
 * @param {*} url
 * @param {*} publicURL
 * @param {*} internalApiPath
 * @param {*} apiPath
 * @returns
 */
export function internalURLCheck(url, publicURL, internalApiPath, apiPath) {
  return (
    url &&
    !/(^http:\/\/|^https:\/\/)/.test(url) &&
    // url.indexOf(publicURL) !== -1 ||
    (url.indexOf(internalApiPath) !== -1 ||
      url.indexOf(apiPath) !== -1 ||
      url.charAt(0) === '/' ||
      url.charAt(0) === '.' ||
      url.startsWith('#'))
  );
}

/**
 * 内部的なURLを綺麗にした上で flattenToAppURL を使うラッパー関数
 *
 * ## 使い方
 *
 * Redux StateのsiteMainInfoステートからexcludeHostsを取得して使ってください
 * 内部で flattenToAppURL も実行するので関数を置き換える形で利用してください
 * ヘルパー内でRedux stateを取得するのは微妙な感じがしたので、siteMainInfoからexcludeHostsを取得して渡す形にしました
 *
 * ```
 * // siteMainInfoからexcludeHostsを取得 (配列)
 * const excludeHosts  = useSelector((state) => state?.siteMainInfo?.result?.data?.attributes?.common?.excludeHosts);
 *
 * // サンプル出力
 * console.log(flattenToAppURLWithClean(targetUrl, excludeHosts));
 * ```
 *
 * ## API
 *
 * - ［OU］ 公式サイト/システム設定/実装（フロント）/01.API設計 | Wiki | Backlog https://cmscom.backlog.jp/wiki/OU/%E5%85%AC%E5%BC%8F%E3%82%B5%E3%82%A4%E3%83%88%2F%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E8%A8%AD%E5%AE%9A%2F%E5%AE%9F%E8%A3%85%EF%BC%88%E3%83%95%E3%83%AD%E3%83%B3%E3%83%88%EF%BC%89%2F01.API%E8%A8%AD%E8%A8%88
 *
 * @param {*} targetUrl
 * @param {*} excludeHosts
 * @returns
 */
export function flattenToAppURLWithClean(targetUrl, excludeHosts) {
  let result = targetUrl;
  if (typeof targetUrl === 'string' && Array.isArray(excludeHosts)) {
    const matchedUrl = excludeHosts.find(
      (item) => targetUrl.indexOf(item) !== -1,
    );
    result = targetUrl.replace(matchedUrl, '');
  }
  return flattenToAppURL(result);
}
