import { GET_RESEARCH_LIST } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  result: {},
  researchList: [],
};

/**
 * Research List Reducer
 * @param {*} state
 * @param {*} action
 */
export default function researchList(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_RESEARCH_LIST}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_RESEARCH_LIST}_SUCCESS`:
      return {
        ...state,
        error: null,
        tst: action,
        result: action?.result,
        researchList: action?.result?.data?.attributes?.data,
        loaded: true,
        loading: false,
      };
    case `${GET_RESEARCH_LIST}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
