/**
 * Placeholder checkbox form widget for the part of Edit component of TextLine, TextArea block for MailForm.
 * @module components/manage/Blocks/Mailform/EditorWidgets/PlaceholderWidget
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Input, Label } from 'semantic-ui-react';
import WidgetNote from './WidgetNote';

/**
 * Edit Placeholder widget class.
 * @class Edit
 * @extends Component
 */
class Placeholder extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    block: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   */
  constructor(props) {
    super(props);
    this.state = {
      placeholder: props.data.placeholder || '',
    };
  }

  /**
   * Component will receive props
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.data.placeholder) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        placeholder: '',
      });
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Form.Field>
        <Label htmlFor="placeholder">
          <FormattedMessage id="Placeholder" defaultMessage="Plaseholder" />
        </Label>
        <WidgetNote>
          <FormattedMessage
            id="PlaceholderWidgetHelp"
            defaultMessage="Please enter a comment to display in the input item. (Optional)"
          />
        </WidgetNote>
        <Input
          name="placeholder"
          value={this.state.placeholder}
          onChange={(e, target) => {
            this.props.onChangeBlock(this.props.block, {
              ...this.props.data,
              placeholder: target.value,
            });
            // this.state.placeholder = (target.value);
            this.setState({ placeholder: target.value });
          }}
        />
      </Form.Field>
    );
  }
}

export default injectIntl(Placeholder);
