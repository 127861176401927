/**
 * Link View.
 * @module components/theme/View/LinkView
 * based on v13.8.2
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isInternalURL, flattenToAppURL } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { URLUtils } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';

// add by karad

/**
 * View container class.
 * @class View
 * @extends Component
 */
class LinkView extends Component {
  /* @karad begin */
  constructor(props) {
    super(props);
    this.state = { render: false };
  }
  /* @karad end */

  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    content: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      remoteUrl: PropTypes.string,
    }),
    token: PropTypes.string,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    content: null,
    token: null,
  };

  componentDidMount() {
    if (!this.props.token) {
      const { remoteUrl } = this.props.content;
      if (isInternalURL(remoteUrl)) {
        if (remoteUrl.indexOf('@@download/file')) {
          window.location.href = flattenToAppURL(remoteUrl);
        } else {
          this.props.history.replace(flattenToAppURL(remoteUrl));
        }
      } else if (!__SERVER__) {
        window.location.href = flattenToAppURL(remoteUrl);
      }
    }
    /* @karad begin */
    this.setState({ ...this.state, render: true });
    /* @karad end */
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { remoteUrl } = this.props.content;
    // change by karad
    // state.renderがtrueになったときに表示
    return this.state.render ? (
      <Container id="page-document">
        <h1 className="documentFirstHeading">{this.props.content.title}</h1>
        {this.props.content.description && (
          <p className="documentDescription">
            {this.props.content.description}
          </p>
        )}
        {remoteUrl && (
          <p>
            <FormattedMessage
              id="The link address is:"
              defaultMessage="The link address is:"
            />{' '}
            <UniversalLink href={remoteUrl}>
              {flattenToAppURL(remoteUrl)}
            </UniversalLink>
          </p>
        )}
      </Container>
    ) : null;
  }
}

export default LinkView;
