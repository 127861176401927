/**
 * OuStoryZFolderView component.
 * @module components/theme/View/OuStoryZFolderView
 */

import _ from 'lodash';

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  StoryZHeader,
  StoryZList,
  StoryZMenu,
  StoryZThemecopy,
} from './OuStoryZTopFolderView';
import { HelmetSite } from '../../index';
import { storyZFolder as storyZFolderAction } from '../../../actions/storyZFolder/storyZFolder';

/**
 * Component to display the OuStoryZFolderView.
 * @function OuStoryZFolderView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OuStoryZFolderView = (props) => {
  const content = props.content;
  const path = _.get(content, '@id');
  const [bSize, bStart, hasNext, navs, rootPath, items] = useSelector(
    (state) => {
      return [
        state?.storyZFolder?.result?.data?.attributes?.b_size ?? 0,
        state?.storyZFolder?.result?.data?.attributes?.b_start ?? 0,
        state?.storyZFolder?.result?.data?.attributes?.has_next ?? true,
        state?.storyZFolder?.result?.data?.attributes?.navs ?? [],
        state?.storyZFolder?.result?.data?.attributes?.root_path ?? '',
        state?.storyZFolder?.storyZList ?? [],
      ];
    },
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(storyZFolderAction(0, path));
  }, [content]);
  return (
    <div className="OuStoryZFolderView OuStoryZsView">
      <HelmetSite content={content} />
      <section className="mainArea">
        <div className="mainAreaInner">
          <StoryZHeader rootPath={rootPath} />
          <StoryZMenu navs={navs} />
          <StoryZThemecopy theme={content.themes.token} />
          <StoryZList
            bSize={bSize}
            bStart={bStart}
            path={path}
            hasNext={hasNext}
            items={items}
            apiAction={storyZFolderAction}
          />
        </div>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuStoryZFolderView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default injectIntl(OuStoryZFolderView);
