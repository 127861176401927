/**
 * UrgentNotification component.
 * @module components/theme/Site/UrgentNotification/UrgentNotification
 */

import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FileTypeIcon } from '../../../index';
import { urgentNotification } from '../../../../actions/urgentNotification/urgentNotification';
import { getFileType } from '../../../../helpers/GlobalStyle/GlobalStyle';
import { getLocaleFromPathname } from '../../../../helpers/Locale/Locale';
import './UrgentNotification.css';
import config from '@plone/volto/registry';

/**
 * Component to display the UrgentNotification.
 * @function UrgentNotification
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const UrgentNotification = () => {
  const [locale, urgentNotificationInfo, urgentTargets] = useSelector(
    (state) => {
      const locale = state?.content?.data?.language?.token ?? 'ja';
      return [
        locale,
        state?.urgentNotification?.result?.data?.attributes,
        state?.siteMainInfo?.result?.data?.attributes?.dynamic_common
          ?.urgent_targets,
      ];
    },
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (__CLIENT__) {
      const lang = getLocaleFromPathname(
        __CLIENT__ ? window?.location.pathname : null,
        config.settings.defaultLanguage,
      );
      dispatch(urgentNotification(lang));
    }
  }, []);

  const isUrgentNotification = urgentNotificationInfo?.is_urgent_notification;
  const displayType = urgentNotificationInfo?.display_type;
  const title = urgentNotificationInfo?.title;
  const leadSentence = urgentNotificationInfo?.lead_sentence;
  const urgentList = urgentNotificationInfo?.items;
  const link = flattenToAppURL(urgentNotificationInfo?.link ?? '');
  const isExternal = link.startsWith('http');

  const getUrgentTarget = (targetID) => {
    for (const target of urgentTargets) {
      if (target?.id === targetID) {
        return { name: target?.[locale], color: target?.color };
      }
    }
    return null;
  };

  return (
    isUrgentNotification === true && (
      <div className="UrgentNotification">
        {displayType === 'small' ? (
          <div className="urgentNotification small">
            <Icon name="exclamation circle" />
            <div className="title">
              {link ? (
                isExternal ? (
                  <a href={link} target="_blank">
                    {title}
                  </a>
                ) : (
                  <Link to={link}>{title}</Link>
                )
              ) : (
                title
              )}
            </div>
          </div>
        ) : (
          <div className="urgentNotification large">
            <div className="urgentNotificationInner">
              <div className="exclamationArea">
                <div className="exclamationAreaInner">
                  <Icon name="exclamation circle" />
                  <div className="caption">
                    <FormattedMessage
                      id="important_notification"
                      defaultMessage=""
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="infoBox">
                  <div className="infoBoxInner">
                    <div className="title">{title}</div>
                  </div>
                  {leadSentence && (
                    <div className="leadSentence">{leadSentence}</div>
                  )}
                </div>
                <hr />
                {urgentList.length > 0 && (
                  <ul className="urgentList">
                    {urgentList.map((item, index) => {
                      const title = item?.name;
                      const url = item?.url;
                      const targetItem = getUrgentTarget(item?.target);
                      return (
                        <li className="item" key={index}>
                          {targetItem && (
                            <div
                              className="target"
                              style={{ backgroundColor: targetItem?.color }}
                            >
                              {targetItem?.name}
                            </div>
                          )}
                          <div className="itemTitle">
                            {url ? (
                              url.startsWith('http') ? (
                                <a href={url} target="_blank">
                                  {title}
                                </a>
                              ) : (
                                <span className="itemTitleInner">
                                  <Link to={flattenToAppURL(url ?? '')}>
                                    {title}
                                  </Link>
                                  <FileTypeIcon fileType={getFileType(url)} />
                                </span>
                              )
                            ) : (
                              title
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
                {link && (
                  <div className="learnMoreWrapper">
                    {isExternal ? (
                      <a href={link} target="_blank">
                        <button className="learnMore arrow">LEARN MORE</button>
                      </a>
                    ) : (
                      <Link to={link}>
                        <button className="learnMore arrow">LEARN MORE</button>
                      </Link>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default injectIntl(UrgentNotification);
