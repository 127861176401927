/**
 * Edit social link block sidebar.
 * @module components/manage/Blocks/SocialLink/Sidebar
 * @nk24:CMScom created 2021/05/13
 */

import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import Schema from './Schema';

const messages = defineMessages({
  social_link: {
    id: 'SNS',
    defaultMessage: 'SNS',
  },
});

const Sidebar = (props) => {
  const _ = props.intl.formatMessage;
  const type = props.data['@type'];
  const init_schema_data = props.data?.[type] ?? {
    background_image_PC: [],
    background_image_SP: [],
    fb_url: '',
    twitter_url: '',
    instagram_url: '',
    youtube_url: '',
    targets: [],
  };
  return (
    <InlineForm
      schema={Schema}
      title={_(messages.social_link)}
      onChangeField={(id, value) => {
        props.onChangeBlock(props.block, {
          ...props.data,
          [type]: { ...props.data[type], [id]: value },
        });
      }}
      formData={props.data?.[type] ?? init_schema_data}
    />
  );
};

export default injectIntl(Sidebar);
