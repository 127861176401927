/**
 * TargetFilter component.
 * @module components/theme/Element/TargetFilter/TargetFilter
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import './TargetFilter.css';

/**
 * Component to display the TargetFilter
 * @function TargetFilter
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const TargetFilter = ({
  loadList,
  locale,
  path,
  setTarget,
  target,
  term,
  userType,
}) => {
  const forYouHandle = () => {
    setTarget(userType);
    // hasNext: true, bSize: 0, bStart: 0
    loadList(true, 0, 0, userType, term, locale, path);
  };
  const allHandle = () => {
    setTarget('');
    // hasNext: true, bSize: 0, bStart: 0
    loadList(true, 0, 0, '', term, locale, path);
  };

  return (
    <div className="TargetFilter">
      <div className="targetFilter">
        <Button.Group>
          <Button className={target ? 'selected' : ''} onClick={forYouHandle}>
            FOR YOU
          </Button>
          <Button className={!target ? 'selected' : ''} onClick={allHandle}>
            ALL
          </Button>
        </Button.Group>
      </div>
    </div>
  );
};

export default injectIntl(TargetFilter);
