/**
 * Date
 */
function dateToString(dt) {
  return `${dt.getFullYear()}.${dt.getMonth() + 1}.${dt.getDate()}`;
}

export function formatedDate(str) {
  const dt = stringToDate(str);
  return `${dateToString(dt)}`;
}

export function formatedDateDay(str) {
  const dt = stringToDate(str);
  return `${dateToString(dt)} ${getYoubi(dt.getDay())}`;
}

export function formatedMonthDate(str) {
  const dt = stringToDate(str);
  return `${dt.getMonth() + 1}.${dt.getDate()}`;
}

export function formatedTime(str) {
  const dt = stringToDate(str);
  return `${dt.getHours()}:${toDoubleDigits(dt.getMinutes())}`;
}

export function formatedYear(str) {
  const dt = stringToDate(str);
  return dt.getFullYear();
}

function getYoubi(day) {
  return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][day];
}

export function stringToDate(str) {
  return new Date(str);
}

const toDoubleDigits = function (num) {
  num += '';
  if (num.length === 1) {
    num = '0' + num;
  }
  return num;
};

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function englishMonth(m) {
  return months[m - 1];
}
