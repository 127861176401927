/**
 * FacilitiesModal component.
 * @module components/theme/Site/FacilitiesModal/FacilitiesModal
 */

import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dimmer, Icon, TransitionablePortal } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import './FacilitiesModal.css';

/**
 * Component to display the FacilitiesModal.
 * @function FacilitiesModal
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const FacilitiesModal = ({ app, facilities, visibleFacilities }) => {
  return (
    <TransitionablePortal
      open={visibleFacilities}
      transition={{ animation: 'fade up', duration: 300 }}
    >
      <Dimmer
        active={visibleFacilities}
        className="FacilitiesModal"
        onClick={() => app.setVisibleFacilities(false)}
        page
      >
        <Icon className="close" name="close" role="button" />
        <div className="facilitiesBox">
          <ul className="facilityList items">
            {_.map(facilities, (item) => {
              return (
                <li className="item" key={item?.name}>
                  {item?.name && item?.src && item?.url ? (
                    <a
                      href={item?.url}
                      onClick={() => app.setVisibleFacilities(false)}
                      target="_blank"
                    >
                      <img alt={item?.name} src={flattenToAppURL(item?.src)} />
                      <span className="name">{item?.name}</span>
                    </a>
                  ) : (
                    <img
                      alt="Now Printing"
                      className="empty"
                      src={flattenToAppURL(
                        '/assets/images/facilities/nowprinting_dr_wani.svg',
                      )}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </Dimmer>
    </TransitionablePortal>
  );
};

const FacilitiesModalContainer = connect(
  (state, props) => {
    const locale = state?.content?.data?.language?.token ?? 'ja';
    return {
      facilities:
        state?.siteMainInfo?.result?.data?.attributes?.locale?.[locale]
          ?.facilities,
    };
  },
  (dispatch, props) => {
    return {};
  },
)(FacilitiesModal);

export default injectIntl(FacilitiesModalContainer);
