import { CookieSetting } from '~/constants/Setting';
import {
  GET_WELCOME_LIST,
  DISPLAY_WELCOME,
  GET_MORE_WELCOME_LIST,
} from '../../constants/ActionTypes';
import { Cookies } from 'react-cookie';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  result: {},
  visible: new Cookies().get(CookieSetting.WELCOME_VISIBLE.name),
};

/**
 * Welcome Reducer
 * @param {*} state
 * @param {*} action
 */
export default function welcomeList(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_WELCOME_LIST}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_WELCOME_LIST}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        result: action?.result,
      };
    case `${GET_WELCOME_LIST}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
        result: {},
      };
    case `${GET_MORE_WELCOME_LIST}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_MORE_WELCOME_LIST}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        result: action?.result,
      };
    case `${GET_MORE_WELCOME_LIST}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
        result: {},
      };
    case DISPLAY_WELCOME:
      new Cookies().set(
        CookieSetting.WELCOME_VISIBLE.name,
        action.visible,
        CookieSetting.WELCOME_VISIBLE.option,
      );
      return {
        ...state,
        visible: action.visible,
      };
    default:
      return state;
  }
}
