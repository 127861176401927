/**
 * Edit.jsx
 * @nk24 2023/03/20
 * @components/manage/Blocks/OuGrid2/InnerBlocks/LinkList/Edit.jsx
 */

import { map, size } from 'lodash';
import { v4 as uuid } from 'uuid';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { Button, Ref } from 'semantic-ui-react';
import { convertToRaw } from 'draft-js';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Icon, SidebarPortal } from '@plone/volto/components';
import addSVG from '@plone/volto/icons/add.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import dragSVG from '@plone/volto/icons/drag.svg';
import Item from './Item';
import OuLinkListSidebar from './Sidebar';
import './Edit.css';

const getId = () => Math.floor(Math.random() * Math.pow(2, 24)).toString(32);

const listItem = () => {
  return { key: getId(), text: null, path: null, isExternal: false };
};

const replaceItemOfArray = (array, index, value) =>
  Object.assign([...array], { [index]: value });

// export const removeFromArray = (array, index) => {
//   let newArray = array.slice();
//   newArray.splice(index, 1);
//   return newArray;
// };

const reorderArray = (list, origin, target) => {
  const result = Array.from(list);
  const [removed] = result.splice(origin, 1);
  result.splice(target, 0, removed);

  return result;
};

const Edit = ({
  data,
  block,
  selected,
  onChangeBlock,
  onSelectBlock,
  dragEndProps,
  setSelectedSubBlock,
}) => {
  const listLen = size(data.ouLinkList);
  const [selectedItem, setSelectedItem] = useState(0);
  const [droppableId, setDroppableId] = useState(uuid());

  const initList = [
    {
      key: getId(),
      text: '',
      path: '',
      isExternal: false,
    },
  ];
  useEffect(() => {
    if (!data?.ouLinkList) {
      onChangeBlock(block, {
        ...data,
        ouLinkList: initList,
      });
    }
  }, []);

  useEffect(() => {
    const source = dragEndProps?.source;
    const destination = dragEndProps?.destination;
    if (
      droppableId === destination?.droppableId &&
      droppableId === source?.droppableId
    ) {
      const ouLinkList = reorderArray(
        data.ouLinkList,
        source.index,
        destination.index,
      );
      onChangeBlock(block, {
        ...data,
        ouLinkList,
      });
      setSelectedItem(destination.index);
    }
  }, [dragEndProps]);

  const addNewListItem = (e) => {
    e.stopPropagation();
    const ouLinkList = [...data.ouLinkList, listItem()];
    onChangeBlock(block, {
      ...data,
      ouLinkList,
    });
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const ouLinkList = reorderArray(
      data.ouLinkList,
      source.index,
      destination.index,
    );

    onChangeBlock(block, {
      ...data,
      ouLinkList,
    });

    setSelectedItem(destination.index);
  };
  const onChangeItem = (index, editorState) => {
    const ouLinkList = [...data.ouLinkList];
    ouLinkList[index].text = editorState.getCurrentContent().getPlainText();
    onChangeBlock(block, {
      ...data,
      ouLinkList,
    });
  };
  const onSelectItem = (index) => {
    setSelectedItem(index);
  };

  const removeColumn = (e, index) => {
    e.stopPropagation();
    const newListState = data.ouLinkList.filter((item, i) => i !== index);
    onChangeBlock(block, {
      ...data,
      ouLinkList: newListState,
    });
  };

  if (__SERVER__ || !data?.ouLinkList) {
    return <div></div>;
  } else {
    return (
      <div className="OuLinkList">
        {selected && (
          <div className="toolbar">
            <Button.Group>
              <Button
                aria-label={`Add grid element`}
                icon
                basic
                onClick={(e) => addNewListItem(e)}
              >
                <Icon name={addSVG} size="24px" />
              </Button>
            </Button.Group>
          </div>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId={droppableId}
            direction="vertical"
            type="inner"
          >
            {(provided) => (
              <Ref innerRef={provided.innerRef}>
                <ul {...provided.droppableProps} className="links">
                  {data.ouLinkList &&
                    map(data.ouLinkList, (item, index) => (
                      <Draggable
                        draggableId={item.key}
                        index={index}
                        key={item.key}
                      >
                        {(provided) => {
                          item = {
                            ...item,
                            block: item.key,
                          };
                          return (
                            <Ref innerRef={provided.innerRef}>
                              <span
                                className="grid-block-linkListItem"
                                key={item.key}
                                {...provided.draggableProps}
                              >
                                <span
                                  className={cx('renderer-wrapper', {
                                    selected:
                                      selected && selectedItem === index,
                                  })}
                                  role="presentation"
                                >
                                  <Button
                                    {...provided.dragHandleProps}
                                    aria-label={`Drag grid element ${index}`}
                                    basic
                                    icon
                                    // This prevents propagation of ENTER
                                    onKeyDown={(e) => e.stopPropagation()}
                                    onClick={(e) => {
                                      setSelectedItem(index);
                                      // setSelectedSubBlock('list');
                                    }}
                                    className="drag-block-button"
                                  >
                                    <Icon name={dragSVG} size="18px" />
                                  </Button>
                                  {listLen > 1 ? (
                                    <Button
                                      aria-label={`Remove grid element ${index}`}
                                      basic
                                      icon
                                      onClick={(e) => removeColumn(e, index)}
                                      className="remove-block-button"
                                    >
                                      <Icon
                                        name={clearSVG}
                                        className="circled"
                                        size="24px"
                                      />
                                    </Button>
                                  ) : null}

                                  <Item
                                    className="link"
                                    text={item.text}
                                    index={index}
                                    onChangeItem={onChangeItem}
                                    onSelectItem={onSelectItem}
                                  />
                                </span>
                              </span>
                            </Ref>
                          );
                        }}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </ul>
              </Ref>
            )}
          </Droppable>
        </DragDropContext>
        <SidebarPortal selected={selected}>
          <OuLinkListSidebar
            data={data}
            block={block}
            index={selectedItem}
            onChangeBlock={onChangeBlock}
          />
        </SidebarPortal>
      </div>
    );
  }
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Edit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onSelectBlock: PropTypes.func.isRequired,
  onDeleteBlock: PropTypes.func.isRequired,
  onAddBlock: PropTypes.func.isRequired,
  onFocusPreviousBlock: PropTypes.func.isRequired,
  onFocusNextBlock: PropTypes.func.isRequired,
  block: PropTypes.string.isRequired,
};

export default injectIntl(Edit);
