import { PREVIEW_BLOCK } from '~/constants/ActionTypes';

/**
 * Set content blocks are previewable.
 * @function setBlocksPreviewable
 * @param {Bool} Blockがpreview可能かどうか.
 * @returns {Object} Set action.
 */
export function previewBlock(preview) {
  return {
    type: PREVIEW_BLOCK,
    preview,
  };
}
