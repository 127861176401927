/**
 * Edit header <H2> block.
 * @module components/manage/Blocks/Header2/Edit
 */

import React, { Component } from 'react';
import { Map } from 'immutable';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor, DefaultDraftBlockRenderMap, EditorState } from 'draft-js';
import { defineMessages, injectIntl } from 'react-intl';
import config from '@plone/volto/registry';
// import { settings } from '~/config';

const getId = () => Math.floor(Math.random() * Math.pow(2, 24)).toString(32);

const messages = defineMessages({
  placeholder: {
    id: 'Type the header text…',
    defaultMessage: 'Type the header text…',
  },
  /* @nk24 Dummy customizations/components/manage/Edit/Edit で必要 */
  /* customization内のmessagesをyarn i18nが拾わないための処置  */
  error_end_must_be_after_start_date: {
    id: 'End date must be after start date.',
    defaultMessage: 'End date must be after start date.',
  },
  error_expiration_must_be_after_effective_date: {
    id: 'Expiration date must be after effective date.',
    defaultMessage: 'Expiration date must be after effective date.',
  },
});

const blockRenderMap = Map({
  unstyled: {
    element: 'h2',
  },
});

const blockStyle = 'Header2';

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

/**
 * Edit title block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    selected: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    onChangeField: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onAddBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    block: PropTypes.string.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);

    if (!__SERVER__) {
      let editorState;
      if (props.data.header2) {
        const contentState = stateFromHTML(props.data.header2);
        editorState = EditorState.createWithContent(contentState);
      } else {
        editorState = EditorState.createEmpty();
      }
      // this.state = { editorState, focus: true };
      this.state = { editorState };
    }

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Component did mount lifecycle method
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.data.key) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        key: getId(),
      });
    }
    if (this.props.selected) {
      this.node.focus();
    }
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.selected && nextProps.selected) {
      this.node.focus();
      this.setState({
        editorState: EditorState.moveFocusToEnd(this.state.editorState),
      });
    }
  }

  /**
   * Component will receive props
   * @method componentWillUnmount
   * @returns {undefined}
   */
  componentWillUnmount() {
    if (this.props.selected) {
      this.node.focus();
    }
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  // /**
  //  * Component did mount lifecycle method
  //  * @method componentDidMount
  //  * @returns {undefined}
  //  */
  // componentDidMount() {
  //   if (this.node) {
  //     this.node.focus();
  //     this.node._onBlur = () => this.setState({ focus: false });
  //     this.node._onFocus = () => this.setState({ focus: true });
  //   }
  // }

  // /**
  //  * Component will receive props
  //  * @method componentWillReceiveProps
  //  * @param {Object} nextProps Next properties
  //  * @returns {undefined}
  //  */
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (
  //     nextProps.properties.header2 &&
  //     this.props.properties.header2 !== nextProps.properties.header2 &&
  //     !this.state.focus
  //   ) {
  //     const contentState = stateFromHTML(nextProps.properties.header2);
  //     this.setState({
  //       editorState: nextProps.properties.header2
  //         ? EditorState.createWithContent(contentState)
  //         : EditorState.createEmpty(),
  //     });
  //   }

  //   if (!this.props.selected && nextProps.selected) {
  //     this.node.focus();
  //     this.setState({ focus: true });
  //   }
  // }

  /**
   * Change event handler method.
   * @method onChange
   * @param {Object} editorState Editor state.
   * @returns {undefined}
   */
  onChange(editorState) {
    if (
      !isEqual(
        editorState.getCurrentContent().getPlainText(),
        this.state.editorState.getCurrentContent().getPlainText(),
      )
    ) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        header2: editorState.getCurrentContent().getPlainText(),
      });
    }
    this.setState({ editorState });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    const placeholder =
      this.props.data.placeholder ||
      this.props.intl.formatMessage(messages.placeholder);

    return (
      <Editor
        onChange={this.onChange}
        editorState={this.state.editorState}
        blockRenderMap={extendedBlockRenderMap}
        handleReturn={() => {
          if (this.props.data.disableNewBlocks) {
            return 'handled';
          }
          this.props.onSelectBlock(
            this.props.onAddBlock(
              config.settings.defaultBlockType,
              this.props.index + 1,
            ),
          );
          return 'handled';
        }}
        placeholder={placeholder}
        blockStyleFn={() => blockStyle}
        onUpArrow={() => {
          const selectionState = this.state.editorState.getSelection();
          const { editorState } = this.state;
          if (
            editorState.getCurrentContent().getBlockMap().first().getKey() ===
            selectionState.getFocusKey()
          ) {
            this.props.onFocusPreviousBlock(this.props.block, this.node);
          }
        }}
        onDownArrow={() => {
          const selectionState = this.state.editorState.getSelection();
          const { editorState } = this.state;
          if (
            editorState.getCurrentContent().getBlockMap().last().getKey() ===
            selectionState.getFocusKey()
          ) {
            this.props.onFocusNextBlock(this.props.block, this.node);
          }
        }}
        ref={(node) => {
          this.node = node;
        }}
      />
    );
  }
}

export default injectIntl(Edit);
