/**
 * OuCategoryFolderView component.
 * @module components/theme/View/OuCategoryFolderView
 */

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  FileTypeIcon,
  FolderHeaderImage,
  HelmetSite,
  OuBody,
} from '../../index';
import { folderList as folderListAction } from '../../../actions/folderList/folderList';
import { getFileType } from '../../../helpers/GlobalStyle/GlobalStyle';
import './OuCategoryFolderView.css';

/**
 * Component to display the OuCategoryFolderView.
 * @function OuCategoryFolderView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
export const FolderItems = ({ items, hasDate = false }) => {
  return (
    items.length > 0 && (
      <div className="FolderItems">
        <ul className="itemList items">
          {items.map((item, index) => {
            const { date, title, type, url } = item;
            return type === 'File' ? (
              <li className="item file" key={index}>
                <a href={flattenToAppURL(`${url}/@@download/file`)}>
                  {hasDate && <div className="date">{date}</div>}
                  <div className="title">{title}</div>
                </a>
                <FileTypeIcon fileType={getFileType(url)} />
              </li>
            ) : (
              <li className="item arrow" key={index}>
                <Link to={flattenToAppURL(url)}>
                  {hasDate && <div className="date">{date}</div>}
                  <div className="title">{title}</div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};

const OuCategoryFolderView = ({ content }) => {
  const [items] = useSelector((state) => {
    return [state?.folderList?.result?.data?.attributes?.items ?? []];
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(folderListAction(_.get(content, '@id')));
  }, [content]);
  return (
    <div className="OuCategoryFolderView">
      <HelmetSite content={content} />
      <FolderHeaderImage content={content} />
      <section className="mainArea">
        <div className="mainAreaInner">
          <div className="mainAreaInnerInner">
            {!content.hide_list && (
              <div>
                <h2 className="subtitle">Contents</h2>
                <FolderItems items={items} />
              </div>
            )}
            <OuBody content={content} />
          </div>
        </div>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuCategoryFolderView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.required,
    lead_sentence: PropTypes.string,
    hide_list: PropTypes.bool,
  }).isRequired,
};

export default injectIntl(OuCategoryFolderView);
