import { EditorState, Modifier, SelectionState } from 'draft-js';
import { convertToRaw } from 'draft-js';
/**
 * editorStateからEntityを取得する関数
 * @function getEntities
 * @param {editorState} editorState
 * @param {string} entityType
 */
const getEntities = (editorState, entityType = null) => {
  const content = editorState.getCurrentContent();
  const entities = [];
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity = null;
    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          if (!entityType || (entityType && entity.getType() === entityType)) {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity()),
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end });
      },
    );
  });
  return entities;
};

/**
 * editorStateからリンク文字列を取り出す
 * @param {EditorState} editorState
 * @returns {string} url
 */
export const getLink = (editorState) => {
  const entities = getEntities(editorState, 'LINK');
  console.log(entities);
  let url = null;
  if (entities.length === 1) {
    url = entities[0].entity.data.url;
  }
  if (url) {
    return url;
  } else {
    return '';
  }
};

/**
 * Block内の全文字列に対してリンクを適用する
 * @param {*} editorState
 * @param {*} url
 */
export const createLink = (editorState, url) => {
  const currentContent = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const anchorKey = selection.getAnchorKey();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const text = currentContentBlock.getText();
  const focusKey = selection.getFocusKey();
  const focusOffset = selection.getFocusOffset();
  const anchorOffset = selection.getAnchorOffset();
  // console.log(anchorKey, text, focusKey, anchorOffset, focusOffset);
  // Create next state
  console.log(url);
  const nextSelection = new SelectionState({
    anchorKey: anchorKey,
    anchorOffset: 0,
    focusKey: anchorKey,
    focusOffset: text.length,
  });
  const nextContent = Modifier.applyEntity(currentContent, nextSelection); // ここで元のentityは削除される
  const nextContentWithEntity = nextContent.createEntity('LINK', 'MUTABLE', {
    url,
  });
  const nextEntityKey = nextContentWithEntity.getLastCreatedEntityKey();
  const nextContentWithLink = Modifier.applyEntity(
    nextContentWithEntity,
    nextSelection,
    nextEntityKey,
  );
  const nextEditorState = EditorState.push(
    editorState,
    nextContentWithLink,
    'change-block-data',
  );
  // カーソル位置を入力文字列の後ろに戻す
  const revertedCursorSelection = new SelectionState({
    anchorKey: focusKey,
    anchorOffset: focusOffset,
    focusKey: focusKey,
    focusOffset: focusOffset,
  });
  const revertedCursorEditorState = EditorState.forceSelection(
    nextEditorState,
    revertedCursorSelection,
  );
  console.log(convertToRaw(editorState.getCurrentContent()));
  return revertedCursorEditorState;
};
