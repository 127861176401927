/**
 * OuStoryZLinkView component.
 * @module components/theme/View/OuStoryZLinkView
 */

import _ from 'lodash';

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { HelmetSite, RedirectElement } from '../../index';
import { storyZLink as storyZLinkAction } from '../../../actions/storyZLink/storyZLink';
import './OuStoryZLinkView.css';

/**
 * Component to display the OuStoryZLinkView.
 * @function OuStoryZLinkView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OuStoryZLinkView = (props) => {
  const content = props.content;
  const token = props.token;
  //if (token === undefined) {
  //  props.history.push(flattenToAppURL(_.get(content.parent, '@id')));
  //}
  const [research] = useSelector((state) => {
    return [state?.storyZLink?.result?.data?.attributes];
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(storyZLinkAction(_.get(content, '@id')));
  }, []);
  return (
    <div className="OuStoryZLinkView OuStoryZsView">
      <HelmetSite content={content} />
      <section className="mainArea">
        <div className="mainAreaInner">
          <RedirectElement token={token} url={_.get(content.parent, '@id')} />
          <h2>Title: {research?.title}</h2>
          {research?.subtitle && <h3>Subtitle: {research?.subtitle}</h3>}
          <img alt="" className="thumbnail" src={research?.image_url} />
        </div>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuStoryZLinkView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default injectIntl(OuStoryZLinkView);
