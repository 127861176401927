/**
 * View emphasis link block.
 * @module components/manage/Blocks/EmphasisLink/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisLink } from '../../../../components';

/**
 * View header2 block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  const text = data.emphasis_link?.blocks[0]?.text;
  const url = data.emphasis_link?.entityMap[0]?.data?.url;
  return text && url ? (
    <EmphasisLink text={text} url={url} elem={data.emphasis_link} />
  ) : (
    <br />
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  // properties: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
