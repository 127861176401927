/**
 * SiteMenuBar component.
 * @module components/theme/Site/SiteMenuBar/SiteMenuBar
 */

import _ from 'lodash';
import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import NoSSR from 'react-no-ssr';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { MediaQueryConfig } from '../../../../helpers/GlobalStyle/GlobalStyle';
import './SiteMenuBar.css';
import { compose } from 'redux';

/**
 * Component to display the SiteMemuBar.
 * @function SiteMenuBar
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const SiteMenuBar = ({
  app,
  curPath,
  locale,
  logoPC,
  visibleLeftBar,
  visibleSearchBox,
  visibleFacilities,
  visibleLanguage,
  intl,
}) => {
  const isPC = useMediaQuery({
    query: MediaQueryConfig.PC,
  });

  const accessPath = `/${locale}/access/top`;
  const isAccessPath = curPath == accessPath;

  return (
    <nav className={`SiteMenuBar ${visibleSearchBox ? 'activeSearch' : ''}`}>
      <NoSSR>
        {isPC && (
          <div className="logo">
            <Link to={flattenToAppURL(logoPC?.url ?? '')}>
              <img alt={logoPC?.alt} src={flattenToAppURL(logoPC?.src ?? '')} />
            </Link>
          </div>
        )}
      </NoSSR>
      <ul className="menuList items">
        <li
          className="item"
          key="menu"
          onClick={() => app.setVisibleLeftBar(true)}
        >
          <Icon className={visibleLeftBar ? 'selected' : ''} name="bars" />
          <div className={`caption${visibleLeftBar ? ' selected' : ''}`}>
            <FormattedMessage id="menu" defaultMessage="Menu" />
          </div>
        </li>
        <li
          className="item"
          key="search"
          onClick={() => app.setVisibleSearchBox(true)}
        >
          <Icon className={visibleSearchBox ? 'selected' : ''} name="search" />
          <div className={`caption${visibleSearchBox ? ' selected' : ''}`}>
            <FormattedMessage id="search" defaultMessage="Search" />
          </div>
        </li>
        <li className="item" key="access">
          <Link to={flattenToAppURL(accessPath)}>
            <Icon
              className={isAccessPath ? 'selected' : ''}
              name="map marker alternate"
            />
            <div className={`caption${isAccessPath ? ' selected' : ''}`}>
              <FormattedMessage id="access" defaultMessage="Access" />
            </div>
          </Link>
        </li>
        <li
          className="item"
          key="facilities"
          onClick={() => app.setVisibleFacilities(true)}
        >
          <Icon
            className={visibleFacilities ? 'selected' : ''}
            name="building"
          />
          <div className={`caption${visibleFacilities ? ' selected' : ''}`}>
            <FormattedMessage id="facilities" defaultMessage="Facilities" />
          </div>
        </li>
        <NoSSR>
          {isPC && (
            <li
              className="item"
              key="language"
              onClick={() => app.setVisibleLanguage(true)}
            >
              <Icon
                className={visibleLanguage ? 'selected' : ''}
                name="world"
              />
              <div className={`caption${visibleLanguage ? ' selected' : ''}`}>
                Language
              </div>
            </li>
          )}
        </NoSSR>
      </ul>
    </nav>
  );
};

// const SiteMenuBarContainer = connect(
//   (state, props) => {
//     const locale = state?.content?.data?.language?.token ?? 'ja';
//     return {
//       locale: locale,
//       logoPC:
//         state?.siteMainInfo?.result?.data?.attributes?.locale?.[locale]?.header
//           ?.logo_pc,
//     };
//   },
//   (dispatch, props) => {
//     return {};
//   },
// )(SiteMenuBar);

// export default injectIntl(SiteMenuBarContainer);

export default compose(
  injectIntl,
  connect(
    (state, props) => {
      const locale = state?.content?.data?.language?.token ?? 'ja';
      return {
        locale: locale,
        logoPC:
          state?.siteMainInfo?.result?.data?.attributes?.locale?.[locale]
            ?.header?.logo_pc,
      };
    },
    (dispatch, props) => {
      return {};
    },
  ),
)(SiteMenuBar);
