/**
 * Options widget form for SelectBlock for Mailform
 * @module components/manage/Blocks/MailForm/Common/OptionsWidget
 * Created @nk24@CMScom 2021/12/08
 */

import { replace, join, split } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Form, Label, TextArea } from 'semantic-ui-react';
import WidgetNote from './WidgetNote';

const messages = defineMessages({
  placeholder: {
    id: 'Select…',
    defaultMessage: 'Select…',
  },
});

const wordToValue = (options, intl) => {
  replace(options, intl.formatMessage(messages.select), '');
};
const valueToWord = (options, intl) => {
  replace(options, '', intl.formatMessage(messages.select));
};
const textToArray = (txt) => split(txt, '\n');
const arrayToText = (ary) => join(ary, '\n');

/**
 * Edit Options widget class.
 * @class Edit
 * @extends Component
 */
class Options extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    block: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   */
  constructor(props) {
    super(props);
    this.state = {
      options: join(props.data.options, '\n') || '',
    };
  }

  /**
   * Component will receive props
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.data.options) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        options: '',
      });
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Form.Field>
        <Label htmlFor="options">
          <FormattedMessage id="Options" defaultMessage="Options" />
        </Label>
        <WidgetNote>
          <FormattedMessage
            id="OptionsWidgetHelp"
            defaultMessage="Please enter your choice in vertical and enter."
          />
        </WidgetNote>
        <TextArea
          id="" //TODO:htmlForと関連づけのため
          name={this.state.options}
          placeholder={''}
          value={this.state.options}
          onChange={(e, target) => {
            this.props.onChangeBlock(this.props.block, {
              ...this.props.data,
              options: split(target.value, '\n'),
            });
            // this.state.placeholder = (target.value);
            this.setState({ options: target.value });
          }}
        />
      </Form.Field>
    );
  }
}

export default injectIntl(Options);
