import { FULL_REVIEW_LIST } from '../../constants/ActionTypes';

export function fullReviewList(locale = 'ja') {
  return {
    type: FULL_REVIEW_LIST,
    request: {
      op: 'get',
      path: `/${locale}/@full-review-list`,
    },
  };
}
