/**
 * schema.js
 * based on @kitconcept/volto-blocks-grid v7.0.0
 * @nk24 2023/03/06
 */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  image_for_ou_grid_2: {
    id: 'Image container for Ou Grid 2',
    defineMessages: 'Image container for Ou Grid 2',
  },
  show_separator: {
    id: 'Show separator',
    defaultMessage: 'Show separator',
  },
});

export const GridSchema = (props) => {
  const { intl } = props;
  return {
    // title: 'Image for Ou Grid 2',
    title: intl.formatMessage(messages.image_for_ou_grid_2),
    // block: '__grid',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['separator'],
      },
    ],

    properties: {
      separator: {
        title: intl.formatMessage(messages.show_separator),
        type: 'boolean',
        // default: true,
      },
    },
    required: [],
  };
};
