/**
 * Item.jsx
 * @nk24 2022/07/28
 * @components/manage/Blocks/OuLinkList/Item.jsx
 */

import React, { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor, DefaultDraftBlockRenderMap, EditorState } from 'draft-js';
import './Item.css';

const messages = defineMessages({
  placeholder: {
    id: 'Type the title of link destination…',
    defaultMessage: 'Type the title of link destination…',
  },
});

const blockRenderMap = Map({
  unstyled: {
    element: 'li',
  },
});
const blockStyle = 'OuLinkListItem';
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

const initEditorState = (text) => {
  if (!__SERVER__) {
    let editorState;
    if (text) {
      const contentState = stateFromHTML(text);
      editorState = EditorState.createWithContent(contentState);
    } else {
      editorState = EditorState.createEmpty();
    }
    return editorState;
  }
};

const Item = ({ text, index, onChangeItem, onSelectItem, intl }) => {
  const [editorState, setEditorstate] = useState(initEditorState(text));
  const node = useRef(null);

  const onSelect = () => {
    onSelectItem(index);
  };
  const onChange = (nextEditorState) => {
    //console.log(nextEditorState.getCurrentContent().getPlainText());
    //console.log(editorState.getCurrentContent().getPlainText());
    if (
      !isEqual(
        nextEditorState.getCurrentContent().getPlainText(),
        editorState.getCurrentContent().getPlainText(),
      )
    ) {
      onChangeItem(index, nextEditorState);
    }
    setEditorstate(nextEditorState);
  };

  return (
    <div className="Item" onClick={onSelect}>
      <Editor
        onChange={onChange}
        editorState={editorState}
        blockRenderMap={extendedBlockRenderMap}
        handleReturn={() => {
          // TODO: 新規Itemを下に追加
          // if (this.props.data.disableNewBlocks) {
          //   return 'handled';
          // }
          // this.props.onSelectBlock(
          //   this.props.onAddBlock(
          //     config.settings.defaultBlockType,
          //     this.props.index + 1,
          //   ),
          // );
          return 'handled';
        }}
        placeholder={intl.formatMessage(messages.placeholder)}
        // placeholder="Type text..."
        blockStyleFn={() => blockStyle}
        //   onUpArrow={() => {
        //     const selectionState = editorState.getSelection();
        //     if (
        //       editorState.getCurrentContent().getBlockMap().first().getKey() ===
        //       selectionState.getFocusKey()
        //     ) {
        //       onFocusPreviousBlock(block, this.node);
        //     }
        //   }}
        //   onDownArrow={() => {
        //     const selectionState = this.state.editorState.getSelection();
        //     const { editorState } = this.state;
        //     if (
        //       editorState.getCurrentContent().getBlockMap().last().getKey() ===
        //       selectionState.getFocusKey()
        //     ) {
        //       onFocusNextBlock(block, this.node);
        //     }
        //   }}
        ref={node}
      />
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Item.propTypes = {
  onSelectItem: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  index: PropTypes.number.isRequired,
  text: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
  isBlockSelected: PropTypes.bool,
  //   onSelectBlock: PropTypes.func.isRequired,
  //   onDeleteBlock: PropTypes.func.isRequired,
  //   onAddBlock: PropTypes.func.isRequired,
  //   onFocusPreviousBlock: PropTypes.func.isRequired,
  //   onFocusNextBlock: PropTypes.func.isRequired,
  //   block: PropTypes.string.isRequired,
};

// static propTypes = {
//     onSelectCell: PropTypes.func.isRequired,
//     row: PropTypes.number,
//     cell: PropTypes.number,
//     value: PropTypes.object,
//     selected: PropTypes.bool,
//     onChange: PropTypes.func.isRequired,
//     isTableBlockSelected: PropTypes.bool,
//     disableNewBlocks: PropTypes.bool,
//     editable: PropTypes.bool,
//   };

export default injectIntl(Item);
