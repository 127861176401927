/**
 * PageTitle component.
 * @module components/theme/Element/PageTitle/PageTitle
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import './PageTitle.css';

/**
 * Component to display the PageTitle
 * @function PageTitle
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const PageTitle = ({ title }) => {
  return <h1 className="PageTitle">{title}</h1>;
};

export default injectIntl(PageTitle);
