/**
 * フォーム入力値のバリデータ
 */

/* emailアドレス正規表現 */
// const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
const emailRegex = new RegExp(
  /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
);

/**
 * validate関数本体
 * 入力必須, Emailチェックに対応
 *
 * @param {*} value フォーム入力値
 * @param {*} label フォームのラベル
 * @param {*} required 入力必須項目
 * @param {*} validation その他のバリデーション方法
 * @returns {string} エラーメッセージ
 */
export const validateFormValue = (
  value,
  label,
  required,
  validation,
  locale,
) => {
  const msgArray = [];
  if (required && !validation && !value) {
    if (locale === 'ja') {
      msgArray.push(`${label}を入力してください`);
    } else {
      msgArray.push(`Please input ${label}`);
    }
  } else if (
    !required &&
    validation === 'email' &&
    value &&
    !emailRegex.test(value)
  ) {
    if (locale === 'ja') {
      msgArray.push('正しいメールアドレスを入力してください');
    } else {
      msgArray.push('Please input valid email address');
    }
  } else if (required && validation === 'email' && !value) {
    if (locale === 'ja') {
      msgArray.push(`${label}を入力してください`);
    } else {
      msgArray.push(`Please input ${label}`);
    }
  } else if (
    required &&
    validation === 'email' &&
    value &&
    !emailRegex.test(value)
  ) {
    if (locale === 'ja') {
      msgArray.push('正しいメールアドレスを入力してください');
    } else {
      msgArray.push('Please input valid email address');
    }
  }
  return msgArray.length ? msgArray.join(' ') : '';
};

/**
 * Validate duplicated form ID method.
 * 編集時にFormIDの重複を確認
 * @function validateDuplicatedId
 * @returns {boolean}
 */
export const validateDuplicatedId = (
  formIds,
  contentUid,
  blockUid,
  nextFormId,
) => {
  if (!formIds || !formIds[contentUid]) {
    return false;
  } else {
    const formIdValues = Object.values(formIds[contentUid]);
    const duplicatedValue = formIdValues.filter((val, idx, arr) => {
      return arr.indexOf(val) === idx && arr.lastIndexOf(val) !== idx;
    });
    const targetBlockUid = formIds?.[contentUid]?.targetBlockUid;
    if (
      blockUid === targetBlockUid &&
      duplicatedValue.indexOf(nextFormId) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  }
};
