/**
 * Chat風UIのテスト用Store
 */

import { Subject, of } from 'rxjs';
import {
  map,
  take,
  filter,
  tap,
  concatMap,
  delay,
  mergeMap,
} from 'rxjs/operators';
const subject = new Subject();

const initialState = {
  data: [],
  current: {
    count: 0,
    b_start: 0,
    b_size: 0,
    has_next: false,
  },
};

let state = initialState;

const searchStore = {
  init: () => {
    state = { ...initialState, newDataCount: 0 };
    subject.next(state);
  },
  subscribe: (setState) =>
    subject
      .pipe(concatMap((val) => of(val).pipe(delay(500))))
      .subscribe(setState),
  sendMessage: (message) => {
    state = {
      ...state,
      data: [...state.data, message],
    };
    if (message?.bullet_list) {
      state = {
        ...state,
        current: {
          count: message?.count ?? initialState.count,
          b_start: message?.b_start ?? initialState.b_start,
          b_size: message?.b_size ?? initialState.b_size,
          has_next: message?.has_next ?? initialState.has_next,
        },
      };
    }
    subject.next(state);

    if (message?.bullet_list && state.current.has_next) {
      state = {
        ...state,
        data: [
          ...state.data,
          {
            id: Math.floor(Math.random() * Math.pow(2, 24)).toString(32),
            type: 'ChatItemMoreButton',
            from: null,
            content: 'MORE',
            thumbnail: null,
            link: null,
          },
        ],
      };
      subject.next(state);
    }
  },
  clearChat: () => {
    state = initialState;
    subject.next(state);
  },
  initialState,
};

export default searchStore;
