import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import Schema from './Schema';
// import _ from 'lodash';

const messages = defineMessages({
  linkList: {
    id: 'List of links',
    defaultMessage: 'List of links',
  },
});

const Sidebar = (props) => {
  const { data, block, index, onChangeBlock } = props;
  //console.log({ data, block, index, onChangeBlock });
  const _ = props.intl.formatMessage;
  //  const type = _.get('@type', props.data);
  //  console.log({ data: data });
  //const type = 'image';
  return (
    <InlineForm
      schema={Schema}
      title={_(messages.linkList)}
      // props.onChangeBlock(props.block, {
      //   ...props.data,
      //   [type]: { ...content, items },
      // });

      onChangeField={(id, value) => {
        const content = data?.ouLinkList;
        content[index][id] = value;

        onChangeBlock(block, {
          ...data,
          ouLinkList: content,
          //   ...data.ouLinkList.slice(0, index),
          //   ...item,
          //   ...data.ouLinkList.slice(index + 1),
          // ],
          // [id]: value,
        });
      }}
      formData={{
        path: data?.ouLinkList?.[index]?.path || [],
        isExternal: data?.ouLinkList?.[index]?.isExternal || false,
      }}
    />
  );
};

export default injectIntl(Sidebar);
