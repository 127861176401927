import { GET_RESEARCH_LIST } from '../../constants/ActionTypes';

export function researchList(locale) {
  let url = '';
  if (locale === 'en') {
    url = 'https://resou.osaka-u.ac.jp/api/en/@@resou-researches-json';
    // url = 'http://localhost:9080/Plone/en/@@resou-researches-json';
  } else {
    // const url = 'https://resou.osaka-u.ac.jp/api/ja/@@feed-json'; //TODO:
    url = 'https://resou.osaka-u.ac.jp/api/ja/@@resou-researches-json';
    // url = 'http://localhost:9080/Plone/en/@@resou-researches-json';
  }
  return {
    type: GET_RESEARCH_LIST,
    request: {
      op: 'get',
      path: url,
      // path: `${
      //   path ? path : `/${locale}/event`
      // }/@event-list?b_start=${bStart}&target=${target}&term=${term}`,
      // headers: { Origin: 'http://localhost:3000' },
    },
    // bStart: bStart,
  };
}
