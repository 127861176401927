import {
  ADD_MAIL_FORM_FORM_ID,
  POP_MAIL_FORM_FORM_ID,
} from '../../constants/ActionTypes';

const initialState = {
  mailFormFormId: {},
};

/**
 * Site Main Info Reducer
 * @param {*} state
 * @param {*} action
 */
export default function mailFormFormIds(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_MAIL_FORM_FORM_ID:
      const addContentUid = action.formIdData?.uid;
      const addBlockUid = action.formIdData?.block;
      const addFormId = action.formIdData?.formId;
      if (addContentUid && addBlockUid) {
        const mailFormFormId = state.mailFormFormId;
        if (!mailFormFormId[addContentUid]) {
          mailFormFormId[addContentUid] = {};
        }
        mailFormFormId[addContentUid][addBlockUid] = addFormId;
        mailFormFormId[addContentUid].targetBlockUid = addBlockUid;
        return { ...state, mailFormFormId };
      } else {
        return state;
      }
    case POP_MAIL_FORM_FORM_ID:
      const popMailFormFormId = state.mailFormFormId;
      const popContentUid = action.formIdData?.uid;
      const popBlockUid = action.formIdData?.block;
      // const formId = action.formIdData?.formId;
      if (popMailFormFormId?.[popContentUid]?.[popBlockUid]) {
        delete popMailFormFormId[popContentUid][popBlockUid];
      }
      if (popMailFormFormId?.[popContentUid]?.targetBlockUid) {
        delete popMailFormFormId[popContentUid].targetBlockUid;
      }
      if (!popMailFormFormId.popContentUid) {
        delete popMailFormFormId[popContentUid];
      }
      return { ...state, mailFormFormId: popMailFormFormId };
    default:
      return state;
  }
}
