/**
 * "0"だった場合はfalseにする。通常"1" or "0"の使い方
 * @param {*} str
 */
export function stringToBoolean(str, undefinedFlag = false) {
  if (undefinedFlag && str === undefinedFlag) {
    return true;
  }
  if (str === '0') {
    return false;
  }
  return true;
}

/**
 * encodedStrToArray
 * 1. URIエンコードされた文字列をデコード
 * 2. Arrayに変換
 */
// export function encodedStrToArray(str) {
export function encodedStrToArray(str) {
  const argType = typeof str;
  if (argType !== 'string' && argType !== 'undefined') {
    throw new TypeError(`unexpected arguemnt ${argType}`);
  }
  if (!str) {
    return [];
  }
  const decoded = decodeURIComponent(str).split(';');
  return decoded;
}

/**
 * updateNumLimitedArray
 * 1. ary(Array型)の先頭にstrを追加
 * 2. strがaryにすでに存在する場合、先にary中のstrを削除してから先頭に追加する
 * 2. ary.lengthが10以上になったら末尾から削除してary.lengthが10になるよう調整
 */
export function updateNumLimitedArray(ary, str, limit = 10) {
  let newAry = ary.concat();
  const exists = newAry.indexOf(str);
  if (exists !== -1) {
    newAry.splice(exists, 1);
  }
  newAry.unshift(str);
  if (newAry.length > limit) {
    newAry = newAry.slice(0, limit);
  }
  return newAry;
}

/**
 * arrayToEncodedStr
 * 1. aryを文字列に変換
 * 2. URIエンコードして返す
 */
export function arrayToEncodedStr(ary) {
  return encodeURIComponent(ary.join(';'));
}
