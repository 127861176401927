/**
 * EmphasisLink Component
 * @module components/theme/Element/EmphasisLink/EmphasisLink
 */

import PropTypes from 'prop-types';
import React from 'react';
import redraft from 'redraft';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { OSAKA_U_DOMAIN } from '../../../../helpers/GlobalStyle/GlobalStyle';
import { FileTypeIcon } from '@package/components/index';
import { getFileType } from '@package/helpers/GlobalStyle/GlobalStyle';
import './EmphasisLink.css';
import config from '@plone/volto/registry';

/**
 * EmphasisLink component
 * @function EmphasisLink
 */
const EmphasisLink = ({ text, url, elem }) => {
  const fileIconType = getFileType(url);
  return (
    <div className="EmphasisLink">
      <p className="emphasisLink arrow">
        {elem ? (
          <>
            {redraft(
              elem,
              // config.settings.ToHTMLRenderers, //
              // config.settings.ToHTMLOptions,
              config.settings.richtextViewSettings.ToHTMLRenderers,
              config.settings.richtextViewSettings.ToHTMLOptions,
            )}
            {fileIconType ? <FileTypeIcon fileType={fileIconType} /> : null}
          </>
        ) : url.startsWith(OSAKA_U_DOMAIN) ? (
          <Link to={flattenToAppURL(url)}>{text}</Link>
        ) : (
          <a className="external" href={flattenToAppURL(url)}>
            {text}
          </a>
        )}
      </p>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
EmphasisLink.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default injectIntl(EmphasisLink);
