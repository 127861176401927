/**
 * View toppage_item block.
 * @module components/manage/Blocks/Feature/View
 */

import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import NoSSR from 'react-no-ssr';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { MediaQueryConfig } from '~/helpers/GlobalStyle/GlobalStyle';
import { isShowBlockByTargets } from '~/helpers/Targets/Targets';
import { getUserType } from '~/helpers/UserType/UserType';
import { LRFBlockHeader } from '~/components';
import './View.css';

const More = ({ link }) => {
  if (link && link.length && link[0]['@id']) {
    if (
      link[0]['@id'].startsWith('http://') ||
      link[0]['@id'].startsWith('https://')
    ) {
      return (
        <div className="more">
          <a
            href={link[0]['@id']}
            target="_blank"
            rel="noreferrer"
            className="external-link"
          >
            MORE
          </a>
        </div>
      );
    } else {
      return (
        <div className="more">
          <Link to={link[0]['@id']}>MORE</Link>
        </div>
      );
    }
  } else {
    return null;
  }
};

/**
 * View univ info block class.
 * @class View
 * @extends Component
 */
const View = ({ data, lang }) => {
  const content = data.toppage_item;
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  const background_image_PC_path = content?.background_image_PC?.[0]?.['@id'];
  const background_image_SP_path = content?.background_image_SP?.[0]?.['@id'];
  const image_path = content?.image?.[0]?.['@id'];
  const image_title = content?.image?.[0]?.title;
  const negative_color = content?.invert_text_color;
  const targets = content?.targets;
  let backgroundStylePC;
  if (background_image_PC_path) {
    backgroundStylePC = {
      backgroundImage: `url(${flattenToAppURL(
        background_image_PC_path,
      )}/@@images/image)`,
    };
  } else {
    backgroundStylePC = { background: 'none' };
  }
  let backgroundStyleSP;
  if (background_image_SP_path) {
    backgroundStyleSP = {
      backgroundImage: `url(${flattenToAppURL(
        background_image_SP_path,
      )}/@@images/image)`,
    };
  } else {
    backgroundStyleSP = { background: 'none' };
  }
  const [showBlock, setShowBlock] = useState(false);
  useEffect(() => {
    const isShowBlock = isShowBlockByTargets(targets, getUserType());
    if (isShowBlock !== showBlock) {
      setShowBlock(isShowBlock);
    }
  }, [targets, setShowBlock, showBlock]);
  return showBlock ? (
    <NoSSR>
      <section
        className="ToppageItem"
        id={data.key}
        style={isSP ? backgroundStyleSP : backgroundStylePC}
        // style={
        //   data.background_color
        //     ? { backgroundColor: data.background_color }
        //     : null
        // }
      >
        <div className="inner">
          <LRFBlockHeader title={content?.title} negative={negative_color} />
          <div className="content">
            {image_path ? (
              <img
                src={`${flattenToAppURL(image_path)}/@@images/image`}
                alt={image_title}
              />
            ) : null}
            {negative_color ? (
              <div className="text negative">
                <p className={lang === 'ja' ? 'equalSpace' : null}>
                  {content?.text}
                </p>
                <More link={content?.link_path} />
              </div>
            ) : (
              <div className="text">
                <p className={lang === 'ja' ? 'equalSpace' : null}>
                  {content?.text}
                </p>
                <More link={content?.link_path} />
              </div>
            )}
          </div>
        </div>
      </section>
    </NoSSR>
  ) : null;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  // properties: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const ViewContainer = connect(
  (state) => {
    return {
      lang: state?.content?.data?.language?.token ?? 'ja',
    };
  },
  // (dispatch, props) => ({
  //   loadResearchList: (locale) => {
  //     dispatch(researchList(locale));
  //   },
  // }),
)(View);

export default injectIntl(ViewContainer);
