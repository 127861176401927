import React, { useState } from 'react';

import EditImageBlock from '@package/components/manage/Blocks/ImageForOuGrid/Edit';
import EditOuGridHeaderBlock from '@package/components/manage/Blocks/OuGridHeader/Edit';
import EditOuGridDescriptionBlock from '@package/components/manage/Blocks/OuGridDescription/Edit';
import EditLinkListBlock from '@package/components/manage/Blocks/OuLinkList/Edit';
import ViewImageBlock from '@package/components/manage/Blocks/ImageForOuGrid/View';
import ViewOuGridHeaderBlock from '@package/components/manage/Blocks/OuGridHeader/View';
import ViewOuGridDescriptionBlock from '@package/components/manage/Blocks/OuGridDescription/View';
import ViewOuLinkListBlock from '@package/components/manage/Blocks/OuLinkList/View';

export const ListForOuGrid = ({ props }) => {
  // Definition
  const { data } = props;
  const blockSelected = props.selected;
  const isEdit = props.edit; // true or false
  const sideImage = data.url; // string or undefined

  // SelectSubBlock
  const [selectedSubBlock, setSelectedSubBlock] = useState(null);
  const patchdProps = (type) => {
    return {
      ...props,
      selected: blockSelected && selectedSubBlock === type,
      type,
    };
  };

  // Condition
  const isEditOrHasSideImage = isEdit || sideImage;

  // Function
  const clickImageSubBlock = (e) => {
    e.target.focus();
    if (selectedSubBlock !== 'image') {
      setSelectedSubBlock('image');
    }
  };
  const clickHeaderSubBlock = (e) => {
    e.target.focus();
    if (selectedSubBlock !== 'header') {
      setSelectedSubBlock('header');
    }
  };
  const clickDescriptionSubBlock = (e) => {
    e.target.focus();
    if (selectedSubBlock !== 'description') {
      setSelectedSubBlock('description');
    }
  };
  const clickListSubBlock = (e) => {
    e.target.focus();
    if (selectedSubBlock !== 'list') {
      setSelectedSubBlock('list');
    }
  };

  return (
    <div className="ListForOuGrid">
      <div className="gridRow">
        {isEditOrHasSideImage && (
          <div className="gridColumn gridColumnImage gridColumnHalf">
            {isEdit ? (
              <div onClick={clickImageSubBlock}>
                <EditImageBlock {...patchdProps('image')} />
              </div>
            ) : (
              <ViewImageBlock {...props} detached onChangeBlock={() => {}} />
            )}
          </div>
        )}
        <div
          className={`gridColumn gridColumn${
            isEditOrHasSideImage ? 'Half' : 'Full'
          }`}
        >
          {isEdit ? (
            <>
              <div className="title" onClick={clickHeaderSubBlock}>
                <EditOuGridHeaderBlock {...patchdProps('header')} />
              </div>
              <div className="description" onClick={clickDescriptionSubBlock}>
                <EditOuGridDescriptionBlock
                  {...patchdProps('description')}
                  type="description"
                />
              </div>
              <div onClick={clickListSubBlock}>
                <EditLinkListBlock {...patchdProps('list')} />
              </div>
            </>
          ) : (
            <>
              <ViewOuGridHeaderBlock
                {...props}
                detached
                onChangeBlock={() => {}}
              />
              <ViewOuGridDescriptionBlock
                {...props}
                detached
                onChangeBlock={() => {}}
              />
              <ViewOuLinkListBlock
                {...props}
                detached
                onChangeBlock={() => {}}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Edit = (props) => {
  return (
    <div className="ManualListForOuGridEdit">
      <ListForOuGrid props={props} />
    </div>
  );
};

export default Edit;
