/**
 * Edit Automatic List Block.
 * @nk24 2022/07/29
 * @module components/manage/Blocks/OuLinkList/Schmema.jsx
 */

const Schema = {
  title: 'Item',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['path', 'isExternal'],
    },
  ],
  properties: {
    path: {
      title: 'リンク先 (Link to)',
      widget: 'object_browser',
      mode: 'link',
      allowExternals: true,
      selectedItemAttrs: [],
    },
    isExternal: {
      title: '新規タブで開く (Open in a new tab)',
      type: 'boolean',
    },
  },
  required: [],
};

export default Schema;
