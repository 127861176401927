/**
 * TermFilterEvent component.
 * @module components/theme/Element/TermFilter/TermFilterEvent
 */

import moment from 'moment';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { englishMonth } from '../../../../helpers/GlobalStyle/Date.js';
import './TermFilter.css';

/**
 * Component to display the TermFilterEvent
 * @function TermFilterEvent
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const TermFilterEvent = ({
  loadList,
  locale,
  path,
  setDisplayPickup,
  setEventTerm,
  target,
}) => {
  const upcomingEvents = locale === 'ja' ? '今後のイベント' : 'Upcoming Events';

  function termFilterOptions() {
    const dt = moment();
    const year = dt.year();
    const month = dt.month() + 1;
    const date = dt.date();
    const today = `${year}-${month}-${date}`;

    const result = [
      {
        key: today,
        value: today,
        text: upcomingEvents,
      },
    ];

    dt.add('months', 3);
    const y = dt.year();
    const m = dt.month() + 1;
    result.push({
      key: m,
      value: `${y}-${m}-1`,
      text:
        locale === 'ja'
          ? `${y}年${m}月以降`
          : `${englishMonth(m)}. ${y} and later`,
      //description: '月別表示',
    });

    for (let i = 6; i > 0; i--) {
      dt.add('months', -1);
      const endOfMonth = dt.endOf('month');
      const y = endOfMonth.year();
      const m = endOfMonth.month() + 1;
      const ld = endOfMonth.date();
      result.push({
        key: m,
        value: `${y}-${m}-1|${y}-${m}-${ld}`,
        text: locale === 'ja' ? `${y}年${m}月` : `${englishMonth(m)}. ${y}`,
        //description: '月別表示',
      });
    }

    for (let y = year; y > 2017; y--) {
      result.push({
        key: y,
        value: `${y}-1-1|${y}-12-31`,
        text: `${y}`,
        //description: '年別表示',
      });
    }
    return result;
  }

  const changeHandle = (event, data) => {
    const eventTerm = data.value;
    setDisplayPickup(false);
    setEventTerm(eventTerm);
    // hasNext: true, bSize: 0, bStart: 0
    loadList(true, 0, 0, target, eventTerm, locale, path);
  };

  return (
    <div className="TermFilterEvent TermFilter">
      <Dropdown
        closeOnBlur
        name="term"
        options={termFilterOptions()}
        placeholder={upcomingEvents}
        selection
        onChange={changeHandle}
      />
    </div>
  );
};

export default injectIntl(TermFilterEvent);
