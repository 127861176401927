/**
 * Select validation type form widget for the part of Edit component of TextLine, TextArea block for MailForm.
 * @module components/manage/Blocks/Mailform/EditorWidgets/ValidationWIdget
 */

import { get, map } from 'lodash';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Form, Label } from 'semantic-ui-react';
import WidgetNote from './WidgetNote';

const messages = defineMessages({
  nothing: {
    id: 'Nothing',
    defaultMessage: 'Nothing',
  },
  email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
});

const getLabel = (value, intl) => {
  return get(
    {
      email: intl.formatMessage(messages.email),
    },
    value,
    intl.formatMessage(messages.nothing),
  );
};

/**
 * Edit validation widget class.
 * @class Edit
 * @extends Component
 */
const Validation = ({ data, onChangeBlock, block, options, intl }) => {
  const storedValue = () => {
    // valueのみ使用
    if (data.validation?.value === '') {
      return '';
    } else if (data.validation?.value) {
      return data.validation.value;
    } else if (data.validation) {
      return data.validation;
    } else {
      return '';
    }
  };
  const [value, setValue] = useState(storedValue());

  const getValue = (value, intl) => ({
    label: getLabel(value, intl),
    value: value,
  });
  const getOptions = (options, intl) =>
    map(options, (value) => ({
      label: getLabel(value, intl),
      value: value,
    }));
  const setSelectedValue = (inputValue, e) => {
    if (inputValue.value !== value) {
      setValue(inputValue.value);
      onChangeBlock(block, { ...data, validation: inputValue.value });
    }
  };

  return (
    <Form.Field>
      <Label htmlFor="validation">
        <FormattedMessage id="Validation" defaultMessage="Validation" />
      </Label>
      <WidgetNote>
        <FormattedMessage
          id="ValidationWidgetHelp"
          defaultMessage="When selected, the automatic check function of the input item is enabled."
        />
      </WidgetNote>
      <Select
        name="validation"
        options={getOptions(options, intl)}
        defaultValue={getValue(value, intl)}
        onChange={setSelectedValue}
      />
    </Form.Field>
  );
};

Validation.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  options: PropTypes.any,
};

export default injectIntl(Validation);
