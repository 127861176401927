/**
 * Edit social link block
 * @module components/manage/Blocks/SocialLink/Edit
 * @nk24:CMScom 2021/05/13
 */

import React, { Component } from 'react';
import { Map } from 'immutable';
import { defineMessages, injectIntl } from 'react-intl';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor, DefaultDraftBlockRenderMap, EditorState } from 'draft-js';
import { SidebarPortal } from '@plone/volto/components';
import config from '@plone/volto/registry';
import Sidebar from './Sidebar';

const getId = () => Math.floor(Math.random() * Math.pow(2, 24)).toString(32);
const messages = defineMessages({
  titlePlaceholder: {
    id: 'Type the title…',
    defaultMessage: 'Type the title…',
  },
  descriptionPlaceholder: {
    id: 'Type description…',
    defaultMessage: 'Type description…',
  },
});
const titleBlockRenderMap = Map({
  unstyled: {
    element: 'h2',
  },
});
const descriptionBlockRenderMap = Map({
  unstyled: {
    element: 'p',
  },
});
const titleBlockStyle = 'SocialLinks_Title';
const descriptionBlockStyle = 'SocialLinks_Description';
const extendedTitleBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  titleBlockRenderMap,
);
const extendedDescriptionBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  descriptionBlockRenderMap,
);

/**
 * Edit SocialLink block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    selected: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    onChangeField: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onAddBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    block: PropTypes.string.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    if (!__SERVER__) {
      let titleEditorState;
      let descriptionEditorState;
      let focus;
      if (props.data.social_link?.title) {
        const titleContentState = stateFromHTML(props.data.social_link.title);
        titleEditorState = EditorState.createWithContent(titleContentState);
      } else {
        titleEditorState = EditorState.createEmpty();
      }
      if (props.data.social_link?.description) {
        const descriptionContentState = stateFromHTML(
          props.data.social_link.description,
        );
        descriptionEditorState = EditorState.createWithContent(
          descriptionContentState,
        );
      } else {
        descriptionEditorState = EditorState.createEmpty();
      }
      this.state = { titleEditorState, descriptionEditorState, focus };
    }
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
  }

  /**
   * Component did mount lifecycle method
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.data.key) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        key: getId(),
      });
    }
    if (this.props.selected) {
      this.titleNode.focus();
    }
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  /**
   * Component will receive props
   * @method componentWillUnmount
   * @returns {undefined}
   */
  componentWillUnmount() {
    if (this.props.selected) {
      this.titleNode.focus();
    }
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  // /**
  //  * Component will receive props
  //  * @method componentWillReceiveProps
  //  * @param {Object} nextProps Next properties
  //  * @returns {undefined}
  //  */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.selected && nextProps.selected) {
      this.titleNode.focus();
      this.setState({ focus: true });
    }
  }

  /**
   * Change event handler method.
   * @method onChangeTitle
   * @param {Object} editorState Editor state.
   * @returns {undefined}
   */
  onChangeTitle(editorState) {
    if (
      !isEqual(
        editorState.getCurrentContent().getPlainText(),
        this.state.titleEditorState.getCurrentContent().getPlainText(),
      )
    ) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        social_link: {
          ...this.props.data.social_link,
          title: editorState.getCurrentContent().getPlainText(),
        },
      });
    }
    this.setState({ titleEditorState: editorState });
  }
  /**
   * Change event handler method.
   * @method onChangeDescription
   * @param {Object} editorState Editor state.
   * @returns {undefined}
   */
  onChangeDescription(editorState) {
    if (
      !isEqual(
        editorState.getCurrentContent().getPlainText(),
        this.state.descriptionEditorState.getCurrentContent().getPlainText(),
      )
    ) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        social_link: {
          ...this.props.data.social_link,
          description: editorState.getCurrentContent().getPlainText(),
        },
      });
    }
    this.setState({ descriptionEditorState: editorState });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }
    const titlePlaceholder =
      this.props.data.titlePlaceholder ||
      this.props.intl.formatMessage(messages.titlePlaceholder);
    const descriptionPlaceholder =
      this.props.data.descriptionPlaceholder ||
      this.props.intl.formatMessage(messages.descriptionPlaceholder);
    return (
      <div>
        <Editor
          onChange={this.onChangeTitle}
          editorState={this.state.titleEditorState}
          blockRenderMap={extendedTitleBlockRenderMap}
          handleReturn={() => {
            const selectionState = this.state.titleEditorState.getSelection();
            const { titleEditorState } = this.state;
            if (
              titleEditorState
                .getCurrentContent()
                .getBlockMap()
                .last()
                .getKey() === selectionState.getFocusKey()
            ) {
              this.textNode.focus();
            }
            return 'handled';
          }}
          placeholder={titlePlaceholder}
          blockStyleFn={() => titleBlockStyle}
          onUpArrow={() => {
            const selectionState = this.state.titleEditorState.getSelection();
            const { titleEditorState } = this.state;
            if (
              titleEditorState
                .getCurrentContent()
                .getBlockMap()
                .first()
                .getKey() === selectionState.getFocusKey()
            ) {
              this.props.onFocusPreviousBlock(this.props.block, this.titleNode);
            }
          }}
          onDownArrow={() => {
            const selectionState = this.state.titleEditorState.getSelection();
            const { titleEditorState } = this.state;
            if (
              titleEditorState
                .getCurrentContent()
                .getBlockMap()
                .last()
                .getKey() === selectionState.getFocusKey()
            ) {
              this.textNode.focus();
            }
          }}
          ref={(node) => {
            this.titleNode = node;
          }}
        />
        <Editor
          onChange={this.onChangeDescription}
          editorState={this.state.descriptionEditorState}
          blockRenderMap={extendedDescriptionBlockRenderMap}
          handleReturn={() => {
            if (this.props.data.disableNewBlocks) {
              return 'handled';
            }
            this.props.onSelectBlock(
              this.props.onAddBlock(
                config.settings.defaultBlockType,
                this.props.index + 1,
              ),
            );
            return 'handled';
          }}
          placeholder={descriptionPlaceholder}
          blockStyleFn={() => descriptionBlockStyle}
          onUpArrow={() => {
            const selectionState = this.state.descriptionEditorState.getSelection();
            const { descriptionEditorState } = this.state;
            if (
              descriptionEditorState
                .getCurrentContent()
                .getBlockMap()
                .first()
                .getKey() === selectionState.getFocusKey()
            ) {
              this.descriptionNode.focus();
            }
          }}
          onDownArrow={() => {
            const selectionState = this.state.textEditorState.getSelection();
            const { descriptionEditorState } = this.state;
            if (
              descriptionEditorState
                .getCurrentContent()
                .getBlockMap()
                .last()
                .getKey() === selectionState.getFocusKey()
            ) {
              this.props.onFocusNextBlock(this.props.block, this.textNode);
            }
          }}
          ref={(node) => {
            this.descriptionNode = node;
          }}
        />
        <SidebarPortal selected={this.props.selected}>
          <Sidebar {...this.props} />
        </SidebarPortal>
      </div>
    );
  }
}

export default injectIntl(Edit);
