/**
 * OuGridCategoryFolderView component.
 * @module components/theme/View/OuGridCategoryFolderView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { FolderHeaderImage, HelmetSite, OuBody } from '../../index';
import './OuGridCategoryFolderView.css';

/**
 * Component to display the OuGridCategoryFolderView.
 * @function OuGridCategoryFolderView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OuGridCategoryFolderView = ({ content }) => {
  return (
    <div className="OuCategoryFolderView OuGridCategoryFolderView">
      <HelmetSite content={content} />
      <FolderHeaderImage content={content} />
      <section className="mainArea">
        <div className="mainAreaInner">
          <div className="mainAreaInnerInner">
            <OuBody content={content} />
          </div>
        </div>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuGridCategoryFolderView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    lead_sentence: PropTypes.string,
    hide_list: PropTypes.bool,
  }).isRequired,
};

export default injectIntl(OuGridCategoryFolderView);
