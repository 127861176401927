/**
 * Edit text block.
 * @module components/manage/Blocks/Title/Edit
 * based on v13.8.2
 * @nk24:CMScom 2021/09/06
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
// @nk24 begin
// import { map, remove } from 'lodash';
import { map, range, remove } from 'lodash';
// @nk24 end
import { compose } from 'redux';
import { Button, Segment, Table, Form } from 'semantic-ui-react';
import { convertToRaw } from 'draft-js';
import { Portal } from 'react-portal';
import cx from 'classnames';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import Cell from '@plone/volto/components/manage/Blocks/Table/Cell';
import { Field, Icon } from '@plone/volto/components';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

import rowBeforeSVG from '@plone/volto/icons/row-before.svg';
import rowAfterSVG from '@plone/volto/icons/row-after.svg';
import colBeforeSVG from '@plone/volto/icons/column-before.svg';
import colAfterSVG from '@plone/volto/icons/column-after.svg';
import rowDeleteSVG from '@plone/volto/icons/row-delete.svg';
import colDeleteSVG from '@plone/volto/icons/column-delete.svg';
// @nk24 begin
import {
  // deleteMatrixCol,
  getSpanFromMatrix,
  INSERT_TYPE,
  // insertMatrixCol,
  toMatrix,
  REMOVE_TYPE,
  transformationMatrix,
  transformMatrix,
} from '@package/helpers/Matrix/Matrix';
// @nk24 end

const getId = () => Math.floor(Math.random() * Math.pow(2, 24)).toString(32);

const valueToDraft = (value) => ({
  blocks: [
    {
      data: {},
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      key: getId(),
      text: value,
      type: 'unstyled',
    },
  ],
  entityMap: {},
});

const emptyCell = (type) => ({
  key: getId(),
  type: type || 'data',
  value: valueToDraft(''),
});

const emptyRow = (cells) => ({
  key: getId(),
  cells: map(cells, () => emptyCell()),
});

const initialTable = () => ({
  fixed: true,
  compact: false,
  basic: false,
  celled: true,
  inverted: false,
  striped: false,
  unscrollable: false,
  rows: [
    {
      key: getId(),
      cells: [
        {
          key: getId(),
          type: 'header',
          value: valueToDraft(''),
        },
        {
          key: getId(),
          type: 'header',
          value: valueToDraft(''),
        },
      ],
    },
    {
      key: getId(),
      cells: [
        {
          key: getId(),
          type: 'data',
          value: valueToDraft(''),
        },
        {
          key: getId(),
          type: 'data',
          value: valueToDraft(''),
        },
      ],
    },
  ],
});

// @nk24 begin
const initialMatrix = () => ({
  body: [
    [
      { colspan: 1, rowspan: 1 },
      { colspan: 1, rowspan: 1 },
    ],
    [
      { colspan: 1, rowspan: 1 },
      { colspan: 1, rowspan: 1 },
    ],
  ],
  rectangles: [],
});
// const initialMatrix = () => [
//   [
//     { colspan: 1, rowspan: 1, origin: [null, null] },
//     { colspan: 1, rowspan: 1, origin: [null, null] },
//   ],
//   [
//     { colspan: 1, rowspan: 1, origin: [null, null] },
//     { colspan: 1, rowspan: 1, origin: [null, null] },
//   ],
// ];
// @nk24 end

const messages = defineMessages({
  insertRowBefore: {
    id: 'Insert row before',
    defaultMessage: 'Insert row before',
  },
  insertRowAfter: {
    id: 'Insert row after',
    defaultMessage: 'Insert row after',
  },
  deleteRow: {
    id: 'Delete row',
    defaultMessage: 'Delete row',
  },
  insertColBefore: {
    id: 'Insert col before',
    defaultMessage: 'Insert col before',
  },
  insertColAfter: {
    id: 'Insert col after',
    defaultMessage: 'Insert col after',
  },
  deleteCol: {
    id: 'Delete col',
    defaultMessage: 'Delete col',
  },
  fixed: {
    id: 'Fixed width table cells',
    defaultMessage: 'Fixed width columns',
  },
  compact: {
    id: 'Make the table compact',
    defaultMessage: 'Reduce cell padding',
  },
  basic: {
    id: 'Reduce complexity',
    defaultMessage: 'Minimalistic table design',
  },
  celled: {
    id: 'Divide each row into separate cells',
    defaultMessage: 'Add border to inner columns',
  },
  striped: {
    id: 'Stripe alternate rows with color',
    defaultMessage: 'Alternate row background color',
  },
  headerCell: {
    id: 'Header cell',
    defaultMessage: 'Header cell',
  },
  // @nk24 begin
  // fixedHelp: {
  //   id: 'Fixed row help',
  //   defaultMessage:
  //     'このチェックボックスを外すと幅広の表の場合スクロールされるようになります',
  // },
  nonScrollable: {
    id: 'Non scrollable',
    defaultMessage: 'Non scrollable',
  },
  // nonScrollableHelp: {
  //   id: 'Non scrollable help',
  //   defaultMessage:
  //     'テーブルのセル幅を固定する場合、このチェックボックスは無効です',
  // },
  colspan: {
    id: 'Colspan',
    defaultMessage: 'Colspan',
  },
  rowspan: {
    id: 'Rowspan',
    defaultMessage: 'Rowspan',
  },
  // @nk24 end
});

/**
 * Edit text block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    detached: PropTypes.bool,
    index: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    onAddBlock: PropTypes.func.isRequired,
    onInsertBlock: PropTypes.func.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onMutateBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    detached: false,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    // @nk24 begin
    // const matrix =
    //   props.data?.table?.matrix || props.data?.table
    //     ? toMatrix(this.props.data.table.rows)
    //     : initialMatrix();
    // @nk24 end
    this.state = {
      selected: {
        row: 0,
        cell: 0,
        // @nk24 begin
        colspan: 1,
        rowspan: 1,
      },
      unscrollable: false,
      // @nk24 end
      // @nk24@2023/10/10 begin
      cellToolbarPOS: -32,
      // @nk24 end
      isClient: false,
      // matrix: [],
      // selectedSpan: {
      //   colspan: matrix[0][0].colspan,
      //   rowspan: matrix[0][0].rowspan,
      // },
    };
    this.onSelectCell = this.onSelectCell.bind(this);
    this.onInsertRowBefore = this.onInsertRowBefore.bind(this);
    this.onInsertRowAfter = this.onInsertRowAfter.bind(this);
    this.onInsertColBefore = this.onInsertColBefore.bind(this);
    this.onInsertColAfter = this.onInsertColAfter.bind(this);
    this.onDeleteRow = this.onDeleteRow.bind(this);
    this.onDeleteCol = this.onDeleteCol.bind(this);
    this.onChangeCell = this.onChangeCell.bind(this);
    this.toggleCellType = this.toggleCellType.bind(this);
    this.toggleBool = this.toggleBool.bind(this);
    this.toggleFixed = this.toggleFixed.bind(this);
    this.toggleCompact = this.toggleCompact.bind(this);
    this.toggleBasic = this.toggleBasic.bind(this);
    this.toggleCelled = this.toggleCelled.bind(this);
    this.toggleStriped = this.toggleStriped.bind(this);
    // @nk24 begin
    this.toggleUnscrollable = this.toggleUnscrollable.bind(this);
    this.selectableSpan = this.selectableSpan.bind(this);
    this.onChangeSpan = this.onChangeSpan.bind(this);
    // this.selectedSpanV = this.selectedSpanV.bind(this);
    this.setCellToolbarPOS = this.setCellToolbarPOS.bind(this);
    // @nk24 end
    const { convertToRaw } = props.draftJs;
    this.convertToRaw = convertToRaw;
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.data.table) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        // @nk24 begin
        // table: initialTable(),
        table: {
          ...initialTable(),
          matrix: initialMatrix(),
        },
        // @nk24 end
      });
      // @nk24 begin
      this.setState({
        isClient: true,
        // matrix: initialMatrix(),
        // unscrollable: false,
      });
    } else if (!this.props.data.table.matrix) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        table: {
          ...this.props.data.table,
          matrix: toMatrix(this.props.data.table.rows),
        },
      });
      this.setState({
        isClient: true,
        // matrix: toMatrix(this.props.data.table.rows),
      });
    } else {
      // this.setState({ isClient: true });
      this.setState({
        isClient: true,
        // matrix: this.props.data.table.matrix,
        unscrollable: this.props.data?.table?.unscrollable ? true : false,
      });
    }
    // if (
    //   // 生成時にscrollableを未実装のコンテンツ
    //   this.props.data?.table &&
    //   !this.props.data?.table?.unscrollable === undefined
    // ) {
    //   this.setState({ unscrollable: false});
    // }
    // @nk24 end
    this.setState({ isClient: true });
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.data.table) {
      this.props.onChangeBlock(nextProps.block, {
        ...nextProps.data,
        // @nk24 begin
        // table: initialTable(),
        table: { ...initialTable(), matrix: initialMatrix },
        // @nk24 end
      });
    }
  }

  // @nk24 begin
  /**
   * Component did updated props
   * @method componentDidUpdate
   * @param {Object} prevProps Previous properties
   * @returns {undefined}
   */
  componentDidUpdate(prevProps) {
    // 当初 UNSAFE_componentWillReceiveProps内に記述したが、blocksの値が書き換わらずMax recursiveでエラーが多発したため移動した
    if (!this.props.data.table?.matrix) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        table: {
          ...this.props.data.table,
          matrix: toMatrix(this.props.data.table.rows),
        },
      });
    }
  }
  // @nk24 end

  /**
   * Select cell handler
   * @method onSelectCell
   * @param {Number} row Row index.
   * @param {Number} cell Cell index.
   * @returns {undefined}
   */
  onSelectCell(row, cell) {
    // @nk24 begin
    const span = getSpanFromMatrix(this.props.data.table.matrix, cell, row);
    this.setState({
      selected: { row, cell, colspan: span.colspan, rowspan: span.rowspan },
    });
    // @nk24 end
  }

  /**
   * Change cell handler
   * @method onChangeCell
   * @param {Number} row Row index.
   * @param {Number} cell Cell index.
   * @param {Object} editorState Editor state.
   * @returns {undefined}
   */
  onChangeCell(row, cell, editorState) {
    const table = { ...this.props.data.table };
    table.rows[row].cells[cell].value = this.convertToRaw(
      editorState.getCurrentContent(),
    );
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      table,
    });
  }

  /**
   * Toggle cell type
   * @method toggleCellType
   * @returns {undefined}
   */
  toggleCellType() {
    const table = { ...this.props.data.table };
    let type =
      table.rows[this.state.selected.row].cells[this.state.selected.cell].type;
    table.rows[this.state.selected.row].cells[this.state.selected.cell].type =
      type === 'header' ? 'data' : 'header';
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      table,
    });
  }

  /**
   * Insert row before handler
   * @method onInsertRowBefore
   * @returns {undefined}
   */
  onInsertRowBefore(e) {
    e.preventDefault();
    e.stopPropagation();
    const table = this.props.data.table;
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      table: {
        ...table,
        rows: [
          ...table.rows.slice(0, this.state.selected.row),
          emptyRow(table.rows[0].cells),
          ...table.rows.slice(this.state.selected.row),
        ],
        // @nk24 begin
        // matrix: insertMatrixCol(table.matrix, this.state.selected.row, true),
        matrix: transformationMatrix(
          table.matrix,
          INSERT_TYPE.ROW,
          this.state.selected.row,
        ),
        // @nk24 end
      },
    });
    this.setState({
      selected: {
        row: this.state.selected.row + 1,
        cell: this.state.selected.cell,
      },
    });
  }

  /**
   * Insert row after handler
   * @method onInsertRowAfter
   * @returns {undefined}
   */
  onInsertRowAfter(e) {
    e.preventDefault();
    e.stopPropagation();
    const table = this.props.data.table;
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      table: {
        ...table,
        rows: [
          ...table.rows.slice(0, this.state.selected.row + 1),
          emptyRow(table.rows[0].cells),
          ...table.rows.slice(this.state.selected.row + 1),
        ],
        // @nk24 begin
        // matrix: insertMatrixCol(
        //   table.matrix,
        //   this.state.selected.row + 1,
        //   true,
        // ),
        matrix: transformationMatrix(
          table.matrix,
          INSERT_TYPE.ROW,
          this.state.selected.row + 1,
        ),
        // @nk24 end
      },
    });
  }

  /**
   * Insert col before handler
   * @method onInsertColBefore
   * @returns {undefined}
   */
  onInsertColBefore(e) {
    e.preventDefault();
    e.stopPropagation();
    const table = this.props.data.table;
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      table: {
        ...table,
        rows: map(table.rows, (row, index) => ({
          ...row,
          cells: [
            ...row.cells.slice(0, this.state.selected.cell),
            emptyCell(table.rows[index].cells[this.state.selected.cell].type),
            ...row.cells.slice(this.state.selected.cell),
          ],
        })),
        // @nk24 begin
        // matrix: insertMatrixCol(table.matrix, this.state.selected.cell),
        matrix: transformationMatrix(
          table.matrix,
          INSERT_TYPE.COLUMN,
          this.state.selected.cell,
        ),
        // @nk24 end
      },
    });
    this.setState({
      selected: {
        row: this.state.selected.row,
        cell: this.state.selected.cell + 1,
      },
    });
  }

  /**
   * Insert col after handler
   * @method onInsertColAfter
   * @returns {undefined}
   */
  onInsertColAfter(e) {
    e.preventDefault();
    e.stopPropagation();
    const table = this.props.data.table;
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      table: {
        ...table,
        rows: map(table.rows, (row, index) => ({
          ...row,
          cells: [
            ...row.cells.slice(0, this.state.selected.cell + 1),
            emptyCell(table.rows[index].cells[this.state.selected.cell].type),
            ...row.cells.slice(this.state.selected.cell + 1),
          ],
        })),
        // @nk24 begin
        // matrix: insertMatrixCol(table.matrix, this.state.selected.cell + 1),
        matrix: transformationMatrix(
          table.matrix,
          INSERT_TYPE.COLUMN,
          this.state.selected.cell + 1,
        ),
        // @nk24 end
      },
    });
  }

  /**
   * Delete col handler
   * @method onDeleteCol
   * @returns {undefined}
   */
  onDeleteCol(e) {
    e.preventDefault();
    e.stopPropagation();
    const table = this.props.data.table;

    if (this.state.selected.cell === table.rows[0].cells.length - 1) {
      this.setState({
        selected: {
          row: this.state.selected.row,
          cell: this.state.selected.cell - 1,
        },
      });
    }

    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      table: {
        ...table,
        rows: map(table.rows, (row) => ({
          ...row,
          cells: remove(
            row.cells,
            (cell, index) => index !== this.state.selected.cell,
          ),
        })),
        // @nk24 begin
        // matrix: deleteMatrixCol(table.matrix, this.state.selected.cell),
        matrix: transformationMatrix(
          table.matrix,
          REMOVE_TYPE.COLUMN,
          this.state.selected.cell,
        ),
        // @nk24 end
      },
    });
  }

  /**
   * Delete row handler
   * @method onDeleteRow
   * @returns {undefined}
   */
  onDeleteRow(e) {
    e.preventDefault();
    e.stopPropagation();
    const table = this.props.data.table;

    if (this.state.selected.row === table.rows.length - 1) {
      this.setState({
        selected: {
          row: this.state.selected.row - 1,
          cell: this.state.selected.cell,
        },
      });
    }

    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      table: {
        ...table,
        rows: remove(
          table.rows,
          (row, index) => index !== this.state.selected.row,
        ),
        // @nk24 begin
        // matrix: deleteMatrixCol(table.matrix, this.state.selected.cell, true),
        matrix: transformationMatrix(
          table.matrix,
          REMOVE_TYPE.ROW,
          this.state.selected.cell,
        ),
        // @nk24 end
      },
    });
  }

  /**
   * Toggle bool
   * @method toggleBool
   * @param {string} value Value to toggle.
   * @returns {undefined}
   */
  toggleBool(value) {
    const table = this.props.data.table;
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      table: {
        ...table,
        [value]: !table[value],
      },
    });
  }

  /**
   * Toggle fixed
   * @method toggleFixed
   * @returns {undefined}
   */
  toggleFixed() {
    this.toggleBool('fixed');
  }

  /**
   * Toggle compact
   * @method toggleCompact
   * @returns {undefined}
   */
  toggleCompact() {
    this.toggleBool('compact');
  }

  /**
   * Toggle basic
   * @method toggleBasic
   * @returns {undefined}
   */
  toggleBasic() {
    this.toggleBool('basic');
  }

  /**
   * Toggle celled
   * @method toggleCelled
   * @returns {undefined}
   */
  toggleCelled() {
    this.toggleBool('celled');
  }

  /**
   * Toggle striped
   * @method toggleStriped
   * @returns {undefined}
   */
  toggleStriped() {
    this.toggleBool('striped');
  }

  /* @nk24 begin */
  /**
   * Toggle scrollable
   * @method toggleScrollable
   * @returns {undefined}
   */
  toggleUnscrollable() {
    this.toggleBool('unscrollable');
    this.setState({ unscrollable: !this.state.unscrollable });
  }
  /* @nk24 end */

  /**
   * Get span form value
   * @param {*} colIdx
   * @param {*} rowIdx
   * @returns
   */
  // selectedSpanV(colIdx, rowIdx) {
  //   const span = this.props.table?.matrix
  //     ? this.props.table.matrix[rowIdx][colIdx]
  //     : { colspan: 1, rowspan: 1 };
  //   console.log({
  //     colspan: [span.colspan, span.colspan],
  //     rowspan: [span.rowspan, span.rowspan],
  //   });
  //   return {
  //     colspan: [span.colspan, span.colspan],
  //     rowspan: [span.rowspan, span.rowspan],
  //   };
  // }

  // @nk24 start
  /**
   * Update colspan/rowspan value on selected cell
   * @param {string} fieldName - colspan or rowspan
   * @param {string} value - colspan/rowspanの値
   * @returns
   */
  onChangeSpan(fieldName, value) {
    // console.log({ fieldName, value });
    const matrix = this.props.data.table.matrix;
    // console.log({ matrix });
    let spanValue = {
      colspan: this.state.selected.colspan,
      rowspan: this.state.selected.rowspan,
    };
    spanValue[fieldName] = parseInt(value);
    // console.log({
    //   cell: this.state.selected.cell,
    //   row: this.state.selected.row,
    //   matrix,
    //   spanValue,
    // });
    const newMatrix = transformMatrix(
      matrix,
      this.state.selected.cell,
      this.state.selected.row,
      spanValue.rowspan,
      spanValue.colspan,
    );
    // console.log({ newMatrix });
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      table: {
        ...this.props.data.table,
        matrix: newMatrix,
      },
    });
    this.setState({
      selected: { ...this.state.selected, ...spanValue },
    });
  }
  /**
   * Get choices on colSpan/rowSpan widgets
   * @returns
   */
  selectableSpan(colIdx, rowIdx) {
    const rows = this.props.data?.table?.rows;
    const cells = rows?.[0]?.cells;
    const maxColLength = cells ? cells.length : 0;
    const maxRowLength = rows ? rows.length : 0;
    const maxColSpan = maxColLength - colIdx + 1;
    const maxRowSpan = maxRowLength - rowIdx + 1;
    return {
      col: map(range(1, maxColSpan), (col) => [col, col]),
      row: map(range(1, maxRowSpan), (row) => [row, row]),
    };
  }

  // selectedSpan = { colspan: 1, rowspan: 1 };
  // selectedSpan = this.state
  //   ? getSpanFromMatrix(
  //       this.state.matrix,
  //       this.state.selected.cell,
  //       this.state.selected.row,
  //     )
  //   : { colspan: 1, rowspan: 1 };

  /**
   * Set position of cell toolbar
   * @param {int} pos
   */
  setCellToolbarPOS(pos) {
    this.setState({ cellToolbarPOS: pos });
  }
  // @nk24 end

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    // if (!this.props.data.table?.matrix) {
    // if (this.props.data !== nextProps.data) {
    //   this.props.onChangeBlock(this.props.block, {
    //     ...this.props.data,
    //     table: {
    //       ...this.props.data.table,
    //       matrix: toMatrix(this.props.data.table.rows),
    //       // matrix: {},
    //     },
    //   });
    // }
    if (!this.state.isClient) {
      return <div />;
    }

    return (
      <div
        className={cx('block table', {
          selected: this.props.selected,
        })}
      >
        {this.props.selected && (
          <div className="toolbar" style={{ top: this.state.cellToolbarPOS }}>
            <Button.Group>
              <Button
                icon
                basic
                onClick={this.onInsertRowBefore}
                title={this.props.intl.formatMessage(messages.insertRowBefore)}
                aria-label={this.props.intl.formatMessage(
                  messages.insertRowBefore,
                )}
              >
                <Icon name={rowBeforeSVG} size="24px" />
              </Button>
            </Button.Group>
            <Button.Group>
              <Button
                icon
                basic
                onClick={this.onInsertRowAfter}
                title={this.props.intl.formatMessage(messages.insertRowAfter)}
                aria-label={this.props.intl.formatMessage(
                  messages.insertRowAfter,
                )}
              >
                <Icon name={rowAfterSVG} size="24px" />
              </Button>
            </Button.Group>
            <Button.Group>
              <Button
                icon
                basic
                onClick={this.onDeleteRow}
                disabled={
                  this.props.data.table &&
                  this.props.data.table.rows.length === 1
                }
                title={this.props.intl.formatMessage(messages.deleteRow)}
                aria-label={this.props.intl.formatMessage(messages.deleteRow)}
              >
                <Icon name={rowDeleteSVG} size="24px" />
              </Button>
            </Button.Group>
            <Button.Group>
              <Button
                icon
                basic
                onClick={this.onInsertColBefore}
                title={this.props.intl.formatMessage(messages.insertColBefore)}
                aria-label={this.props.intl.formatMessage(
                  messages.insertColBefore,
                )}
              >
                <Icon name={colBeforeSVG} size="24px" />
              </Button>
            </Button.Group>
            <Button.Group>
              <Button
                icon
                basic
                onClick={this.onInsertColAfter}
                title={this.props.intl.formatMessage(messages.insertColAfter)}
                aria-label={this.props.intl.formatMessage(
                  messages.insertColAfter,
                )}
              >
                <Icon name={colAfterSVG} size="24px" />
              </Button>
            </Button.Group>
            <Button.Group>
              <Button
                icon
                basic
                onClick={this.onDeleteCol}
                disabled={
                  this.props.data.table &&
                  this.props.data.table.rows[0].cells.length === 1
                }
                title={this.props.intl.formatMessage(messages.deleteCol)}
                aria-label={this.props.intl.formatMessage(messages.deleteCol)}
              >
                <Icon name={colDeleteSVG} size="24px" />
              </Button>
            </Button.Group>
          </div>
        )}
        {this.props.data.table && (
          // {/* @nk24 begin */}
          // <div className={this.state.scrollable ? 'scrollable' : null}>
          <div className={!this.state.unscrollable ? 'scrollable' : null}>
            {/* @nk24 end */}
            <Table
              fixed={this.props.data.table.fixed}
              compact={this.props.data.table.compact}
              basic={this.props.data.table.basic ? 'very' : false}
              celled={this.props.data.table.celled}
              inverted={this.props.data.table.inverted}
              striped={this.props.data.table.striped}
            >
              <Table.Body>
                {map(this.props.data.table.rows, (row, rowIndex) => (
                  <Table.Row key={row.key}>
                    {/* @nk24 begin */}
                    {map(row.cells, (cell, cellIndex) => {
                      const { colspan, rowspan } = getSpanFromMatrix(
                        this.props.data.table.matrix,
                        cellIndex,
                        rowIndex,
                      );
                      if (colspan > 0 && rowspan > 0) {
                        return (
                          <Table.Cell
                            key={cell.key}
                            as={cell.type === 'header' ? 'th' : 'td'}
                            className={
                              rowIndex === this.state.selected.row &&
                              cellIndex === this.state.selected.cell &&
                              this.props.selected
                                ? 'selected'
                                : ''
                            }
                            colSpan={colspan > 1 ? colspan : null}
                            rowSpan={rowspan > 1 ? rowspan : null}
                          >
                            {/* @nk24 end */}
                            {/* the block attr on <Cell> added@nk24@200230831 */}
                            <Cell
                              editable={this.props.editable}
                              value={cell.value}
                              row={rowIndex}
                              cell={cellIndex}
                              onSelectCell={this.onSelectCell}
                              selected={
                                rowIndex === this.state.selected.row &&
                                cellIndex === this.state.selected.cell
                              }
                              isTableBlockSelected={this.props.selected}
                              onAddBlock={this.props.onAddBlock}
                              onSelectBlock={this.props.onSelectBlock}
                              onChange={this.onChangeCell}
                              index={this.props.index}
                              disableNewBlocks={
                                this.props.data?.disableNewBlocks
                              }
                              block={this.props.block}
                              setCellToolbarPOS={this.setCellToolbarPOS}
                            />
                            {/* @nk24 begin */}
                          </Table.Cell>
                        );
                      } else return null;
                      // @nk24 end
                    })}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            {/* @nk24 begin */}
          </div>
          // {/* @nk24 end */}

          // {/* @nk24 begin debug */}
          // {/* <Table>
          //   <Table.Body>
          //     {
          //       (console.log(this.props.data.table),
          //       map(this.props.data.table.rows, (row, rowIndex) => {
          //         return (
          //           <Table.Row key={rowIndex}>
          //             {map(row.cells, (cell, cellIndex) => {
          //               const { colspan, rowspan } = getSpanFromMatrix(
          //                 this.props.data.table.matrix,
          //                 cellIndex,
          //                 rowIndex,
          //               );
          //               return (
          //                 <Table.Cell
          //                   key={cell.key}
          //                   colspan={
          //                     this.props.data.table.body[rowIdx][cellIdx]
          //                       .colspan
          //                   }
          //                   rowspan={
          //                     this.props.data.table.body[rowIdx][cellIdx]
          //                       .rowspan
          //                   }
          //                 >
          //                   <div>
          //                     {this.props.data.table.body}
          //                     <br />
          //                     {(rowIndex, cellIndex)}
          //                     {
          //                       this.props.data.table.body[rowIdx][cellIdx]
          //                         .rowspan
          //                     }
          //                     {
          //                       this.props.data.table.body[rowIdx][cellIdx]
          //                         .colspan
          //                     }
          //                     <br />
          //                     {cell.origin[0]}:{cell.origin[1]}
          //                   </div>
          //                 </Table.Cell>
          //               );
          //             })}
          //           </Table.Row>
          //         );
          //       }))
          //     }
          //   </Table.Body>
          // </Table> */}
          // </>
        )}
        {this.props.selected && this.state.isClient && (
          <Portal node={document.getElementById('sidebar-properties')}>
            <Form
              method="post"
              onSubmit={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <Segment secondary attached>
                <FormattedMessage id="Table" defaultMessage="Table" />
              </Segment>
              <Segment attached>
                <Field
                  id="fixed"
                  title={this.props.intl.formatMessage(messages.fixed)}
                  // @nk24 begin
                  // description={this.props.intl.formatMessage(
                  //   messages.fixedHelp,
                  // )}
                  description="このチェックボックスを外すと幅広の表の場合スクロールされるようになります"
                  // @nk24 end
                  type="boolean"
                  value={this.props.data.table && this.props.data.table.fixed}
                  onChange={() => this.toggleFixed()}
                />
                <Field
                  id="celled"
                  title={this.props.intl.formatMessage(messages.celled)}
                  type="boolean"
                  value={this.props.data.table && this.props.data.table.celled}
                  onChange={this.toggleCelled}
                />
                <Field
                  id="striped"
                  title={this.props.intl.formatMessage(messages.striped)}
                  type="boolean"
                  value={this.props.data.table && this.props.data.table.striped}
                  onChange={this.toggleStriped}
                />
                <Field
                  id="compact"
                  title={this.props.intl.formatMessage(messages.compact)}
                  type="boolean"
                  value={this.props.data.table && this.props.data.table.compact}
                  onChange={() => this.toggleCompact()}
                />
                <Field
                  id="basic"
                  title={this.props.intl.formatMessage(messages.basic)}
                  type="boolean"
                  value={this.props.data.table && this.props.data.table.basic}
                  onChange={this.toggleBasic}
                />
                {/* @nk24 begin */}
                <Field
                  id="unScrollable"
                  title={this.props.intl.formatMessage(messages.nonScrollable)}
                  // description={this.props.intl.formatMessage(
                  //   messages.nonScrollableHelp,
                  // )}
                  description="テーブルのセル幅を固定する場合、このチェックボックスは無効です"
                  type="boolean"
                  value={this.props?.data?.table?.unscrollable}
                  onChange={this.toggleUnscrollable}
                />
                {/* @nk24 end */}
              </Segment>
              <Segment secondary attached>
                <FormattedMessage id="Cell" defaultMessage="Cell" />
              </Segment>
              <Segment attached>
                <Field
                  id="celltype"
                  title={this.props.intl.formatMessage(messages.headerCell)}
                  type="boolean"
                  value={
                    this.props.data.table &&
                    this.props.data.table.rows[this.state.selected.row].cells[
                      this.state.selected.cell
                    ].type === 'header'
                  }
                  onChange={this.toggleCellType}
                  minimum="0"
                />
                {/* @nk24 begin */}
                <Field
                  id="colspan"
                  title={this.props.intl.formatMessage(messages.colspan)}
                  type="number"
                  value={this.state.selected.colspan}
                  // value={
                  //   getSpanFromMatrix(
                  //     this.props.data.table.matrix,
                  //     this.state.selected.cell,
                  //     this.state.selected.row,
                  //   ).colspan
                  // }
                  onChange={this.onChangeSpan}
                  minimum="1"
                />
                <Field
                  id="rowspan"
                  title={this.props.intl.formatMessage(messages.rowspan)}
                  type="number"
                  value={this.state.selected.rowspan}
                  // value={
                  //   getSpanFromMatrix(
                  //     this.props.data.table.matrix,
                  //     this.state.selected.cell,
                  //     this.state.selected.row,
                  //   ).rowspan
                  // }
                  onChange={this.onChangeSpan}
                  minimum="1"
                />
                {/* @nk24 end */}
              </Segment>
            </Form>
          </Portal>
        )}
      </div>
    );
  }
}

export default compose(injectLazyLibs(['draftJs']), injectIntl)(Edit);
