import { GET_SITE_MAIN_INFO } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  items: [],
  loaded: false,
  loading: false,
  result: {},
  common: {},
  current: {},
};

/**
 * Site Main Info Reducer
 * @param {*} state
 * @param {*} action
 */
export default function siteMainInfo(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case `${GET_SITE_MAIN_INFO}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_SITE_MAIN_INFO}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        result: action?.result,
      };
    case `${GET_SITE_MAIN_INFO}_FAIL`:
      return {
        ...state,
        error: action.error,
        items: [],
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
