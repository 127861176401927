import React from 'react';

const View = ({ data }) => {
  return data && data.length && data[0].text ? (
    <ul className="links">
      {data
        .filter((item) => item.text)
        .map((item) => (
          <li key={item.key}>
            <a
              href={item.path?.[0]?.['@id']}
              title={item.path?.[0]?.title}
              target={item.isExternal ? '_blank' : null}
            >
              {item.text}
            </a>
          </li>
        ))}
      {/* <li>
        <a href="/ja">秋田研究室のホームページ</a>
      </li> */}
    </ul>
  ) : null;
};
export default View;
