import {
  GET_WELCOME_LIST,
  DISPLAY_WELCOME,
  GET_MORE_WELCOME_LIST,
} from '../../constants/ActionTypes';

export function getWelcomeList(locale) {
  return {
    type: GET_WELCOME_LIST,
    request: {
      op: 'get',
      path: `/${locale}/@welcome`,
    },
  };
}

export function getMoreWelcomeList(locale, target) {
  return {
    type: GET_MORE_WELCOME_LIST,
    request: {
      op: 'get',
      path: `/${locale}/@welcome?target=${target}`,
    },
  };
}

/**
 * Welcome画面の表示非表示
 * @param {*} visible
 * @returns
 */
export function displayWelcome(visible) {
  return {
    type: DISPLAY_WELCOME,
    visible: visible,
  };
}
