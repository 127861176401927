import React from 'react';
import ChatItemElement from './ChatItemElement';
import { ChatItemType } from '../../../../constants/Types';
import './ChatItemListElement.css';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';

/**
 * Chat Item List Element
 * @param {*} param0
 */
const ChatItemListElement = ({ item }) => {
  return (
    <>
      {item?.bullet_list?.length ? (
        <ChatItemElement item={item} additionalClass={''}>
          <div className="ChatItemListElement">
            <div className="content">
              {item?.content ? <p>{item?.content}</p> : null}
              <ul className="navigation-list">
                {_.map(item?.bullet_list, (item) => {
                  return (
                    <li className="navigation-list-item" key={item?.link}>
                      <div className="body">
                        {item?.flag ? (
                          <div className="flag">
                            <Icon className="" name="flag" />
                          </div>
                        ) : null}
                        {item?.thumbnail?.src ? (
                          <div className="thumbnail">
                            <img
                              src={item?.thumbnail?.src ?? ''}
                              alt={item?.thumbnail?.alt ?? ''}
                            />
                          </div>
                        ) : null}
                        <div className="title">
                          <a href={item.link}>{item.title}</a>
                        </div>
                      </div>
                      <div className="list-link">
                        <a href={item.link}>
                          <Icon
                            className="list-link-arrow"
                            name="chevron right"
                          />
                        </a>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </ChatItemElement>
      ) : null}
    </>
  );
};

ChatItemListElement.propTypes = ChatItemType;

export default ChatItemListElement;
