/**
 * Edit toppage_item block.
 * @module components/manage/Blocks/Feature/Edit
 * @nk24:CMScom created 2021/03/18
 */

import React, { Component } from 'react';
import { Map } from 'immutable';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor, DefaultDraftBlockRenderMap, EditorState } from 'draft-js';
import { defineMessages, injectIntl } from 'react-intl';
import { SidebarPortal } from '@plone/volto/components';
import config from '@plone/volto/registry';
// import { settings } from '~/config';
import Sidebar from './Sidebar';

const getId = () => Math.floor(Math.random() * Math.pow(2, 24)).toString(32);

const messages = defineMessages({
  titlePlaceholder: {
    id: 'Type the title…',
    defaultMessage: 'Type the title…',
  },
  textPlaceholder: {
    id: 'Type text…',
    defaultMessage: 'Type text…',
  },
});

const blockTitleRenderMap = Map({
  unstyled: {
    element: 'h2',
  },
});
const blockTextRenderMap = Map({
  unstyled: {
    element: 'p',
  },
});

const titleBlockStyle = 'Feature_Title';
const textBlockStyle = 'Feature_Text';

const extendedTitleBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  blockTitleRenderMap,
);
const extendedTextBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  blockTextRenderMap,
);

/**
 * Edit toppage_item block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    selected: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    onChangeField: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onAddBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    block: PropTypes.string.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);

    if (!__SERVER__) {
      let titleEditorState;
      let textEditorState;
      let focus;
      if (props.data.toppage_item?.title) {
        const titleContentState = stateFromHTML(props.data.toppage_item.title);
        titleEditorState = EditorState.createWithContent(titleContentState);
      } else {
        titleEditorState = EditorState.createEmpty();
      }
      if (props.data.toppage_item?.text) {
        const textContentState = stateFromHTML(props.data.toppage_item.text);
        textEditorState = EditorState.createWithContent(textContentState);
      } else {
        textEditorState = EditorState.createEmpty();
      }
      this.state = { titleEditorState, textEditorState, focus };
    }

    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
  }

  /**
   * Component did mount lifecycle method
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.data.key) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        key: getId(),
      });
    }
    if (this.props.selected) {
      this.titleNode.focus();
    }
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  /**
   * Component will receive props
   * @method componentWillUnmount
   * @returns {undefined}
   */
  componentWillUnmount() {
    if (this.props.selected) {
      this.titleNode.focus();
    }
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  // /**
  //  * Component will receive props
  //  * @method componentWillReceiveProps
  //  * @param {Object} nextProps Next properties
  //  * @returns {undefined}
  //  */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.selected && nextProps.selected) {
      this.titleNode.focus();
      this.setState({ focus: true });
    }
  }

  /**
   * Change event handler method.
   * @method onChange
   * @param {Object} editorState Editor state.
   * @returns {undefined}
   */
  onChangeTitle(editorState) {
    if (
      !isEqual(
        editorState.getCurrentContent().getPlainText(),
        this.state.titleEditorState.getCurrentContent().getPlainText(),
      )
    ) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        toppage_item: {
          ...this.props.data.toppage_item,
          title: editorState.getCurrentContent().getPlainText(),
        },
      });
    }
    this.setState({ titleEditorState: editorState });
  }
  onChangeText(editorState) {
    if (
      !isEqual(
        editorState.getCurrentContent().getPlainText(),
        this.state.textEditorState.getCurrentContent().getPlainText(),
      )
    ) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        toppage_item: {
          ...this.props.data.toppage_item,
          text: editorState.getCurrentContent().getPlainText(),
        },
      });
    }
    this.setState({ textEditorState: editorState });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    const titlePlaceholder =
      this.props.data.titlePlaceholder ||
      this.props.intl.formatMessage(messages.titlePlaceholder);
    const textPlaceholder =
      this.props.data.textPlaceholder ||
      this.props.intl.formatMessage(messages.textPlaceholder);

    return (
      <div>
        <Editor
          onChange={this.onChangeTitle}
          editorState={this.state.titleEditorState}
          blockRenderMap={extendedTitleBlockRenderMap}
          handleReturn={() => {
            const selectionState = this.state.titleEditorState.getSelection();
            const { titleEditorState } = this.state;
            if (
              titleEditorState
                .getCurrentContent()
                .getBlockMap()
                .last()
                .getKey() === selectionState.getFocusKey()
            ) {
              this.textNode.focus();
            }
            return 'handled';
          }}
          placeholder={titlePlaceholder}
          blockStyleFn={() => titleBlockStyle}
          onUpArrow={() => {
            const selectionState = this.state.titleEditorState.getSelection();
            const { titleEditorState } = this.state;
            if (
              titleEditorState
                .getCurrentContent()
                .getBlockMap()
                .first()
                .getKey() === selectionState.getFocusKey()
            ) {
              this.props.onFocusPreviousBlock(this.props.block, this.titleNode);
            }
          }}
          onDownArrow={() => {
            const selectionState = this.state.titleEditorState.getSelection();
            const { titleEditorState } = this.state;
            if (
              titleEditorState
                .getCurrentContent()
                .getBlockMap()
                .last()
                .getKey() === selectionState.getFocusKey()
            ) {
              this.textNode.focus();
            }
          }}
          ref={(node) => {
            this.titleNode = node;
          }}
        />

        <Editor
          onChange={this.onChangeText}
          editorState={this.state.textEditorState}
          blockRenderMap={extendedTextBlockRenderMap}
          handleReturn={() => {
            if (this.props.data.disableNewBlocks) {
              return 'handled';
            }
            this.props.onSelectBlock(
              this.props.onAddBlock(
                config.settings.defaultBlockType,
                this.props.index + 1,
              ),
            );
            return 'handled';
          }}
          placeholder={textPlaceholder}
          blockStyleFn={() => textBlockStyle}
          onUpArrow={() => {
            const selectionState = this.state.textEditorState.getSelection();
            const { textEditorState } = this.state;
            if (
              textEditorState
                .getCurrentContent()
                .getBlockMap()
                .first()
                .getKey() === selectionState.getFocusKey()
            ) {
              this.titleNode.focus();
            }
          }}
          onDownArrow={() => {
            const selectionState = this.state.textEditorState.getSelection();
            const { textEditorState } = this.state;
            if (
              textEditorState
                .getCurrentContent()
                .getBlockMap()
                .last()
                .getKey() === selectionState.getFocusKey()
            ) {
              this.props.onFocusNextBlock(this.props.block, this.textNode);
            }
          }}
          ref={(node) => {
            this.textNode = node;
          }}
        />
        <SidebarPortal selected={this.props.selected}>
          <Sidebar {...this.props} />
        </SidebarPortal>
      </div>
    );
  }
}

export default injectIntl(Edit);
