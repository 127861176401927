/**
 * Edit social link block sidebar schema.
 * @module components/manage/Blocks/SocialLink/Edit
 * @nk24:CMScom created 2021/05/13
 */

const Schema = {
  title: 'SNS Links',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [],
    },
    {
      id: 'background-images',
      title: 'Background Images',
      fields: ['background_image_PC', 'background_image_SP'],
    },
    {
      id: 'link_url',
      title: 'Link URL',
      fields: [
        'fb_url',
        'twitter_url',
        'instagram_url',
        'youtube_url',
        'linkedin_url',
      ],
    },
    {
      id: 'targets',
      title: 'Targets',
      fields: ['targets'],
    },
  ],
  properties: {
    background_image_PC: {
      title: 'Background image for PC',
      widget: 'object_browser',
      mode: 'image',
      selectedItemAttrs: [],
    },
    background_image_SP: {
      title: 'Background image for SP',
      widget: 'object_browser',
      mode: 'image',
      selectedItemAttrs: [],
    },
    fb_url: {
      title: 'Facebook URL',
      type: 'uri',
    },
    twitter_url: {
      title: 'twitter URL',
      type: 'uri',
    },
    instagram_url: {
      title: 'Instagram URL',
      type: 'uri',
    },
    youtube_url: {
      title: 'Youtube URL',
      type: 'uri',
    },
    linkedin_url: {
      title: 'Linkedin URL',
      type: 'uri',
    },
    targets: {
      title: 'Targets',
      widget: 'target_with_unselected',
      isMulti: true,
    },
  },
  required: [],
};

export default Schema;
