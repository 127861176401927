/**
 * OuFAQView component.
 * @module components/theme/View/OuFAQView
 */

import _ from 'lodash';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { HelmetSite, RedirectElement } from '../../index';
import './OuFAQView.css';

/**
 * Component to display the OuFAQView.
 * @function OuFAQView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OuFAQView = (props) => {
  const content = props?.content;
  const token = props?.token;
  //if (token === undefined) {
  //  props.history.push(_.get(content.parent, '@id'));
  //}
  return (
    <div className="OuFAQView">
      <HelmetSite content={content} />
      <section className="mainArea">
        <div className="mainAreaInner">
          <RedirectElement token={token} url={_.get(content.parent, '@id')} />
          <h2>{content.title}</h2>
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            children={content?.textarea}
          />
        </div>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuFAQView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default injectIntl(OuFAQView);
