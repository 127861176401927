import TargetsWidget from '~/components/manage/Widgets/TargetsWidget';

const Schema = {
  title: 'OU Numbers',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['unit'],
    },
    {
      id: 'background-images',
      title: 'Background Images',
      fields: ['background_image_PC', 'background_image_SP'],
    },
    {
      id: 'taregets',
      title: 'Targets',
      fields: ['targets'],
    },
  ],
  properties: {
    unit: {
      title: 'unit',
    },
    background_image_PC: {
      title: 'Background image for PC',
      widget: 'object_browser',
      mode: 'image',
      selectedItemAttrs: [], // path and Title
    },
    background_image_SP: {
      title: 'Background image for SP',
      widget: 'object_browser',
      mode: 'image',
      selectedItemAttrs: [], // path and Title
    },
    targets: {
      title: 'Targets',
      widget: 'target_with_unselected',
      isMulti: true,
    },
  },
  required: [],
};

export default Schema;
