import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import Schema from './Schema';

const messages = defineMessages({
  ou_now: {
    id: 'OU Now',
    defaultMessage: 'OU Now',
  },
});

const Sidebar = (props) => {
  const _ = props.intl.formatMessage;
  const type = props.data['@type'];
  const content = props.data?.[type];
  const index = props.index;
  if (!content) {
    return null;
  }
  return (
    <InlineForm
      schema={Schema}
      title={_(messages.ou_now)}
      onChangeField={(id, value) => {
        if (id === 'image' || id === 'link') {
          const items = content.items;
          items[index][id] = value;
          props.onChangeBlock(props.block, {
            ...props.data,
            [type]: { ...content, items },
          });
        } else {
          props.onChangeBlock(props.block, {
            ...props.data,
            [type]: { ...content, [id]: value },
          });
        }
      }}
      formData={{
        image: content.items[index].image || [],
        link: content.items[index].link || [],
        targets: content.targets || [],
      }}
    />
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(Sidebar);
