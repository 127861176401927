import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import Schema from './Schema';
// import _ from 'lodash';

const messages = defineMessages({
  automatic_list: {
    id: 'Automatic List',
    defaultMessage: 'Automatic List',
  },
});

const Sidebar = (props) => {
  const { data, block, onChangeBlock } = props;
  const _ = props.intl.formatMessage;
  const type = data?.['@type'];
  //  const type = _.get('@type', props.data);
  //  console.log({ data: data });
  //const type = 'image';
  return (
    <InlineForm
      schema={Schema}
      title={_(messages.automatic_list)}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={{
        invert_text_color: data?.invert_text_color || false,
        object_path: data.object_path || [],
        image: data.image || [],
        description: data.description || [],
        alt: data.alt || [],
      }}
    />
  );
};

export default injectIntl(Sidebar);
