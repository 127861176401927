/**
 * OuEventView component.
 * @module components/theme/View/OuEventView
 */

import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
//import NoSSR from 'react-no-ssr';
import { connect } from 'react-redux';
import { RRule, rrulestr } from 'rrule';
import { Icon, List } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { EventListItem, EventTags } from './OuEventFolderView';
import {
  BlackBox,
  HelmetSite,
  MoreButton,
  OuBody,
  PageTitle,
  Pickup,
  SNSShare,
  TargetFilter,
  TermFilterEvent,
  VisualClear,
} from '../../index';
import { eventList } from '../../../actions/eventList/eventList';
import {
  formatedDateDay,
  formatedTime,
} from '../../../helpers/GlobalStyle/Date.js';
import { getUserType } from '../../../helpers/UserType/UserType.js';
import './OuEventView.css';

/**
 * Component to display the OuEventView.
 * @function OuEventView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const MainArea = ({ content, intl }) => {
  const msg = defineMessages({
    intended: {
      id: 'intended_for',
      defaultMessage: 'Intended for',
    },
    date: {
      id: 'date',
      defaultMessage: 'Date',
    },
    venue: {
      id: 'venue',
      defaultMessage: 'Venue',
    },
    how_to_apply: {
      id: 'how_to_apply',
      defaultMessage: 'How to apply',
    },
    application: {
      id: 'application',
      defaultMessage: 'Application',
    },
    contact: {
      id: 'contact',
      defaultMessage: 'Contact',
    },
  });

  const mainImageSrc = flattenToAppURL(content?.thumbnail?.download ?? '');
  const targets = content?.targets;
  const startDate = formatedDateDay(content?.start);
  const endDate = formatedDateDay(content?.end);
  const startTime = formatedTime(content?.start);
  const endTime = formatedTime(content?.end);
  const locations = content?.locations;
  const locationDetails = content?.location_details;
  const locationURL = content?.location_url;
  const appProcess = content?.application_process;
  const appURL = content?.application_url;
  const contact = content?.contact;
  const email = content?.email;
  const tel = content?.tel;
  const appStart = content?.application_start;
  const appEnd = content?.application_end;

  const datesForDisplay = (start, end) => {
    const mStart = moment(start);
    const mEnd = moment(end);

    return {
      startDate: formatedDateDay(start),
      startTime: mStart.format('LT'),
      endDate: formatedDateDay(end),
      endTime: mEnd.format('LT'),
    };
  };

  const Recurrence = ({ recurrence, start }) => {
    if (recurrence.indexOf('DTSTART') < 0) {
      var dtstart = RRule.optionsToString({
        dtstart: new Date(start),
      });
      recurrence = dtstart + '\n' + recurrence;
    }
    const rrule = rrulestr(recurrence, { unfold: true, forceset: true });

    return (
      <List
        items={rrule
          .all()
          .map((date) => datesForDisplay(date))
          .map((date) => date.startDate)}
      />
    );
  };

  const TableHeader = ({ caption, icon }) => {
    return (
      <th className="TableHeader">
        <div className="tableHeader">
          <Icon name={icon} />
          <div className="caption">{caption}</div>
        </div>
      </th>
    );
  };

  const AttachementFile = ({ file, filename }) => {
    return (
      file && (
        <div className="AttachementFile">
          <p className="attachementFile arrow">
            <a href={flattenToAppURL(file?.download)}>
              {filename || file?.filename}
            </a>
          </p>
        </div>
      )
    );
  };

  return (
    <section className="mainArea">
      <div className="mainAreaInner">
        <div className="blackBoxWrapper">
          <BlackBox title="Events" />
        </div>
        {content?.thumbnail && (
          <div className="mainImage">
            <img alt={content?.title} src={mainImageSrc} />
          </div>
        )}
        <EventTags eventTags={content?.event_tags} />
        <PageTitle title={content?.title} />
        <div className="eventBox">
          <table>
            <tbody>
              <tr>
                <TableHeader
                  icon="user"
                  caption={intl.formatMessage(msg.intended)}
                />
                <td>
                  {targets?.length > 0 ? (
                    <ul className="targetList">
                      {_.map(targets, (target) => {
                        return (
                          <li className="target" key={target?.token}>
                            {target?.title}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <ul className="targetList">
                      <li className="target">ALL</li>
                    </ul>
                  )}
                </td>
              </tr>

              <tr>
                <TableHeader
                  icon="calendar alternate outline"
                  caption={intl.formatMessage(msg.date)}
                />
                <td>
                  <div className="startEnd">
                    {content?.recurrence ? (
                      <Recurrence
                        recurrence={content?.recurrence}
                        start={content?.start}
                      />
                    ) : startDate == endDate ? (
                      `${startDate} ${startTime} - ${endTime}`
                    ) : (
                      `${startDate} - ${endDate}`
                    )}
                  </div>
                </td>
              </tr>
              {((locations !== null && locations?.length !== 0) ||
                locationDetails) && (
                <tr>
                  <TableHeader
                    icon="map marker alternate"
                    caption={intl.formatMessage(msg.venue)}
                  />
                  <td>
                    {locations?.length > 0 && (
                      <ul className="locationList">
                        {_.map(locations, (location) => {
                          return (
                            <li className="location" key={location?.token}>
                              {location?.title}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                    {locationDetails && (
                      <div className="locationDetails">
                        {locationURL ? (
                          <>
                            <a href={locationURL} target="_blank">
                              {locationDetails}
                            </a>
                            {content?.location_url_icon && (
                              <Icon name="map marker alternate" />
                            )}
                          </>
                        ) : (
                          locationDetails
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              )}

              {(appProcess || appURL) && (
                <tr>
                  <TableHeader
                    icon="pencil"
                    caption={intl.formatMessage(msg.how_to_apply)}
                  />
                  <td>
                    {appProcess && (
                      <div className="appProcess">{appProcess}</div>
                    )}
                    {appURL && (
                      <div className="appURL">
                        <a href={appURL} target="_blank">
                          <FormattedMessage
                            id="application_from"
                            defaultMessage=""
                          />
                        </a>
                      </div>
                    )}
                  </td>
                </tr>
              )}

              {appStart && appEnd && (
                <tr>
                  <TableHeader
                    icon="check circle outline"
                    caption={intl.formatMessage(msg.application)}
                  />
                  <td>
                    <div className="appTerm">
                      {formatedDateDay(appStart)} - {formatedDateDay(appEnd)}
                    </div>
                  </td>
                </tr>
              )}

              {(contact || email || tel) && (
                <tr>
                  <TableHeader
                    icon="question"
                    caption={intl.formatMessage(msg.contact)}
                  />
                  <td>
                    {contact && <div className="contact">{contact}</div>}
                    {email && (
                      <div className="email">
                        <a href={`mailto:${email}`}>{email}</a>
                      </div>
                    )}
                    {tel && <div className="tel">{tel}</div>}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <OuBody content={content} />
        <AttachementFile file={content?.file1} filename={content?.file_name1} />
        <AttachementFile file={content?.file2} filename={content?.file_name2} />
        <AttachementFile file={content?.file3} filename={content?.file_name3} />
        <VisualClear />
        <SNSShare url={content['@id']} />
      </div>
    </section>
  );
};

const SideAreaComponent = ({
  bSize,
  bStart,
  content,
  eventList,
  hasNext,
  loadEventList,
  locale,
  pickups,
}) => {
  const userType = getUserType() === undefined ? '' : getUserType();
  const [target, setTarget] = useState(userType);
  const dt = new Date();
  const today = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
  const [eventTerm, setEventTerm] = useState(today);
  const [displayPickup, setDisplayPickup] = useState(true);
  useEffect(() => {
    // hasNext: true, bSize: 0, bStart: 0
    loadEventList(true, 0, 0, target, eventTerm, locale); // 1回目の読み込み
  }, []);

  return (
    <aside className="sideArea">
      <div className="sideAreaInner">
        <div className="blackBoxWrapper">
          <BlackBox title="Search Events" />
        </div>
        <div className="filters">
          <div className="targetFilterWrapper">
            <TargetFilter
              loadList={loadEventList}
              locale={locale}
              setTarget={setTarget}
              target={target}
              term={eventTerm}
              userType={userType}
            />
          </div>
          <div className="termFilterWrapper">
            <TermFilterEvent
              loadList={loadEventList}
              locale={locale}
              setDisplayPickup={setDisplayPickup}
              setEventTerm={setEventTerm}
              target={target}
            />
          </div>
        </div>
        {displayPickup && <Pickup ctype="OuEvent" pickups={pickups} />}
        {eventList?.length > 0 && (
          <ul className="eventList items">
            {_.map(eventList, (item) => {
              return (
                <li className="item" key={item?.url}>
                  <EventListItem contentURL={content['@id']} item={item} />
                </li>
              );
            })}
          </ul>
        )}
        <MoreButton
          bSize={bSize}
          bStart={bStart}
          hasNext={hasNext}
          loadList={loadEventList}
          locale={locale}
          target={target}
          term={eventTerm}
        />
      </div>
    </aside>
  );
};

const SideArea = connect(
  (state, props) => {
    return {
      bSize: state?.eventList?.result?.data?.attributes?.b_size ?? 0,
      bStart: state?.eventList?.result?.data?.attributes?.b_start ?? 0,
      eventList: state?.eventList?.eventList,
      hasNext: state?.eventList?.result?.data?.attributes?.has_next ?? true,
      locale: state?.content?.data?.language?.token ?? 'ja',
      pickups: state?.eventList?.result?.data?.attributes?.pickups,
    };
  },
  (dispatch, props) => ({
    loadEventList: (hasNext, bSize, bStart, target, term, locale) => {
      if (hasNext) {
        dispatch(eventList(bSize + bStart, target, term, locale));
      }
    },
  }),
)(SideAreaComponent);

const OuEventView = ({ content, intl }) => {
  return (
    <div className="OuEventView OuNewsAndEventView">
      <HelmetSite content={content} />
      <div className="ouNewsAndEventViewWrapper">
        <MainArea className="mainArea" content={content} intl={intl} />
        <SideArea className="sideArea" content={content} />
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuEventView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

const OuEventViewContainer = connect(
  (state, props) => {
    return {};
  },
  (dispatch, props) => ({}),
)(OuEventView);

export default injectIntl(OuEventViewContainer);
