/**
 * App container.
 * based on v13.8.2
 * @module components/theme/App/App
 */

import React, { Component } from 'react';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { asyncConnect, Helmet } from '@plone/volto/helpers';
import { Segment, Sidebar } from 'semantic-ui-react';
import { renderRoutes } from 'react-router-config';
import { Slide, ToastContainer, toast } from 'react-toastify';
import split from 'lodash/split';
import join from 'lodash/join';
import trim from 'lodash/trim';
import cx from 'classnames';
import config from '@plone/volto/registry';
import { PluggablesProvider } from '@plone/volto/components/manage/Pluggable';
import { visitBlocks } from '@plone/volto/helpers/Blocks/Blocks';
import { injectIntl } from 'react-intl';

import Error from '@plone/volto/error';

import {
  Breadcrumbs,
  Footer,
  Header,
  Icon,
  OutdatedBrowser,
  AppExtras,
  SkipLinks,
} from '@plone/volto/components';
import { BodyClass, getBaseUrl, getView, isCmsUi } from '@plone/volto/helpers';
import {
  getBreadcrumbs,
  getContent,
  getNavigation,
  getTypes,
  getWorkflow,
} from '@plone/volto/actions';

import clearSVG from '@plone/volto/icons/clear.svg';
import MultilingualRedirector from '@plone/volto/components/theme/MultilingualRedirector/MultilingualRedirector';
import WorkingCopyToastsFactory from '@plone/volto/components/manage/WorkingCopyToastsFactory/WorkingCopyToastsFactory';
import LockingToastsFactory from '@plone/volto/components/manage/LockingToastsFactory/LockingToastsFactory';

import * as Sentry from '@sentry/browser';

/* @nk24 begin */
import _ from 'lodash';
import { Icon as IconSemantic } from 'semantic-ui-react';
import { getSiteMainInfo } from '@package/actions';
import {
  FacilitiesModal,
  ForYou,
  ForYouButton,
  LanguageBox,
  SearchBox,
  SiteMenu,
  SiteMenuBar,
} from '@package/components';
import './App.css';
import { SiteSetting } from 'constants/Setting';
/* @nk24 end */

/**
 * @export
 * @class App
 * @extends {Component}
 */
class App extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
  };

  state = {
    hasError: false,
    error: null,
    errorInfo: null,
    /** @nk24 begin */
    visibleLeftBar: false,
    visibleSearchBox: false,
    visibleFacilities: false,
    visibleLanguage: false,
    visibleForYou: false,
    visibleForYouButton: true,
    /** @nk24 end */
  };

  /** @nk24 begin */
  /**
   * Knob Left
   * @param {*} visible
   */
  setVisibleLeftBar(visible) {
    this.setState({ visibleLeftBar: visible });
  }

  setVisibleSearchBox(visible) {
    this.setState({ visibleSearchBox: visible });
  }

  setVisibleFacilities(visible) {
    this.setState({ visibleFacilities: visible });
  }

  setVisibleLanguage(visible) {
    this.setState({ visibleLanguage: visible });
  }

  setVisibleForYou(visible) {
    this.setState({ visibleForYou: visible });
  }

  setVisibleForYouButton(visible) {
    this.setState({ visibleForYouButton: visible });
  }
  /** @nk24 end */

  /**
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    }
  }

  /**
   * ComponentDidCatch
   * @method ComponentDidCatch
   * @param {string} error  The error
   * @param {string} info The info
   * @returns {undefined}
   */
  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, errorInfo: info });
    if (__CLIENT__) {
      if (window?.env?.RAZZLE_SENTRY_DSN || __SENTRY__?.SENTRY_DSN) {
        Sentry.captureException(error);
      }
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { views } = config;
    const path = getBaseUrl(this.props.pathname);
    const action = getView(this.props.pathname);
    const isCmsUI = isCmsUi(this.props.pathname);
    const ConnectionRefusedView = views.errorViews.ECONNREFUSED;

    const language =
      this.props.content?.language?.token ?? this.props.intl?.locale;

    return (
      <div className="App">
        <PluggablesProvider>
          <BodyClass className={`view-${action}view`} />

          {/* Body class depending on content type */}
          {this.props.content && this.props.content['@type'] && (
            <BodyClass
              className={`contenttype-${this.props.content['@type']
                .replace(' ', '-')
                .toLowerCase()}`}
            />
          )}

          {/* Body class depending on sections */}
          <BodyClass
            className={cx({
              [trim(join(split(this.props.pathname, '/'), ' section-'))]:
                this.props.pathname !== '/',
              siteroot: this.props.pathname === '/',
              'is-authenticated': !!this.props.token,
              'is-anonymous': !this.props.token,
              'cms-ui': isCmsUI,
              'public-ui': !isCmsUI,
            })}
          />

          {/* @nk24 begin */}
          {this.props?.content && (
            <SiteMenuBar
              app={this}
              curPath={this.props.pathname}
              visibleLeftBar={this.state.visibleLeftBar}
              visibleSearchBox={this.state.visibleSearchBox}
              visibleFacilities={this.state.visibleFacilities}
              visibleLanguage={this.state.visibleLanguage}
            />
          )}
          <SearchBox
            app={this}
            visibleSearchBox={this.state.visibleSearchBox}
            isSearchPage={SiteSetting.searchPath === this.props.pathname}
          />
          <FacilitiesModal
            app={this}
            visibleFacilities={this.state.visibleFacilities}
          />
          <LanguageBox
            app={this}
            visibleLanguage={this.state.visibleLanguage}
          />
          <nav>
            <Breadcrumbs pathname={path} />
          </nav>
          <ForYouButton
            app={this}
            content={this.props.content}
            visibleForYouButton={this.state.visibleForYouButton}
          />
          <SkipLinks />

          {/* @nk24 begin */}
          <Sidebar.Pushable>
            <Sidebar.Pusher>
              <Sidebar.Pushable as="div" className="AppMain">
                {/* Close Button of Menu */}
                {/* karad : デザイン修正のため一旦除去
                <button
                  className="closeMenuButton"
                  style={{
                    display: this.state.visibleLeftBar
                      ? 'inline-block'
                      : 'none',
                  }}
                  onClick={() => this.setVisibleLeftBar(false)}
                >
                  <IconSemantic name="close" className="closeMenu" />
                </button>
                */}
                {/* Body of Menu */}
                <Sidebar
                  animation="overlay"
                  as="div"
                  className="SideBar"
                  onHide={() => this.setVisibleLeftBar(false)}
                  visible={this.state.visibleLeftBar}
                >
                  <SiteMenu app={this} />
                </Sidebar>
                <Sidebar.Pusher
                  className="main"
                  dimmed={this.state.visibleLeftBar}
                >
                  <Header
                    ctype={_.get(this.props.content, '@type')}
                    pathname={path}
                  />
                  <MultilingualRedirector
                    pathname={this.props.pathname}
                    contentLanguage={this.props.content?.language?.token}
                  >
                    {/*<Segment basic className="content-area">*/}
                    <main>
                      <OutdatedBrowser />
                      {this.props.connectionRefused ? (
                        <ConnectionRefusedView />
                      ) : this.state.hasError ? (
                        <Error
                          message={this.state.error.message}
                          stackTrace={this.state.errorInfo.componentStack}
                        />
                      ) : (
                        renderRoutes(this.props.route.routes, {
                          staticContext: this.props.staticContext,
                        })
                      )}
                      {/* @nk24 begin */}
                      <ForYou
                        app={this}
                        content={this.props.content}
                        visibleForYou={this.state.visibleForYou}
                      />
                      {/* @nk24 end */}
                    </main>
                    {/*</Segment>*/}
                  </MultilingualRedirector>
                  <Footer />
                </Sidebar.Pusher>
              </Sidebar.Pushable>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
          {/* @nk24 end */}

          <LockingToastsFactory
            content={this.props.content}
            user={this.props.userId}
          />
          <WorkingCopyToastsFactory content={this.props.content} />
          <ToastContainer
            position={toast.POSITION.BOTTOM_CENTER}
            hideProgressBar
            transition={Slide}
            autoClose={5000}
            closeButton={
              <Icon
                className="toast-dismiss-action"
                name={clearSVG}
                size="18px"
              />
            }
          />

          <AppExtras {...this.props} />
        </PluggablesProvider>
      </div>
    );
  }
}

export const __test__ = connect(
  (state, props) => ({
    pathname: props.location.pathname,
    token: state.userSession.token,
    content: state.content.data,
    apiError: state.apierror.error,
    connectionRefused: state.apierror.connectionRefused,
  }),
  {},
)(App);

export const fetchContent = async ({ store, location }) => {
  const content = await store.dispatch(
    getContent(getBaseUrl(location.pathname)),
  );

  const promises = [];
  const { blocksConfig } = config.blocks;

  const visitor = ([id, data]) => {
    const blockType = data['@type'];
    const { getAsyncData } = blocksConfig[blockType];
    if (getAsyncData) {
      const p = getAsyncData({
        store,
        dispatch: store.dispatch,
        path: location.pathname,
        location,
        id,
        data,
      });
      if (!p?.length) {
        throw new Error(
          'You should return a list of promises from getAsyncData',
        );
      }
      promises.push(...p);
    }
  };

  visitBlocks(content, visitor);

  await Promise.allSettled(promises);

  return content;
};

export default compose(
  asyncConnect([
    {
      key: 'breadcrumbs',
      promise: ({ location, store: { dispatch } }) =>
        __SERVER__ && dispatch(getBreadcrumbs(getBaseUrl(location.pathname))),
    },
    {
      key: 'content',
      promise: ({ location, store }) =>
        __SERVER__ && fetchContent({ store, location }),
    },
    {
      key: 'navigation',
      promise: ({ location, store: { dispatch } }) =>
        __SERVER__ &&
        dispatch(
          getNavigation(
            getBaseUrl(location.pathname),
            config.settings.navDepth,
          ),
        ),
    },
    {
      key: 'types',
      promise: ({ location, store: { dispatch } }) =>
        __SERVER__ && dispatch(getTypes(getBaseUrl(location.pathname))),
    },
    {
      key: 'workflow',
      promise: ({ location, store: { dispatch } }) =>
        __SERVER__ && dispatch(getWorkflow(getBaseUrl(location.pathname))),
    },
    /* @nk24 begin */
    {
      key: 'siteMainInfo',
      promise: ({ location, store: { dispatch } }) =>
        __SERVER__ && dispatch(getSiteMainInfo(getBaseUrl(location.pathname))),
    },
    /* @nk24 end */
  ]),
  injectIntl,
  connect(
    (state, props) => ({
      pathname: props.location.pathname,
      token: state.userSession.token,
      userId: state.userSession.token
        ? jwtDecode(state.userSession.token).sub
        : '',
      content: state.content.data,
      apiError: state.apierror.error,
      connectionRefused: state.apierror.connectionRefused,
    }),
    null,
  ),
)(App);
