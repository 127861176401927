import React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Segment } from 'semantic-ui-react';

const Sidebar = () => {
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="ouGrid" defaultMessage="OU Grid" />
        </h2>
      </header>
    </Segment.Group>
  );
};

export default injectIntl(Sidebar);
