/**
 * OuEventFolderView component.
 * @module components/theme/View/OuEventFolderView
 */

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  BlackBox,
  FolderHeaderImage,
  HelmetSite,
  MoreButton,
  Pickup,
  TargetFilter,
  TermFilterEvent,
} from '../../index';
import { eventList } from '../../../actions/eventList/eventList';
import { formatedDate } from '../../../helpers/GlobalStyle/Date.js';
import '../../../helpers/GlobalStyle/GlobalStyle.css';
import {
  tagIDToColor,
  tagIDToName,
} from '../../../helpers/GlobalStyle/GlobalStyle.js';
import { getUserType } from '../../../helpers/UserType/UserType.js';
import './OuEventFolderView.css';

/**
 * Component to display the OuEventFolderView.
 * @function OuEventFolderView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const EventTagsComponent = ({ allEventTags, eventTags, locale }) => {
  return (
    <div className="EventTags">
      {eventTags?.length > 0 && (
        <ul className="eventTags">
          {_.map(eventTags, (tag) => {
            return (
              <li
                className="tag"
                key={tag?.token}
                style={{
                  backgroundColor: tagIDToColor(allEventTags, tag?.token),
                }}
              >
                {tagIDToName(allEventTags, locale, tag?.token)}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
EventTagsComponent.propTypes = {
  allEventTags: PropTypes.array,
  locale: PropTypes.string,
};

export const EventTags = connect(
  (state, props) => {
    return {
      allEventTags:
        state?.siteMainInfo?.result?.data?.attributes?.dynamic_common
          ?.event_tags,
      locale: state?.content?.data?.language?.token ?? 'ja',
    };
  },
  (dispatch, props) => ({}),
)(EventTagsComponent);

export const EventListItem = ({ contentURL = '', item }) => {
  const itemPath = flattenToAppURL(item?.url);
  const contentPath = flattenToAppURL(contentURL);
  return (
    <div className="EventListItem">
      <Link className={itemPath == contentPath ? 'selected' : ''} to={itemPath}>
        <div className="thumbnail">
          <img alt={item?.name} src={flattenToAppURL(item?.src)} />
        </div>
        <EventTags eventTags={item?.event_tags} />
        <div className="date">
          <span className="start">{formatedDate(item?.start)}</span>
          {item?.start != item?.end && (
            <span className="toEnd">
              <span className="to">▶︎</span>
              <span className="end">{formatedDate(item?.end)}</span>
            </span>
          )}
        </div>
        <div className="title">{item?.name}</div>
      </Link>
    </div>
  );
};

const OuEventFolderView = ({
  bSize,
  bStart,
  content,
  eventList,
  hasNext,
  loadEventList,
  locale,
  pickups,
}) => {
  const userType = getUserType() === undefined ? '' : getUserType();
  const [eventTarget, setEventTarget] = useState(userType);
  const dt = new Date();
  const today = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
  const [eventTerm, setEventTerm] = useState(today);
  const path = flattenToAppURL(content['@id']).replace(/\?.*$/, '');
  const [displayPickup, setDisplayPickup] = useState(true);
  useEffect(() => {
    // hasNext: true, bSize: 0, bStart: 0
    loadEventList(true, 0, 0, eventTarget, eventTerm, locale, path); // 1回目の読み込み
  }, []);

  return (
    <div className="OuEventFolderView OuNewsFolderAndEventFolderView">
      <HelmetSite content={content} />
      <FolderHeaderImage content={content} />
      <section className="mainArea">
        <div className="mainAreaInner">
          <div className="blackBoxWrapper">
            <BlackBox title="Search Events" />
          </div>
          <div className="filters">
            <div className="targetFilterWrapper">
              <TargetFilter
                loadList={loadEventList}
                locale={locale}
                path={path}
                setTarget={setEventTarget}
                target={eventTarget}
                term={eventTerm}
                userType={userType}
              />
            </div>
            <div className="termFilterWrapper">
              <TermFilterEvent
                loadList={loadEventList}
                locale={locale}
                path={path}
                setDisplayPickup={setDisplayPickup}
                setEventTerm={setEventTerm}
                target={eventTarget}
              />
            </div>
          </div>
          {displayPickup && <Pickup ctype="OuEventFolder" pickups={pickups} />}
          {eventList?.length > 0 && (
            <div>
              <ul className="eventList items">
                {_.map(eventList, (item) => {
                  return (
                    <li className="item" key={item?.url}>
                      <EventListItem item={item} />
                    </li>
                  );
                })}
              </ul>
              <MoreButton
                bSize={bSize}
                bStart={bStart}
                hasNext={hasNext}
                loadList={loadEventList}
                locale={locale}
                path={path}
                target={eventTarget}
                term={eventTerm}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuEventFolderView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  bSize: PropTypes.number,
  bStart: PropTypes.number,
  eventList: PropTypes.array,
  hasNext: PropTypes.bool,
  loadEventList: PropTypes.func.isRequired,
  locale: PropTypes.string,
  pickups: PropTypes.array,
};

const OuEventFolderViewContainer = connect(
  (state, props) => {
    return {
      bSize: state?.eventList?.result?.data?.attributes?.b_size ?? 0,
      bStart: state?.eventList?.result?.data?.attributes?.b_start ?? 0,
      eventList: state?.eventList?.eventList,
      hasNext: state?.eventList?.result?.data?.attributes?.has_next ?? true,
      locale: state?.content?.data?.language?.token ?? 'ja',
      pickups: state?.eventList?.result?.data?.attributes?.pickups,
    };
  },
  (dispatch, props) => ({
    loadEventList: (hasNext, bSize, bStart, target, term, locale, path) => {
      if (hasNext) {
        dispatch(eventList(bSize + bStart, target, term, locale, path));
      }
    },
  }),
)(OuEventFolderView);

export default injectIntl(OuEventFolderViewContainer);
