/**
 * VisualClear component.
 * @module components/theme/Element/VisualClear/VisualClear
 */

import React from 'react';
import { injectIntl } from 'react-intl';

/**
 * Component to display the PageTitle
 * @function VisualClear
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const VisualClear = ({ title }) => {
  return <div className="VisualClear" style={{ clear: 'both' }}></div>;
};

export default injectIntl(VisualClear);
