/**
 * FullReviewListView component.
 * @module components/theme/View/FullReviewListView
 */

import _ from 'lodash';

import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PageTitle } from '@package/components';
import { flattenToAppURL, Helmet } from '@plone/volto/helpers';
import { FolderItems } from './OuCategoryFolderView';
import { fullReviewList as fullReviewListAction } from '../../../actions/fullReviewList/fullReviewList';
import './FullReviewListView.css';
import { Table } from 'semantic-ui-react';

/**
 * Component to display the FullReviewListView.
 * @function FullReviewListView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const FullReviewListView = (props) => {
  const [pendings, timed_publisheds, locale] = useSelector((state) => {
    return [
      state?.fullReviewList?.result?.data?.attributes?.pending ?? [],
      state?.fullReviewList?.result?.data?.attributes?.timed_published ?? [],
      state?.content?.data?.language?.token ?? 'ja',
    ];
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fullReviewListAction(locale));
  }, []);
  return (
    <div className="FullReviewListView OuPageView">
      <Helmet title="Full Review List" />
      <section className="mainArea">
        <div className="mainAreaInner">
          <PageTitle title="審査中リスト" />
          <div className="red">
            他の部署が作成したコンテンツも表示されますが、ご自身が作成したもの以外は触らないでください。
            リストに表示される時間は実際の公開時間の9時間前です。
          </div>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>タイトル</Table.HeaderCell>
                <Table.HeaderCell>コンテンツタイプ</Table.HeaderCell>
                <Table.HeaderCell>作成者</Table.HeaderCell>
                <Table.HeaderCell>公開日付</Table.HeaderCell>
                <Table.HeaderCell>最終更新日</Table.HeaderCell>
                <Table.HeaderCell>状態</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {pendings.map((item, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell>
                      <Link to={item.url}>{item.title}</Link>
                    </Table.Cell>
                    <Table.Cell>{item.type}</Table.Cell>
                    <Table.Cell>{item.creator}</Table.Cell>
                    <Table.Cell>{item.effective}</Table.Cell>
                    <Table.Cell>{item.modified}</Table.Cell>
                    <Table.Cell>{item.review_state}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <PageTitle title="時限公開中リスト" />
          <div className="red">
            他の部署が作成したコンテンツも表示されますが、ご自身が作成したもの以外は触らないでください。
            リストに表示される時間は実際の公開時間の9時間前です。
          </div>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>タイトル</Table.HeaderCell>
                <Table.HeaderCell>コンテンツタイプ</Table.HeaderCell>
                <Table.HeaderCell>作成者</Table.HeaderCell>
                <Table.HeaderCell>公開日付</Table.HeaderCell>
                <Table.HeaderCell>最終更新日</Table.HeaderCell>
                <Table.HeaderCell>状態</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {timed_publisheds.map((item, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell>
                      <Link to={item.url}>{item.title}</Link>
                    </Table.Cell>
                    <Table.Cell>{item.type}</Table.Cell>
                    <Table.Cell>{item.creator}</Table.Cell>
                    <Table.Cell>{item.effective}</Table.Cell>
                    <Table.Cell>{item.modified}</Table.Cell>
                    <Table.Cell>{item.review_state}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </section>
    </div>
  );
};

export default injectIntl(FullReviewListView);
