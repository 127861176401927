import { STORYZ } from '../../constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

export function storyZ(path) {
  return {
    type: STORYZ,
    request: {
      op: 'get',
      path: `${flattenToAppURL(path)}/@storyz`,
    },
  };
}
