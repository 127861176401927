import { GET_RECOMMEND_LIST } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  result: {},
};

/**
 * Recommend Reducer
 * @param {*} state
 * @param {*} action
 */
export default function recommendList(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case `${GET_RECOMMEND_LIST}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_RECOMMEND_LIST}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        result: action?.result,
      };
    case `${GET_RECOMMEND_LIST}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
        result: {}
      };
    default:
      return state;
  }
}
