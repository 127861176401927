import React from 'react';

import { ListForOuGrid } from './Edit';

const View = (props) => {
  return (
    <div className="AutomaticListForOuGridView">
      <ListForOuGrid props={props} />
    </div>
  );
};

export default View;
