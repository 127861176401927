/**
 * HelmetSite component.
 * @module components/theme/Site/HelmetSite/HelmetSite
 */

import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Helmet, flattenToAppURL } from '@plone/volto/helpers';
import { getEnvValue } from 'helpers/Env/Env';

const HAS_HEADER_IMAGE = [
  'OuCategoryFolderView',
  'OuNewsFolderView',
  'OuEventFolderView',
  'OuFolderView',
  'OuGeneralFolderView',
];

const HAS_MAIN_IMAGE = [
  'OuPageView',
  'OuNewsView',
  'OuEventView',
  'OuMigratePageView',
];

/**
 * Component to display the HelmetSite.
 * @function HelmetSite
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const HelmetSite = ({
  content,
  layout,
  link,
  locale,
  ogImagePath,
  siteTitle,
}) => {
  const url = _.get(content, '@id');
  // const uri = new URL(url);
  // const baseUrl =
  //   uri.origin + flattenToAppURL(url) + (content?.is_folderish ? '/' : '');
  // console.log({ env: getEnvValue('RAZZLE_BASE_URL') });
  // console.log({ url: flattenToAppURL(url) });
  const baseUrl =
    getEnvValue('RAZZLE_BASE_URL') +
    flattenToAppURL(url) +
    (content?.is_folderish ? '/' : '');
  const helmetTitle = `${content?.title} － ${siteTitle}`;
  const ogImage = `${ogImagePath}/@@images/image`;
  const ogLocale = locale === 'en' ? 'en_US' : 'ja_JP';
  const OU_URL = 'https://www.osaka-u.ac.jp';
  function getOgImage() {
    if (HAS_HEADER_IMAGE.includes(layout)) {
      return `${OU_URL}${flattenToAppURL(
        content?.header_image?.download ?? ogImage,
      )}`;
    } else if (HAS_MAIN_IMAGE.includes(layout)) {
      return `${OU_URL}${flattenToAppURL(
        content?.thumbnail?.download ?? ogImage,
      )}`;
    }
    return `${OU_URL}${flattenToAppURL(ogImage)}`;
  }
  const ogType = layout === 'LanguageRootFolderView' ? 'website' : 'article';
  const links = link ? link : [];
  links.push({
    rel: 'apple-touch-icon',
    href: `${OU_URL}${flattenToAppURL('/touch_icon.png')}`,
  });
  links.push({
    rel: 'preconnect',
    href: 'https://fonts.googleapis.com',
  });
  links.push({
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: '',
  });
  links.push({
    rel: 'stylesheet',
    href:
      'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap',
  });
  links.push({
    rel: 'stylesheet',
    href: `/print.css`,
    media: 'print',
  });
  return (
    <Helmet
      title={helmetTitle}
      base={{ href: baseUrl }}
      link={links}
      meta={[
        { name: 'httpEquiv', content: locale },
        { name: 'google', content: 'notranslate' },
        { property: 'og:title', content: content?.title },
        { property: 'og:description', content: content?.description },
        {
          property: 'og:url',
          content: `${OU_URL}${flattenToAppURL(content['@id'])}`,
        },
        { property: 'og:locale', content: ogLocale },
        { property: 'og:image', content: getOgImage() },
        { property: 'og:type', content: ogType },
        { property: 'og:site_name', content: siteTitle },
      ]}
    />
  );
};

const HelmetSiteContainer = connect(
  (state, props) => {
    const locale = state?.content?.data?.language?.token ?? 'ja';
    return {
      layout: state?.content?.data?.layout,
      locale: locale,
      ogImagePath:
        state?.siteMainInfo?.result?.data?.attributes?.locale?.[locale]?.assets
          ?.og_image,
      siteTitle:
        state?.siteMainInfo?.result?.data?.attributes?.locale?.[locale]?.assets
          ?.site_title,
    };
  },
  (dispatch, props) => {
    return {};
  },
)(HelmetSite);

export default injectIntl(HelmetSiteContainer);
