/**
 * View events collection block.
 * @module components/manage/Blocks/Events/Edit
 * @CMScom:nk24 created 2021/03/30
 */

import { map } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import NoSSR from 'react-no-ssr';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { flattenToAppURL } from '@plone/volto/helpers';
import { eventList } from '~/actions';
import { LRFBlockHeader, Pickup, TargetFilter } from '~/components';
import {
  formatedDate,
  formatedMonthDate,
  formatedYear,
} from '~/helpers/GlobalStyle/Date.js';
import { getUserType } from '~/helpers/UserType/UserType.js';
import { MediaQueryConfig } from '~/helpers/GlobalStyle/GlobalStyle';
import './View.css';

/**
 * View events collection block class.
 * @class View
 * @extends Component
 */

const View = ({
  background_image_PC,
  background_image_SP,
  title,
  eventList,
  loadEventList,
  locale,
  pickups,
  eventNum,
}) => {
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  const backgroundImagePath = !isSP
    ? background_image_PC?.[0]?.['@id']
    : background_image_SP?.[0]?.['@id'];
  const backgroundStyle = backgroundImagePath
    ? {
        backgroundImage: `url(${flattenToAppURL(
          backgroundImagePath,
        )}/@@images/image)`,
      }
    : null;
  const dt = new Date();
  const today = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
  const userType = getUserType() === undefined ? '' : getUserType();
  const [target, setTarget] = useState(userType);
  // useEffect(() => {
  //   if (locale) {
  //     loadEventList(true, '0', '0', target, '', locale);
  //   }
  // }, []);
  const [eventListState, setEventListState] = useState();
  useEffect(() => {
    // hasNext: true, bSize: 0, bStart: 0
    loadEventList(true, 0, 0, target, today, locale); // 1回目の読み込み
  }, []);
  useEffect(() => {
    if (eventList !== eventListState) {
      setEventListState(eventList);
    }
  }, [eventList, eventListState, setEventListState]);
  return (
    <NoSSR>
      <section className="Events" style={backgroundStyle}>
        <div className="inner">
          <LRFBlockHeader title={title} />
          <div className="filters">
            <div className="targetFilterWrapper">
              <TargetFilter
                loadList={loadEventList}
                locale={locale}
                setTarget={setTarget}
                target={target}
                term={today}
                userType={userType}
              />
            </div>
          </div>
          <section className="contents">
            <Pickup ctype="OuEvent" pickups={pickups} />
            <ul className="eventsList">
              {map(eventList, (item) => (
                <li className="item">
                  <div className="date">
                    <div className="startYear">{formatedYear(item?.start)}</div>
                    <div className="startDate">
                      {formatedMonthDate(item?.start)}
                    </div>
                    {item?.start !== item?.end && (
                      <div className="toEnd">
                        <span className="to">▶︎</span>
                        <span className="end">{formatedDate(item?.end)}</span>
                      </div>
                    )}
                  </div>
                  <div className="title">
                    <span>
                      <Link to={flattenToAppURL(item?.url)}>{item?.name}</Link>
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </section>
          <footer className="more">
            <Link to={flattenToAppURL(`/${locale}/event`)}>MORE</Link>
          </footer>
        </div>
      </section>
    </NoSSR>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  background_image_PC: PropTypes.arrayOf(PropTypes.string).isRequired,
  background_image_SP: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  eventList: PropTypes.array,
  loadEventList: PropTypes.func.isRequired,
  locale: PropTypes.string,
  pickups: PropTypes.array,
  eventNum: PropTypes.any,
};

const ViewContainer = connect(
  (state, props) => {
    return {
      title: props?.data?.events?.title,
      background_image_PC: props?.data?.events?.background_image_PC,
      background_image_SP: props?.data?.events?.background_image_SP,
      eventList: state?.eventList?.eventList,
      locale: state?.content?.data?.language?.token ?? 'ja',
      pickups: state?.eventList?.result?.data?.attributes?.pickups,
      eventNum: state?.eventList,
    };
  },
  (dispatch, props) => ({
    loadEventList: (hasNext, bSize, bStart, target, term, locale) => {
      if (hasNext) {
        dispatch(eventList(bSize + bStart, target, term, locale));
      }
    },
  }),
)(View);

// export default ViewContainer;
export default injectIntl(ViewContainer);
