/**
 * SNSShare component.
 * @module components/theme/Element/SNSShare/SNSShare
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { Icon as IconComponent } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { OSAKA_U_DOMAIN } from '../../../../helpers/GlobalStyle/GlobalStyle';
import xSVG from '~/icons/circle_x.svg';
import './SNSShare.css';

/**
 * Component to display the SNSShare
 * @function SNSShare
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const SNSShare = ({ url }) => {
  const pageURL = OSAKA_U_DOMAIN + flattenToAppURL(url);

  return (
    <div className="SNSShare">
      <div className="snsShare">
        <TwitterShareButton className="snsButton" url={pageURL}>
          <IconComponent name={xSVG} size={45} />
        </TwitterShareButton>
        <FacebookShareButton className="snsButton" url={pageURL}>
          <FacebookIcon size="3.21rem" round />
        </FacebookShareButton>
        <div className="caption">share !</div>
      </div>
    </div>
  );
};

export default injectIntl(SNSShare);
