/**
 * BlockCooser.jsx
 * based on v13.8.2
 * @module components/manage/BlockChooser/BlockChooser
 */

import React from 'react';
import PropTypes from 'prop-types';
import { filter, map, groupBy, isEmpty } from 'lodash';
import { Accordion, Button } from 'semantic-ui-react';
import { useIntl, defineMessages } from 'react-intl';
/* @nk24 begin */
import { connect } from 'react-redux';
/* @nk24 end */
import { Icon } from '@plone/volto/components';
import AnimateHeight from 'react-animate-height';
import config from '@plone/volto/registry';

import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';

const messages = defineMessages({
  fold: {
    id: 'Fold',
    defaultMessage: 'Fold',
  },
  unfold: {
    id: 'Unfold',
    defaultMessage: 'Unfold',
  },
});

const BlockChooser = ({
  currentBlock,
  onInsertBlock,
  onMutateBlock,
  allowedBlocks,
  showRestricted,
  blocksConfig = config.blocks.blocksConfig,
  blockChooserRef,
  properties = {},
  /** nk24 begin */
  addType,
  /** nk24 end */
}) => {
  const intl = useIntl();
  const useAllowedBlocks = !isEmpty(allowedBlocks);

  const filteredBlocksConfig = filter(blocksConfig, (item) => {
    if (showRestricted) {
      if (useAllowedBlocks) {
        return allowedBlocks.includes(item.id);
      } else {
        return true;
      }
    } else {
      if (useAllowedBlocks) {
        return allowedBlocks.includes(item.id);
      } else {
        // Overload restricted as a function, so we can decide the availability of a block
        // depending on this function, given properties (current present blocks) and the
        // block being evaluated
        return typeof item.restricted === 'function'
          ? !item.restricted({ properties, block: item })
          : !item.restricted;
      }
    }
  });
  // @nk24 begin
  const isGlobal = properties.is_global || false;
  const ouFilteredBlocksConfig = isGlobal
    ? filter(filteredBlocksConfig, (item) => {
        return !(item.global === false); // true or undefined
      })
    : filteredBlocksConfig;
  // const mostUsedBlocks = filter(filteredBlocksConfig, (item) => item.mostUsed);
  const mostUsedBlocks = filter(
    ouFilteredBlocksConfig,
    (item) => item.mostUsed,
  );
  let blocksAvailable = {}; // 改造以前は変数定義なしで動いていたが??
  // @nk24 end

  if (mostUsedBlocks) {
    blocksAvailable.mostUsed = mostUsedBlocks;
  }

  /* @nk24 begin */
  // const groupedBlocks = groupBy(filteredBlocksConfig, (item) => item.group);
  const contentType = properties?.['@type'];
  const type = contentType ?? addType;
  // let groupedBlocks;
  const groupedBlocks = groupBy(
    filter(
      // filteredBlocksConfig, //filteredBlocksConfigが使用されないからrestrictedが効かない
      ouFilteredBlocksConfig, //@nk24
      (item) => !item.type || item.type === type,
    ),
    (item) => item.group,
  );
  /* @nk24 end */

  blocksAvailable = {
    ...blocksAvailable,
    ...groupedBlocks,
  };

  const groupBlocksOrder = filter(config.blocks.groupBlocksOrder, (item) =>
    Object.keys(blocksAvailable).includes(item.id),
  );
  const [activeIndex, setActiveIndex] = React.useState(0);

  function handleClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  }

  return (
    <div className="blocks-chooser" ref={blockChooserRef}>
      <Accordion fluid styled className="form">
        {map(groupBlocksOrder, (groupName, index) => (
          <React.Fragment key={groupName.id}>
            <Accordion.Title
              aria-label={
                activeIndex === index
                  ? `${intl.formatMessage(messages.fold)} ${
                      groupName.title
                    } blocks`
                  : `${intl.formatMessage(messages.unfold)} ${
                      groupName.title
                    } blocks`
              }
              active={activeIndex === index}
              index={index}
              onClick={handleClick}
            >
              {intl.formatMessage({
                id: groupName.id,
                defaultMessage: groupName.title,
              })}
              <div className="accordion-tools">
                {activeIndex === 0 ? (
                  <Icon name={upSVG} size="20px" />
                ) : (
                  <Icon name={downSVG} size="20px" />
                )}
              </div>
            </Accordion.Title>
            <Accordion.Content
              className={groupName.id}
              active={activeIndex === index}
            >
              <AnimateHeight
                animateOpacity
                duration={500}
                height={activeIndex === index ? 'auto' : 0}
              >
                {map(blocksAvailable[groupName.id], (block) => (
                  <Button.Group key={block.id}>
                    <Button
                      icon
                      basic
                      className={block.id}
                      onClick={(e) => {
                        onInsertBlock
                          ? onInsertBlock(currentBlock, { '@type': block.id })
                          : onMutateBlock(currentBlock, { '@type': block.id });
                        e.stopPropagation();
                      }}
                    >
                      <Icon name={block.icon} size="36px" />
                      {intl.formatMessage({
                        id: block.title,
                        defaultMessage: block.title,
                      })}
                    </Button>
                  </Button.Group>
                ))}
              </AnimateHeight>
            </Accordion.Content>
          </React.Fragment>
        ))}
      </Accordion>
    </div>
  );
};

BlockChooser.propTypes = {
  currentBlock: PropTypes.string.isRequired,
  onMutateBlock: PropTypes.func,
  onInsertBlock: PropTypes.func,
  allowedBlocks: PropTypes.arrayOf(PropTypes.string),
  blocksConfig: PropTypes.objectOf(PropTypes.any),
  /* @nk24 begin */
  addType: PropTypes.string.isRequired,
  /* @nk24 end */
};

/* @nk24 begin */
const BlockChooserContainer = connect(
  (state, props) => {
    return {
      addType: state.router?.location?.query?.type,
    };
  },
  (dispatch, props) => {
    return {};
  },
)(BlockChooser);
/* @nk24 end */

/* @nk24 begin */
export default React.forwardRef((props, ref) => (
  // const BlockChooserWithRef = React.forwardRef((props, ref) => (
  /* @nk24 end */
  <BlockChooserContainer {...props} blockChooserRef={ref} />
));

/* @nk24 begin */
// export default injectIntl(BlockChooserContainer, {});
/* @nk24 end */
