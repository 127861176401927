/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */

/**
 * サイト全体の設定情報
 */
export const GET_SITE_MAIN_INFO = 'GET_SITE_MAIN_INFO';
/**
 * View
 */
export const URGENT_NOTIFICATION = 'URGENT_NOTIFICATION';
export const GET_EVENT_LIST = 'GET_EVENT_LIST';
export const GET_NEWS_LIST = 'GET_NEWS_LIST';
export const GET_RESEARCH_LIST = 'GET_RESEARCH_LIST';
export const FAQ_FOLDER_LIST = 'FAQ_FOLDER_LIST';
export const FAQ_LIST = 'FAQ_LIST';
export const FAQ_SEARCH = 'FAQ_SEARCH';
export const STORYZ_TOP_FOLDER = 'STORYZ_TOP_FOLDER';
export const STORYZ_FOLDER = 'STORYZ_FOLDER';
export const STORYZ = 'STORYZ';
export const STORYZ_LINK = 'STORYZ_LINK';
export const FOLDER_LIST = 'FOLDER_LIST';
export const SITEMAP = 'SITEMAP';
/**
 * 移行用ページタイプの状態
 */
export const MIGRATE_CONTENT = 'MIGRATE_CONTENT';
export const RESET_MIGRATE_CONTENT = 'RESET_MIGRATE_CONTENT';
export const CAN_BLOCK_PREVIEW = 'CAN_BLOCK_PREVIEW';
export const PREVIEW_BLOCK = 'PREVIEW_BLOCK';

/**
 * Recommend関係 : Recommend情報
 */
export const GET_RECOMMEND_LIST = 'GET_RECOMMEND_LIST';

/**
 * Search関係 : Search情報
 */
export const GET_SEARCH_RESULT = 'GET_SEARCH_RESULT';

/**
 * 検索結果取得リセット
 */
export const RESET_SEARCH_RESULT_CONTENT = 'RESET_SEARCH_RESULT_CONTENT';

/**
 * 検索結果取得
 */
export const SEARCH_RESULT_CONTENT = 'SEARCH_RESULT_CONTENT';

/**
 * Welcome関係 : Welcome情報
 */
export const GET_WELCOME_LIST = 'GET_WELCOME_LIST';
export const GET_MORE_WELCOME_LIST = 'GET_MORE_WELCOME_LIST';

/**
 * Welcome画面を表示するかどうか
 */
export const DISPLAY_WELCOME = 'DISPLAY_WELCOME';

/**
 * For You
 */
export const FOR_YOU = 'FOR_YOU';
export const FOR_YOU_MORE = 'FOR_YOU_MORE';
export const FOR_YOU_BUTTON = 'FOR_YOU_BUTTON';

/**
 * userTypeに関連付いたtourを取得
 */
export const GET_TOURS = 'GET_TOURS';

/**
 * 関連TourとTourItem取得
 */
export const GET_TOUR_ITEMS = 'GET_TOUR_ITEMS';

/**
 * Full Review List
 */
export const FULL_REVIEW_LIST = 'FULL_REVIEW_LIST';

/*
 * メールフォーム送信/状態クリア
 */
export const POST_MAIL_FORM_CONTENT = 'POST_MAIL_FORM_CONTENT';
export const POST_MAIL_FORM_CONTENT_CLEAR = 'POST_MAIL_FORM_CONTENT_CLEAR';

/**
 * メールフォームのformId重複チェック用
 */
export const POP_MAIL_FORM_FORM_ID = 'POP_MAIL_FORM_FORM_ID';
export const ADD_MAIL_FORM_FORM_ID = 'ADD_MAIL_FORM_FORM_ID';

export const AUTOMATIC_LIST_FOR_OU_GRID = 'AUTOMATIC_LIST_FOR_OU_GRID';
