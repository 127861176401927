import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import './View.css';

const View = ({ children, className, key, tagName }) => {
  if (children) {
    if (tagName === 'header-two') {
      return <h2 className={className}>{children}</h2>;
    } else {
      return <div className={className}>{children}</div>;
    }
  } else return null;
  //   <div id={data.key} className="title">
  //     Title
  //   </div>;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  //   data: PropTypes.objectOf(PropTypes.any).isRequired,
  //   children: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.string,
  className: PropTypes.string,
  key: PropTypes.string,
  tagName: PropTypes.string | null,
};

export default injectIntl(View);
