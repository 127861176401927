/**
 * Edit FromIDWidget widget component function
 * @function FormIDWidget
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Input, Label } from 'semantic-ui-react';
import { addMailFormFormId, popMailFormFormId } from '../../../../../actions/';
import { validateDuplicatedId } from '../../../../../helpers/Validator/Validator';
import WidgetNote from './WidgetNote';

/**
 * Validate duplicated form ID method.
 * @function validateDuplicatedId
 * @returns {boolean}
 */
const FormID = ({
  state,
  data,
  storedFormId,
  blockUid,
  contentUid,
  storedFormIds,
  onChangeBlock,
  storeFormIds,
  removeFormIds,
}) => {
  const [formId, setFormId] = useState(storedFormId);
  const [error, setError] = useState(false);
  const nextFormId = storedFormIds[contentUid]?.[blockUid];
  const targetBlockUid = storedFormIds[contentUid]?.targetBlockUid;
  useEffect(() => {
    if (!storedFormIds?.contentUid?.blockUid) {
      storeFormIds(contentUid, blockUid, storedFormId);
    }
    return () => {
      removeFormIds(contentUid, blockUid, storedFormId);
    };
  }, []);
  useEffect(() => {
    if (storedFormIds?.[contentUid]?.[blockUid]) {
      if (blockUid === targetBlockUid) {
        if (nextFormId !== formId) {
          setFormId(nextFormId);
        }
        const validate = validateDuplicatedId(
          storedFormIds,
          contentUid,
          blockUid,
          nextFormId,
        );
        if (validate) {
          setError(true);
        } else {
          setError(false);
        }
      }
    }
  }, [
    blockUid,
    contentUid,
    data,
    formId,
    nextFormId,
    onChangeBlock,
    storeFormIds,
    storedFormId,
    storedFormIds,
    targetBlockUid,
  ]);
  return (
    <Form.Field>
      <Label htmlFor="form-id" horizontal>
        <FormattedMessage id="FormID" defaultMessage="Form ID" />
      </Label>
      <WidgetNote>
        <FormattedMessage
          id="FormIDWidgetHelp"
          defaultMessage="Please enter any ID with half-width alphanumeric characters."
        />
      </WidgetNote>
      {error ? (
        <Label basic color="red" pointing="below">
          <FormattedMessage
            id="formIdDuplicated"
            defaultMessage="This ID is already used in this form. Please change it."
          />
        </Label>
      ) : null}
      <Input
        name="form-id"
        value={formId}
        error={error}
        onChange={(e, target) => {
          setFormId(target.value);
          onChangeBlock(blockUid, { ...data, formId: target.value });
          storeFormIds(contentUid, blockUid, target.value);
        }}
      />
    </Form.Field>
  );
};

/**
 * Container method component to connect the redux state and FormID component.
 * @function FormIDContainer
 * @param {function connect(params)} connect connect function.
 * @returns {undefined}
 */
const FormIDContainer = connect(
  (state, props) => {
    return {
      state,
      blockUid: props.block,
      storedFormId: props.data?.formId,
      contentUid: state?.content?.data?.UID,
      storedFormIds: state?.mailFormFormIds?.mailFormFormId,
      locale: state?.content?.data?.language?.token ?? 'ja',
    };
  },
  (dispatch, props) => {
    return {
      storeFormIds: (uid, block, formId) => {
        dispatch(addMailFormFormId(uid, block, formId));
      },
      removeFormIds: (uid, block, formId) => {
        dispatch(popMailFormFormId(uid, block, formId));
      },
    };
  },
)(FormID);

FormID.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  storedFormIds: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
};

export default injectIntl(FormIDContainer);
