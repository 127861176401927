import { STORYZ_LINK } from '../../constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

export function storyZLink(path) {
  return {
    type: STORYZ_LINK,
    request: {
      op: 'get',
      path: `${flattenToAppURL(path)}/@storyz-link`,
    },
  };
}
