/**
 * SearchBox component.
 * @module components/theme/Site/SearchBox/SearchBox
 */

import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import NoSSR from 'react-no-ssr';
import { useMediaQuery } from 'react-responsive';
import { Form, Icon, Input, Sidebar } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { MediaQueryConfig } from '../../../../helpers/GlobalStyle/GlobalStyle';
import config from '@plone/volto/registry';
//import cookie from 'react-cookie';
import { Cookies } from 'react-cookie';
import './SearchBoxForPage.css';
import { getLocaleFromPathname } from 'helpers/Locale/Locale';

/**
 * Component to display the SearchBox.
 * @function SearchBoxForPage
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const SearchBoxForPage = ({ inputValue }) => {
  /**
   * 検索語の状態管理
   */
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    // 検索語の値を変更
    setSearchText(inputValue);
  }, [inputValue]);

  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });

  const locale =
    new Cookies().get('I18N_LANGUAGE') || config.settings.defaultLanguage;
  // const locale = getLocaleFromPathname(
  //   __CLIENT__ ? window?.location.pathname : null,
  //   config.settings.defaultLanguage,
  // );

  return (
    <div className="SearchBoxForPage">
      <NoSSR>
        <Form action={flattenToAppURL('/search')} className="searchForm">
          <Form.Field>
            <input type="hidden" name="lang" value={locale}></input>
            <Input
              type="text"
              className="searchableText"
              name="q"
              value={searchText}
              onChange={(e, { value }) => setSearchText(value)}
            />
            <button className="searchButton">
              <Icon name="search" />
            </button>
          </Form.Field>
        </Form>
      </NoSSR>
    </div>
  );
};

export default injectIntl(SearchBoxForPage);
