//import cookie from 'react-cookie';
import { Cookies } from 'react-cookie';
import { CookieSetting } from '../../constants/Setting';
import { isJa } from 'helpers/I18N/I18N';
import _ from 'lodash';

/**
 * Cookieに保存されているUserTypeを取得する
 * @returns
 */
export function getUserType() {
  const userType = new Cookies().get(CookieSetting.USER_TYPE.name);
  return userType;
}

export function saveUserType(userTypeId) {
  new Cookies().set(
    CookieSetting.USER_TYPE.name,
    userTypeId,
    CookieSetting.USER_TYPE.option,
  );
}

/**
 * ターゲット名を探す
 * @param {*} targets
 * @param {*} id
 * @param {*} locale
 * @returns
 */
export function findTargetName(targets, id, locale) {
  const result = _.filter(targets, (item) => item?.id === id);
  // console.log({ findTargetName: result });
  return result?.length > 0
    ? isJa(locale)
      ? result[0]?.ja
      : result[0]?.en
    : '-';
}
