/**
 * OuForbiddenView component.
 * @module components/theme/View/OuForbiddenView
 */

import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { SiteHeaderForError } from '@package/components';
import { Helmet } from '@plone/volto/helpers';
import { OuNotFoundInnerView } from './OuNotFoundView';
import './OuNotFoundView.css';

/**
 * Component to display the OuForbiddenView.
 * @function OuForbiddenView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OuForbiddenView = () => {
  return (
    <section className="OuForbiddenView OuNotFoundView">
      <Helmet title="403 Forbidden" />
      <SiteHeaderForError />
      <div className="mainAreaInner">
        <h2 className="title">403 Forbidden</h2>
        <OuNotFoundInnerView />
      </div>
    </section>
  );
};

export default injectIntl(OuForbiddenView);
