import { FOLDER_LIST } from '../../constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

export function folderList(path) {
  return {
    type: FOLDER_LIST,
    request: {
      op: 'get',
      path: `${flattenToAppURL(path)}/@folder-list`,
    },
  };
}
