/**
 * OuNotFoundView component.
 * @module components/theme/View/OuNotFoundView
 */

import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { getLocaleFromPathname } from '../../../helpers/Locale/Locale';
import { SiteHeaderForError } from '@package/components';
import { Helmet } from '@plone/volto/helpers';
import './OuNotFoundView.css';
import config from '@plone/volto/registry';

/**
 * Component to display the OuNotFoundView.
 * @function OuNotFoundView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
export const OuNotFoundInnerView = () => {
  const locale = getLocaleFromPathname(
    __CLIENT__ ? window?.location.pathname : null,
    config.settings.defaultLanguage,
  );
  return (
    <>
      <div className="leadSentence">
        {locale == 'ja'
          ? 'お探しのページは見つかりませんでした'
          : 'The page you requested could not befound.'}
      </div>
      <div className="desc">
        {locale == 'ja'
          ? '大阪大学の公式サイトをご覧いただきありがとうございます。ご不便をおかけして申し訳ございません。再度アドレスをご確認いただき、お間違いがなければ、ご指定のページは削除、変更されたか、現在利用できない可能性があります。お手数ですが、メニューまたはサイト内検索よりお探しください。'
          : 'It may have been moved or deleted, or it may be unavailable at this time. Please access the menu or try performing an on-site search.'}
      </div>
    </>
  );
};

const OuNotFoundView = () => {
  //const bodyClass = __CLIENT__
  //  ? document.querySelector('body').className
  //  : null;
  return (
    <section className="OuNotFoundView">
      <Helmet
        title="404 Not Found"
        //bodyAttributes={{ class: `${bodyClass} not-found-view` }}
      />
      <SiteHeaderForError />
      <div className="mainAreaInner">
        <h2 className="title">404 Not Found</h2>
        <OuNotFoundInnerView />
      </div>
    </section>
  );
};

export default injectIntl(OuNotFoundView);
