/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
// 20230131 will remove
// import filesfolderList from '@package/reducers/filesfolders/filesfolders';
import canBlockPreview from './canBlockPreview/canBlockPreview';
// import onMigrate from './onMigrate/onMigrate';
import onMigrateBlock from './onMigrateBlock/onMigrateBlock';
import previewBlock from './previewBlock/previewBlock';
import siteMainInfo from './siteMainInfo/siteMainInfo';
import recommendList from './recommend/recommend';
import searchResult from './searchResult/searchResult';
// import searchResult from './search/search';
import welcomeList from './welcome/welcome';
import eventList from './eventList/eventList';
import newsList from './newsList/newsList';
import researchList from './researchList/researchList';
import urgentNotification from './urgentNotification/urgentNotification';
import faqFolderList from './faqFolderList/faqFolderList';
import faqList from './faqList/faqList';
import faqSearch from './faqSearch/faqSearch';
import storyZTopFolder from './storyZTopFolder/storyZTopFolder';
import storyZFolder from './storyZFolder/storyZFolder';
import tourItem from './tourItem/tourItem';
import tours from './tours/tours';
import storyZ from './storyZ/storyZ';
import storyZLink from './storyZLink/storyZLink';
import forYou from './forYou/forYou';
import forYouMore from './forYouMore/forYouMore';
import forYouButton from './forYouButton/forYouButton';
import folderList from './folderList/folderList';
import fullReviewList from './fullReviewList/fullReviewList';
import sendMailForm from './sendMailForm/sendMailForm';
import mailFormFormIds from './mailFormFormIds/mailFormFormIds';
import sitemap from './sitemap/sitemap';
import automaticListForOuGrid from './automaticListForOuGrid/automaticListForOuGrid';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  // Add your reducers here
  // filesfolderList,
  canBlockPreview,
  // onMigrate,
  onMigrateBlock,
  previewBlock,
  siteMainInfo: siteMainInfo,
  recommendList,
  searchResult,
  welcomeList,
  eventList: eventList,
  newsList: newsList,
  researchList,
  urgentNotification: urgentNotification,
  faqFolderList: faqFolderList,
  faqList: faqList,
  faqSearch: faqSearch,
  storyZFolder: storyZFolder,
  tourItem,
  tours,
  storyZ: storyZ,
  storyZLink: storyZLink,
  storyZTopFolder: storyZTopFolder,
  forYou: forYou,
  forYouMore: forYouMore,
  forYouButton: forYouButton,
  folderList: folderList,
  fullReviewList: fullReviewList,
  sendMailForm,
  mailFormFormIds,
  sitemap,
  automaticListForOuGrid,
};

export default reducers;
