import _ from 'lodash';
import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import './SearchDisplayElement.css';
import { interval, from, of, BehaviorSubject, Subject } from 'rxjs';
import { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import searchStore from './store/search';
import {
  ChatItemNormalElement,
  ChatItemGreetingElement,
  ChatItemSelectElement,
  ChatItemSelectForYouElement,
  ChatItemListElement,
  ChatItemBannerElement,
  ChatItemMoreButtonElement,
} from '../../../index';
import { connect, useDispatch } from 'react-redux';
import { getSearchResult, resetSearchResult } from '../../../../actions';
import { CHAT_ITEM_TYPE } from '../ChatItemElement/ChatItemElement';
import { ChatItemType } from '../../../../constants/Types';
import { isEn, isJa } from '../../../../helpers/I18N/I18N';
import { POSITION } from '../ChatItemElement/ChatItemElement';
import { doSearch } from '../../../../helpers/Search/Search';
import ChatItemTourElement from '../ChatItemElement/ChatItemTourElement';

/**
 * アイテムサンプル
 * @param {*} param0
 */
export const SearchItem = ({ item, callback }) => {
  // console.log(item?.type);
  // console.log({type: CHAT_ITEM_TYPE.CHAT_ITEM_NORMAL})
  switch (item?.type) {
    case `${CHAT_ITEM_TYPE.CHAT_ITEM_NORMAL}`:
      return <ChatItemNormalElement item={item}></ChatItemNormalElement>;
    case `${CHAT_ITEM_TYPE.CHAT_ITEM_GREETING}`:
      return <ChatItemGreetingElement item={item}></ChatItemGreetingElement>;
    case `${CHAT_ITEM_TYPE.CHAT_ITEM_SELECT}`:
      return <ChatItemSelectElement item={item}></ChatItemSelectElement>;
    case `${CHAT_ITEM_TYPE.CHAT_ITEM_SELECT_FOR_YOU}`:
      return (
        <ChatItemSelectForYouElement
          item={item}
          callback={callback}
        ></ChatItemSelectForYouElement>
      );
    case `${CHAT_ITEM_TYPE.CHAT_ITEM_LIST}`:
      return <ChatItemListElement item={item}></ChatItemListElement>;
    case `${CHAT_ITEM_TYPE.CHAT_ITEM_BANNER}`:
      return <ChatItemBannerElement item={item}></ChatItemBannerElement>;
    case `${CHAT_ITEM_TYPE.CHAT_ITEM_MORE_BUTTON}`:
      return (
        <ChatItemMoreButtonElement
          item={item}
          callback={callback}
        ></ChatItemMoreButtonElement>
      );
    case `${CHAT_ITEM_TYPE.CHAT_ITEM_TOUR}`:
      return <ChatItemTourElement item={item}></ChatItemTourElement>;
    default:
      return <ChatItemNormalElement item={item}></ChatItemNormalElement>;
  }
};

/**
 * Type
 */
SearchItem.propTypes = ChatItemType;

/**
 * チャット表示コンポーネント
 * @param {*} param0
 */
const SearchDisplayElement = ({
  getSearchResult,
  intl,
  parent,
  displayList,
  resetSearchResult,
  history,
  inputValue,
  path,
  locale,
}) => {
  const [chatState, setChatState] = useState(searchStore.initialState);

  /**
   * 表示エリアのノード
   */
  const [node, setNode] = useState(null);

  const dispatch = useDispatch();

  /**
   * chat state更新時の動作
   */
  useEffect(() => {
    if (node) {
      // console.log(parent.scrollHeight);
      // console.log(parent.scrollTop);
      parent.scrollTop = parent.scrollHeight;
    }
  }, [chatState, node, parent]);

  useEffect(() => {
    // getSearchResult()
    // console.log({ displayList });
    // console.log({ display: !!displayList?.length });
    // if (displayList?.length) {
    //   displayList.slice(0, 8).map((item) => {
    //     return searchStore.sendMessage({
    //       id: item.id,
    //       type: item.type,
    //       position: item?.from?.position
    //         ? item.from.position.toLowerCase()
    //         : POSITION.NONE,
    //       icon: {
    //         src: '/assets/images/you_icon@2x.png',
    //         alt: 'ChatItemNormalの自分アイコンのALT',
    //         position: item?.from?.position
    //           ? item.from.position.toLowerCase()
    //           : POSITION.NONE,
    //       },
    //       content: `${item.content} // ${item.id}`,
    //       thumbnail: null,
    //       link: null,
    //     });
    //   });
    //   resetSearchResult();
    // }
  }, [resetSearchResult, displayList]);

  useLayoutEffect(() => {
    if (__CLIENT__) {
      const subs = searchStore.subscribe(setChatState);
      searchStore.init();
      return () => subs.unsubscribe();
    }
  }, []);

  return (
    <main
      className="SearchDisplayElement"
      ref={(node) => {
        // 表示エリア判定用のノード
        setNode(node);
      }}
    >
      {/* <pre>{JSON.stringify(chatState.current)}</pre> */}
      <div className="inner">
        {chatState.data?.map((item) => {
          return (
            <SearchItem
              key={Math.random().toString(32).substring(2)}
              item={item}
              callback={
                item?.type === CHAT_ITEM_TYPE.CHAT_ITEM_MORE_BUTTON
                  ? () => {
                      doSearch(
                        history,
                        dispatch,
                        inputValue,
                        null,
                        path,
                        null,
                        chatState.current.b_start + chatState.current.b_size,
                        locale,
                      );
                    }
                  : null
              }
            ></SearchItem>
          );
        })}
      </div>
    </main>
  );
};

const SearchDisplayElementContainer = connect(
  (state, props) => {
    return {
      displayList: state.searchResult.items,
    };
  },
  (dispatch, props) => {
    return {
      getSearchResult: (locale) => dispatch(getSearchResult(locale)),
      resetSearchResult: () => dispatch(resetSearchResult()),
    };
  },
)(SearchDisplayElement);

export default injectIntl(SearchDisplayElementContainer, {});

// -------------------------------
/**
 * TODO: 以下テスト用メッセージ
 */
// searchStore.sendMessage({
//   id: 'X123456',
//   type: 'ChatItemNormal',
//   position: 'right',
//   icon: {
//     src: '/assets/images/you_icon@2x.png',
//     alt: 'ChatItemNormalの自分アイコンのALT',
//     position: 'right',
//   },
//   content: isJa(intl?.locale) ? `XXXについて知りたい` : `Hello`,
//   thumbnail: null,
//   link: null,
// });
// searchStore.sendMessage({
//   id: 'X123456',
//   type: 'ChatItemNormal',
//   position: 'left',
//   icon: {
//     src: '/assets/images/wani_icon@2x.png',
//     alt: 'ChatItemNormalの自分アイコンのALT',
//     position: 'left',
//   },
//   content: isJa(intl?.locale)
//     ? `\"XXX\"に関係するページは100件です。あなたへのお勧め順に表示します。`
//     : `Hello2`,
//   thumbnail: null,
//   link: null,
// });
// searchStore.sendMessage({
//   id: 'X123456',
//   type: 'ChatItemNormal',
//   position: 'none',
//   icon: {
//     src: '/assets/images/wani_icon@2x.png',
//     alt: 'ChatItemNormalの自分アイコンのALT',
//     position: 'none',
//   },
//   content: isJa(intl?.locale)
//     ? `検索結果の表示（ここはリストコンポーネントになります）`
//     : `Hello3`,
//   thumbnail: null,
//   link: null,
// });
// searchStore.sendMessage({
//   id: 'X123456',
//   type: 'ChatItemNormal',
//   position: 'none',
//   icon: {
//     src: '/assets/images/wani_icon@2x.png',
//     alt: 'ChatItemNormalの自分アイコンのALT',
//     position: 'none',
//   },
//   content: isJa(intl?.locale)
//     ? `検索結果の表示１（ここはリストコンポーネントになります）`
//     : `Hello4`,
//   thumbnail: null,
//   link: null,
// });
// searchStore.sendMessage({
//   id: 'X123456',
//   type: 'ChatItemNormal',
//   position: 'none',
//   icon: {
//     src: '/assets/images/wani_icon@2x.png',
//     alt: 'ChatItemNormalの自分アイコンのALT',
//     position: 'none',
//   },
//   content: isJa(intl?.locale)
//     ? `検索結果の表示その２（ここはリストコンポーネントになります）`
//     : `Hello5`,
//   thumbnail: null,
//   link: null,
// });
// searchStore.sendMessage({
//   id: 'X123456',
//   type: 'ChatItemNormal',
//   position: 'left',
//   icon: {
//     src: '/assets/images/wani_icon@2x.png',
//     alt: 'ChatItemNormalの自分アイコンのALT',
//     position: 'left',
//   },
//   content: isJa(intl?.locale)
//     ? `さらにおすすめのコンテンツを表示します`
//     : `Hello`,
//   thumbnail: null,
//   link: null,
// });
// searchStore.sendMessage({
//   id: 'X123456',
//   type: 'ChatItemNormal',
//   position: 'none',
//   icon: {
//     src: '/assets/images/wani_icon@2x.png',
//     alt: 'ChatItemNormalの自分アイコンのALT',
//     position: 'none',
//   },
//   content: isJa(intl?.locale)
//     ? `検索結果の表示その３（ここはリストコンポーネントになります）`
//     : `Hello2`,
//   thumbnail: null,
//   link: null,
// });
// searchStore.sendMessage({
//   id: 'X123456',
//   type: 'ChatItemNormal',
//   position: 'none',
//   icon: {
//     src: '/assets/images/wani_icon@2x.png',
//     alt: 'ChatItemNormalの自分アイコンのALT',
//     position: 'none',
//   },
//   content: isJa(intl?.locale)
//     ? `検索結果の表示その４（ここはリストコンポーネントになります）`
//     : `Hello3`,
//   thumbnail: null,
//   link: null,
// });
// searchStore.sendMessage({
//   id: 'X123456',
//   type: 'ChatItemNormal',
//   position: 'left',
//   icon: {
//     src: '/assets/images/wani_icon@2x.png',
//     alt: 'ChatItemNormalの自分アイコンのALT',
//     position: 'left',
//   },
//   content: isJa(intl?.locale)
//     ? `さらにおすすめのコンテンツを表示します`
//     : `Hello4`,
//   thumbnail: null,
//   link: null,
// });
// searchStore.sendMessage({
//   id: 'X123456',
//   type: 'ChatItemNormal',
//   position: 'none',
//   icon: {
//     src: '/assets/images/wani_icon@2x.png',
//     alt: 'ChatItemNormalの自分アイコンのALT',
//     position: 'none',
//   },
//   content: isJa(intl?.locale)
//     ? `検索結果の表示その５（ここはリストコンポーネントになります）`
//     : `Hello5`,
//   thumbnail: null,
//   link: null,
// });
// searchStore.sendMessage({
//   id: 'X123456',
//   type: 'ChatItemNormal',
//   position: 'none',
//   icon: {
//     src: '/assets/images/wani_icon@2x.png',
//     alt: 'ChatItemNormalの自分アイコンのALT',
//     position: 'none',
//   },
//   content: isJa(intl?.locale)
//     ? `検索結果の表示その６（ここはリストコンポーネントになります）`
//     : `Hello5`,
//   thumbnail: null,
//   link: null,
// });
// searchStore.sendMessage({
//   id: 'X123456',
//   type: 'ChatItemNormal',
//   position: 'left',
//   icon: {
//     src: '/assets/images/wani_icon@2x.png',
//     alt: 'ChatItemNormalの自分アイコンのALT',
//     position: 'left',
//   },
//   content: isJa(intl?.locale)
//     ? `検索結果は以上です。あなたと同じ設定の方がよく見られているページを紹介します。`
//     : `Hello5`,
//   thumbnail: null,
//   link: null,
// });
// searchStore.sendMessage({
//   id: 'X123456',
//   type: 'ChatItemNormal',
//   position: 'none',
//   icon: {
//     src: '/assets/images/wani_icon@2x.png',
//     alt: 'ChatItemNormalの自分アイコンのALT',
//     position: 'none',
//   },
//   content: isJa(intl?.locale) ? `よく見られているページその１` : `Hello5`,
//   thumbnail: null,
//   link: null,
// });
// searchStore.sendMessage({
//   id: 'X123456',
//   type: 'ChatItemNormal',
//   position: 'none',
//   icon: {
//     src: '/assets/images/wani_icon@2x.png',
//     alt: 'ChatItemNormalの自分アイコンのALT',
//     position: 'none',
//   },
//   content: isJa(intl?.locale) ? `よく見られているページその２` : `Hello5`,
//   thumbnail: null,
//   link: null,
// });
