/**
 * View text block.
 * based on v13.8.2
 * updated for v15.16.0 @2023/02/16 nk24@CMScom
 * @module components/manage/Blocks/Text/View
 */

import PropTypes from 'prop-types';
import redraft from 'redraft';
import React from 'react';
import config from '@plone/volto/registry';
/* @nk24 begin */
import './View.css';
/* @nk24 end */

/**
 * View text block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) =>
  data.text ? (
    /* @nk24 begin */
    <div className="Text">
      {redraft(
        data.text,
        // config.settings.ToHTMLRenderers,
        // config.settings.ToHTMLOptions,
        config.settings.richtextViewSettings.ToHTMLRenderers,
        config.settings.richtextViewSettings.ToHTMLOptions,
      )}
    </div>
  ) : (
    /* @nk24 end */
    <br />
  );

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
