/**
 * Welcome Component
 */

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from 'semantic-ui-react';
import { MediaQueryConfig } from '../../../../helpers/GlobalStyle/GlobalStyle';
import './WelcomeElement.css';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';

//　Chat表示コンポーネント関連
import WelcomeDisplayElement from './WelcomeDisplayElement';
//import cookie from 'react-cookie';
import { Cookies } from 'react-cookie';
import { CookieSetting } from 'constants/Setting';
import { displayWelcome } from 'actions';
import { stringToBoolean } from 'helpers/Cookie/Cookie';
import qs from 'query-string';
import config from '@plone/volto/registry';
import { getLocaleFromPathname } from 'helpers/Locale/Locale';

// const observable = new Subject();

function isVisible(visible) {
  return stringToBoolean(visible);
}

const WelcomeElement = ({
  displayWelcome,
  intl,
  visible,
  locale,
  defaultVisible,
}) => {
  useEffect(() => {
    const visible = new Cookies().get(CookieSetting.WELCOME_VISIBLE.name);
    // setVisible(visible);
    displayWelcome(visible);
  }, [displayWelcome]);

  return (
    <NoSSR>
      {isVisible(visible) || defaultVisible ? (
        <div className="WelcomeElement">
          <div className="outer">
            <div className="inner">
              {__CLIENT__ ? (
                <div className="body">
                  <WelcomeDisplayElement
                    locale={locale}
                  ></WelcomeDisplayElement>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </NoSSR>
  );
};

WelcomeElement.propTypes = {
  displayWelcome: PropTypes.func,
  intl: PropTypes.object,
  visible: PropTypes.string,
};

const WelcomeElementContainer = connect(
  (state, props) => {
    // const locale = state?.content?.data?.language?.token ?? 'ja';
    const locale =
      qs.parse(props?.location?.search).lang ||
      // new Cookies('I18N_LANGUAGE') ||
      // config.settings.defaultLanguage;
      getLocaleFromPathname(
        __CLIENT__ ? window?.location.pathname : null,
        config.settings.defaultLanguage,
      );
    return {
      visible: state?.welcomeList?.visible,
      locale,
    };
  },
  (dispatch, props) => {
    return {
      displayWelcome: (visible) => dispatch(displayWelcome(visible)),
    };
  },
)(WelcomeElement);

export default injectIntl(WelcomeElementContainer, {});
