/**
 * Search reducer.
 * @module reducers/searchResult/searchResult
 */

import { map, omit } from 'lodash';
// import { settings } from '~/config';
import config from '@plone/volto/registry';

import _ from 'lodash';
import {
  RESET_SEARCH_RESULT_CONTENT,
  SEARCH_RESULT_CONTENT,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  items: null,
  total: 0,
  loaded: false,
  loading: false,
  batching: {},
  subrequests: {},
  b_start: 0,
  b_size: 0,
  has_next: true,
};

/**
 * Search reducer.
 * @function search
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function searchResult(state = initialState, action = {}) {
  switch (action.type) {
    case `${SEARCH_RESULT_CONTENT}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${SEARCH_RESULT_CONTENT}_SUCCESS`:
      return {
        ...state,
        error: null,
        items: _.concat(
          state.items ?? [],
          map(action.result.data.attributes.display, (item) => ({
            ...item,
            '@id': item['id'],
          })),
        ),
        total: action.result.items_total,
        loaded: true,
        loading: false,
        batching: { ...action.result.batching },
        b_start: action.result.b_start,
        b_size: action.result.b_size,
        has_next: action.result.has_next,
      };
    case `${SEARCH_RESULT_CONTENT}_FAIL`:
      return {
        ...state,
        error: action.error,
        items: [],
        total: 0,
        loading: false,
        loaded: false,
        batching: {},
      };
    case RESET_SEARCH_RESULT_CONTENT:
      return {
        ...state,
        error: null,
        items: [],
        total: 0,
        loading: false,
        loaded: false,
        batching: {},
      };
    default:
      return state;
  }
}
