/**
 * ObjectBrowserWidget component for tour (sortable).
 * @module components/manage/Widgets/SortableObjectBrowserWidget
 * based on components/manage/Widgets/ObjectBrowserWidget / v12.0.0
 * nk24@cmscom 2021/06/19
 */

import move from 'lodash-move';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { remove } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Label, Popup, Button } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';
import { defineMessages, injectIntl } from 'react-intl';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import FormFieldWrapper from '@plone/volto/components/manage/Widgets/FormFieldWrapper';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import homeSVG from '@plone/volto/icons/home.svg';
import './SortableObjectBrowserWidget.css';

const messages = defineMessages({
  placeholder: {
    id: 'No items selected',
    defaultMessage: 'No items selected',
  },
  edit: {
    id: 'Edit',
    defaultMessage: 'Edit',
  },
  delete: {
    id: 'Delete',
    defaultMessage: 'Delete',
  },
});

/**
 * Sortable ObjectBrowserWidget component class.
 * @class SortablelObjectBrowserWidget
 * @extends Component
 */
class SortableObjectBrowserWidget extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    mode: PropTypes.string, //link,image,multiple
    required: PropTypes.bool,
    error: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.object,
    ]),
    onChange: PropTypes.func.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    description: null,
    required: false,
    error: [],
    value: [],
    mode: 'multiple',
  };

  constructor(props) {
    super(props);
    this.selectedItemsRef = React.createRef();
    this.placeholderRef = React.createRef();
    this.state = { items: props.value };
    console.log(this.state);
  }
  renderLabel(item, index) {
    return (
      <Draggable draggableId={item.UID} index={index} key={item.UID}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={provided.draggableProps.style}
          >
            <Popup
              key={flattenToAppURL(item['@id'])}
              content={
                <>
                  <Icon name={homeSVG} size="18px" />
                  {flattenToAppURL(item['@id'])}
                </>
              }
              trigger={
                <Label>
                  {item.title}
                  {this.props.mode === 'multiple' && (
                    <Icon
                      name={clearSVG}
                      size="12px"
                      className="right"
                      onClick={(event) => {
                        event.preventDefault();
                        this.removeItem(item);
                      }}
                    />
                  )}
                </Label>
              }
            />
          </div>
        )}
      </Draggable>
    );
  }

  removeItem = (item) => {
    let value = [...this.props.value];
    remove(value, function (_item) {
      return _item['@id'] === item['@id'];
    });
    this.props.onChange(this.props.id, value);
  };

  onChange = (item) => {
    // item: ObjectBrowserで選択したアイテム
    let value = this.props.mode === 'multiple' ? [...this.props.value] : [];
    // value: 保存済みのリスト
    value = value.filter((item) => item != null);
    const maxSize =
      this.props.widgetOptions?.pattern_options?.maximumSelectionSize || -1;
    if (maxSize === 1 && value.length === 1) {
      value = []; //enable replace of selected item with another value, if maxsize is 1
    }
    let exists = false;
    let index = -1;
    value.forEach((_item, _index) => {
      if (flattenToAppURL(_item['@id']) === flattenToAppURL(item['@id'])) {
        exists = true;
        index = _index;
      }
    });
    if (exists) {
      //remove item first
      value.splice(index, 1);
      this.props.onChange(this.props.id, value);
      this.setState({ items: value });
    }
    // add item
    // Check if we want to filter the attributes of the selected item
    let resultantItem = item;

    const allowedItemKeys = ['title', 'UID'];
    resultantItem = Object.keys(item)
      .filter((key) => allowedItemKeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = item[key];
        return obj;
      }, {});
    // Add required @id field, just in case
    resultantItem = { ...resultantItem, '@id': item['@id'] };
    value.push(resultantItem);
    this.props.onChange(this.props.id, value);
    this.setState({ items: value });
  };

  showObjectBrowser = (ev) => {
    ev.preventDefault();
    this.props.openObjectBrowser({
      mode: this.props.mode,
      onSelectItem: (url, item) => {
        this.onChange(item);
      },
      propDataName: 'value',
      selectableTypes: this.props.widgetOptions?.pattern_options
        ?.selectableTypes,
      maximumSelectionSize: this.props.widgetOptions?.pattern_options
        ?.maximumSelectionSize,
    });
  };

  handleSelectedItemsRefClick = (e) => {
    if (this.props.isDisabled) {
      return;
    }

    if (
      e.target.contains(this.selectedItemsRef.current) ||
      e.target.contains(this.placeholderRef.current)
    ) {
      this.showObjectBrowser(e);
    }
  };

  onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const newItems = move(this.state.items, source.index, destination.index);
    this.setState({ items: newItems });
    this.props.onChange(this.props.id, newItems);
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { id, description, value, mode, onChange, isDisabled } = this.props;

    let icon =
      mode === 'multiple' || value.length === 0 ? navTreeSVG : clearSVG;
    let iconAction =
      mode === 'multiple' || value.length === 0
        ? this.showObjectBrowser
        : (e) => {
            e.preventDefault();
            onChange(id, []);
          };

    let items = value ? value.filter((item) => item != null) : [];

    return (
      <FormFieldWrapper
        {...this.props}
        className={description ? 'help text' : 'text'}
      >
        <div className="objectbrowser-field TourObjectBrowserWidget">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="tour-refers">
              {(provided, snapshot) => (
                <div
                  className="selected-values"
                  onClick={this.handleSelectedItemsRefClick}
                  onKeyDown={this.handleSelectedItemsRefClick}
                  role="searchbox"
                  tabIndex={0}
                  // ref={this.selectedItemsRef}
                >
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {items.map((item, index) => this.renderLabel(item, index))}
                    {provided.placeholder}
                    {/* {items.length === 0 && (
                      <div className="placeholder" ref={this.placeholderRef}>
                        {this.props.intl.formatMessage(messages.placeholder)}
                      </div>
                    )} */}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Button onClick={iconAction} className="action" disabled={isDisabled}>
            <Icon name={icon} size="18px" />
          </Button>
        </div>
      </FormFieldWrapper>
    );
  }
}

const SortableObjectBrowserWidgetMode = (mode) =>
  compose(
    injectIntl,
    withObjectBrowser,
  )((props) => <SortableObjectBrowserWidget {...props} mode={mode} />);
export { SortableObjectBrowserWidgetMode };
export default compose(
  injectIntl,
  withObjectBrowser,
)(SortableObjectBrowserWidget);
