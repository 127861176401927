/**
 * View textarea form block for MailForm.
 * @module components/manage/Blocks/MailForm/TextAreaBlock/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Form, TextArea } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import './View.css';

/**
 * View definition list block functional component.
 * @function View
 * @param {Object} data property data
 * @parma {}
 * @returns {string} Markup of the component.
 */
const View = ({ id, data, formData, handleInputData, handleBlur }) => {
  const errorMsg = formData?.errorMsg;
  const formId = formData?.formId;
  const placeholder = formData?.placeholder;
  const required = formData?.required;
  const value = formData?.value;
  return (
    <Form.Field>
      <label>
        {data.label}
        <span className="RequiredMark">{required ? '*' : ''}</span>
        <span className="ErrorMessage">{errorMsg}</span>
      </label>
      <TextArea
        id={formId}
        className="StyledTextArea"
        name={formId}
        placeholder={placeholder}
        value={value}
        onChange={(e, target) => {
          formData.value = target.value;
          handleInputData(formData);
        }}
        onBlur={() => {
          handleBlur(formData);
        }}
      />
    </Form.Field>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  id: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  formData: PropTypes.any,
  handleInputData: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export default injectIntl(View);
