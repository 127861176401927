/**
 * Feature block sidebar.
 * @module components/manage/Blocks/Feature/Sidebar
 * @nk24:CMScom created 2021/03/19
 */

import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import Schema from './Schema';

const messages = defineMessages({
  toppage_item: {
    id: 'Toppage Item',
    defaultMessage: 'Toppage Item',
  },
});

const Sidebar = (props) => {
  const _ = props.intl.formatMessage;
  const type = props.data['@type'];
  const content = props.data?.[type];
  if (!content) {
    return null;
  }
  // const init_schema_data = props.data?.[type] || {
  //   text_color: false,
  //   image: [],
  //   background_image_PC: [],
  //   background_image_SP: [],
  //   link: [],
  //   target: {},
  // };
  return (
    <InlineForm
      schema={Schema}
      title={_(messages.toppage_item)}
      onChangeField={(id, value) => {
        props.onChangeBlock(props.block, {
          ...props.data,
          [type]: { ...props.data[type], [id]: value },
        });
      }}
      // formData={props.data?.[type] ?? init_schema_data}
      formData={{
        invert_text_color: content.invert_text_color || false,
        image: content.image || [],
        background_image_PC: content.background_image_PC || [],
        background_image_SP: content.background_image_SP || [],
        link_path: content.link_path || [],
        targets: content.targets || [],
      }}
    />
  );
};

export default injectIntl(Sidebar);
