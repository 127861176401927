/**
 * View image container block for OuGrid2.
 * @module components/manage/Blocks/ImageForOuGrid2/View
 * based on v13.8.2?
 */

import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import ViewVideoBlock from '@package/components/manage/Blocks/OuGrid2/InnerBlocks/Video/View';
import ViewLinkListBlock from '@package/components/manage/Blocks/OuGrid2/InnerBlocks/LinkList/View';
import ViewPlainTextBlock from '@package/components/manage/Blocks/OuGrid2/InnerBlocks/PlainText/View';
import './View.css';

const View = ({ data }) => {
  return (
    <div className="VideoForOuGrid2">
      <div className="mediaBox">
        <div className="mediaBoxInner">
          <ViewVideoBlock data={data} />
        </div>
      </div>
      <div className="textBox">
        {data.OuGrid2Label ? (
          <ViewPlainTextBlock className="label">
            {data?.OuGrid2Label}
          </ViewPlainTextBlock>
        ) : null}
        {data.OuGrid2Title ? (
          <ViewPlainTextBlock
            className="nameOrHeader headline"
            tagName="header-two"
          >
            {data?.OuGrid2Title}
          </ViewPlainTextBlock>
        ) : null}
        {data?.separator ? <hr className="divider"></hr> : null}
        {data.OuGrid2SubTitle ? (
          <ViewPlainTextBlock className="description">
            {data?.OuGrid2SubTitle}
          </ViewPlainTextBlock>
        ) : null}
        {data.OuGrid2Description ? (
          <ViewPlainTextBlock className="body">
            {data?.OuGrid2Description}
          </ViewPlainTextBlock>
        ) : null}
        <ViewLinkListBlock data={data?.ouLinkList} />
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(View);
