/**
 * SiteFooter component.
 * @module components/theme/Site/SiteFooter/SiteFooter
 */

import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import './SiteFooter.css';
//import cookie from 'react-cookie';
import { Cookies } from 'react-cookie';
import { Icon as IconComponent } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { getLocaleFromPathname } from 'helpers/Locale/Locale';
import xSVG from '~/icons/x.svg';

/**
 * Component to display the SiteFooter.
 * @function SiteFooter
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const SiteFooter = ({ footer }) => {
  return (
    <footer className="SiteFooter">
      <div className="siteFooter">
        <ul className="snsList items">
          {_.map(footer?.sns, (item) => {
            return (
              <li className="item" key={item?.url}>
                <a href={flattenToAppURL(item?.url ?? '')} target="_blank">
                  {item?.icon === 'twitter' ? (
                    <IconComponent name={xSVG} size="18px" />
                  ) : (
                    <Icon name={item?.icon} />
                  )}
                </a>
              </li>
            );
          })}
        </ul>
        <div className="univInfo">
          <div className="univInfoInner">
            <div className="logo">
              <img
                alt={footer?.logo?.alt}
                src={flattenToAppURL(footer?.logo?.src ?? '')}
              />
            </div>
            <div className="universityName">{footer?.university_name}</div>
            <address className="address">{footer?.address}</address>
          </div>
          <div className="univInfoSecondInner">
            <div className="links">
              <ul className="linkList">
                {_.map(footer?.links, (item) => {
                  return (
                    <li className="item" key={item?.url}>
                      <Link to={flattenToAppURL(item?.url)}>{item?.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <small className="copyright">{footer?.copyright}</small>
          </div>
        </div>
      </div>
    </footer>
  );
};

const SiteFooterContainer = connect(
  (state, props) => {
    // const locale = state?.content?.data?.language?.token ?? 'ja';
    // const locale = new Cookies().get('I18N_LANGUAGE') || config.settings.defaultLanguage;
    const locale = getLocaleFromPathname(
      __CLIENT__ ? window?.location.pathname : null,
      config.settings.defaultLanguage,
    );
    return {
      footer:
        state?.siteMainInfo?.result?.data?.attributes?.locale?.[locale]?.footer,
    };
  },
  (dispatch, props) => {
    return {};
  },
)(SiteFooter);

export default injectIntl(SiteFooterContainer);
