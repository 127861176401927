/**
 * FileTypeIcon component.
 * @module components/theme/Element/FileTypeIcon/FileTypeIcon
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import './FileTypeIcon.css';

/**
 * Component to display the FileTypeIcon
 * @function FileTypeIcon
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const FileTypeIcon = ({ fileType = null }) => {
  return (
    fileType !== null && (
      <div className="FileTypeIcon">
        <div className={`fileTypeIcon ${fileType}`}>
          .{fileType.toUpperCase()}
        </div>
      </div>
    )
  );
};

export default injectIntl(FileTypeIcon);
