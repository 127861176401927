/**
 * Dispatch to be rendered by serverside or client.
 */

import { useEffect, useState } from 'react';
export default function IsClient() {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  return isClient;
}
