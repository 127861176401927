/**
 * OuFolderView component.
 * @module components/theme/View/OuFolderView
 */

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FolderItems } from './OuCategoryFolderView';
import { HelmetSite, OuBody, PageTitle } from '../../index';
import { folderList as folderListAction } from '../../../actions/folderList/folderList';
import './OuFolderView.css';

/**
 * Component to display the OuFolderView.
 * @function OuFolderView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OuFolderView = ({ content }) => {
  const [items] = useSelector((state) => {
    return [state?.folderList?.result?.data?.attributes?.items ?? []];
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(folderListAction(_.get(content, '@id')));
  }, [content]);
  const headerImageSrc = flattenToAppURL(content.header_image?.download ?? '');
  return (
    <div className="OuFolderView">
      <HelmetSite content={content} />
      <section className="mainArea">
        <div className="mainAreaInner">
          {headerImageSrc && (
            <div
              className="headerImage"
              style={{ backgroundImage: `url(${headerImageSrc})` }}
            ></div>
          )}
          <div
            className={`mainAreaInnerInner ${
              content.hide_list ? 'hideList' : ''
            }`}
          >
            <div className="parentTitle arrow">
              <Link to={flattenToAppURL(content.parent['@id'])}>
                {content.parent.title}
              </Link>
            </div>
            <div className="infoBox">
              <PageTitle title={content.title} />
              {content.lead_sentence && (
                <div className="leadSentence">{content.lead_sentence}</div>
              )}
            </div>
            {!content.hide_list && (
              <FolderItems hasDate={content.has_date} items={items} />
            )}
            <OuBody content={content} />
          </div>
        </div>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuFolderView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.required,
    lead_sentence: PropTypes.string,
    hide_list: PropTypes.bool,
  }).isRequired,
};

export default injectIntl(OuFolderView);
