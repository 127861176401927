import _ from 'lodash';
import { FAQ_LIST } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  result: {},
};

/**
 * UrgentNotification Reducer
 * @param {*} state
 * @param {*} action
 */
export default function faqList(state = initialState, action = {}) {
  switch (action.type) {
    case `${FAQ_LIST}_PENDING`:
      return {
        ...state,
        error: null, // state.error = null
        loaded: false, // state.loaded = false
        loading: true, // state.loading = true
      };
    case `${FAQ_LIST}_SUCCESS`:
      let faqList;
      if (action.bStart === 0) {
        faqList = action?.result?.data?.attributes?.items;
      } else {
        faqList = _.concat(
          state.faqList ?? [],
          action?.result?.data?.attributes?.items,
        );
      }
      return {
        ...state,
        error: null,
        result: action?.result,
        faqList: faqList,
        loaded: true,
        loading: false,
      };
    case `${FAQ_LIST}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
