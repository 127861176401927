/**
 * RichTextEditor.jsx
 * @module constants/RichTextEditor
 * @nk24 2021-04-14
 * WYSIWYGEditor周りのカスタマイズモジュール
 */

import React from 'react';
import createInlineStyleButton from 'draft-js-buttons/lib/utils/createInlineStyleButton';
import createBlockStyleButton from 'draft-js-buttons/lib/utils/createBlockStyleButton';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import superindexSVG from '@plone/volto/icons/superindex.svg';
import subindexSVG from '@plone/volto/icons/subindex.svg';
import textSVG from '@plone/volto/icons/text.svg';
import alignLeftSVG from '@plone/volto/icons/align-left.svg';
import alignCenterSVG from '@plone/volto/icons/align-center.svg';
import alignRightSVG from '@plone/volto/icons/align-right.svg';

const SuperIndexButton = createInlineStyleButton({
  style: 'SUPERSCRIPT',
  children: <Icon name={superindexSVG} size="24px" />,
});
const SubIndexButton = createInlineStyleButton({
  style: 'SUBSCRIPT',
  children: <Icon name={subindexSVG} size="24px" />,
});
const HighlightButton = createInlineStyleButton({
  style: 'HIGHLIGHT',
  children: <Icon name={textSVG} size="24px" color="#E80000" />,
});
const AlignLeftButton = createBlockStyleButton({
  blockType: 'alignleft',
  children: <Icon name={alignLeftSVG} size="24px" />,
});
const AlignCenterButton = createBlockStyleButton({
  blockType: 'aligncenter',
  children: <Icon name={alignCenterSVG} size="24px" />,
});
const AlignRightButton = createBlockStyleButton({
  blockType: 'alignright',
  children: <Icon name={alignRightSVG} size="24px" />,
});
export const inlineToolbarButtons = [
  SuperIndexButton,
  SubIndexButton,
  HighlightButton,
];
export const inlineToolbarBlockButtons = [
  AlignLeftButton,
  AlignCenterButton,
  AlignRightButton,
];
