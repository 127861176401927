/**
 * LanguageRootFolderView component.
 * @module components/theme/View/LanguageRootFolderView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { OuBody } from '../../index';
import { HelmetSite, UrgentNotification, WelcomeElement } from '../../index';
import './LanguageRootFolderView.css';

/**
 * Component to display the default view.
 * @function DefaultView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const LanguageRootFolderView = ({ content }) => {
  return (
    <div className="LanguageRootFolderView">
      <HelmetSite content={content} />
      {/* Chat風UIのテスト用 */}
      <WelcomeElement />
      <UrgentNotification />
      <OuBody content={content} />
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
LanguageRootFolderView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default injectIntl(LanguageRootFolderView);
