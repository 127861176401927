import PropTypes from 'prop-types';

export const ChatItemType = {
  item: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    from: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      position: PropTypes.string,
    }),
    icon: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
    content: PropTypes.string,
    thumbnail: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      caption: PropTypes.string,
    }),
    link: PropTypes.string,
    meta: PropTypes.shape({
      message: PropTypes.string,
    }),
  }),
};
