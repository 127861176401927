/**
 * Search component.
 * @module components/theme/Search/Search
 */

import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { updateIntl } from 'react-intl-redux';
import { Link } from 'react-router-dom';
// import { asyncConnect } from 'redux-connect';
import { asyncConnect } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import { Container, Pagination, Button, Header } from 'semantic-ui-react';
import qs from 'query-string';
import classNames from 'classnames';

// import { settings } from '~/config';
import config from '@plone/volto/registry';
import { Helmet } from '@plone/volto/helpers';
import { searchContent } from '@plone/volto/actions';
import { SearchTags, Toolbar, Icon } from '@plone/volto/components';

import paginationLeftSVG from '@plone/volto/icons/left-key.svg';
import paginationRightSVG from '@plone/volto/icons/right-key.svg';

// @kara_d begin

import { getSearchResult, resetSearchResult } from 'actions';
import { Locale } from '~/helpers';
import NoSSR from 'react-no-ssr';
import { SerachElement } from '~/components';
import './Search.css';
import cookie from 'react-cookie';
import { getLocaleFromPathname } from 'helpers/Locale/Locale';
import { Cookies } from 'react-cookie';

let locales = {};

if (config.settings) {
  config.settings.supportedLanguages.forEach((lang) => {
    import('../../../../../locales/' + lang + '.json').then((locale) => {
      locales = { ...locales, [lang]: locale.default };
    });
  });
}

/**
 * 検索オプションの作成
 * @param {*} q
 * @param {*} subject
 * @param {*} path
 * @param {*} b_start
 * @param {*} locale
 * @returns
 */
export const toSearchOptions = (q, subject, path, b_start, locale) => {
  return {
    ...(q && { q: q }),
    ...(subject && {
      Subject: subject,
    }),
    ...(path && {
      path: path,
    }),
    ...(!isNaN(b_start) && {
      b_start: b_start,
    }),
    ...(locale && {
      lang: locale,
    }),
  };
};

/**
 * ダミーデータ(API実装までの間)
 */
const dummy_data = {
  id: 'search',
  data: {
    attributes: {
      total: 111,
      b_start: 1,
      b_size: 1,
      has_next: true,
      display: [
        // TODO: 冒頭では、検索ワードが表示
        // TODO: 検索結果をクエリーストリングから表示する
        // {
        //   id: 'X123450',
        //   type: 'ChatItemNormal',
        //   position: 'right',
        //   icon: {
        //     src: '/assets/images/you_icon@2x.png',
        //     alt: 'ChatItemNormalの自分アイコンのALT',
        //   },
        //   content: '(後々除去) 入試について知りたい（本当は埋め込みになる）',
        //   thumbnail: null,
        //   link: null,
        // },
        // TODO: 検索結果についての概要を表示
        // TODO: totalから出力
        // {
        //   id: 'X123451',
        //   type: 'ChatItemNormal',
        //   position: 'left',
        //   icon: {
        //     src: '/assets/images/icon_ou@2x.png',
        //     alt: 'ChatItemNormalのアイコンのALT',
        //   },
        //   content:
        //     '”入試”に関係するページは100件です。あなたへのお勧め順に表示します。',
        //   thumbnail: null,
        //   link: null,
        // },
        // TODO: 検索結果の表示
        // TODO: Response部分から取得JSONの作成
        {
          '': '（検索結果リスト）',
          flag: true,
        },
        // TODO: 検索結果表示の完了
        // TODO: リスト表示後にメッセージを表示
        // {
        //   id: 'X123451',
        //   type: 'ChatItemNormal',
        //   position: 'left',
        //   icon: {
        //     src: '/assets/images/icon_ou@2x.png',
        //     alt: 'ChatItemNormalのアイコンのALT',
        //   },
        //   content:
        //     '検索結果は以上です。あなたと同じ設定の方がよく見られているページを紹介します。',
        //   thumbnail: null,
        //   link: null,
        // },
        // TODO: おススメ情報の表示
        // TODO: Response部分からJSONの作成
        {
          '': '（リスト）',
          flag: true,
        },
        // TODO: 続きの検索結果の表示用ボタン
        // TODO: 検索結果表示の後表示
        // {
        //   '': '（Moreボタン）',
        // },
        // TODO: 検索結果の続きを表示する
        // TODO: Moreボタンを押したら表示
        // {
        //   id: 'X123451',
        //   type: 'ChatItemNormal',
        //   position: 'left',
        //   icon: {
        //     src: '/assets/images/icon_ou@2x.png',
        //     alt: 'ChatItemNormalのアイコンのALT',
        //   },
        //   content: 'さらにお勧めのコンテンツを表示します',
        //   thumbnail: null,
        //   link: null,
        // },
      ],
      recommend: [
        // TODO: レコメンドの内容を表示
        // TODO: 一旦検証の必要あり
      ],
    },
  },
};

/**
 * 検索結果ChatItemの表示
 * @param {*} props
 * @returns
 */
export const displaySearchResult = (props) => {
  return <div></div>;
};

/**
 * 検索結果完了ChatItemの表示
 * @param {*} props
 * @returns
 */
export const displayCompleteSearchResult = (props) => {
  return <div></div>;
};

/**
 * おススメの検索結果ChatItemの表示
 * @param {*} props
 * @returns
 */
export const displayRecommendSearchResult = (props) => {
  return <div></div>;
};

/**
 * MoreボタンChatItemの表示
 * @param {*} props
 * @returns
 */
export const displayMoreButton = (props) => {
  return <div></div>;
};

/**
 * 続きの検索結果を表示するメッセージChatItemの表示
 * @param {*} props
 * @returns
 */
export const displayMoreMessageButton = (props) => {
  return <div></div>;
};

// -------------------------------

/**
 * 検索エンジンコンポーネント
 * @param {*}
 * @returns
 */
const Search = ({
  items = [],
  q = null,
  subject = null,
  pathname = null,
  locale,
  total,
  b_start,
  b_size,
  has_next,
  location,
  history,
  lang,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getSearchResult();
    // console.log({
    //   lang: qs.parse(location.search).lang,
    //   locale: new Cookies().get('I18N_LANGUAGE'),
    // });
    if (lang !== locale) {
      new Cookies().set('I18N_LANGUAGE', lang, {
        expires: new Date((2 ** 31 - 1) * 1000),
        path: '/',
      });
      dispatch(
        updateIntl({
          locale: lang,
          messages: locales[lang],
        }),
      );
    }
  }, [lang, dispatch, location]);

  return (
    <div id="page-search" className="Search">
      <Helmet title="Search" />
      <NoSSR>
        <div className="container">
          {/* TODO: ここにチャットアイテムを表示させる */}
          {/* TODO: APIから取得したらRxJSステートに投げる */}
          {__CLIENT__ ? (
            <SerachElement
              location={location}
              history={history}
            ></SerachElement>
          ) : null}
        </div>
      </NoSSR>
      <Portal node={__CLIENT__ && document.getElementById('toolbar')}>
        <Toolbar pathname={pathname} hideDefaultViewButtons inner={<span />} />
      </Portal>
    </div>
  );
};

Search.propTypes = {
  // searchContent: PropTypes.func.isRequired,
  q: PropTypes.string,
  subject: PropTypes.string,
  path: PropTypes.string,
  // TODO: ここはチャットアイテムになる
  items: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string,
      '@type': PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  pathname: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};

/**
 * Searchコンポーネントのエクスポート
 */
export default compose(
  connect(
    (state, props) => {
      return {
        // TODO: 要修正
        // items: state.search.items,
        // TODO: 要確認
        q: qs.parse(props.history.location.search).q,
        // TODO: 要確認
        pathname: props.location.pathname,
        // locale: Locale.getLocale(state?.content?.data?.language?.token),
        locale:
          new Cookies().get('I18N_LANGUAGE') || config.settings.defaultLanguage,
        // locale: getLocaleFromPathname(
        //   __CLIENT__ ? window?.location.pathname : null,
        //   config.settings.defaultLanguage,
        // ),
        total: state.searchResult.total,
        b_start: state.searchResult.b_start,
        b_size: state.searchResult.b_size,
        has_next: state.searchResult.has_next,
        lang: qs.parse(props.history.location.search).lang,
      };
    },
    {
      // TODO: 不要
      // getSearchResult,
    },
  ),
  asyncConnect([
    {
      key: 'search',
      promise: ({ location, store: { dispatch } }) => {
        // TODO: 不要であれば除去
        dispatch(getSearchResult('', qs.parse(location.search)));
      },
    },
  ]),
)(Search);

// @kara_d end

// /**
//  * Search class.
//  * @class SearchComponent
//  * @extends Component
//  */
// class Search extends Component {
//   /**
//    * Property types.
//    * @property {Object} propTypes Property types.
//    * @static
//    */
//   static propTypes = {
//     searchContent: PropTypes.func.isRequired,
//     searchableText: PropTypes.string,
//     subject: PropTypes.string,
//     path: PropTypes.string,
//     items: PropTypes.arrayOf(
//       PropTypes.shape({
//         '@id': PropTypes.string,
//         '@type': PropTypes.string,
//         title: PropTypes.string,
//         description: PropTypes.string,
//       }),
//     ),
//     pathname: PropTypes.string.isRequired,
//   };

//   /**
//    * Default properties.
//    * @property {Object} defaultProps Default properties.
//    * @static
//    */
//   static defaultProps = {
//     items: [],
//     searchableText: null,
//     subject: null,
//     path: null,
//   };

//   constructor(props) {
//     super(props);
//     this.state = { currentPage: 1, isClient: false, active: 'relevance' };
//   }

//   /**
//    * Component did mount
//    * @method componentDidMount
//    * @returns {undefined}
//    */
//   componentDidMount() {
//     this.doSearch();
//     this.setState({ isClient: true });
//   }

//   /**
//    * Component will receive props
//    * @method componentWillReceiveProps
//    * @param {Object} nextProps Next properties
//    * @returns {undefined}
//    */
//   UNSAFE_componentWillReceiveProps = (nextProps) => {
//     if (this.props.location.search !== nextProps.location.search) {
//       this.doSearch();
//     }
//   };

//   /**
//    * Search based on the given searchableText, subject and path.
//    * @method doSearch
//    * @param {string} searchableText The searchable text string
//    * @param {string} subject The subject (tag)
//    * @param {string} path The path to restrict the search to
//    * @returns {undefined}
//    */

//   doSearch = () => {
//     const options = qs.parse(this.props.history.location.search);
//     this.setState({ currentPage: 1 });
//     options['use_site_search_settings'] = 1;
//     this.props.searchContent('', options);
//   };

//   handleQueryPaginationChange = (e, { activePage }) => {
//     const { settings } = config;
//     window.scrollTo(0, 0);
//     let options = qs.parse(this.props.history.location.search);
//     options['use_site_search_settings'] = 1;

//     this.setState({ currentPage: activePage }, () => {
//       this.props.searchContent('', {
//         ...options,
//         b_start: (this.state.currentPage - 1) * settings.defaultPageSize,
//       });
//     });
//   };

//   onSortChange = (event, sort_order) => {
//     let options = qs.parse(this.props.history.location.search);
//     options.sort_on = event.target.name;
//     options.sort_order = sort_order || 'ascending';
//     if (event.target.name === 'relevance') {
//       delete options.sort_on;
//       delete options.sort_order;
//     }
//     let searchParams = qs.stringify(options);
//     this.setState({ currentPage: 1, active: event.target.name }, () => {
//       // eslint-disable-next-line no-restricted-globals
//       this.props.history.replace({
//         search: searchParams,
//       });
//     });
//   };

//   /**
//    * Render method.
//    * @method render
//    * @returns {string} Markup for the component.
//    */
//   render() {
//     const { settings } = config;
//     return (
//       <Container id="page-search">
//         <Helmet title="Search" />
//         <div className="container">
//           <article id="content">
//             <header>
//               <h1 className="documentFirstHeading">
//                 {this.props.searchableText ? (
//                   <FormattedMessage
//                     id="Search results for {term}"
//                     defaultMessage="Search results for {term}"
//                     values={{
//                       term: <q>{this.props.searchableText}</q>,
//                     }}
//                   />
//                 ) : (
//                   <FormattedMessage
//                     id="Search results"
//                     defaultMessage="Search results"
//                   />
//                 )}
//               </h1>

//               <SearchTags />

//               {this.props.search?.items_total > 0 ? (
//                 <div className="items_total">
//                   {this.props.search.items_total}{' '}
//                   <FormattedMessage
//                     id="results found"
//                     defaultMessage="results"
//                   />
//                   <Header>
//                     <Header.Content className="header-content">
//                       <div className="sort-by">
//                         <FormattedMessage
//                           id="Sort By:"
//                           defaultMessage="Sort by:"
//                         />
//                       </div>
//                       <Button
//                         onClick={(event) => {
//                           this.onSortChange(event);
//                         }}
//                         name="relevance"
//                         size="tiny"
//                         className={classNames('button-sort', {
//                           'button-active': this.state.active === 'relevance',
//                         })}
//                       >
//                         <FormattedMessage
//                           id="Relevance"
//                           defaultMessage="Relevance"
//                         />
//                       </Button>
//                       <Button
//                         onClick={(event) => {
//                           this.onSortChange(event);
//                         }}
//                         name="sortable_title"
//                         size="tiny"
//                         className={classNames('button-sort', {
//                           'button-active':
//                             this.state.active === 'sortable_title',
//                         })}
//                       >
//                         <FormattedMessage
//                           id="Alphabetically"
//                           defaultMessage="Alphabetically"
//                         />
//                       </Button>
//                       <Button
//                         onClick={(event) => {
//                           this.onSortChange(event, 'reverse');
//                         }}
//                         name="effective"
//                         size="tiny"
//                         className={classNames('button-sort', {
//                           'button-active': this.state.active === 'effective',
//                         })}
//                       >
//                         <FormattedMessage
//                           id="Date (newest first)"
//                           defaultMessage="Date (newest first)"
//                         />
//                       </Button>
//                     </Header.Content>
//                   </Header>
//                 </div>
//               ) : (
//                 <div>
//                   <FormattedMessage
//                     id="No results found"
//                     defaultMessage="No results found"
//                   />
//                 </div>
//               )}
//             </header>
//             <section id="content-core">
//               {this.props.items.map((item) => (
//                 <article className="tileItem" key={item['@id']}>
//                   <h2 className="tileHeadline">
//                     <Link
//                       to={item['@id']}
//                       className="summary url"
//                       title={item['@type']}
//                     >
//                       {item.title}
//                     </Link>
//                   </h2>
//                   {item.description && (
//                     <div className="tileBody">
//                       <span className="description">{item.description}</span>
//                     </div>
//                   )}
//                   <div className="tileFooter">
//                     <Link to={item['@id']}>
//                       <FormattedMessage
//                         id="Read More…"
//                         defaultMessage="Read More…"
//                       />
//                     </Link>
//                   </div>
//                   <div className="visualClear" />
//                 </article>
//               ))}

//               {this.props.search?.batching && (
//                 <div className="search-footer">
//                   <Pagination
//                     activePage={this.state.currentPage}
//                     totalPages={Math.ceil(
//                       this.props.search.items_total / settings.defaultPageSize,
//                     )}
//                     onPageChange={this.handleQueryPaginationChange}
//                     firstItem={null}
//                     lastItem={null}
//                     prevItem={{
//                       content: <Icon name={paginationLeftSVG} size="18px" />,
//                       icon: true,
//                       'aria-disabled': !this.props.search.batching.prev,
//                       className: !this.props.search.batching.prev
//                         ? 'disabled'
//                         : null,
//                     }}
//                     nextItem={{
//                       content: <Icon name={paginationRightSVG} size="18px" />,
//                       icon: true,
//                       'aria-disabled': !this.props.search.batching.next,
//                       className: !this.props.search.batching.next
//                         ? 'disabled'
//                         : null,
//                     }}
//                   />
//                 </div>
//               )}
//             </section>
//           </article>
//         </div>
//         {this.state.isClient && (
//           <Portal node={document.getElementById('toolbar')}>
//             <Toolbar
//               pathname={this.props.pathname}
//               hideDefaultViewButtons
//               inner={<span />}
//             />
//           </Portal>
//         )}
//       </Container>
//     );
//   }
// }

// export const __test__ = connect(
//   (state, props) => ({
//     items: state.search.items,
//     searchableText: qs.parse(props.history.location.search).SearchableText,
//     pathname: props.history.location.pathname,
//   }),
//   { searchContent },
// )(Search);

// export default compose(
//   connect(
//     (state, props) => ({
//       items: state.search.items,
//       searchableText: qs.parse(props.history.location.search).SearchableText,
//       pathname: props.location.pathname,
//     }),
//     { searchContent },
//   ),
//   asyncConnect([
//     {
//       key: 'search',
//       promise: ({ location, store: { dispatch } }) =>
//         dispatch(
//           searchContent('', {
//             ...qs.parse(location.search),
//             use_site_search_settings: 1,
//           }),
//         ),
//     },
//   ]),
// )(Search);
