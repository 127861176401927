import { FAQ_FOLDER_LIST } from '../../constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

export function faqFolderList(faqRootPath) {
  return {
    type: FAQ_FOLDER_LIST,
    request: {
      op: 'get',
      path: `${flattenToAppURL(faqRootPath)}/@faq-folder-list`,
    },
  };
}
