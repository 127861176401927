/**
 * OuTourView component.
 * @module components/theme/View/OuTourView
 * nk24@CMScom 2021/07/05
 */

import { map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  BlackBox,
  HelmetSite,
  OuBody,
  //   PageMainImage,
  PageTitle,
  SNSShare,
} from '../../index';
import { TourIndicator } from '@package/components';
import './OuPageView.css';

/**
 * Component to display the OuPageView.
 * @function OuPageView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OuTourView = ({ content, token }) => {
  console.log({ content });
  return token === undefined ? (
    <Redirect
      to={`${flattenToAppURL(content?.tour_items?.[0]?.['@id'])}?tour=${
        content?.UID
      }`}
    ></Redirect>
  ) : (
    <div className="OuPageView">
      <HelmetSite content={content} />
      <section className="mainArea">
        <TourIndicator />
        <div className="mainAreaInner">
          <div className="mainAreaInnerInner">
            <div className="blackBoxWrapper">
              <Link to={flattenToAppURL(content?.parent['@id'])}>
                <BlackBox title={content?.parent?.title} />
              </Link>
            </div>
            <div className="bodyArea">
              <PageTitle title={content?.title} />
              <img
                src={`${flattenToAppURL(
                  content?.['@id'],
                )}/@@images/thumbnail/preview`}
                alt=""
              />
              <p>{content?.description}</p>
              {/* <OuBody content={content} /> */}
              {/* <SNSShare url={content['@id']} /> */}
              <h3>Targets</h3>
              {content?.targets ? (
                <ul>
                  {map(content.targets, (target) => (
                    <li key={target?.token}>{target?.title}</li>
                  ))}
                </ul>
              ) : null}
              <h3>Tour items</h3>
              {content?.tour_items ? (
                <ol>
                  {map(content.tour_items, (tour) => (
                    <li key={tour?.uid}>{tour?.title}</li>
                  ))}
                </ol>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuTourView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default injectIntl(OuTourView);
