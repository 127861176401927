/**
 * # Caution
 *
 * Node 14 で実行すること
 *
 * # Matrixライブラリのドキュメント
 *
 * https://mathjs.org/docs/datatypes/matrices.html
 *
 * # Usage
 *
 * 1. 配列から初期Matrixを作成する関数 : toMatrix(rows)
 * 2. マトリックスのデータを上書きする関数 : transformMatrix(currentMatrix, startX, startY, rowspan, colspan)
 * 3. マトリックスの任意の位置のデータを取得する関数 : getSpanFromMatrix(currentMatrix, startX, startY)
 * 4. 現在のマトリックスに対してINSERT_TYPE.ROW/INSERT_TYPE.COLUMN/REMOVE_TYPE.ROW/REMOVE_TYPE.COLUMNのpositionIndexの位置に行や列を挿入、その際に発生する矩形の移動や拡張も行う : transformationMatrix(currentMatrix, tableActionType, positionIndex)
 *     tableActionTypeは、INSERT_TYPE/REMOVE_TYPEが指定される
 *
 * # Test
 *
 * ```
 * $ yarn jest src/helpers/Matrix/Matrix.test.js
 * ```
 *
 * # currentMatrix の構造
 *
 * Matrixを格納するbodyとそれぞれの矩形を格納するrectanglesのプロパティがある
 *
 * ```
 * {
 *   body: [[{"colspan":1,"rowspan":1},{"colspan":1,"rowspan":1}, ... ]],
 *   rectangles: [ {"startX":1,"startY":2,"rowspan":2,"colspan":4}, ... ]
 * }
 * ```
 *
 * # Rectangleオブジェクトについて
 *
 * 以下のような構成
 *
 * ```
 * { startX: 1, startY: 1, colspan: 1, rowspan: 1 }
 * ```
 *
 * # INSERT_TYPE/REMOVE_TYPEについて
 *
 * - INSERT_TYPE/REMOVE_TYPEをimportして使うこと。COLUMNとROWの2値
 */

import * as math from 'mathjs';
import _ from 'lodash';

/**
 * 配列から初期Matrixを作成する関数
 *
 * @param {*} rows
 * @returns matrix
 */
export function toMatrix(rows) {
  const colSizes = _.map(rows, (item) => _.size(item?.cells));
  const _result = math.matrix().resize([_.size(rows), Math.max(...colSizes)], {
    colspan: 1,
    rowspan: 1,
  });
  return { body: _result.toArray(), rectangles: [] };
}

export function transformMatrix(
  currentMatrix,
  startX,
  startY,
  rowspan,
  colspan,
) {
  // Matrix本体を取得
  const body = currentMatrix?.body;
  // rectangles を取得
  const rectangles = addRectangles(
    { startX, startY, rowspan, colspan },
    currentMatrix?.rectangles || [],
  );
  let result = rectangles?.reduce(
    (prev, current) => {
      return transformMatrixOnce(
        prev,
        current?.startX,
        current?.startY,
        current?.rowspan,
        current?.colspan,
      );
    },
    {
      body: plainMatrixSimple(body || []),
      rectangles: [],
    },
  );
  return result;
}

/**
 * マトリックスのデータを上書きする関数
 *
 * 既存のMatrixに対してcolspan, rowspanを設定する
 *
 * @param {*} currentMatrix
 * @param {*} startX
 * @param {*} startY
 * @param {*} rowspan
 * @param {*} colspan
 * @returns matrix
 */
export function transformMatrixOnce(
  currentMatrix,
  startX,
  startY,
  rowspan,
  colspan,
) {
  let _matrix = math.matrix(currentMatrix?.body);
  let _rectangles = currentMatrix?.rectangles || [];
  let _result = [];
  if (rowspan === 1 && colspan === 1) {
    // この場合は矩形とみなさない
    _result = _matrix;
  } else {
    // 重複したStartXとStartYがあれば更新
    _rectangles = addRectangles(
      { startX, startY, rowspan, colspan },
      _rectangles,
    );
    const colspanArrX = math.range(0 + startX, colspan + startX);
    const colspanArrY = math.range(0 + startY, rowspan + startY);
    const colspanArrReplace = math
      .matrix()
      .resize([colspanArrY.size()[0], colspanArrX.size()[0]], {
        colspan: 0,
        rowspan: 0,
      });
    _result = math.subset(
      _matrix,
      math.index(colspanArrY, colspanArrX.toArray()),
      colspanArrReplace,
    );
    _result = math.subset(_result, math.index(startY, startX), {
      colspan,
      rowspan,
    });
  }
  return { body: _result.toArray(), rectangles: _rectangles };
}

/**
 * RectanglesにRectangleを追加
 * 重複したStartXとStartYがあれば更新
 * @param {*} newRectangle
 * @param {*} currentRectangles
 * @returns
 */
export function addRectangles(newRectangle, currentRectangles = []) {
  const findResult = _.findIndex(
    currentRectangles,
    (item) =>
      item?.startX === newRectangle?.startX &&
      item?.startY === newRectangle?.startY,
  );
  // console.log({
  //   findResult,
  //   item: currentRectangles?.[findResult],
  // });
  if (findResult > -1) {
    currentRectangles[findResult] = newRectangle;
  } else {
    currentRectangles.push({
      startX: newRectangle?.startX,
      startY: newRectangle?.startY,
      rowspan: newRectangle?.rowspan,
      colspan: newRectangle?.colspan,
    });
  }

  return currentRectangles;
}

/**
 * サイズに沿ったPlainなマトリクスを作成
 * @param {*} rows
 * @returns
 */
export function plainMatrixSimple(body) {
  const result = math
    .matrix()
    .resize([body?.length || 0, body?.[0]?.length || 0], {
      colspan: 1,
      rowspan: 1,
    })
    .toArray();
  // console.log({ body, result });
  return result;
}

/**
 * マトリックスの任意の位置のデータを取得する関数
 *
 * @param {*} currentMatrix
 * @param {*} startX
 * @param {*} startY
 * @returns { colspan, rowspan }
 */
export function getSpanFromMatrix(currentMatrix, startX, startY) {
  if (currentMatrix?.body) {
    return _.get(currentMatrix?.body, `[${startY}][${startX}]`);
  } else {
    // return { colspan: startX, rowspan: startY };
    return { colspan: 1, rowspan: 1 };
  }
}

// ------------------------------- @karad begin

/**
 * 行や列の挿入・削除
 * TODO: 行削除時はrectangleが消える場合がある
 * @param {*} currentMatrix
 * @param {*} tableActionType
 * @param {*} positionIndex
 */
export function transformationMatrix(
  currentMatrix,
  tableActionType,
  positionIndex,
) {
  // Matrix本体を取得
  const body = currentMatrix?.body;
  // rectangles を取得
  const rectangles = currentMatrix?.rectangles || [];

  // rectangles の回数だけ移動・拡張をし rectangles に反映
  const transformedRectangles = rectangles
    ?.map((item) => {
      return getTransformationedRectangle(item, tableActionType, positionIndex);
    })
    .filter((item) => {
      // 幅もしくは高さがない矩形は除去
      return item?.colspan > 0 && item?.rowspan > 0;
    });
  // 1で埋め尽くした結果の Matrix を用意 resultPlainMatrix()
  // transformedRectangles の数だけ Matrix に適用
  let result = transformedRectangles?.reduce(
    (prev, current) => {
      return transformMatrixOnce(
        prev,
        current?.startX,
        current?.startY,
        current?.rowspan,
        current?.colspan,
      );
    },
    {
      body: resultPlainMatrix(body, tableActionType),
      rectangles: [],
    },
  );
  return result;
}

/**
 * 1で埋め尽くした結果の Matrix
 * @param {*} currentMatrixBody
 * @param {*} transformationTarget
 */
export function resultPlainMatrix(currentMatrixBody, tableActionType) {
  if (tableActionType === INSERT_TYPE.COLUMN) {
    return math
      .matrix()
      .resize([currentMatrixBody.length, currentMatrixBody[0].length + 1], {
        colspan: 1,
        rowspan: 1,
      })
      .toArray();
  } else if (tableActionType === INSERT_TYPE.ROW) {
    return math
      .matrix()
      .resize([currentMatrixBody.length + 1, currentMatrixBody[0].length], {
        colspan: 1,
        rowspan: 1,
      })
      .toArray();
  } else if (tableActionType === REMOVE_TYPE.COLUMN) {
    return math
      .matrix()
      .resize([currentMatrixBody.length, currentMatrixBody[0].length - 1], {
        colspan: 1,
        rowspan: 1,
      })
      .toArray();
  } else if (tableActionType === REMOVE_TYPE.ROW) {
    return math
      .matrix()
      .resize([currentMatrixBody.length - 1, currentMatrixBody[0].length], {
        colspan: 1,
        rowspan: 1,
      })
      .toArray();
  }
}

/**
 * 挿入予定の行/列
 */
export const INSERT_TYPE = {
  COLUMN: 'INSERT_TYPE_COLUMN',
  ROW: 'INSERT_TYPE_ROW',
};

/**
 * 除去予定の行/列
 */
export const REMOVE_TYPE = {
  COLUMN: 'REMOVE_TYPE_COLUMN',
  ROW: 'REMOVE_TYPE_ROW',
};

/**
 * Rectangle の Transformation Type
 */
export const TRANSFORMATION_TYPE = {
  INSERT_MOVE: 'INSERT_MOVE',
  REMOVE_MOVE: 'REMOVE_MOVE',
  EXPAND: 'EXPAND',
  SHRINK: 'SHRINK',
  NONE: 'NONE',
};

/**
 * 変換された Rectangle を返す
 * @param {*} rectangle
 * @param {*} tableActionType
 * @param {*} positionIndex
 */
export function getTransformationedRectangle(
  rectangle,
  tableActionType,
  positionIndex,
) {
  let isMoveOrExpand;
  if (
    tableActionType === INSERT_TYPE.COLUMN ||
    tableActionType === REMOVE_TYPE.COLUMN
  ) {
    isMoveOrExpand = checkMoveOrExpand(
      rectangle.startX,
      rectangle.startX + rectangle.colspan,
      positionIndex,
      tableActionType,
    );
    if (isMoveOrExpand === TRANSFORMATION_TYPE.EXPAND) {
      return expandColumnRectangle(rectangle);
    } else if (isMoveOrExpand === TRANSFORMATION_TYPE.INSERT_MOVE) {
      return insertMoveXRectangle(rectangle);
    } else if (isMoveOrExpand === TRANSFORMATION_TYPE.SHRINK) {
      return shrinkColumnRectangle(rectangle);
    } else if (isMoveOrExpand === TRANSFORMATION_TYPE.REMOVE_MOVE) {
      return removeMoveXRectangle(rectangle);
    } else {
      return rectangle;
    }
  } else if (
    (tableActionType === INSERT_TYPE.ROW) |
    (tableActionType === REMOVE_TYPE.ROW)
  ) {
    isMoveOrExpand = checkMoveOrExpand(
      rectangle.startY,
      rectangle.startY + rectangle.rowspan,
      positionIndex,
      tableActionType,
    );
    if (isMoveOrExpand === TRANSFORMATION_TYPE.EXPAND) {
      return expandRowRectangle(rectangle);
    } else if (isMoveOrExpand === TRANSFORMATION_TYPE.INSERT_MOVE) {
      return insertMoveYRectangle(rectangle);
    } else if (isMoveOrExpand === TRANSFORMATION_TYPE.SHRINK) {
      return shrinkRowRectangle(rectangle);
    } else if (isMoveOrExpand === TRANSFORMATION_TYPE.REMOVE_MOVE) {
      return removeMoveYRectangle(rectangle);
    } else {
      return rectangle;
    }
  }
}

/**
 * 移動もしくは拡張の判定
 * @param {*} start
 * @param {*} end
 * @param {*} target
 * @returns
 */
export function checkMoveOrExpand(start, end, target, tableActionType) {
  if (
    tableActionType === INSERT_TYPE.COLUMN ||
    tableActionType === INSERT_TYPE.ROW
  ) {
    if (target <= start) {
      return TRANSFORMATION_TYPE.INSERT_MOVE;
    } else if (start < target && target < end) {
      return TRANSFORMATION_TYPE.EXPAND;
    } else {
      return TRANSFORMATION_TYPE.NONE;
    }
  }
  if (
    tableActionType === REMOVE_TYPE.COLUMN ||
    tableActionType === REMOVE_TYPE.ROW
  ) {
    if (target < start) {
      return TRANSFORMATION_TYPE.REMOVE_MOVE;
    } else if (start <= target && target < end) {
      return TRANSFORMATION_TYPE.SHRINK;
    } else {
      return TRANSFORMATION_TYPE.NONE;
    }
  }
}

/**
 * Rectangle を右に移動
 * @param {*} params
 */
export function insertMoveXRectangle(rectangle) {
  return {
    startX: rectangle.startX + 1,
    startY: rectangle.startY,
    colspan: rectangle.colspan,
    rowspan: rectangle.rowspan,
  };
}

/**
 * Rectangle を下に移動
 * @param {*} params
 */
export function insertMoveYRectangle(rectangle) {
  return {
    startX: rectangle.startX,
    startY: rectangle.startY + 1,
    colspan: rectangle.colspan,
    rowspan: rectangle.rowspan,
  };
}

/**
 * Rectangle に行方向に拡大
 * @param {*} rectangle
 */
export function expandRowRectangle(rectangle) {
  return {
    startX: rectangle.startX,
    startY: rectangle.startY,
    colspan: rectangle.colspan,
    rowspan: rectangle.rowspan + 1,
  };
}

/**
 * Rectangle を列方向に拡大
 * @param {*} rectangle
 */
export function expandColumnRectangle(rectangle) {
  return {
    startX: rectangle.startX,
    startY: rectangle.startY,
    colspan: rectangle.colspan + 1,
    rowspan: rectangle.rowspan,
  };
}

/**
 * Rectangle を行方向に詰める
 * @param {*} params
 */
export function removeMoveXRectangle(rectangle) {
  return {
    startX: rectangle.startX - 1,
    startY: rectangle.startY,
    colspan: rectangle.colspan,
    rowspan: rectangle.rowspan,
  };
}

/**
 * Rectangle を上に詰める
 * @param {*} params
 */
export function removeMoveYRectangle(rectangle) {
  return {
    startX: rectangle.startX,
    startY: rectangle.startY - 1,
    colspan: rectangle.colspan,
    rowspan: rectangle.rowspan,
  };
}

/**
 * Rectangle に行方向に縮小
 * @param {*} rectangle
 */
export function shrinkRowRectangle(rectangle) {
  return {
    startX: rectangle.startX,
    startY: rectangle.startY,
    colspan: rectangle.colspan,
    rowspan: rectangle.rowspan - 1,
  };
}

/**
 * Rectangle を列方向に縮小
 * @param {*} rectangle
 */
export function shrinkColumnRectangle(rectangle) {
  return {
    startX: rectangle.startX,
    startY: rectangle.startY,
    colspan: rectangle.colspan - 1,
    rowspan: rectangle.rowspan,
  };
}
