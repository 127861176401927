/**
 * Content migrate (html to block) reducer.
 * @module reducers/migrateHtmlToBlock
 */

import {
  MIGRATE_CONTENT,
  RESET_MIGRATE_CONTENT,
} from '~/constants/ActionTypes';

const initialState = {
  newFormData: null,
};

/**
 * Migrate button reducer.
 * @function toolbar
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function onMigrateBlock(state = initialState, action = {}) {
  switch (action.type) {
    case MIGRATE_CONTENT:
      return {
        ...state,
        newFormData: action.newFormData,
      };
    case RESET_MIGRATE_CONTENT:
      return {
        ...state,
        newFormData: null,
      };
    default:
      return state;
  }
}
