/**
 * Edit image container block for OuGrid2.
 * @module components/manage/Blocks/ImageForOuGrid2/Edit
 * based on v13.8.2?
 */

import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';
import { createContent } from '@plone/volto/actions';
import { Icon, SidebarPortal } from '@plone/volto/components';
import EditImageBlock from '@package/components/manage/Blocks/OuGrid2/InnerBlocks/Image/Edit';
import EditLinkListBlock from '@package/components/manage/Blocks/OuGrid2/InnerBlocks/LinkList/Edit';
import EditPlainTextBlock from '@package/components/manage/Blocks/OuGrid2/InnerBlocks/PlainText/Edit';
import GridData from './Data';
import './Edit.css';

/**
 * Edit image block class.
 * @class Edit
 * @extends Component
 */
const Edit = (props) => {
  const [selectedSubBlock, setSelectedSubBlock] = useState(null);
  const patchdProps = (type) => {
    return {
      ...props,
      selected: props.selected && selectedSubBlock === type,
      type,
    };
  };
  const onClickSubBlock = (e, innerBlockId) => {
    e.target.focus();
    if (innerBlockId === 'image' && selectedSubBlock !== 'image') {
      setSelectedSubBlock('image');
    } else if (innerBlockId === 'list' && selectedSubBlock !== 'list') {
      setSelectedSubBlock('list');
    } else if (
      innerBlockId === 'OuGrid2Label' &&
      selectedSubBlock !== 'OuGrid2Label'
    ) {
      setSelectedSubBlock('OuGrid2Label');
    } else if (
      innerBlockId === 'OuGrid2Title' &&
      selectedSubBlock !== 'OuGrid2Title'
    ) {
      setSelectedSubBlock('OuGrid2Title');
    } else if (
      innerBlockId === 'OuGrid2SubTitle' &&
      selectedSubBlock !== 'OuGrid2SubTitle'
    ) {
      setSelectedSubBlock('OuGrid2SubTitle');
    } else if (
      innerBlockId === 'OuGrid2Description' &&
      selectedSubBlock !== 'OuGrid2Description'
    ) {
      setSelectedSubBlock('OuGrid2Description');
    }
  };
  return (
    <div className="ImageForOuGrid2 Edit">
      <div className="mediaBox">
        <div className="mediaBoxInner">
          <div
            role="button"
            tabIndex={0}
            onClick={(e) => onClickSubBlock(e, 'image')}
            onKeyPress={(e) => {
              if (e.key === 'Enter') onClickSubBlock(e, 'image');
            }}
          >
            <EditImageBlock {...patchdProps('image')} />
          </div>
        </div>
      </div>
      <div className="textBox">
        <div
          role="button"
          tabIndex={0}
          onClick={(e) => onClickSubBlock(e, 'OuGrid2Label')}
          onKeyPress={(e) => {
            if (e.key === 'Enter') onClickSubBlock(e, 'OuGrid2Label');
          }}
        >
          <EditPlainTextBlock {...patchdProps('OuGrid2Label')} />
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={(e) => onClickSubBlock(e, 'OuGrid2Title')}
          onKeyPress={(e) => {
            if (e.key === 'Enter') onClickSubBlock(e, 'OuGrid2Title');
          }}
        >
          <EditPlainTextBlock {...patchdProps('OuGrid2Title')} />
        </div>
        {props.data.separator ? <hr className="divider"></hr> : null}
        <div
          role="button"
          tabIndex={0}
          onClick={(e) => onClickSubBlock(e, 'OuGrid2SubTitle')}
          onKeyPress={(e) => {
            if (e.key === 'Enter') onClickSubBlock(e, 'OuGrid2SubTitle');
          }}
        >
          <EditPlainTextBlock {...patchdProps('OuGrid2SubTitle')} />
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={(e) => onClickSubBlock(e, 'OuGrid2Description')}
          onKeyPress={(e) => {
            if (e.key === 'Enter') onClickSubBlock(e, 'OuGrid2Description');
          }}
        >
          <EditPlainTextBlock {...patchdProps('OuGrid2Description')} />
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={(e) => onClickSubBlock(e, 'list')}
          onKeyPress={(e) => {
            if (e.key === 'Enter') onClickSubBlock(e, 'list');
          }}
        >
          <EditLinkListBlock {...patchdProps('list')} />
        </div>
      </div>
      <SidebarPortal selected={props.selected}>
        <GridData {...props}></GridData>
      </SidebarPortal>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Edit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  selected: PropTypes.bool,
  edit: PropTypes.bool,
  url: PropTypes.string,
};

export default Edit;
