import _ from 'lodash';
import { GET_NEWS_LIST } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  result: {},
  newsList: [],
};

/**
 * News List Reducer
 * @param {*} state
 * @param {*} action
 */
export default function newsList(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_NEWS_LIST}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_NEWS_LIST}_SUCCESS`:
      let newsList;
      if (action.bStart === 0) {
        newsList = action?.result?.data?.attributes?.items;
      } else {
        newsList = _.concat(
          state.newsList ?? [],
          action?.result?.data?.attributes?.items,
        );
      }
      return {
        ...state,
        error: null,
        result: action?.result,
        newsList: newsList,
        loaded: true,
        loading: false,
      };
    case `${GET_NEWS_LIST}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
