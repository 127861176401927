/**
 * Schema component for sidebar on UnivInfo block.
 * @module components/manage/Blocks/UnivInfo/Schema
 * @nk24@CMScom
 */

const Schema = {
  // title: 'Univ Info',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [],
    },
    {
      id: 'background-images',
      title: 'Background Images',
      fields: ['background_image_PC', 'background_image_SP'],
    },
    {
      id: 'targets',
      title: 'Targets',
      fields: ['targets'],
    },
  ],
  properties: {
    background_image_PC: {
      title: 'Background image for PC',
      widget: 'object_browser',
      mode: 'image',
      selectedItemAttrs: [], // path and Title
    },
    background_image_SP: {
      title: 'Background image for SP',
      widget: 'object_browser',
      mode: 'image',
      selectedItemAttrs: [],
    },
    targets: {
      title: 'Targets',
      widget: 'target_with_unselected',
      isMulti: true,
    },
  },
  required: [],
};

export default Schema;
