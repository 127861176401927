import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Map } from 'immutable';
import { isEqual } from 'lodash';
import { Editor, DefaultDraftBlockRenderMap, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { defineMessages, injectIntl } from 'react-intl';
import config from '@plone/volto/registry';

const messages = defineMessages({
  placeholder_OuGrid2Label: {
    id: 'Type the label text…',
    defaultMessage: 'Type the label text…',
  },
  placeholder_OuGrid2Title: {
    id: 'Type the title…',
    defaultMessage: 'Type the title…',
  },
  placeholder_OuGrid2SubTitle: {
    id: 'Type subtitle…',
    defaultMessage: 'Type subtitle…',
  },
  placeholder_OuGrid2Description: {
    id: 'Type description…',
    defaultMessage: 'Type description…',
  },
});

const blockRenderMap = Map({
  'header-two': { elememt: 'h2' },
  unstyled: { element: 'div' },
});
const blockStyle = 'title';
const blockStyleMap = {
  OuGrid2Label: 'label',
  OuGrid2Title: 'nameOrHeader headline',
  OuGrid2SubTitle: 'description',
  OuGrid2Description: 'body',
};
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

const initEditorState = (text) => {
  if (!__SERVER__) {
    let editorState;
    //console.log({ text });
    if (text) {
      const contentState = stateFromHTML(text);
      editorState = EditorState.createWithContent(contentState);
    } else {
      editorState = EditorState.createEmpty();
    }
    return editorState;
  }
};

const Edit = ({
  block,
  data,
  intl,
  onAddBlock,
  onChangeBlock,
  onSelectBlock,
  type,
  selected,
  index,
  rootBlockIndex,
}) => {
  const node = useRef(null);
  const text = data?.[type];
  const [editorState, setEditorState] = useState(initEditorState(text));

  const onChange = (nextEditorState) => {
    if (
      !isEqual(
        nextEditorState.getCurrentContent().getPlainText(),
        editorState.getCurrentContent().getPlainText(),
      )
    ) {
      onChangeBlock(block, {
        ...data,
        [type]: nextEditorState.getCurrentContent().getPlainText(),
      });
    }
    setEditorState(nextEditorState);
  };

  return (
    <div className="PlainText Edit">
      <span
        className={cx('renderer-wrapper', { selected })}
        role="presentation"
      >
        <Editor
          onChange={onChange}
          editorState={editorState}
          blockRenderMap={extendedBlockRenderMap}
          handleReturn={() => {
            if (data.disableNewBlocks) {
              return 'handled';
            }
            onSelectBlock(
              onAddBlock(config.settings.defaultBlockType, rootBlockIndex + 1),
            );
            return 'handled';
          }}
          placeholder={intl.formatMessage(messages[`placeholder_${type}`])}
          //   blockStyleFn={() => blockStyle}
          blockStyleFn={() => blockStyleMap[type]}
          //   onUpArrow={() => {
          //     const selectionState = editorState.getSelection();
          //     if (
          //       editorState.getCurrentContent().getBlockMap().first().getKey() ===
          //       selectionState.getFocusKey()
          //     ) {
          //       onFocusPreviousBlock(block, this.node);
          //     }
          //   }}
          //   onDownArrow={() => {
          //     const selectionState = this.state.editorState.getSelection();
          //     const { editorState } = this.state;
          //     if (
          //       editorState.getCurrentContent().getBlockMap().last().getKey() ===
          //       selectionState.getFocusKey()
          //     ) {
          //       onFocusNextBlock(block, this.node);
          //     }
          //   }}
          ref={node}
        />
      </span>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Edit.propTypes = {
  block: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

export default injectIntl(Edit);
