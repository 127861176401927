import { tap, map, ignoreElements } from 'rxjs/operators';
import {
  GET_WELCOME_LIST,
  SEARCH_RESULT_CONTENT,
  GET_MORE_WELCOME_LIST,
} from '../constants/ActionTypes';
import { combineEpics, ofType, mapTo, i } from 'redux-observable';
import welcomeStore from '../components/theme/Element/WelcomeElement/store/welcome';
import searchStore from '../components/theme/Element/SearchElement/store/search';
import { POSITION } from 'components/theme/Element/ChatItemElement/ChatItemElement';

// ストリーム用の配列
let list = [
  { message: 'あなたは学生ですね' },
  { message: '学生の方向けのお知らせを表示します' },
  { message: 'お知らせは特にありません' },
];
let listAdditional = [
  { message: '最新のお知らせを表示します' },
  { message: '新コロナウイルスによる影響はありません' },
  { message: 'GOTOキャンペーン実施中です' },
];

/**
 * Welcome用のEPIC
 * @param {*} action$
 * @param {*} state$
 * @returns
 */
const welcomeEpic = (action$, state$) =>
  action$.pipe(
    ofType(`${GET_WELCOME_LIST}_SUCCESS`, `${GET_MORE_WELCOME_LIST}_SUCCESS`),
    // tap((action) => console.log(action)), // debug用
    tap((action) => {
      const messages = action?.result?.data?.attributes?.display;
      if (messages) {
        messages.map((item) => welcomeStore.sendMessage(item));
      }
    }),
    ignoreElements(),
  );

/**
 * Search用のEPIC
 * @param {*} action$
 * @param {*} state$
 * @returns
 */
const searchEpic = (action$, state$) =>
  action$.pipe(
    ofType(`${SEARCH_RESULT_CONTENT}_SUCCESS`),
    // tap((action) => console.log(action)), // debug用
    tap((action) => {
      const messages = action?.result?.data?.attributes?.display;
      // searchStore.sendMessage(listAdditional[0]);
      if (messages) {
        messages.map((item) =>
          searchStore.sendMessage({
            id: item.id,
            type: item.type,
            from: {
              ...item.from,
              src: item.from?.src
                ? item.from.src
                : '/assets/images/you_icon@2x.png',
              position: item?.from?.position
                ? item?.from?.position
                : POSITION.NONE,
            },
            content: `${item.content}`,
            thumbnail: item?.thumbnail,
            link: item?.link,
            bullet_list: item?.bullet_list,
            count: item?.count,
            has_next: item?.has_next,
            b_size: item?.b_size,
            b_start: item?.b_start,
          }),
        );
      }
    }),
    ignoreElements(),
  );

export const rootEpic = combineEpics(welcomeEpic, searchEpic);
