/**
 * Toolbar.js
 * @module helpers/Toolbar/Toolbar
 * @nk24:CMScom 2021/12/19
 */

/**
 * インラインツールバーの左右はみ出しを抑制
 * @function updateToolbarPOS
 * @param {*} mutations
 */
export const updateToolbarPOS = (mutations) => {
  const target = mutations[0].target;
  const offsetLeft = target.offsetLeft;
  const offsetWidth = target.offsetWidth;
  const parentOffsetWidth = target.offsetParent.offsetWidth;
  if (offsetWidth >= parentOffsetWidth) {
    target.style.left = `${parentOffsetWidth / 2}px`;
    target.style.width = `${parentOffsetWidth}px`;
    target.style.flexWrap = 'wrap';
  } else {
    target.style.flexWrap = 'nowrap';
    if (offsetLeft < offsetWidth / 2) {
      target.style.left = `${offsetWidth / 2}px`;
    } else if (
      offsetLeft >= offsetWidth / 2 &&
      offsetLeft <= parentOffsetWidth - offsetWidth / 2
    ) {
      target.style.left = offsetLeft;
    } else if (offsetLeft > parentOffsetWidth - offsetWidth / 2) {
      target.style.left = `${parentOffsetWidth - offsetWidth / 2}px`;
    } else {
      console.log('error');
    }
  }
};
