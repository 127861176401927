/**
 * Pickup component.
 * @module components/theme/Element/Pickup/Pickup
 */

import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  formatedDate,
  formatedDateDay,
  formatedMonthDate,
  formatedYear,
} from '../../../../helpers/GlobalStyle/Date.js';
import './Pickup.css';

/**
 * Component to display the Pickup
 * @function Pickup
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Pickup = ({ ctype, pickups }) => {
  const pickupSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const ouEventGroup = ['OuEventFolder', 'OuEvent'];
  const ouNewsGroup = ['OuNewsFolder', 'OuNews'];

  return (
    pickups !== undefined &&
    pickups.length > 0 && (
      <div className="Pickup">
        <div className="pickup">
          <div className="pickupInner">
            <div className="pickupLabel">PickUp</div>
            <ul className="pickupList items">
              <Slider {...pickupSettings}>
                {_.map(pickups, (item) => {
                  return (
                    <li className="item" key={item?.url}>
                      <Link to={flattenToAppURL(item?.url)}>
                        <img
                          alt={item?.name}
                          src={flattenToAppURL(item?.src)}
                        />
                        {ouEventGroup.includes(ctype) && (
                          <div className="infoBox event">
                            <div className="date">
                              <div className="startYear">
                                {formatedYear(item?.start)}
                              </div>
                              <div className="startDate">
                                {formatedMonthDate(item?.start)}
                              </div>
                              {item?.start != item?.end && (
                                <div className="toEnd">
                                  <span className="to">▶︎</span>
                                  <span className="end">
                                    {formatedDate(item?.end)}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="title">{item?.name}</div>
                          </div>
                        )}
                        {ouNewsGroup.includes(ctype) && (
                          <div className="infoBox news">
                            <div className="date">
                              {formatedDateDay(item?.date)}
                            </div>
                            <div className="title">{item?.name}</div>
                          </div>
                        )}
                      </Link>
                    </li>
                  );
                })}
              </Slider>
            </ul>
          </div>
        </div>
      </div>
    )
  );
};

export default injectIntl(Pickup);
