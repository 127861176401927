import { flattenToAppURL } from '@plone/volto/helpers';
import { FOR_YOU_BUTTON } from '../../constants/ActionTypes';

export function forYouButton(path) {
  return {
    type: FOR_YOU_BUTTON,
    request: {
      op: 'get',
      path: `${flattenToAppURL(path)}/@for-you-button`,
    },
  };
}
