import { WelcomeElement } from 'components';
import React from 'react';
import { SearchItem } from '../SearchElement/SearchDisplayElement';
import WelcomeDisplayElement from '../WelcomeElement/WelcomeDisplayElement';
import './SampleChatItem.css';

/**
 * ChatItemの開発用サンプル
 * いずれこのコンポーネントはコメントアウトして利用をやめる (ただし、消さないでください)
 * @returns
 */
export const SampleChatItem = () => {
  return (
    <div className="SampleChatItem">
      <div className="outer">
        <div className="inner">
          <h1>Sample ChatItems</h1>
          {/* ChatItemGreeting */}
          <h2>ChatItemGreeting</h2>
          <p>ChatItemGreetingは、Welcomeページ用です</p>
          <SearchItem
            item={{
              id: 'X123451',
              type: 'ChatItemGreeting',
              position: 'left',
              icon: {
                src: '/assets/images/icon_ou@2x.png',
                alt: 'ChatGreetingItemのアイコンのALT',
              },
              title: 'ようこそ!大阪大学へ',
              content:
                '2021年に、大阪大学は創立90周年、大阪外国語大学は創立100周年を迎えます。',
              thumbnail: {
                src: '/assets/images/chat_item_greeting_banner.png',
                alt: 'ChatGreetingItemのサムネイル画像のALT',
                caption: 'ChatGreetingItemのサムネイル画像のキャプション',
              },
              link: 'https://www.osaka-u.ac.jp/ja',
              meta: { message: 'メタ情報を表示します1' },
            }}
          ></SearchItem>
          {/* ChatItemSelect */}
          <h2>ChatItemSelect</h2>
          <p>
            ChatItemSelectは、特殊なコンポーネントでWelcomeコンポーネント内でないと動作しません。ここでは表示確認用に表示しています
          </p>
          <SearchItem
            item={{
              id: 'X123451',
              type: 'ChatItemSelect',
              position: 'none',
              icon: null,
              title: '※当てはまる箇所を選択してください',
              content: '',
              thumbnail: null,
              link: null,
              meta: { message: 'メタ情報を表示しますメタ情報を表示します2' },
            }}
          ></SearchItem>
          {/* ChatItemSelectForYou */}
          <h2>ChatItemSelectForYou (For You用)</h2>

          <SearchItem
            item={{
              id: 'X123451',
              type: 'ChatItemSelectForYou',
              position: 'none',
              icon: null,
              label: '個人設定',
              title: '現在の設定は',
              content: '設定を変更する場合は当てはまる箇所を選択してください。',
              thumbnail: null,
              link: null,
              meta: { message: 'メタ情報を表示します3' },
            }}
            callback={() => alert('ボタンが押されました')}
          ></SearchItem>
          {/* ChatItemNormal */}
          <h2>ChatItemNormal</h2>
          <h3>吹き出し左</h3>
          <SearchItem
            item={{
              id: 'X123456',
              type: 'ChatItemNormal',
              from: {
                position: 'left',
                src: '/assets/images/icon_wani_search@2x.png',
                alt: 'ChatItemNormalのワニアイコンのALT',
              },
              content: null,
              thumbnail: null,
              link: null,
              title: `XXXについて知りたい`,
              meta: {
                message:
                  'メタ情報を表示しますメタ情報を表示しますメタ情報を表示します。メタ4',
              },
            }}
          ></SearchItem>
          {/* 吹き出し無し */}
          <h3>吹き出し無し</h3>
          <SearchItem
            item={{
              id: 'X123456',
              type: 'ChatItemNormal',
              from: {
                position: 'none',
                src: '',
                alt: '',
              },
              content: null,
              thumbnail: null,
              link: null,
              title: `XXXについて知りたい`,
              meta: { message: 'メタ情報を表示します5' },
            }}
          ></SearchItem>
          {/* 吹き出し右 */}
          <h3>吹き出し右</h3>
          <SearchItem
            item={{
              id: 'X123456',
              type: 'ChatItemNormal',
              from: {
                position: 'right',
                src: '/assets/images/you_icon@2x.png',
                alt: 'ChatItemNormalの自分アイコンのALT',
              },
              content: null,
              thumbnail: null,
              link: null,
              title: `XXXについて知りたい`,
              meta: { message: 'メタ情報を表示します6' },
            }}
          ></SearchItem>
          {/* 吹き出し左 (タイトル、概要、リンク付き) */}
          <h3>吹き出し左 (タイトル、概要、リンク付き)</h3>
          <SearchItem
            item={{
              id: 'X123456',
              type: 'ChatItemNormal',
              from: {
                position: 'left',
                src: '/assets/images/wani_icon@2x.png',
                alt: 'ChatItemNormalのワニアイコンのALT',
              },
              content: `XXXについて知りたい`,
              thumbnail: null,
              link: 'https://google.com/',
              title: 'タイトル部分',
              meta: { message: 'メタ情報を表示します7' },
            }}
          ></SearchItem>
          {/* 吹き出し左 (サムネイル、タイトル、概要、リンク付き) */}
          <h3>吹き出し左 (サムネイル、タイトル、概要、リンク付き)</h3>
          <SearchItem
            item={{
              id: 'X123456',
              type: 'ChatItemNormal',
              from: {
                position: 'left',
                src: '/assets/images/wani_icon@2x.png',
                alt: 'ChatItemNormalのワニアイコンのALT',
              },
              content: `XXXについて知りたい。XXXについて知りたい。XXXについて知りたい`,
              thumbnail: {
                alt: '',
                caption: '',
                src: '/assets/images/nowprinting/h220.jpg',
              },
              link: 'https://google.com/',
              title: 'タイトル部分',
              meta: { message: 'メタ情報を表示します8' },
            }}
          ></SearchItem>
          {/* ChatItemList */}
          <h2>ChatItemList</h2>
          <p>
            ChatItemListは、flag、thumbnailの設定が可能。基本的にpositionはnoneで使う
          </p>
          <SearchItem
            item={{
              id: Math.floor(Math.random() * Math.pow(2, 24)).toString(32),
              type: 'ChatItemList',
              from: null,
              content: '',
              thumbnail: {},
              link: null,
              bullet_list: [
                {
                  content: '',
                  flag: true,
                  link: '/ja/Guide/ABC/fo7emv',
                  thumbnail: {
                    alt: '',
                    caption: '',
                    src: '/assets/images/nowprinting/h220.jpg',
                  },
                  title:
                    '各学部・研究科のアドミッション・ポリシー（入学者受け入れ方針）',
                },
                {
                  content: '',
                  flag: true,
                  link: '/ja/Guide/ABC/copy_of_fo7emv',
                  thumbnail: {
                    alt: '',
                    caption: '',
                    src: '/assets/images/nowprinting/h220.jpg',
                  },
                  title: '\u3042\u3044\u3046\u3048\u304a',
                },
                {
                  content: '',
                  flag: true,
                  link: '/ja/Guide/ABC/copy2_of_fo7emv',
                  thumbnail: null,
                  title:
                    '各学部・研究科のアドミッション・ポリシー（入学者受け入れ方針）',
                },
                {
                  content: '',
                  flag: true,
                  link: '/ja/Guide/ABC/copy3_of_fo7emv',
                  thumbnail: null,
                  title: '\u3042\u3044\u3046\u3048\u304a',
                },
                {
                  content: '',
                  flag: true,
                  link: '/ja/Guide/ABC/copy4_of_fo7emv',
                  thumbnail: null,
                  title: '\u3042\u3044\u3046\u3048\u304a',
                },
                {
                  content: '',
                  flag: false,
                  link: '/ja/Guide/ABC/copy5_of_fo7emv',
                  thumbnail: null,
                  title: '\u3042\u3044\u3046\u3048\u304a',
                },
                {
                  content: '',
                  flag: false,
                  link: '/ja/Guide/ABC/copy6_of_fo7emv',
                  thumbnail: null,
                  title: '\u3042\u3044\u3046\u3048\u304a',
                },
                {
                  content: '',
                  flag: false,
                  link: '/ja/Guide/ABC/copy7_of_fo7emv',
                  thumbnail: null,
                  title: '\u3042\u3044\u3046\u3048\u304a',
                },
                {
                  content: '',
                  flag: false,
                  link: '/ja/Guide/ABC/copy8_of_fo7emv',
                  thumbnail: null,
                  title: '\u3042\u3044\u3046\u3048\u304a',
                },
                {
                  content: '',
                  flag: false,
                  link: '/ja',
                  thumbnail: null,
                  title: '\u65e5\u672c\u8a9e',
                },
              ],
              meta: { message: 'メタ情報を表示します9' },
            }}
          ></SearchItem>
          {/* ChatItemBanner */}
          <h2>ChatItemBanner</h2>
          <p>バナーアイテムはChatItemBannerを使ってください</p>
          <SearchItem
            item={{
              id: 'X123456',
              type: 'ChatItemBanner',
              from: {
                position: 'none',
                src: '',
                alt: '',
              },
              content:
                'バナーコンテンツが入ります。バナーコンテンツが入ります。バナーコンテンツが入ります。バナーコンテ',
              thumbnail: {
                alt: '',
                caption: '',
                src: '/assets/images/nowprinting/h220.jpg',
              },
              link: 'https://google.com/',
              title: `XXXについて知りたい`,
              meta: { message: 'メタ情報を表示します10' },
            }}
          ></SearchItem>
          {/* ChatItemTour */}
          <h2>ChatItemTour</h2>
          <p>ツアーアイテムはChatItemTourを使ってください</p>
          <SearchItem
            item={{
              id: 'X12345678',
              type: 'ChatItemTour',
              from: {
                position: 'none',
                src: '',
                alt: '',
              },
              content:
                'ツアーコンテンツが入ります。ツアーコンテンツが入ります。ツアーコンテンツが入ります。ツアーコンテンツが入ります。',
              thumbnail: {
                alt: '',
                caption: '',
                src: '/assets/images/nowprinting/h220.jpg',
              },
              link: 'https://google.com/',
              title: `ツアータイトルが入ります`,
              // 追加されたノードです
              tour: {
                title: 'LINE風_タイトルA/14px/行間20',
                list: [
                  { title: 'ページタイトル1', link: 'https://google.com/1' },
                  { title: 'ページタイトル2', link: 'https://google.com/2' },
                ],
              },
              meta: { message: 'メタ情報を表示します11' },
            }}
          ></SearchItem>
          {/* Moreボタン (ChatItemMoreButton) */}
          <h2>Moreボタン (ChatItemMoreButton)</h2>
          <p>ボタンを押したときに実行する関数はcallbackで渡します</p>
          <SearchItem
            item={{
              id: Math.floor(Math.random() * Math.pow(2, 24)).toString(32),
              type: 'ChatItemMoreButton',
              from: null,
              content: 'MORE',
              thumbnail: null,
              link: null,
              title: '',
            }}
            callback={() => {
              console.log('ボタンが押されました');
            }}
          ></SearchItem>
          <hr />
        </div>
      </div>
    </div>
  );
};
