/**
 * OuStoryZView component.
 * @module components/theme/View/OuStoryZView
 */

import _ from 'lodash';

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import NoSSR from 'react-no-ssr';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  StoryZHeader,
  StoryZMenu,
  StoryZThemecopy,
} from './OuStoryZTopFolderView';
import { HelmetSite, OuBody, SNSShare, VisualClear } from '../../index';
import { MediaQueryConfig } from '../../../helpers/GlobalStyle/GlobalStyle';
import { storyZ as storyZAction } from '../../../actions/storyZ/storyZ';
import './OuStoryZView.css';

/**
 * Component to display the OuStoryZView.
 * @function OuStoryZView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const StoryZContents = ({ content, theme }) => {
  const isPC = useMediaQuery({
    query: MediaQueryConfig.PC,
  });
  const titleImage = content.title_image?.download ?? '';
  const thumbnail = content.thumbnail?.download ?? '';
  return (
    <div className={`StoryZContents ${theme}`}>
      <NoSSR>
        {isPC ? (
          titleImage && (
            <div className="storyZContentsHeader">
              <img alt="" src={flattenToAppURL(titleImage)} />
            </div>
          )
        ) : (
          <div className="storyZContentsHeader">
            {thumbnail && <img alt="" src={flattenToAppURL(thumbnail)} />}
            <div className="storyZContentsHeaderInner">
              <h2>{content.title}</h2>
              {content.subtitle && <h3>{content.subtitle}</h3>}
              {content.lead_sentence && (
                <div className="leadSentence">{content.lead_sentence}</div>
              )}
            </div>
          </div>
        )}
      </NoSSR>
      <div className="storyZContentsBody">
        {content.textarea ? (
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            children={content.textarea}
          />
        ) : (
          <OuBody content={content} />
        )}
        <VisualClear />
        <SNSShare url={_.get(content, '@id')} />
      </div>
    </div>
  );
};

const OuStoryZView = ({ content }) => {
  const [navs, rootPath, toc, next, prev, theme] = useSelector((state) => {
    return [
      state?.storyZ?.result?.data?.attributes?.navs ?? [],
      state?.storyZ?.result?.data?.attributes?.root_path ?? '',
      state?.storyZ?.result?.data?.attributes?.table_of_contents,
      state?.storyZ?.result?.data?.attributes?.table_of_contents?.next,
      state?.storyZ?.result?.data?.attributes?.table_of_contents?.prev,
      state?.storyZ?.result?.data?.attributes?.theme,
    ];
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(storyZAction(_.get(content, '@id')));
  }, [content]);
  const prevSrc = prev?.src ?? '';
  const nextSrc = next?.src ?? '';
  const prevUrl = prev?.url ?? '';
  const nextUrl = next?.url ?? '';
  return (
    <div className="OuStoryZView OuStoryZsView">
      <HelmetSite content={content} />
      <section className="mainArea">
        <div className="mainAreaInner">
          <StoryZHeader rootPath={rootPath} />
          <StoryZMenu navs={navs} />
          <StoryZThemecopy theme={theme} />
          <StoryZContents content={content} theme={theme} />
          {toc && (
            <nav className="StoryZTOC">
              <Link
                className="next"
                to={{
                  pathname:
                    next?.portal_type === 'OuStoryZLink'
                      ? nextUrl
                      : flattenToAppURL(nextUrl),
                }}
                target={next?.portal_type === 'OuStoryZLink' ? '_blank' : ''}
              >
                <div>
                  <img alt="" src={flattenToAppURL(nextSrc)} />
                  <p>{next?.title}</p>
                </div>
              </Link>
              <Link
                className="prev"
                to={{
                  pathname:
                    prev?.portal_type === 'OuStoryZLink'
                      ? prevUrl
                      : flattenToAppURL(prevUrl),
                }}
                target={prev?.portal_type === 'OuStoryZLink' ? '_blank' : ''}
              >
                <div>
                  <img alt="" src={flattenToAppURL(prevSrc)} />
                  <p>{prev?.title}</p>
                </div>
              </Link>
              <Link className="toTop" to={rootPath}>
                一覧表示
              </Link>
            </nav>
          )}
        </div>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OuStoryZView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default injectIntl(OuStoryZView);
