/**
 * Media Query Width
 */
export const MediaQueryWidth = {
  SP: 480,
  TABLET: 768,
  PC: 1025,
};

/**
 * Global Style Config
 */
export const StyleConfig = {
  UniversityColor: '#2d287f',
  IconSize: '18px',
};

/**
 * Media Query Config
 * SP: 0-480, TABLET: 481-768, PC: 769-*
 */
export const MediaQueryConfig = {
  PC: `screen and (min-width: ${MediaQueryWidth.TABLET + 1}px)`,
  TABLET: `screen and (min-width: ${
    MediaQueryWidth.SP + 1
  }px) and (max-width: ${MediaQueryWidth.TABLET}px)`,
  SP: `screen and (max-width: ${MediaQueryWidth.SP}px)`,
  PC_AND_TABLET: `screen and (min-width: ${MediaQueryWidth.SP + 1}px)`,
  SP_AND_TABLET: `screen and (max-width: ${MediaQueryWidth.TABLET}px)`,
};

/**
 * Domain
 */
export const OSAKA_U_DOMAIN = 'https://www.osaka-u.ac.jp';

/**
 * FileIcon
 */
const FILE_TYPES = ['doc', 'xls', 'ppt', 'docx', 'xlsx', 'pptx', 'pdf'];

export const getFileType = (url) => {
  const fileType = url
    .split()[0]
    .split('.')
    .pop()
    .trim()
    .replace('/@@download/file', '');
  return FILE_TYPES.includes(fileType) ? fileType : null;
};

/**
 * Event Tags
 */
export function tagIDToColor(eventTags, tagID) {
  for (var item of eventTags) {
    if (item?.id === tagID) {
      return item?.color;
    }
  }
  return '#2d287f';
}

export function tagIDToName(eventTags, locale, tagID) {
  for (var item of eventTags) {
    if (item?.id === tagID) {
      return locale === 'en' ? item?.en : item?.ja;
    }
  }
  return '';
}
