import { GET_TOUR_ITEMS } from '@package/constants/ActionTypes';

export function tourItem(locale, uid, tourId) {
  let query;
  if (tourId) {
    query = `?uid=${uid}&tour-id=${tourId}`;
  } else {
    query = `?uid=${uid}`;
  }
  return {
    type: GET_TOUR_ITEMS,
    request: {
      op: 'get',
      path: `/${locale}/@tour-item${query}`,
    },
  };
}
